import { UserProfile } from "@microsoft/shifts-models-data";
import { ObservableMap } from "mobx";
import { createStore } from "satcheljs";

import { UserProfilesStoreSchema } from "./schema/UserProfilesStore";

/* Register Orchestrators */
import "sh-stores/sh-userprofiles-store/orchestrators/updateUserProfiles";

let UserProfilesStore = createStore<UserProfilesStoreSchema>(
    "userprofilestore",
    {
        userprofiles: new ObservableMap<string, UserProfile>()
    });

export default UserProfilesStore;