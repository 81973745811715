export const DEVICEHEADERNAME = "x-ms-shft-dev";
export const FIRSTPARTYHEADER = "x-ms-shft-fp";
export const BEARERKEY = "Bearer ";
export const AUTHORIZATIONKEY = "Authorization";
export const CONTENTLENGTHHEADER = "Content-Length";
export const CONTENTTYPEHEADER = "Content-Type";
export const CLIENTSESSIONIDHEADER = "ClientSessionId";
export const CLIENTREQUESTIDHEADER = "ClientRequestId";
export const APIVERSIONHEADER = "apiVersion";
export const CLIENTPLATFORMHEADER = "ClientPlatform";
export const CLIENTVERSIONHEADER = "ShiftrClientVersion";
export const SERVICEPROCESSINGTIMEHEADER = "x-ms-shft-spd";
export const CONNECTOR_AUTH_DATA_HEADER = "X-MS-WFMAuthData";
export const ACCEPTLANGUAGEHEADER = "Accept-Language";
export const WWW_AUTHENTICATE_HEADER = "www-authenticate";
export const RETRYAFTERHEADER = "Retry-After";
export const RETRY_COUNT = 3;
export const MONTH = "Month";
export const DAY = "Day";
export const WEEK = "Week";
export const OROPERATORTYPE = "Or";                                                      // Or Operator type for member filtering
export const ANDOPERATORTYPE = "And";                                                    // And Operator type for member filtering
export const DEFAULT_ACTIVITY_THEME = "themeBlue";
export const MAX_ACTIVITY_CODE_LENGTH = 2;
export const MAX_ACTIVITY_TITLE_LENGTH = 50;
export const DEFAULT_OPEN_SHIFT_SLOTS = 1;
export const MINIMUM_OPEN_SHIFT_SLOTS = 1;
export const MAXIMUM_OPEN_SHIFT_SLOTS = 255;
export const DEFAULT_START_OF_WEEK = "Sunday";
export const DEFAULT_TIMEZONE = "Etc/UTC";
export const TAGNAME_ERROR_TIMEOUT = 3500; // In milliseconds
export const SECONDS_IN_DAY = 86400;
export const MILLISECONDS_IN_DAY = SECONDS_IN_DAY * 1000;
// id prefixes
export const TAG_ID_PREFIX = "TAG_";
export const SHIFT_ID_PREFIX = "SHFT_";
export const OPENSHIFT_ID_PREFIX = "OPNSHFT_";
export const MEMBER_ID_PREFIX = "MBER_";
export const NOTE_ID_PREFIX = "NOTE_";
export const TEAM_SETTING_ID_PREFIX = "TMST_";
// cowuertz: Day view specs will eventually be a setting that is stored per team so that teams can customize their day view start and end times
// For now, all teams will use a 24 hour view that we store here
export const DayViewFormatStartHours = 0;
export const DayViewFormatEndHours = 24;
export const DayViewFormatCellInterval = .5;
export const DayViewFormatBoldInterval = 3;
// ShiftEditor default values
export const DEFAULT_START_TIME = 8;
export const DEFAULT_SHIFT_DURATION = 9;
export const MAX_SHIFTNOTES_LENGTH = 500;
export const MAX_NOTES_LENGTH = 300; // chars
export const NOTES_MULTILINE_TEXTFIELD_MAX_ROW = 6;
export const MAX_SHIFT_TITLE_LENGTH = 30;
// cowuertz: subshift/activity minimum duration is currently 10 min, but activities must start at least 15 min apart
export const ACTIVITY_MINIMUM_DURATION_IN_MINUTES = 10;
export const ACTIVITY_MINIMUM_SEPARATION_IN_MINUTES = 15;
export const ACTIVITY_MINIMUM_SEPARATION_IN_HOURS = ACTIVITY_MINIMUM_SEPARATION_IN_MINUTES / 60;
export const DEFAULT_ACTIVITY_DURATION_IN_MINUTES = 15;
export const SHIFT_TIME_NOTIFICATION_IN_MILLISECS = 4000;
export const MIN_TIMEOFF_REASON_LENGTH = 1;
export const MAX_TIMEOFF_REASON_LENGTH = 40;
// Selection cell dummy item key
export const DUMMY_SELECTION_CELL_KEY = "dummy_selection_cell";
export const SHIFT_CELL_CONTEXT_MENU_CALLOUT = "shiftCellContextualMenuCallout";
export const XLSX_MIME_TYPE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
export const FILENAME_MAX_CHAR = 138;
export const URL_EXTENSION = ".url";
export const FILENAME_INVALID_CHAR_REGEX = /[<>*%:&|()#]/gi;
// If user download sample or partial success file multiple time then file name contains ().
// For example: partial-success-data(1). For better usability, creating new regex without ()
export const IMPORT_FILENAME_INVALID_CHAR_REGEX = /[<>*%:&|#]/gi;
export const FILENAME_PROTOCOL_REGEX = /(^\w+:|^)\/\//;
export const FILE_MAX_SIZE = 60 * 1024 * 1024; // 60mb
// The testing team identifier suffix
export const TestingTeamIdSuffix = "00000";
// This string is included in every id created for test data. It is used to clean up test data.
export const TestDataIDConstant = "decaface";
// URL validator
// Source: http://blog.mattheworiordan.com/post/13174566389/url-regular-expression-for-links-with-or-without
// Redirected from : https://stackoverflow.com/a/8234912
// Changed to make protocol optional
export const URL_VALID_REGEX = /((([A-Za-z]{3,9}:(?:\/\/)?)?(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;
export const CUSTOM_FIELD_MAX_LEN = 100;
export const MEDIUM_PANEL_WIDTH = "680px";
export const GENERATED_ALERTS_DISPLAY_LIMIT = 10;
export const TAGNAME_CHAR_LIMIT = 40;
export const SCHEDULE_HEADER_VISIBLE_PERCENT = 0.60;
// Very relaxed email validation logic. Complex validation is done on the server
export const EMAIL_VALID_REGEX = /.+@.+\..+/;
export const PRINT_SCALE_MARGIN_ALLOWANCE = 1;
export const PRINT_SCALE_MARGIN_ALLOWANCE_EDGE = 1;
export const PRINT_NO_SCALE_ROOT_FONT_SIZE = "1vw";
export const PRINT_IE_DEFAULT_FONT_SIZE = "11px";
export const PRINT_SCALE_TO_ONE_PAGE_ROOT_FONT_SIZE = 15;
export const PRINT_PASSES = 2;

export const HEADER_HEIGHT_PX = 0;         // this needs to stay in sync with $header-height in _header.scss
export const COMMAND_BAR_HEIGHT_PX = 40;    // standard height of the Fabric Command Bar
export const GLOBAL_COMMAND_BAR_HEIGHT_PX = 59; // height of the Global Command Bar, this needs to stay in sync with $global-commandbar-height in _header.scss
export const STICKY_Z_INDEX = 100;          // this needs to stay in sync with $stickyZ in _zindex.scss

export const SHOW_DYNAMIC_BUNDLE_SPINNER_DELAY = 1000;  // delay in ms to wait to show spinner when on demand loading a route bundle

export const ORIGIN_QUERYSTRING_PARAMETER_NAME = 'origin'; // parameter name for origin in query string
export const REQUEST_ID_QUERY_PARAMETER_NAME = 'requestId'; // parameter name for request id in query string
export const SHIFT_ID_QUERY_PARAMETER_NAME = 'shiftId'; // parameter name for shift id in query string
export const REQUEST_TYPE_QUERY_PARAMETER_NAME = 'requestType'; // parameter name for request type in query string
export const UI_CULTURE_QUERYSTRING_PARAMETER_NAME: string = "ui-culture";  // parameter name for the language code used by .NET (added by the Teams SDK)
export const USER_ID_QUERYSTRING_PARAMETER_NAME: string = "userObjectId";   // parameter name for the user ID (added by the Teams SDK)
export const TENANT_ID_QUERYSTRING_PARAMETER_NAME: string = "tid";          // parameter name for the tenant ID (added by the Teams SDK)
export const SESSION_ID_QUERYSTRING_PARAMETER_NAME: string = "sessionId";   // parameter name for the Tea,s session ID (added by the Teams SDK)
export const RING_ID_QUERYSTRING_PARAMETER_NAME: string = "ringId";         // parameter name for the ring name (added by the Teams SDK)
export const THEME_QUERYSTRING_PARAMETER_NAME: string = "theme";            // parameter name for the theme (added by the Teams SDK)
export const SUBENTITYID_QUERYSTRING_PARAMETER_NAME: string = "subEntityId"; // parameter name for the subEntityId - part of Teams notification deep link
export const START_QUERYSTRING_PARAMETER_NAME: string = "startDateTime";            // parameter name for scheduler startDate - scheduler deep link
export const END_QUERYSTRING_PARAMETER_NAME: string = "endDateTime";                // parameter name for scheduler endDate - scheduler deep link
export const ACTIVITYFEED_DEEPLINK_SOURCE: string = "activityFeed";                // deeplink source = activityFeed

export const SHELL_VALUE_NAME = 'shell'; // value of origin parameter in case user enters from waffle

export const DEFAULT_SAVE_DELAY = 1000; // default save delay for updates that get auto saved

/**
 * Block instrumenting requests to the following domains:
 *      Aria: aria.microsoft.com
 *      AppInsights: services.visualstudio.com
 */
export const BLOCKED_INSTRUMENTING_DOMAINS_REGEX = /(aria.microsoft.com|services.visualstudio.com)/i;
export const MEMBER_ROW_HEIGHT = 60;  // Height of a row on the schedule in pixels

// Fallback strings for if the StringsStore is not initialized
export const UNKNOWN_ERROR_TITLE_FALLBACK = "It's not you, it's us!";
export const UNKNOWN_ERROR_MESSAGE_FALLBACK = "Something went wrong while loading Shifts. Please try again.";
export const REFRESH_BUTTON_TEXT_FALLBACK = "Refresh";
export const APP_NAME = "Shifts";

// The number of milliseconds to wait before triggering a network refresh of data we already found in the DB
// We add this delay so that we can prioritize other network requests
export const BACKGROUND_DB_DATA_REFERSH_DELAY = 2000;

export enum PrintSize {
    Letter = "Letter",
    Legal = "Legal",
    Tabloid = "Tabloid",
    A3 = "A3",
    A4 = "A4",
    A5 = "A5"
}

export enum ClientTypes {
    Web = "Web",
    Desktop = "Desktop"
}

export enum ClientPlatformTypes {
    Web = "Web",
    TeamsWeb = "TeamsWeb",
    TeamsDesktop = "TeamsDesktop"
}

/**
 * Determines which size to render the ScheduleCell with.
 */
export enum ScheduleCellRenderSize {
    Large = 0,        // currently used for cells in the day view
    Normal = 1,       // currently used for cells the week view
    Small = 2         // currently used for cells the month view
}

export enum PrintOrientation {
    Landscape = "Landscape",
    Portrait = "Portrait"
}

export enum PrintEntryPoint {
    PrintButton = "PrintButton",
    PrintInDropDown = "PrintInDropDown",
    Other = "Other" // Ctrl + P shortcut
}

export enum PrintFAQEntryPoint {
    FRE = "FRE",
    DropDown = "DropDown"
}

export enum NetworkErrorCodes {
    ECONNABORTED = "ECONNABORTED",
    ECONNREFUSED = "ECONNREFUSED",
    ECONNRESET = "ECONNRESET",
    ETIMEDOUT = "ETIMEDOUT",
    EADDRINUSE = "EADDRINUSE",
    ESOCKETTIMEDOUT = "ESOCKETTIMEDOUT",
    EPIPE = "EPIPE",
    EHOSTUNREACH = "EHOSTUNREACH",
    EAI_AGAIN = "EAI_AGAIN"
}

export enum ShiftStoreTypes {
    ShiftStore = "ShiftStore",
    MyShiftStore = "MyShiftStore"
}

export const PRINT_DIMENSIONS: any = {
    [PrintSize.Letter]: {
        height: 279,
        width: 216
    },
    [PrintSize.Legal]: {
        height: 356,
        width: 216
    },
    [PrintSize.Tabloid]: {
        height: 432,
        width: 279
    },
    [PrintSize.A3]: {
        height: 420,
        width: 297
    },
    [PrintSize.A4]: {
        height: 297,
        width: 210
    },
    [PrintSize.A5]: {
        height: 210,
        width: 148
    }
};
export const SHIFT_REQUESTS_PAGE_SIZE = 25;
export const teamIdPrefix = "TEAM_";

export const KRONOS_USERNAME_KEY = "username_DoNotLogInProd";

export const MANAGE_TEAM_URL = "https://teams.microsoft.com/l/team-dashboard/";
export const MANAGE_TEAM_URL_PARAM = "/td.members";
export const FILE_DOWNLOAD_WHITELIST_URL_PATTERNS = [ "flw.teams.microsoft.com", "staffhub.ms", "blob.core.windows.net", "staffhub.office.com", "blob.core.usgovcloudapi.net"];

// Help page deep link. shifts_hp1 indicates the shifts page in the help app
export const HELP_PAGE_URL = "https://teams.microsoft.com/l/entity/5a0e35f9-d3c8-45b6-9dd9-983ab47f1b83/topics?context={\"subEntityId\":\"shifts_hp1\"}";

// Resources which are used to fetch tokens
export const TOKEN_RESOURCES = {
    ShiftsService: "AdalResourceUri",
    TeamsSpaces: "TeamsAADResourceUri",
    ResponseCode: { ok: 200, error: 400, forbidden: 403}
};
// element name for the team picker icon
export const TEAM_PICKER_ICON_ID: string = "team_Picker_Icon_Id";

// Default number of days for a given period of months
export enum MonthInDays {
    twoMonths = 62,
    threeMonths = 94,
    fourMonths = 126,
    fiveMonths = 158,
    sixMonths = 182
}

// default values for visibility permission settings
export const VISIBILITY_DEFAULT_PERMISSIONS = {
    canSeeOtherTeamMemberScheduleData: true,
    canSeeTimeOffDetails: true,
    canSeeShiftDetails: true,
    canSeeUnlimitedPastScheduleData: true,
    pastScheduleDataLimitInDays: MonthInDays.sixMonths
};

export const IMPORT_JOB_COUNT = 10;

// TODO: Move these to AppSettings
export const IMPORT_EXCEL_HELP_PAGE_URL = "https://support.microsoft.com/office/import-a-schedule-from-excel-to-shifts-cf3923e4-c5f5-457f-9c98-d344684dc0b0";
export const CROSS_LOCATION_MARKETPLACE_INFO_PAGE_URL = "https://go.microsoft.com/fwlink/?linkid=2273935";

// In app Feedback constants
export const MicrosoftTeams_WEB_OCV_APPID = 2759;
export const MicrosotTeams_WINDOWS_OCV_APPID = 2758;
export const MicrosoftTeamsClient = "MicrosoftTeams";