import * as moment from "moment";
import * as React from "react";
import AutomationUtil from "sh-application/utility/AutomationUtil";
import classNames from "classnames/bind";
import ConflictUtils from "sh-application/utility/ConflictUtils";
import DateTimeFormatter, { DateTimeFormatType } from "sh-application/utility/DateTimeFormatter";
import KeyboardUtils from "sh-application/utility/KeyboardUtils";
import setGlobalMessages from "sh-application/actions/setGlobalMessages";
import Spinner from "sh-application/components/common/Spinner";
import StringsStore from "sh-strings/store";
import { action } from "satcheljs/lib/legacy";
import { appViewState } from "sh-application/store";
import { calculateRequestConflicts, conflictRequestStore } from "sh-requestconflict-store";
import {
    DefaultButton,
    DirectionalHint,
    FocusTrapCallout,
    Icon,
    IconButton,
    MessageBarType,
    PrimaryButton,
    TextField
    } from "@fluentui/react";
import { getGenericEventPropertiesObject } from "sh-instrumentation";
import { InstrumentationService } from "sh-services";
import { InstrumentScheduleEventFunction } from "sh-application/components/schedules/Schedules";
import { IShiftEntity, IShiftRequestEntity } from "sh-models";
import { MAX_SHIFTNOTES_LENGTH } from "sh-application/../StaffHubConstants";
import { Moment } from "moment";
import { observable } from "mobx";
import { observer } from "mobx-react";
import { ShiftRequestDataService } from "sh-services";
import { ShiftRequestType } from "sh-models";

const styles = require("./TimeOffRequestApprovalCallout.scss");
const conflictStyles = require("../shiftrequest/conflict/RequestConflict.scss");

const MANAGER_MSG_ROW_COUNT: number = 1;

interface TimeOffRequestApprovalCalloutProps {
    shift: IShiftEntity;
    navigateToRequestPage: (initialRequestId: string, shiftId: string, requestType: ShiftRequestType) => void;  // Navigate to Requests page
    onDismissCallback: VoidFunction;    // Call back function when the callout is dismissed
    targetElement: HTMLElement;            // target element for the callout
    instrumentScheduleEvent: InstrumentScheduleEventFunction; // helper function for scheudler events instrumentation
}

/**
 * TimeOff Shift Request Approval callout - to approve/deny timeoff shift request
 */
@observer
export default class TimeOffRequestApprovalCallout extends React.Component<TimeOffRequestApprovalCalloutProps, any> {

    private _timeOffApprovalCalloutStrings: Map<string, string>;
    private _shiftRequest: IShiftRequestEntity;
    private _managerMessage: string;
    private _conflictStrings: Map<string, string>;

    // Observables within component to trigger render when updated
    @observable _errorMessage: string = "";
    @observable _isSaving: boolean = false;
    @observable _isTimeOffRequestDataLoaded: boolean = false;

    constructor(props: TimeOffRequestApprovalCalloutProps) {
        super(props);
        this._shiftRequest = null;
        this._timeOffApprovalCalloutStrings = StringsStore().registeredStringModules.get("timeOffApprovalCallout").strings;
        this._conflictStrings = StringsStore().registeredStringModules.get("conflictManagement").strings;
    }

    componentDidMount() {
        // Load shift request corresponding to the shift
        this.loadTimeOffShiftRequestForShift(this.props.shift);
    }

    componentWillUnmount() {
        this.resetViewState();
    }

    /**
     * Reset View state to initial state
     */
    private resetViewState = () => {
        this.setTimeOffRequestSavingState(false);
        this.setTimeOffRequestLoadedState(false);
        this.setErrorMessage("");
    }

    /**
     * Set Request saving state
     * @param isSaving
     */
    private setTimeOffRequestSavingState = action("setTimeOffRequestSavingState")((isSaving: boolean) => {
        this._isSaving = isSaving;
    });

    /**
     * Set request loading state
     * @param isLoaded
     */
    private setTimeOffRequestLoadedState = action("setTimeOffRequestLoadingState")((isLoaded: boolean) => {
        this._isTimeOffRequestDataLoaded = isLoaded;
    });

    /**
     * Set error message to show on the callout
     * @param errorMsg
     */
    private setErrorMessage = action("setErrorMessage")((errorMsg: string) => {
        this._errorMessage = errorMsg;
    });

    /**
     * Load Shift request associated with ShiftId
     * @param shift
     */
    private loadTimeOffShiftRequestForShift = async (shift: IShiftEntity) => {
        // trigger request conflict calculations
        calculateRequestConflicts(this._shiftRequest, false /* isOpenShiftRequest */ );
        // get the shift request
        const shiftRequests: IShiftRequestEntity[] = await ShiftRequestDataService.getShiftRequestsForShiftId(shift.teamId, shift.id);
        if (shiftRequests.length) {
            // For Time off request, there will only be single shift request that is associated with shift
            this._shiftRequest = shiftRequests[0];
        } else {
            this.setErrorMessage(this._timeOffApprovalCalloutStrings.get("failedToLoadRequestErrorMessage"));
        }

        // indicate that the required data is loaded
        this.setTimeOffRequestLoadedState(true);
        if (this._shiftRequest) {
            // set the read status of shift request asynchronously
            this._shiftRequest = await ShiftRequestDataService.setShiftRequestReadStatus(
                this._shiftRequest.tenantId,
                this._shiftRequest.teamId,
                this._shiftRequest.id,
                this._shiftRequest.lastModifiedTime);
        }
    }

    /**
     * Get TimeOffRequest time range as string to render in the callout
     * @param shift
     */
    private getTimeOffRequestTimeRangeAsString = (shift: IShiftEntity): string => {
        // Example:
        // returns "Apr 2, All day" All day event 1 day;
        // returns "Apr 2 - Apr 3" Two day event all day
        // returns "Apr 2, 2 PM - Apr 3, 12 PM"  Two days event with hour
        // returns "Apr 2, 9 AM - 2:30 PM" One day, partial day
        return DateTimeFormatter.getShiftRequestDateTimeRangeAsString(shift.startTime, shift.endTime);

    }

    /**
     * Returns the string that indicates when the request is sent
     * @param createdTime
     */
    private getTimeOffRequestCreatedTimeAsString = (createdTime: Moment): string => {
        // return "Sent 6/30 7:33 AM";
        return this._timeOffApprovalCalloutStrings.get("requestSentTime").format(DateTimeFormatter.getDateTimeAsString(createdTime, DateTimeFormatType.DateTime_DateNumeric_Time));
    }

    /**
     * Callback invoked when manager updates the message
     * @param newValue
     */
    private onManagerMessageChanged = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        this._managerMessage = newValue;
    }

    /**
     * Callback invoked when callout is dismissed
     */
    private onDismissCallback = () => {
        if (this.props.onDismissCallback) {
            this.props.onDismissCallback();
        }
    }

    /**
     * Approve or Decline time off request. This sends network request to backend
     * @param isAccepting
     */
    private approveDenyTimeOffRequest = async (isAccepting: boolean) => {
        // act on it if all data is loaded
        if (this._isTimeOffRequestDataLoaded && this._shiftRequest) {
            // mark progress indicator
            this.setTimeOffRequestSavingState(true);
            try {
                // send the request to approve/decline
                let shiftRequestResponseEntity = await ShiftRequestDataService.approveDeclineTimeOffRequest(this._shiftRequest.tenantId, this._shiftRequest.teamId, this._shiftRequest.id, this._shiftRequest.eTag, isAccepting, this._managerMessage);
                this._shiftRequest = shiftRequestResponseEntity.shiftRequest;
                if (shiftRequestResponseEntity.alerts && shiftRequestResponseEntity.alerts.length > 0) {
                    // display rule violations
                    setGlobalMessages(appViewState().globalMessageViewState, shiftRequestResponseEntity.alerts, MessageBarType.error, null /* action button title */, null /* action button callback */, false /* auto dismiss */, true /* is multiline */);
                }
            } catch (e) {
                this.setErrorMessage(this._timeOffApprovalCalloutStrings.get("approveDenyErrorMessage"));
            }
             // complete progress
             this.setTimeOffRequestSavingState(false);
             if (!this._errorMessage) {
                 // dismiss the callback if everything is good
                this.onDismissCallback();
             }

             this.instrumentApproveDenyAction(isAccepting);
        }
    }

    /**
     * Instrument Approve Deny user action
     * @param isAccepting
     */
    instrumentApproveDenyAction = (isAccepting: boolean) => {
        if (this.props.instrumentScheduleEvent) {
            this.props.instrumentScheduleEvent(InstrumentationService.events.Requests, [
                getGenericEventPropertiesObject(InstrumentationService.properties.EventType, InstrumentationService.values.RequestActionClicked),
                getGenericEventPropertiesObject(InstrumentationService.properties.RequestType, InstrumentationService.ShiftRequestTypes.TimeOff),
                getGenericEventPropertiesObject(InstrumentationService.properties.ActedOnBy, InstrumentationService.ShiftRequestUsertype.Manager),
                getGenericEventPropertiesObject(InstrumentationService.properties.ActionTaken, isAccepting ? InstrumentationService.ShiftRequestActionTaken.Approve :  InstrumentationService.ShiftRequestActionTaken.Deny),
                getGenericEventPropertiesObject(InstrumentationService.properties.WasReasonProvided, this._managerMessage && this._managerMessage.length > 0),
                getGenericEventPropertiesObject(InstrumentationService.properties.TimeToRespondSeconds, this._shiftRequest && this._shiftRequest.creationTime ? moment().diff(this._shiftRequest.creationTime, "seconds") : "")]);
        }
    }

    /**
     * Callback when user clicks Approve button
     */
    private onApproveShiftRequest = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        this.approveDenyTimeOffRequest(true);
    }

    /**
     * Callback when user clicks Deny button
     * @param e
     */
    private onDenyShiftRequest = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        this.approveDenyTimeOffRequest(false);
    }

    /* Redirect to Requests call out along with the request id needed to scroll to this request */
    expandCallOut = () => {
        if (this.props.navigateToRequestPage && this._shiftRequest && this._shiftRequest.id) {
            this.props.navigateToRequestPage(this._shiftRequest.id, null /*shiftId*/, null /*shiftRequestTime*/);
        }
    }

    /* Callback for expand callout on key press */
    onKeyDownOnexpandCallout = (e: React.KeyboardEvent<HTMLElement>) => {
        if (KeyboardUtils.isActionKeyPressed(e)) {
            this.expandCallOut();
        }
    }

    render() {
        const shift = this.props.shift;
        const conflictEntities = ConflictUtils.getRequestConflictEntities(shift.id, conflictRequestStore().requestShiftIdToConflictsMap);
        const showShiftConflict = conflictEntities && conflictEntities.length > 0;

        return <FocusTrapCallout
                    focusTrapProps={ {
                        isClickableOutsideFocusTrap: true,
                        elementToFocusOnDismiss: this.props.targetElement
                    } }
                    preventDismissOnScroll={ true }
                    onDismiss={ this.onDismissCallback }
                    target={ this.props.targetElement }
                    setInitialFocus={ true }
                    doNotLayer={ true }
                    directionalHint= { DirectionalHint.rightCenter }
                    className={ styles.timeoffRequestCallout }>
                        <div className={ styles.expandIconContainer } >
                            <IconButton
                                iconProps={ { styles: { root: styles.panelCloseButtonIcon }, iconName: "teams-icons-expand" } }
                                onClick={ this.expandCallOut }
                                onKeyDown={ this.onKeyDownOnexpandCallout }
                                title={ this._timeOffApprovalCalloutStrings.get("navigateToRequestsPageLabel") }
                                className={ styles.expandIcon } />
                        </div>
                        <div className={ styles.timeOffRequestContainer }>

                            {/* <IconButton
                                onClick={ this.expandCallOut }
                                onKeyDown={ this.onKeyDownOnexpandCallout }
                                iconProps={ { styles: { root: styles.expandIcon }, iconName: "teams-icons-expand" } }
                                title={ this._timeOffApprovalCalloutStrings.get("navigateToRequestsPageLabel") }>
                            </IconButton> */}
                            <>
                                <div className={ styles.calloutTitle } >{ this._timeOffApprovalCalloutStrings.get("requestTitle") }</div>
                                <div className={ styles.timeOffRequestTitle } >{ this.getTimeOffRequestTimeRangeAsString(shift) } </div>
                            </>
                            <div>
                                <div className={ styles.timeOffRequestSentTime }>
                                    {
                                        /* If time off request is not loaded, show a spinner till its loaded */
                                        !!this._isTimeOffRequestDataLoaded
                                        ?
                                            null
                                        :
                                            <Spinner/>
                                    }
                                </div>
                                <div className={ styles.timeOffRequestSenderMessage }>
                                    {
                                        !!this._isTimeOffRequestDataLoaded &&
                                            <div>{ this._shiftRequest?.senderMessage }</div>
                                    }
                                </div>
                                {
                                    showShiftConflict &&
                                        <div className={ conflictStyles.requestConflictContainer }>
                                            <div className={ conflictStyles.requestConflictMessage } data-automation-id={ AutomationUtil.getAutomationId("conflictCallout", "QAIDRequestConflictMesssage") }>
                                                <Icon className={ classNames(conflictStyles.conflictIcon, conflictStyles.red) } iconName={ "teams-conflict-icon" }></Icon>
                                                { this._conflictStrings.get("requestShiftConflict") }
                                            </div>
                                        </div>
                                }
                                <div className={ styles.managerMessage }>
                                    <TextField
                                        className={ styles.notesField }
                                        placeholder={ this._timeOffApprovalCalloutStrings.get("requestManagerMessagePlaceholder") }
                                        disabled={ !this._isTimeOffRequestDataLoaded || this._isSaving }
                                        maxLength={ MAX_SHIFTNOTES_LENGTH }
                                        defaultValue={ this._managerMessage || "" }
                                        onChange={ this.onManagerMessageChanged }
                                        resizable={ false }
                                        multiline
                                        rows={ MANAGER_MSG_ROW_COUNT } />
                                </div>
                            </div>
                            {
                                this._errorMessage &&
                                <div className={ classNames(styles.approveDenyError, { error: !!this._errorMessage }) }>
                                    { this._errorMessage }
                                </div>
                            }
                            <div className={ styles.btnContainer }>
                                <DefaultButton
                                    className={ styles.approveDenyButton }
                                    disabled={ !this._isTimeOffRequestDataLoaded || this._isSaving || !this._shiftRequest}
                                    onClick={ this.onDenyShiftRequest }
                                    >
                                        { this._timeOffApprovalCalloutStrings.get("requestDenyButtonLabel") }
                                </DefaultButton>
                                <PrimaryButton
                                    className={ styles.approveDenyButton }
                                    disabled={ !this._isTimeOffRequestDataLoaded || this._isSaving || !this._shiftRequest}
                                    onClick={ this.onApproveShiftRequest }
                                    >
                                    { this._timeOffApprovalCalloutStrings.get("requestApproveButtonLabel") }
                                </PrimaryButton>
                            </div>
                        </div>
                </FocusTrapCallout>;
    }

}