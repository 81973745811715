/**
 * Instrumentation Attribute Values
 */
export const instrumentationValues = {
    Custom: "Custom",
    LoginSuccessful: "LoginSuccessful",
    OrgIDSignIn: "OrgIDSignIn",
    SignOutLink: "SignOutLink",
    AccessBlockedOkButton: "AccessBlockedOkButton",
    Error: "Error",
    TagNotFound: "TagNotFound",
    MemberNotFound: "MemberNotFound",
    MemberNotPartOfGroup: "MemberNotPartOfGroup",
    DataNotLoadedError: "DataNotLoadedError",
    PrintToOnePageNotAllowedError: "PrintToOnePageNotAllowedError",
    SomethingWentWrongError: "SomethingWentWrongError",
    SilentNotAuthorized: "SilentNotAuthorized",
    Starting: "Starting",
    Started: "Started",
    FailedMaxRetries: "FailedMaxRetries",
    ShiftContextMenu: "ShiftContextMenu",
    MemberContextMenu: "MemberContextMenu",
    ProfileContextMenu: "ProfileContextMenu",
    ShiftTypeWorking: "ShiftTypeWorking",
    ShiftTypeAbsence: "ShiftTypeAbsence",
    TimeOffReasonOther: "TimeOffReasonOther",
    TimeOffReasonVacation: "TimeOffReasonVacation",
    TimeOffReasonSickDay: "TimeOffReasonSickDay",
    TimeOffReasonJuryDuty: "TimeOffReasonJuryDuty",
    TimeOffReasonParentalLeave: "TimeOffReasonParentalLeave",
    TimeOffReasonUnpaid: "TimeOffReasonUnpaid",
    TimeOffReasonOff: "TimeOffReasonOff",
    TimeOffReasonCustom: "TimeOffReasonCustom",
    DayView: "DayView",
    WeekView: "WeekView",
    MonthView: "MonthView",
    ShiftItemSelected: "ShiftItemSelected",
    CreateNewShift: "CreateNewShift",
    EditShift: "EditShift",
    EditTimeOff: "EditTimeOff",
    EscKey: "EscKey",
    EnterKey: "EnterKey",
    Click: "Click",
    DoubleClick: "DoubleClick",
    Resize: "Resize",
    Member: "Member",
    Shift: "Shift",
    TimeOff: "TimeOff",
    TimeOffRequest: "TimeOffRequest",
    Empty: "Empty",
    Other: "Other",
    Email: "Email",
    Phone: "Phone",
    Both: "Both",
    None: "None",
    Mouse: "Mouse",
    Keyboard: "Keyboard",
    AutoCompleteExistingTeamMember: "AutoCompleteExistingTeamMember",
    AutoCompleteGalUser: "AutoCompleteGalUser",
    ManualEntry: "AutoCompleteManualEntry",
    OtherItemSelected: "OtherItemSelected",
    SaveClicked: "SaveClicked",
    DeleteClicked: "DeleteClicked",
    AddMemberClicked: "AddMemberClicked",
    CreateNewTeam: "CreateNewTeam",
    SwitchTeam: "SwitchTeam",
    GoTo: "GoTo",
    Today: "Today",
    PreviousView: "PreviousView",
    NextView: "NextView",
    PreviousSchedule: "PreviousSchedule",
    NextSchedule: "NextSchedule",
    DeleteIndex: "DeleteIndex",
    SelectIndex: "SelectIndex",
    AddShift: "AddShift",
    Copy: "Copy",
    Paste: "Paste",
    AddTimeOff: "AddTimeOff",
    Delete: "Delete",
    AssignPeople: "AssignPeople",
    MoveToOpenShifts: "MoveToOpenShifts",
    ReportUnavailable: "ReportUnavailable",
    TimeOutRetry: "TimeOutRetry",
    LookupMenu: "LookupMenu",
    ShiftAndActivitiesPanel: "ShiftAndActivitiesPanel",
    CopyPaste: "CopyPaste",
    DeleteKey: "DeleteKey",
    ShiftDeleted: "ShiftDeleted",
    NewTeam: "New",
    ExistingTeam: "Existing",
    Add: "Add",
    Edit: "Edit",
    SetupStaffhub: "SetupStaffhub",
    ProvisionStaffhub: "ProvisionStaffhub",
    LicenseStaffhub: "LicenseStaffhub",
    AddEmployeesStaffhub: "AddEmployeesStaffhub",
    InvalidField: "InvalidField",
    LearnMoreStaffhub: "LearnMoreStaffhub",
    ShareIdea: "ShareIdea",
    ReportProblem: "ReportProblem",
    CreateIssue: "CreateIssue",
    SuggestedTopic: "SuggestedTopic",
    Panel: "Panel",
    ContextMenu: "ContextMenu",
    NoShifts: "NoShifts",
    ShiftsExisting: "ShiftsExisting",
    Unknown: "Unknown",
    BreakDeleted: "BreakDeleted",
    BreakToActivityChange: "BreakToActivityChange",
    ActivityToBreakChange: "ActivityToBreakChange",
    EntryPointPressed: "EntryPointPressed",
    FREDoNotShowAgain: "FREDoNotShowAgain",
    FREShown: "FREShown",
    FAQPressed: "FAQPressed",
    PrintGenerated: "PrintGenerated",
    DropDownOpened: "DropDownOpened",
    BeginSetupClicked: "BeginSetupClicked",
    TeamNameContinueClicked: "TeamNameContinueClicked",
    TeamNameBackClicked: "TeamNameBackClicked",
    TeamTimeZoneCreateTeamClicked: "TeamTimeZoneCreateTeamClicked",
    TeamTimeZoneBackClicked: "TeamTimeZoneBackClicked",
    CustomFieldsContinueClicked: "CustomFieldsContinueClicked",
    CustomFieldsBackClicked: "CustomFieldsBackClicked",
    O365GroupContinueClicked: "O365GroupContinueClicked",
    New: "New",
    O365: "O365",
    Success: "Success",
    AuthError: "AuthError",
    ServiceError: "ServiceError",
    OtherError: "OtherError",
    Availability: "Availability",
    StaffPer30: "StaffPer30",
    StaffPerInterval: "StaffPerInterval",
    GroupedView: "GroupedView",
    CorporateAnnouncementsAllowToggleClicked: "CorporateAnnouncements_AllowToggleClicked",
    CorporateAnnouncementsSendersWhitelistEdited: "CorporateAnnouncements_SendersWhitelistEdited",
    OpenShifts: "OpenShifts",
    ShowProfilePictures: "ShowProfilePictures",
    Waffle: "Waffle",
    A_Task: "A_Task",
    A_Sched: "A_Sched",
    A_Share: "A_Share",
    I_Task: "I_Task",
    I_Sched: "I_Sched",
    I_Share: "I_Share",
    Letter: "Letter",
    Legal: "Legal",
    Tabloid: "Tabloid",
    A3: "A3",
    A4: "A4",
    A5: "A5",
    Landscape: "Landscape",
    Portrait: "Portrait",
    a_task: "a_task",
    a_sched: "a_sched",
    a_share: "a_share",
    i_task: "i_task",
    i_sched: "i_sched",
    i_share: "i_share",
    AccessibleCompleted: "Accessible_Completed",
    RequestsEntryPointClicked: "RequestsEntryPointClicked",
    Flyout: "Flyout",
    RequestDropDownItemClicked: "RequestDropDownItemClicked",
    ViewAll: "ViewAll",
    RequestDetailsClicked: "RequestDetailsClicked",
    RequestActionClicked: "RequestActionClicked",
    RequestRefreshClicked: "RequestRefreshClicked",
    RequestNewRequestClicked: "RequestNewRequestClicked",
    RequestTypeClicked: "RequestTypeClicked",
    RequestSendRequestClicked: "RequestSendRequestClicked",
    RequestNewRequestCancelClicked: "RequestNewRequestCancelClicked",
    TeamPickerEntryPointClicked: "TeamPickerEntryPointClicked",
    ImportExcelEntryPointClicked: "ImportExcelEntryPointClicked",
    ImportExcelUploadClicked: "ImportExcelUploadClicked",
    ImportExcelCloseClicked: "ImportExcelCloseClicked",
    ImportExcelUploadFileClicked: "ImportExcelUploadFilelicked",
    DownloadTemplateLinkClicked: "DownloadTemplateLinkClicked",
    DownloadErrorFileClicked: "DownloadErrorFileClicked",
    ImportExcelDataFileLoaded: "ImportExcelDataFileLoaded",
    ImportJobsLoaded: "ImportJobsLoaded",
    ImportExcelCompleted: "ImportExcelCompleted",
    ChooseScheduleClicked: "ChooseScheduleClicked",
    CreateNewScheduleClicked: "CreateNewScheduleClicked",
    ScheduleTeamPickerViewed: "ScheduleTeamPickerViewed",
    CreateNewScheduleButtonClicked: "CreateNewScheduleButtonClicked",
    ScheduleTeamSelectedClicked: "ScheduleTeamSelectedClicked",
    MSTeamPickerViewed: "MSTeamPickerViewed",
    MSTeamSelectedClicked: "MSTeamSelectedClicked",
    TimeZoneSetupViewed: "TimeZoneSetupViewed",
    ScheduleTeamProvisioned: "ScheduleTeamProvisioned",
    ConfirmTimeZone: "ConfirmTimeZone",
    StartTourSetupViewed: "StartTourSetupViewed",
    StartTourClicked: "StartTourClicked",
    SkipTourClicked: "SkipTourClicked",
    Slots: "Slots",
    True: "true",
    False: "false",
    Null: "null",
    FLW: "FLW",
    HeaderHover: "HeaderHover",
    ScrollToTopButtonClicked: "ScrollToTopButtonClicked",
    CopyShiftActivitiesToggleClicked: "CopyShiftActivitiesToggleClicked",
    EmployeeViewType: "EmployeeViewType",
    ClockInClicked: "ClockInClicked",
    ClockInConfirmed: "ClockInConfirmed",
    ClockInCanceled: "ClockInCanceled",
    ClockOutClicked: "ClockOutClicked",
    ClockOutConfirmed: "ClockOutConfirmed",
    ClockOutCanceled: "ClockOutCanceled",
    StartBreakClicked: "StartBreakClicked",
    EndBreakClicked: "EndBreakClicked",
    StartBreakConfirmed: "StartBreakConfirmed",
    StartBreakCanceled: "StartBreakCanceled",
    EndBreakConfirmed: "EndBreakConfirmed",
    EndBreakCanceled: "EndBreakCanceled",
    ExpandMenuClicked: "ExpandMenuClicked",
    ExportSheetClicked: "ExportSheetClicked",
    SetupLaunched: "SetupLaunched",
    ClockInFailed: "ClockInFailed",
    ClockOutFailed: "ClockOutFailed",
    StartBreakFailed: "StartBreakFailed",
    EndBreakFailed: "EndBreakFailed",
    TimeClockSyncNotificationRecieved: "TimeClockSyncNotificationRecieved",
    TimeClockCreatedOrUpdated: "TimeClockCreatedOrUpdated",
    ProductionEnvironment: "Production",
    DevEnvironment: "Dev",
    // FLWs privacy settings
    CanSeeTimeOffDetails: "CanSeeTimeOffDetails",
    CanSeeUnlimitedPastScheduleData: "CanSeeUnlimitedPastScheduleData",
    PastScheduleDataLimitInDays: "PastScheduleDataLimitInDays",
    CanSeeShiftDetails: "CanSeeShiftDetails",
    // CLM settings
    ClmShiftsEnabled: "ClmShiftsEnabled",
    ClmManagerApprovalRequired: "ClmManagerApprovalRequired",
    // Open shifts experience
    OtherOpenShiftsTabClicked: "OtherOpenShiftsTabClicked",
    OpenShiftRequested: "OpenShiftRequested",
    OpenShiftsLoaded: "OpenShiftsLoaded",
    OpenShiftClicked: "OpenShiftClicked",
    OpenShiftRequestClicked: "OpenShiftRequestClicked",
    TeamClicked: "TeamClicked",
    TeamsLoaded: "TeamsLoaded",
    UsersProfilesLoaded: "UsersProfilesLoaded",
    FeedbackEntryDismissed: "FeedbackEntryDismissed",
    FeedbackEntrySucceeded: "FeedbackEntrySucceeded",
    FeedbackEntryErrored: "FeedbackEntryErrored",
    FeedbackInitializationComplete: "FeedbackInitializationComplete",
    // Team settings experience
    CrossLocationPrerequisitesLoaded: "CrossLocationPrerequisitesLoaded"
};
