import { orchestrator } from "satcheljs";
import { setIsSavingInStore } from "../store";
import { ShiftRequestDataService } from "sh-services";
import { setGlobalMessageFromException } from 'sh-application';
import requestOpenShift from "../actions/requestOpenShift";

export const requestOpenShiftOrchestrator = orchestrator(requestOpenShift, async actionMessage => {
    const { openShift, onFinish } = actionMessage;
        if (openShift) {
            let shiftRequest;
            try {
                setIsSavingInStore(true);
                shiftRequest = await ShiftRequestDataService.requestOpenShift(openShift);

            } catch (error) {
                setGlobalMessageFromException(error, false /* AutoDismiss */);
            } finally {
                setIsSavingInStore(false);
                if (onFinish) {
                    onFinish(shiftRequest);
                }
            }
        }
    }
);
