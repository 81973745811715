import { IContextualMenuItemStyleProps } from "@fluentui/react";
import { ITagEntity } from "sh-models";
import { MemberMapper } from "sh-models/member/mappers/MemberMapper";
import { GraphService } from "sh-services";
import { TagStore, setTagPeopleCount } from "sh-tag-store";

import { TeamPermissionsStore } from "../../../../sh-stores/sh-team-permissions-store";
import { PeopleCountProps } from "../../common/peopleCount/PeopleCountProps";
import schedulesViewStateStore from "../../schedules/lib/store/store";

import { TagHeaderProps } from "./TagHeaderProps";

import { ScheduleGroupManager } from "sh-application/../../managers/scheduleGroup/ScheduleGroupManager";
import { MemberShiftsDataGroup } from "../ScheduleData";

/**
 * Based on Schedule Group team permission enable or disable Delete Schedule Group menu item.
 * @param props TagHeaderProps
 * @returns MenuStyleProps or undefined.
 */
export const getDeleteGroupMenuProps = (props: TagHeaderProps): Partial<IContextualMenuItemStyleProps> => {
    if (!TeamPermissionsStore().permission) {
        return { disabled: props.disableDeleteGroup};
    }

    const enableScheduleGroupMenuItem = TeamPermissionsStore().permission.canModifySchedulingGroups;

    const enableAddGroupButtonProps: Partial<IContextualMenuItemStyleProps> = {
        disabled: !enableScheduleGroupMenuItem || props.disableDeleteGroup
    };
    return enableAddGroupButtonProps;
};

/**
 * Gets the 'PeopleCount' component properties.
 * If filters are applied, it will be reflected on people count.
 * @param inputTag The tag model (aka schedule group).
 * @returns The 'PeopleCount' component properties or undefined if given 'tag' is undefined or unknown.
 */
export const getPeopleCountProps = (inputTag?: ITagEntity, groupData?: MemberShiftsDataGroup): PeopleCountProps | undefined => {
    const { tagIdToPeopleCount, tags } = TagStore();
    const tag = tags.get(inputTag?.id);

    if (tag && !tagIdToPeopleCount.has(tag.id)) {
        const { filteredMembers } = schedulesViewStateStore();
        // TODO: Use callback instead of inlined manager.
        const manager = new ScheduleGroupManager(GraphService, new MemberMapper());

        setTagPeopleCount(tag.id, manager.getPeopleCount(tag, filteredMembers, groupData));
    }

    if (tagIdToPeopleCount.has(tag?.id)) {
        return { count: tagIdToPeopleCount.get(tag.id) };
    }
};

/**
 * Based on Schedule Group team permission enable or disable Rename Schedule Group menu item.
 * @returns MenuStyleProps or undefined.
 */
export const getRenameGroupMenuProps = (): Partial<IContextualMenuItemStyleProps> | undefined => {
    if (!TeamPermissionsStore().permission) {
        return undefined;
    }

    const enableScheduleGroupMenuItem = TeamPermissionsStore().permission.canModifySchedulingGroups;

    const enableAddGroupButtonProps: Partial<IContextualMenuItemStyleProps> = {
        disabled: !enableScheduleGroupMenuItem
    };
    return enableAddGroupButtonProps;
};
