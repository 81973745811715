import ShiftRequestUtils from "sh-application/utility/ShiftRequestUtils";
import { IGroupedOpenShiftRequestEntity, IShiftRequestEntity, ShiftRequestTypes } from "sh-models";
import { mutatorAction } from "satcheljs";
import { shiftRequestStore } from "../";

export default mutatorAction('DELETE_OPEN_SHIFTREQUEST_FROM_GROUP', function deleteOpenShiftRequestFromGroupedItem(shiftRequest: IShiftRequestEntity) {

    if (shiftRequest && (shiftRequest.requestType === ShiftRequestTypes.Open || shiftRequest.requestType === ShiftRequestTypes.CrossLocationOpen)) {
        const groupedOpenShiftRequestId: string = ShiftRequestUtils.getGroupedEntityIdFromShiftIdAndState(shiftRequest.shiftId, shiftRequest.state);

        if (shiftRequestStore().groupedOpenShiftRequests.has(groupedOpenShiftRequestId) ) {
            const groupedOpenShiftRequest: IGroupedOpenShiftRequestEntity = shiftRequestStore().groupedOpenShiftRequests.get(groupedOpenShiftRequestId);
            if (groupedOpenShiftRequest) {
                // remove the item from the group
                groupedOpenShiftRequest.removeShiftRequest(shiftRequest.id);

                // If there are no more items on the group, then remove it from the store
                if (groupedOpenShiftRequest.shiftRequests.size === 0) {
                    shiftRequestStore().groupedOpenShiftRequests.delete(groupedOpenShiftRequestId);
                }
            }
        }
    }
});
