/**
 * Custom Dimensions
 */
export const instrumentationCustomDimensionProperties = {
    IsManager: "DataBag.IsManager",
    TeamId: "Team.Id",
    TeamGroupId: "TeamInfo.ADGroupId",
    TeamScheduleId: "Team.ScheduleId",
    CurrentAppState: "CurrentAppState",
    TeamSize: "Team.Size",
    TenantId: "TenantId",
    UserId: "UserId",
    User_Id: "User_Id",
    MemberId: "MemberId",
    UserLocale: "UserLocale",
    FlightSignature: "FlightSignature",
    IsExternalTeam: "IsExternalTeam",
    InstallId: "InstallId",
    ClientSessionId: "ClientSessionId",
    ReferralSource: "ReferralSource",
    InstrumentationSource: "InstrumentationSource",
    AppInfo_SessionId: "AppInfo.SessionId",
    AppInfo_ClientType: "AppInfo.ClientType",
    AppInfo_BuildType: "AppInfo.BuildType",
    AppInfo_Environment: "AppInfo.Environment",

    /**
     * The application host name.
     * This can be used to differentiate between Teams v1 and Teams v2.
     * @example "TeamsModern"
     */
    AppInfo_HostName: "AppInfo.HostName",

    RingId: "RingId",
    UserInfo_RolloutIds: "UserInfo.RolloutIds",
    UserInfo_Etag: "UserInfo.ETag",
    AppInfo_ExpIds: "AppInfo.ExpIds",
    IsProgressiveRendering: "IsProgressiveRendering",
    WorkforceIntegrationEnabled: "WorkforceIntegrationEnabled",
    CrossLocationShiftsEnabled: "CrossLocationShiftsEnabled"
};
