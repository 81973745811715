import DateTimeFormatter, { DateTimeFormatType } from "sh-application/utility/DateTimeFormatter";
import DateUtils from "sh-application/utility/DateUtils";
import MemberDataService from "sh-services/dataservice/MemberDataService";
import MemberUtils from "sh-application/utility/MemberUtils";
import StringsStore from "sh-strings/store";
import StringUtils from "sh-application/utility/StringUtils";
import {
    DataProcessingHelpers,
    ECSConfigKey,
    ECSConfigService,
    FlightSettingsService
    } from "sh-services";
import {
    ExtraTeamSettingsKeys,
    IBaseShiftEntity,
    IBaseShiftRequestEntity,
    IGroupedOpenShiftRequestEntity,
    IMemberEntity,
    IShiftRequestEntity,
    IShiftRequestMemberReadResponse,
    ITeamInfoEntity,
    RequestTypes,
    ShiftRequestState,
    ShiftRequestStates,
    ShiftRequestType,
    ShiftRequestTypes,
    IBaseConflictEntity
    } from "sh-models";
import { IndividualOpenShiftRequestDetails } from "sh-application/components/shiftrequest/groupedrequestdetails/store/schema/GroupedRequestDetailsStoreSchema";
import { MobxUtils } from "sh-application";
import { Moment } from "moment";
import { TeamStore } from "sh-team-store";
import { trace } from "owa-trace";

/**
 * Enum for shift request status
 * It groups the states into these values to help in displaying
 * The value is a string as it is also used in grouping open shift requests based on this status
 */
export enum ShiftRequestStatus {
    Unknown = "Unknown",
    InProgress = "InProgress",
    Approved = "Approved",
    Declined = "Declined",
    Cancelled = "Cancelled"
}

/**
 * Enum for the Shift request status messages.  Used for choosing color
 */
export enum ShiftRequestStatusState {
    Approved,
    Declined,
    None
}

/**
 * Common Props type used for ListItem components
 */
export interface ShiftRequestListItemProps {
    shiftRequest: IBaseShiftRequestEntity;
    isSelected?: boolean;
    onRequestSelectCallback: (selectedRequestId: string) => void;
    setItemRef?: (el: HTMLElement) => void;
}

/**
 * Common Props type used for RequestDetails Components
 */
export interface ShiftRequestDetailsProps {
    shiftRequest: IShiftRequestEntity;
    isSaving?: boolean;
    onActionTaken?: VoidFunction;
    afterCancelOpenShiftRequest?: VoidFunction;
    onCancelShiftRequest?: VoidFunction;
    onApproveDenyShiftRequest?: (isAccepting: boolean) => void;
    conflictEntities?: IBaseConflictEntity[];
    receiverConflictEntities?: IBaseConflictEntity[];
}

/**
 * Utilities for Shift Requests
 */
export default class ShiftRequestUtils {

    private static _strings: Map<string, string>;
    private static _commonStrings: Map<string, string>;

    private static getStrings(): Map<string, string> {
        if (!ShiftRequestUtils._strings) {
            ShiftRequestUtils._strings = StringsStore().registeredStringModules.get("shiftRequests").strings;
        }

        return ShiftRequestUtils._strings;
    }

    private static getCommonStrings(): Map<string, string> {
        if (!ShiftRequestUtils._commonStrings) {
            ShiftRequestUtils._commonStrings = StringsStore().registeredStringModules.get("common").strings;
        }

        return ShiftRequestUtils._commonStrings;
    }

    /**
     * Returns the shift request status
     */
    public static getShiftRequestStatus(shiftRequest: IBaseShiftRequestEntity): ShiftRequestStatus {
        if (shiftRequest) {
            return this.getRequestStatusFromState(shiftRequest.state);
        } else {
            return ShiftRequestStatus.Unknown;
        }
    }

    /**
     * Gets the sender id of the first request within the grouped open shift request
     * @param groupedOpenShiftRequest
     */
    public static getFirstSenderIdFromGroupedRequest(groupedOpenShiftRequest: IGroupedOpenShiftRequestEntity): string {
        let id: string = "";
        if (groupedOpenShiftRequest && groupedOpenShiftRequest.shiftRequests && groupedOpenShiftRequest.shiftRequests.size) {
            const firstShiftRequest = DataProcessingHelpers.getArrayFromMap(groupedOpenShiftRequest.shiftRequests).sort(DateUtils.startTimeComparator)[0];
            if (firstShiftRequest) {
                id = firstShiftRequest.senderMemberId;
            }
        }

        return id;
    }

    /**
     * Returns the shift request status for the state
     * @param shiftRequestState
     */
    private static getRequestStatusFromState(shiftRequestState: ShiftRequestState): ShiftRequestStatus {
        let status: ShiftRequestStatus = ShiftRequestStatus.Unknown;

        if (shiftRequestState === ShiftRequestStates.ManagerDeclined
            || shiftRequestState == ShiftRequestStates.HomeManagerDeclined
            || shiftRequestState === ShiftRequestStates.ReceiverDeclined
            || shiftRequestState === ShiftRequestStates.AutoDeclined
            || shiftRequestState === ShiftRequestStates.AnotherApproved) {
                status = ShiftRequestStatus.Declined;
        } else if (shiftRequestState === ShiftRequestStates.SenderDeclined) {
                status = ShiftRequestStatus.Cancelled;
        } else if (shiftRequestState === ShiftRequestStates.ManagerApproved) {
                status = ShiftRequestStatus.Approved;
        } else if (shiftRequestState === ShiftRequestStates.WaitingOnManager
            || shiftRequestState == ShiftRequestStates.WaitingOnHomeManager
            || shiftRequestState === ShiftRequestStates.WaitingOnReceiver) {
                status = ShiftRequestStatus.InProgress;
        }
        return status;
    }

    /**
     * Get the time that the user sent the shift request for display purposes.
     * ie: Sent: "2:30 PM"
     * @param shiftRequest
     */
    public static getSenderStatusForDisplay(shiftRequest: IShiftRequestEntity): string {
        let status: string = "";
        const strings: Map<string, string> = ShiftRequestUtils.getStrings();

        if (shiftRequest.state === ShiftRequestStates.SenderDeclined) {
            const cancelledTimeForDisplay: string = DateTimeFormatter.getDisplayTimeInRelativeFormat(shiftRequest.lastModifiedTime);
            status = strings.get("cancelledStatusTime").format(cancelledTimeForDisplay);
        } else {
            const sentTimeForDisplay: string = DateTimeFormatter.getDisplayTimeInRelativeFormat(shiftRequest.creationTime);
            status = strings.get("sentTimeLabel").format(sentTimeForDisplay);
        }
        return status;
    }

    /**
     * Get the time that the user sent the shift request for display purposes.
     * ie: "2:30 PM"
     * @param shiftRequest
     */
    public static getSenderLastStatusTimeForDisplay(shiftRequest: IShiftRequestEntity): string {
        let lastStatusTime: string = "";

        if (shiftRequest.state === ShiftRequestStates.SenderDeclined) {
            lastStatusTime = DateTimeFormatter.getDisplayTimeInRelativeFormat(shiftRequest.lastModifiedTime);
        } else {
            lastStatusTime = DateTimeFormatter.getDisplayTimeInRelativeFormat(shiftRequest.creationTime);
        }
        return lastStatusTime;
    }

    /**
     * Get the statusState for the sender
     * @param shiftRequest
     */
    public static getSenderStatusState(shiftRequest: IShiftRequestEntity): ShiftRequestStatusState {
        return shiftRequest.state === ShiftRequestStates.SenderDeclined
            ? ShiftRequestStatusState.Declined : ShiftRequestStatusState.None;
    }

    /**
     * Helper method to get last modified shift request time
     */
    public static getShiftRequestLastModifiedTime(shiftRequest: IBaseShiftRequestEntity): string {
        return DateTimeFormatter.getDisplayTimeInTimeFormat(shiftRequest.lastModifiedTime);
    }

    /**
     * Helper method to get last modified shift request time in week day format
     */
    public static getShiftRequestLastModifiedTimeDayFormat(shiftRequest: IBaseShiftRequestEntity): string {
        return DateTimeFormatter.getDisplayTimeInWeekDayFormat(shiftRequest.lastModifiedTime);
    }

    /**
     * Helper method to get the status to be shown for a shift request
     */
    public static getShiftRequestStatusForDisplay(shiftRequest: IBaseShiftRequestEntity, memberName: string, shiftType: string, isOpenShift?: boolean): string {
        let statusToDisplay: string = "";
        const strings: Map<string, string> = ShiftRequestUtils.getStrings();

        const requestStatus: ShiftRequestStatus = ShiftRequestUtils.getShiftRequestStatus(shiftRequest);

        if (requestStatus === ShiftRequestStatus.Approved) {
            if (isOpenShift) {
                statusToDisplay = strings.get("approvedStatusGroupedOpenshiftRequest");
                return statusToDisplay;
            } else {
                statusToDisplay = strings.get("approvedStatusTime");
            }
        } else if (requestStatus === ShiftRequestStatus.Declined) {
            if (isOpenShift) {
                statusToDisplay = strings.get("declinedStatusGroupedOpenshiftRequest");
                return statusToDisplay;
            } else {
                statusToDisplay = strings.get("declinedStatusTime");
            }
        } else if (requestStatus === ShiftRequestStatus.Cancelled) {
            statusToDisplay = strings.get("cancelledStatusTime");
        } else {
            statusToDisplay = strings.get("inprogressOrUnknownStatusTime");
        }
        return statusToDisplay.format(memberName, shiftType);
    }

    /**
     * Comparator for sorting shift requests by last modified time
     */
    public static requestsComparator(firstRequest: IBaseShiftRequestEntity, secondRequest: IBaseShiftRequestEntity): number {
        if (!firstRequest) {
            return 1;
        } else if (!secondRequest) {
            return -1;
        } else {
            return secondRequest.lastModifiedTime.isAfter(firstRequest.lastModifiedTime) ? 1 : -1;
        }
    }

    /**
     * Comparator for sorting open shift requests list to approve/deny. Should show the earlier created request first
     */
    public static openShiftRequestDetailsComparator(firstRequest: IndividualOpenShiftRequestDetails, secondRequest: IndividualOpenShiftRequestDetails): number {
        if (!(firstRequest && firstRequest.request)) {
            return 1;
        } else if (!(secondRequest && secondRequest.request)) {
            return -1;
        } else {
            return secondRequest.request.creationTime.isBefore(firstRequest.request.creationTime) ? 1 : -1;
        }
    }

     /**
     * Get the message that the sender of the shift request included if present
     * @param shiftRequest
     */
    public static getSenderMessageForDisplay(shiftRequest: IShiftRequestEntity): string {
        let message: string = "";
        if (shiftRequest.senderMessage) {
            const strings: Map<string, string> = ShiftRequestUtils.getStrings();
            message = strings.get("senderMessageLabel").format(shiftRequest.senderMessage);
        }
        return message;
    }

    /**
     * Get the Manager or Receiver's response to a shift request
     * @param shiftRequest
     * @param forManagerRow - get the manager's reponse to the shiftRequest
     * @param receiverMember
     * @param managerMember
     */
    public static getResponseForDisplay(shiftRequest: IShiftRequestEntity, forManagerRow: boolean): string {
        const strings: Map<string, string> = ShiftRequestUtils.getStrings();
        let messageLabel: string = "";
        const rawMessage: string = forManagerRow ? shiftRequest.managerMessage : shiftRequest.receiverMessage;
        if (rawMessage) {
            messageLabel = strings.get("responseMessageLabel").format(rawMessage);
        } else if (!forManagerRow && ShiftRequestUtils.isPastReceiver(shiftRequest) && shiftRequest.requestType !== ShiftRequestTypes.Swap // don't show "No response" for receiver row of a swap
            || forManagerRow && ShiftRequestUtils.isPastManager(shiftRequest)) {
            messageLabel = strings.get("noResponse");
        } else if (shiftRequest.state === ShiftRequestStates.AnotherApproved && !forManagerRow) {
            // AnotherApproved means that a different shift request was approved for the same shifts
            messageLabel = strings.get("responseNotApplicable");
        }
        return messageLabel;
    }

    /**
     * Has the shift request made it past the state of receiver response
     * @param shiftRequest
     */
    public static isPastReceiver(shiftRequest: IShiftRequestEntity): boolean {
        return ShiftRequestUtils.isApprovedByReceiver(shiftRequest)
            || shiftRequest.state === ShiftRequestStates.ReceiverDeclined;
    }

    /**
     * Has the shift request made it pass the state of manager response
     * @param shiftRequest
     */
    public static isPastManager(shiftRequest: IShiftRequestEntity): boolean {
        return shiftRequest.state === ShiftRequestStates.ManagerApproved
            || shiftRequest.state === ShiftRequestStates.ManagerDeclined;
    }

    /**
     * Get the type of the shift request for display purposes
     * @param shiftRequest
     */
    public static getTypeForDisplay(shiftRequest: IBaseShiftRequestEntity): string {
        let headerTitle: string = "";
        if (shiftRequest) {
            const strings: Map<string, string> = ShiftRequestUtils.getStrings();
            switch (shiftRequest.requestType) {
                case (ShiftRequestTypes.Swap):
                    headerTitle = strings.get("swap");
                    break;
                case (ShiftRequestTypes.HandOff):
                    headerTitle = strings.get("handOff");
                    break;
                case (ShiftRequestTypes.TimeOff):
                    headerTitle = strings.get("timeOff");
                    break;
                case (ShiftRequestTypes.GroupedOpenShifts):
                case (ShiftRequestTypes.Open):
                case (ShiftRequestTypes.CrossLocationOpen):
                    headerTitle = strings.get("openShiftRequest");
                    break;
            }
        }
        return headerTitle;
    }

    /**
     * Get the type of the shift request for accessibility messages
     * @param shiftRequest
     */
    public static getTypeForAccessibility(shiftRequest: IBaseShiftRequestEntity): string {
        let headerTitle: string = "";
        if (shiftRequest) {
            const strings: Map<string, string> = ShiftRequestUtils.getStrings();
            switch (shiftRequest.requestType) {
                case (ShiftRequestTypes.Swap):
                    headerTitle = strings.get("swap");
                    break;
                case (ShiftRequestTypes.HandOff):
                    headerTitle = strings.get("handOff");
                    break;
                case (ShiftRequestTypes.TimeOff):
                    headerTitle = strings.get("timeOff");
                    break;
                case (ShiftRequestTypes.GroupedOpenShifts):
                case (ShiftRequestTypes.Open):
                    headerTitle = strings.get("openShift");
                    break;
            }
        }
        return headerTitle;
    }

    /**
     * Get the status of the response to a shift request.  Either the manager's response or the receiver's respone.
     * @param shiftRequest
     * @param forManagerRow Is this the status for the manager's response or the receiver's response
     * @param receiverMember Needed whenever forManagerRow is false
     */
    public static getResponseStatusForDisplay(shiftRequest: IShiftRequestEntity, forManagerRow: boolean, receiverMember: IMemberEntity, displayStatusWithTime?: boolean) {
        let status: string = "";
        const strings: Map<string, string> = ShiftRequestUtils.getStrings();

        if (!shiftRequest) {
            return status;
        }

        const isReceiver: boolean = TeamStore().me && TeamStore().me.id === shiftRequest.receiverMemberId;
        const isWaitingOnManager: boolean = shiftRequest.state === ShiftRequestStates.WaitingOnManager;
        const isWaitingOnReceiver: boolean = shiftRequest.state === ShiftRequestStates.WaitingOnReceiver;
        const isApprovedByManager: boolean = shiftRequest.state === ShiftRequestStates.ManagerApproved;
        const isAnotherApproved: boolean = shiftRequest.state === ShiftRequestStates.AnotherApproved;
        const isApprovedByReceiver: boolean = ShiftRequestUtils.isApprovedByReceiver(shiftRequest);

        // if this is the status for the manager and the shift request is waiting on their response
        if (forManagerRow && (isWaitingOnManager || isReceiver && isWaitingOnReceiver)) {
            status = strings.get("pendingManagerLabel");
        } else if (!forManagerRow && isWaitingOnReceiver) { // if this is the status for the receiver and the request is waiting on the receiver's response
            if (isReceiver) {
                status = strings.get("pendingYouLabel");
            } else {
                const receiverFirstName: string = receiverMember && receiverMember.firstName;
                if (receiverFirstName) {
                    status = strings.get("pendingFirstNameResponse").format(receiverFirstName);
                } else {
                    status = strings.get("pendingResponse");
                }
            }
        } else if (shiftRequest.state === ShiftRequestStates.SenderDeclined) {
            status = strings.get("noResponse");
        } else if (isAnotherApproved && forManagerRow) {
            status = strings.get("anotherRequestApproved");
        } else { // otherwise this request isn't pending and therefore has a status time to display
            // status time is used in aria-labels
            const statusTime: Moment = (forManagerRow || isAnotherApproved) ? shiftRequest.lastModifiedTime : shiftRequest.receiverResponseTime;
            const statusTimeForDisplay: string = displayStatusWithTime ? DateTimeFormatter.getDisplayTimeInRelativeFormat(statusTime) : "";

            if (forManagerRow && isApprovedByManager) {
                status = strings.get("approvedStatus").format(statusTimeForDisplay);
            } else if (!forManagerRow && isApprovedByReceiver) {
                status = strings.get("acceptedStatus").format(statusTimeForDisplay);
            } else {
                status = strings.get("declinedStatus").format(statusTimeForDisplay);
            }
        }
        return status;
    }

    /**
     * Get the status of a response to a shift request
     * @param shiftRequest
     * @param forManagerRow
     */
    public static getResponseStatusState(shiftRequest: IShiftRequestEntity, forManagerRow: boolean): ShiftRequestStatusState {
        let statusState: ShiftRequestStatusState = ShiftRequestStatusState.None;
        if (!shiftRequest) {
            return statusState;
        }

        if (forManagerRow) {
            const isApprovedByManager: boolean = shiftRequest.state === ShiftRequestStates.ManagerApproved;
            const isDeclinedByManager: boolean = shiftRequest.state === ShiftRequestStates.ManagerDeclined;
            const autoDeclined: boolean = shiftRequest.state === ShiftRequestStates.AutoDeclined;

            if (isApprovedByManager) {
                statusState = ShiftRequestStatusState.Approved;
            } else if (isDeclinedByManager || autoDeclined) {
                statusState = ShiftRequestStatusState.Declined;
            }
        } else {
            const isApprovedByReceiver: boolean = ShiftRequestUtils.isApprovedByReceiver(shiftRequest);
            const isDeclinedByReceiverOrAnotherApproved: boolean = shiftRequest.state === ShiftRequestStates.ReceiverDeclined
                || shiftRequest.state == ShiftRequestStates.AnotherApproved;
            if (isApprovedByReceiver) {
                statusState = ShiftRequestStatusState.Approved;
            } else if (isDeclinedByReceiverOrAnotherApproved) {
                statusState = ShiftRequestStatusState.Declined;
            }
        }

        return statusState;
    }

    /**
     * Get the status of the shift request without reference to time
     * @param shiftRequest
     */
    public static getShiftRequestStatusWithoutTimeForDisplay(shiftRequest: IBaseShiftRequestEntity): string {
        if (!shiftRequest) {
            return "";
        }

        const strings: Map<string, string> = ShiftRequestUtils.getStrings();
        switch (shiftRequest.state) {
            case ShiftRequestStates.SenderDeclined:
                return strings.get("cancelled");
            case ShiftRequestStates.WaitingOnManager:
                return strings.get("pendingManagerLabel");
            case ShiftRequestStates.ReceiverDeclined:
            case ShiftRequestStates.ManagerDeclined:
            case ShiftRequestStates.AutoDeclined:
            case ShiftRequestStates.AnotherApproved:
                return strings.get("declined");
            case  ShiftRequestStates.WaitingOnReceiver: {
                const shiftRequestEntity = shiftRequest as IShiftRequestEntity;
                if (shiftRequestEntity) {
                    const currentMemberIsReceiver: boolean = TeamStore().me && TeamStore().me.id === shiftRequestEntity.receiverMemberId;
                    if (currentMemberIsReceiver) {
                        return strings.get("pendingYouLabel");
                    } else {
                        return strings.get("pendingResponse");
                    }
                }

                break;
            }
            case ShiftRequestStates.ManagerApproved:
                return strings.get("approved");
        }

        return "";
    }

    /**
     * Get a status string for screenreaders indicating the state of an open shift request
     * @param state
     */
    public static getOpenShiftRequestStatusAriaString(state: ShiftRequestState): string {
        let statusString: string = "";
        const strings: Map<string, string> = ShiftRequestUtils.getStrings();

        switch (state) {
            case ShiftRequestStates.SenderDeclined:
                statusString = strings.get("cancelled");
                break;
            case ShiftRequestStates.WaitingOnManager:
                statusString =  strings.get("inProgress");
                break;
            case ShiftRequestStates.ManagerDeclined:
            case ShiftRequestStates.AutoDeclined:
                statusString = strings.get("declined");
                break;
                case ShiftRequestStates.ManagerApproved:
                statusString = strings.get("approved");
                break;
            case ShiftRequestStates.ReceiverDeclined:
            case ShiftRequestStates.AnotherApproved:
            case  ShiftRequestStates.WaitingOnReceiver:
                trace.info(`Unexpected request state found for grouped open shift request: ${state}.`);
                break;
        }

        return statusString;
    }

    /**
     * Check if a shiftRequest has been approved by the receiver of the shiftRequest
     * @param shiftRequest
     */
    public static isApprovedByReceiver(shiftRequest: IShiftRequestEntity): boolean {
        const state = shiftRequest.state;
        return state === ShiftRequestStates.ManagerApproved
            || state === ShiftRequestStates.WaitingOnManager
            || state === ShiftRequestStates.ManagerDeclined;
    }

    /**
     * Get a shift request from a list of shift requests by it's id
     * @param shiftRequests
     * @param shiftRequestId
     */
    public static getShiftRequestFromListById(shiftRequests: IShiftRequestEntity[], shiftRequestId: string): IShiftRequestEntity {
        let shiftRequest: IShiftRequestEntity = null;
        if (shiftRequests && shiftRequestId) {
            shiftRequest =  shiftRequests.find((shiftRequest) => shiftRequest.id === shiftRequestId) || null;
        }
        return shiftRequest;
    }

    /**
     * Returns True if the request is unread for the user
     */
    public static isShiftRequestUnread(shiftRequest: IBaseShiftRequestEntity): boolean {
        if (!shiftRequest) {
            return false;
        }

        if (shiftRequest.requestType === ShiftRequestTypes.GroupedOpenShifts) {
            // If any openShiftRequest is unread, the group should be displayed as unread
            const groupedOpenShiftRequest: IGroupedOpenShiftRequestEntity = shiftRequest as IGroupedOpenShiftRequestEntity;
            return DataProcessingHelpers.getArrayFromMap<string, IShiftRequestEntity>(groupedOpenShiftRequest.shiftRequests).some((openShiftRequest: IShiftRequestEntity ) =>
                (!ShiftRequestUtils.isRequestReadByMe(openShiftRequest)) // Checks if the request is unread for the user
            );
        } else {
            return !ShiftRequestUtils.isRequestReadByMe(shiftRequest as IShiftRequestEntity);
        }
    }

    /*
     * Check if the current member has seen the most recent changes to a shift request
     * @param shiftRequest
     */
    public static isRequestReadByMe(shiftRequest: IShiftRequestEntity): boolean {
        const me: IMemberEntity = TeamStore() && TeamStore().me;
        return me ? ShiftRequestUtils.isLastModifiedTimeSeenByMember(shiftRequest, me.id) : false;
    }

    /** Checks if the shift request type is groupable (e.g. Open, CrossLocationOpen).
     * @param requestType The type of the shift request.
     * @returns True if the shift request type is groupable.
     */
    public static isRequestTypeGroupable(requestType: ShiftRequestType): boolean {
        return requestType === ShiftRequestTypes.Open || requestType === ShiftRequestTypes.CrossLocationOpen;
    }

    /**
     * Check if the most recent changes to a shift request are seen by a member
     * @param shiftRequest
     * @param memberId
     */
    public static isLastModifiedTimeSeenByMember(shiftRequest: IShiftRequestEntity, memberId: string): boolean {
        let isLastModifiedTimeSeen: boolean = false;
        if (shiftRequest && shiftRequest.memberReads && memberId) {
            isLastModifiedTimeSeen = shiftRequest.memberReads.some((memberReadResponse: IShiftRequestMemberReadResponse) => {
                return memberReadResponse
                    && memberReadResponse.memberId === memberId
                    && memberReadResponse.userLastReadTime
                    && memberReadResponse.userLastReadTime.isSame(shiftRequest.lastModifiedTime);
            });
        }
        return isLastModifiedTimeSeen;
    }

    /**
     * Get the list of members who have seen a given shift request.  Optionally exclude one member id from the list
     * @param shiftRequest
     * @param excludeMemberId
     */
    public static getMembersWhoHaveSeenShiftRequest(shiftRequest: IShiftRequestEntity, excludeMemberId?: string): IMemberEntity[] {
        let memberList: IMemberEntity[] = null;
        if (shiftRequest && shiftRequest.memberReads) {
            const lastModifiedTime: Moment = shiftRequest.lastModifiedTime;
            let filteredMemberReadResponses: IShiftRequestMemberReadResponse[] = shiftRequest.memberReads.filter((memberReadResponse: IShiftRequestMemberReadResponse) => {
                // service sets the userLastReadTime to the value of lastModifiedTime when they looked at the shift request.  If they've seen the shift request
                // in it's current state, then their lastReadTime will be the same as the current lastModifiedTime
                return memberReadResponse.userLastReadTime && memberReadResponse.userLastReadTime.isSame(lastModifiedTime);
            });
            if (excludeMemberId) {
                filteredMemberReadResponses = filteredMemberReadResponses.filter((memberReadResponse: IShiftRequestMemberReadResponse) => {
                    return memberReadResponse.memberId !== excludeMemberId;
                });
            }
            memberList = filteredMemberReadResponses.map((memberReadResponse: IShiftRequestMemberReadResponse) => {
                return MemberDataService.getMemberByIdFromStore(memberReadResponse.memberId);
            });
        }
        return memberList;
    }

    /**
     * Get the seen by message that a given member should see. e.g. "Seen by John Smith, Suzy Carlile"
     * @param shiftRequest
     * @param member
     */
    public static getSeenByMessage(shiftRequest: IShiftRequestEntity, member: IMemberEntity): string {
        let seenByMessage: string = "";
        if (shiftRequest && member) {
            const memberList: IMemberEntity[] = ShiftRequestUtils.getMembersWhoHaveSeenShiftRequest(shiftRequest, member.id);
            if (memberList && memberList.length > 0) {
                const strings: Map<string, string> = ShiftRequestUtils.getStrings();

                const memberNameList: string[] = memberList.map((member: IMemberEntity) => MemberUtils.getDisplayNameForMember(member));
                seenByMessage = strings.get("seenByName").format(StringUtils.getCommaSeparatedList(memberNameList));
            }
        }
        return seenByMessage;
    }

    /**
     * Get the screenreader label to describe a non-open shift shift request
     * @param shiftRequest
     * @param requester
     * @param receiver
     * @deprecated Aria labels should not display text that does not match with the visual text.
     */
    public static getAriaLabel(shiftRequest: IShiftRequestEntity, requester: IMemberEntity, receiver: IMemberEntity): string {
        let label: string = "";
        const strings: Map<string, string> = ShiftRequestUtils.getStrings();
        const commonStrings: Map<string, string> = ShiftRequestUtils.getCommonStrings();

        if (shiftRequest) {
            const me = TeamStore().me;
            const requesterName: string = requester && requester.id === me.id ? commonStrings.get("you") : requester && MemberUtils.getDisplayNameForMember(requester);
            const receiverName: string = receiver ? MemberUtils.getDisplayNameForMember(receiver) : "";
            const shiftDetails: string = DateTimeFormatter.getShiftRequestDateTimeRangeAsString(shiftRequest.startTime, shiftRequest.endTime);
            const senderNote: string = this._strings.get("accessibilityNotesAriaLabel").format(shiftRequest.senderMessage || this._strings.get("accessibilityNoNotesAddedAriaLabel"));

            switch (shiftRequest.requestType) {
                case ShiftRequestTypes.HandOff:
                    let offerRequestStatus: string;
                        switch (shiftRequest.state) {
                            case ShiftRequestStates.SenderDeclined:
                                offerRequestStatus = strings.get("handOffShiftRequestCompletedAriaLabel").format(requesterName || "", strings.get("cancelled"), shiftDetails, senderNote);
                                break;
                            case ShiftRequestStates.ReceiverDeclined:
                            case ShiftRequestStates.AnotherApproved:
                            case ShiftRequestStates.ManagerDeclined:
                            case ShiftRequestStates.AutoDeclined:
                                offerRequestStatus = strings.get("handOffShiftRequestCompletedAriaLabel").format(requesterName || "", strings.get("declined"), shiftDetails, senderNote);
                                break;
                            case ShiftRequestStates.ManagerApproved:
                                offerRequestStatus = strings.get("handOffShiftRequestCompletedAriaLabel").format(requesterName || "", strings.get("approved"), shiftDetails, senderNote);
                                break;
                            default: offerRequestStatus = strings.get("handOffShiftRequestAriaLabel").format( requesterName || "", receiverName, shiftDetails, senderNote);
                        }
                    label = offerRequestStatus;
                    break;
                case ShiftRequestTypes.Swap:
                    const otherShiftDetails: string = shiftRequest.otherShiftInfo
                        ? DateTimeFormatter.getShiftRequestDateTimeRangeAsString(shiftRequest.otherShiftInfo.startTime, shiftRequest.otherShiftInfo.endTime)
                        : "";
                        let swapRequestStatus: string;
                        switch (shiftRequest.state) {
                            case ShiftRequestStates.SenderDeclined:
                                    swapRequestStatus = strings.get("swapShiftRequestCompletedAriaLabel").format(requesterName || "", strings.get("cancelled"), shiftDetails, otherShiftDetails, senderNote);
                                break;
                            case ShiftRequestStates.ReceiverDeclined:
                            case ShiftRequestStates.AnotherApproved:
                            case ShiftRequestStates.ManagerDeclined:
                            case ShiftRequestStates.AutoDeclined:
                                    swapRequestStatus = strings.get("swapShiftRequestCompletedAriaLabel").format(requesterName || "", strings.get("declined"), shiftDetails, otherShiftDetails, senderNote);
                                break;
                            case ShiftRequestStates.ManagerApproved:
                                    swapRequestStatus = strings.get("swapShiftRequestCompletedAriaLabel").format(requesterName || "", strings.get("approved"), shiftDetails, otherShiftDetails, senderNote);
                                break;
                            default: swapRequestStatus = strings.get("swapShiftRequestAriaLabel").format( requesterName || "", shiftDetails, receiverName, otherShiftDetails, senderNote);
                        }
                    label = swapRequestStatus;
                    break;
                case ShiftRequestTypes.TimeOff:
                        let timeoffRequestStatus: string;
                        switch (shiftRequest.state) {
                            case ShiftRequestStates.SenderDeclined:
                                    timeoffRequestStatus = strings.get("timeOffShiftRequestCompletedAriaLabel").format(requesterName || "", strings.get("cancelled"), shiftDetails, senderNote);
                                break;
                            case ShiftRequestStates.ReceiverDeclined:
                            case ShiftRequestStates.AnotherApproved:
                            case ShiftRequestStates.ManagerDeclined:
                            case ShiftRequestStates.AutoDeclined:
                                    timeoffRequestStatus = strings.get("timeOffShiftRequestCompletedAriaLabel").format(requesterName || "", strings.get("declined"), shiftDetails, senderNote);
                                break;
                            case ShiftRequestStates.ManagerApproved:
                                    timeoffRequestStatus = strings.get("timeOffShiftRequestCompletedAriaLabel").format(requesterName || "", strings.get("approved"), shiftDetails, senderNote);
                                break;
                            default: timeoffRequestStatus = strings.get("timeOffShiftRequestAriaLabel").format( requesterName || "", receiverName, shiftDetails, senderNote);
                        }
                    label = timeoffRequestStatus;
                    break;
            }
        }

        return label;
    }

    /**
     * Get the screenreader label to describe an open shift request
     * @param groupedOpenShiftRequest
     * @param groupName
     * @param firstRequesterName
     * @deprecated Aria labels should not display text that does not match with the visual text.
     */
    public static getAriaLabelForOpenShiftRequest(shiftRequest: IBaseShiftRequestEntity, groupName: string, firstRequesterName: string): string {
        let label: string = "";
        const strings: Map<string, string> = ShiftRequestUtils.getStrings();

        const isGrouped: boolean =  shiftRequest.requestType === ShiftRequestTypes.GroupedOpenShifts;

        if (shiftRequest) {
            const numRequests: number = isGrouped ? (shiftRequest as IGroupedOpenShiftRequestEntity).shiftRequests.size : 1;
            const pluralNumRequests: boolean = StringUtils.usePluralForm(numRequests);
            const baseFormatString: string = !!groupName
                                                ?
                                                    pluralNumRequests ? strings.get("openShiftRequestWithGroupPluralAriaLabel") : strings.get("openShiftRequestWithGroupSingularAriaLabel")
                                                :
                                                    pluralNumRequests ? strings.get("openShiftRequestWithoutGroupPluralAriaLabel") : strings.get("openShiftRequestWithoutGroupSingularAriaLabel");
            const timeString: string = DateTimeFormatter.getShiftRequestDateTimeRangeAsString(shiftRequest.startTime, shiftRequest.endTime);
            const statusString: string = ShiftRequestUtils.getOpenShiftRequestStatusAriaString(shiftRequest.state);
            let formatStringParams: string[] = [statusString, firstRequesterName];

            if (pluralNumRequests) {
                formatStringParams.push(String(numRequests - 1));
            }

            if (groupName) {
                formatStringParams.push(groupName);
            }

            formatStringParams.push(timeString);
            label = baseFormatString.format(...formatStringParams);
        }

        return label;
    }

    /**
     * Returns the Id for the GroupedOpenShiftRequestEntity associated with the given shiftId and the
     * given ShiftRequestState
     * @param shiftId
     * @param state
     */
    public static getGroupedEntityIdFromShiftIdAndState(shiftId: string, state: ShiftRequestState): string {
        return shiftId + this.getRequestStatusFromState(state);
    }

    /**
     * Returns if the request if a Timeoff/Swap/Handoff type
     * @param shiftRequest
     */
    public static isRegularShiftRequest(shiftRequest: IBaseShiftRequestEntity): boolean {
        return (shiftRequest &&
            (shiftRequest.requestType === ShiftRequestTypes.Swap
                || shiftRequest.requestType === ShiftRequestTypes.HandOff
                || shiftRequest.requestType === ShiftRequestTypes.TimeOff ));
    }

    /**
     * Check if this is shift request is of type GroupedOpenShifts
     * @param shiftRequest
     */
    public static isGroupedOpenShiftRequest(shiftRequest: IBaseShiftRequestEntity): boolean {
        return shiftRequest.requestType === ShiftRequestTypes.GroupedOpenShifts;
    }

    /**
     * Check if this is openshift request is in active state
     * @param openShiftRequest
     */
    public static isActiveOpenShiftRequest(openShiftRequest: IShiftRequestEntity): boolean {
        return (openShiftRequest.requestType === ShiftRequestTypes.Open && openShiftRequest.state === ShiftRequestStates.WaitingOnManager);
    }

     /**
     * Check if this is CL openshift request is in active state
     * @param openShiftRequest
     */
     public static isActiveCrossLocationOpenShiftRequest(openShiftRequest: IShiftRequestEntity): boolean {
        return (openShiftRequest.requestType === ShiftRequestTypes.CrossLocationOpen  && openShiftRequest.state === ShiftRequestStates.WaitingOnManager);
    }

    /**
     * Gets the time range display for open shift sub title in panel header
     */
    public static getTimeRangeForDisplay(openShiftRequest: IGroupedOpenShiftRequestEntity) {
        if (openShiftRequest.startTime && openShiftRequest.endTime) {
            const openShiftDetails: string = DateTimeFormatter.getShiftRequestDateTimeRangeAsString(openShiftRequest.startTime, openShiftRequest.endTime);
            return openShiftDetails;
        }

        return "";
    }

    /*
     * Get the response status for an open shift request
     * @param shiftRequest
     * @param managerMember
     */
    public static getOpenShiftRequestResponseStatusForDisplay(shiftRequest: IShiftRequestEntity, managerMember: IMemberEntity): string {
        let status: string = "";
        if (shiftRequest) {
            const strings: Map<string, string> = this.getStrings();
            const dateOfAction = DateTimeFormatter.getDateTimeAsString(shiftRequest.lastModifiedTime, DateTimeFormatType.DateTime_DateShort_Time);
            // no manager member needed for cancelled and auto declined requests
            if (shiftRequest.state === ShiftRequestStates.SenderDeclined) {
                status = strings.get("cancelledOpenShiftRequestStatus").format(dateOfAction);
            } else if (shiftRequest.state === ShiftRequestStates.AutoDeclined) {
                status = strings.get("autoDeclinedOpenShiftRequestStatus").format(dateOfAction);
            } else if (managerMember) {
                const managerName = MemberUtils.getDisplayNameForMember(managerMember);

                if (shiftRequest.state === ShiftRequestStates.ManagerApproved) {
                    status = strings.get("approvedOpenShiftRequestStatus").format(dateOfAction, managerName);
                } else if (shiftRequest.state === ShiftRequestStates.ManagerDeclined) {
                    status = strings.get("declinedOpenShiftRequestStatus").format(dateOfAction, managerName);
                }
            }
        }
        return status;
    }

    /**
     * Get the response message for an open shift request
     * @param request
     */
    public static getResponseMessageForOpenShiftRequest(request: IShiftRequestEntity): string {
        return request.managerMessage;
    }

    /**
     * get the shift request id of the first shift request in a list of grouped requests
     * @param groupedRequest
     */
    public static getFirstShiftRequestIdFromGroupedRequest(groupedRequest: IGroupedOpenShiftRequestEntity): string {
        const requests: IShiftRequestEntity[] = groupedRequest && groupedRequest.shiftRequests && MobxUtils.MapToArray(groupedRequest.shiftRequests);
        const individualRequest = requests && requests.length > 0 && requests[0];
        return individualRequest ? individualRequest.id : "";
    }

    /* This function is to check if all the request types are eligible or not */

    public static isRequestEnabled(team: ITeamInfoEntity) {
        if (!team) {
            return false;
        }
        if (team.timeOffRequestsEnabled || team.swapShiftsRequestsEnabled
            || team.offerShiftRequestsEnabled || !team.hideOpenShifts) {
            return true;
        }
        return false;

    }

    /* Check if the request type is eligbile or not */

    public static isRequestTypeEligible(team: ITeamInfoEntity, type: ShiftRequestType) {
        if (!team) {
            return false;
        }
        if ((type === ShiftRequestTypes.TimeOff && team.timeOffRequestsEnabled) ||
           (type === ShiftRequestTypes.Swap && team.swapShiftsRequestsEnabled) ||
           (type === ShiftRequestTypes.HandOff && team.offerShiftRequestsEnabled) ||
           (type  === ShiftRequestTypes.GroupedOpenShifts && !team.hideOpenShifts) ||
           (type === ShiftRequestTypes.Open && !team.hideOpenShifts)) {
            return true;
        }
        return false;
    }

    /* Filter the monthly shifts with the eligible shifts id returned from requestableShifts service api */

    public static getFilteredRequestableShifts(eligibleShiftIds: string[], thisMonthsShifts: IBaseShiftEntity[]): IBaseShiftEntity[] {
        if (!eligibleShiftIds ||  (eligibleShiftIds.length === 0 ))  {
            return [];
        }

        return thisMonthsShifts.filter(currentshift => {
            return eligibleShiftIds.findIndex(shiftid => shiftid == currentshift.id) > -1;
        });
    }

    /**
     * Validates if the user has enabled an specific permission.
     * @param { RequestTypes } requestType value of the RequestType enum
     * @returns { boolean } true if the user has the permission enabled
     */
    private static isRequestTypeEnabled(requestType: RequestTypes): boolean {
        const eligibilityFilteringEnabledEntities =
            FlightSettingsService.getExtraTeamSettingsValues(
                ExtraTeamSettingsKeys.EligibilityFilteringEnabledEntities
            );

        return (
            !!eligibilityFilteringEnabledEntities &&
                eligibilityFilteringEnabledEntities.indexOf(requestType) > -1
        );
    }

    /* Validate the ECS flag as well as the appSetting returned from service if filter is for Swap */

    public static isRequestSwapFilteringEnabled(): boolean {
        if (ECSConfigService.isECSFeatureEnabled(ECSConfigKey.EnableRequestSwapFiltering)) {
            return this.isRequestTypeEnabled(RequestTypes.SwapRequest);
        }
        return false;
    }

    /**
     * Validate the ECS flag as well as the appSetting returned from service if filter is enabled for TimeOff reasons
     * @returns { boolean } true if the user has the permission enabled
     */
     public static isRequestTimeOffReasonsFilteringEnabled(): boolean {
        if (ECSConfigService.isECSFeatureEnabled(ECSConfigKey.EnableTimeOffReasonsFiltering)) {
            return this.isRequestTypeEnabled(RequestTypes.TimeOffReason);
        }

        return false;
    }
}