import ShiftRequestUtils from "sh-application/utility/ShiftRequestUtils";
import updateShiftRequestsInStore from "../actions/updateShiftRequestsInStore";
import { default as addOpenShiftRequestToGroupedItem } from "../mutatorActions/addOpenShiftRequestToGroupedItem";
import { default as deleteOpenShiftRequestFromGroupedItem } from "../mutatorActions/deleteOpenShiftRequestFromGroupedItem";
import { default as updateShiftRequestInStore } from "../mutatorActions/updateShiftRequestInStore";
import { default as updatedRegularAndGroupedShiftRequests } from "../actions/updatedRegularAndGroupedShiftRequests";
import { IGroupedOpenShiftRequestEntity, IShiftRequestEntity } from "sh-models";
import { orchestrator } from "satcheljs";
import { shiftRequestStore } from "../";

/**
 * This orchestrator listens to any updates in the shift requests.
 * If it is an open shift request, then it also updates the grouped open shift request list
 * After updating the store, it triggers the action updatedRegularAndGroupedShiftRequests with the updated request items. Needed for workflows like Requests panel updating the currently selected item
 */
export default orchestrator(updateShiftRequestsInStore, actionMessage => {

    const { shiftRequests } = actionMessage;
    let updatedGroupedOpenShiftRequests: Map<string, IGroupedOpenShiftRequestEntity> = new Map<string, IGroupedOpenShiftRequestEntity>();

    shiftRequests.forEach((shiftRequest: IShiftRequestEntity) => {
        if (shiftRequest) {

            // For OpenShift Requests, also update the grouped entity
            if (ShiftRequestUtils.isRequestTypeGroupable(shiftRequest.requestType)) {

                // Remove the previous item from the grouped open shift requests
                // Previous version can have different state which is used to identify the group, so use that version to remove from Grouped item
                if (shiftRequestStore().shiftRequests.has(shiftRequest.id)) {
                    const prevShiftRequest: IShiftRequestEntity = shiftRequestStore().shiftRequests.get(shiftRequest.id);
                    const prevGroupedOpenShiftRequestId: string = ShiftRequestUtils.getGroupedEntityIdFromShiftIdAndState(prevShiftRequest.shiftId, prevShiftRequest.state);
                    if (shiftRequestStore().groupedOpenShiftRequests.has(prevGroupedOpenShiftRequestId) ) {
                        deleteOpenShiftRequestFromGroupedItem(prevShiftRequest);

                        // Track the updated items list here
                        updatedGroupedOpenShiftRequests.set(prevGroupedOpenShiftRequestId, shiftRequestStore().groupedOpenShiftRequests.get(prevGroupedOpenShiftRequestId));
                    }
                }
                // Add the new grouped open shift request
                addOpenShiftRequestToGroupedItem(shiftRequest);

                // Track the updated items list here
                const groupedOpenShiftRequestId: string = ShiftRequestUtils.getGroupedEntityIdFromShiftIdAndState(shiftRequest.shiftId, shiftRequest.state);
                updatedGroupedOpenShiftRequests.set(groupedOpenShiftRequestId, shiftRequestStore().groupedOpenShiftRequests.get(groupedOpenShiftRequestId));
            }

            // Update the shift request in store
            updateShiftRequestInStore(shiftRequest);
        }
    });

    // Trigger the action with updated items. Used for workflows like Requests panel updating the currently selected item
    // updatedGroupedOpenShiftRequests has to be a map to track deleted items. the value in map is null when an item is removed (its status changed)
    updatedRegularAndGroupedShiftRequests(shiftRequests, updatedGroupedOpenShiftRequests);
});
