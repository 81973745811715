import * as React from "react";
import ScheduleTeamPickerUtils from "./ScheduleTeamPickerUtils";
import Spinner, { SpinnerSize } from "sh-application/components/common/Spinner";
import StringsStore from "sh-strings/store";
import { AriaProperties, AriaRoles, generateDomPropertiesForAria } from "owa-accessibility";
import { computed } from "mobx";
import { FocusZone } from "@fluentui/react";
import { Icon } from "@fluentui/react";
import { ITeamInfoEntity } from "sh-models";
import { MobxUtils } from "sh-application";
import { observer } from "mobx-react";
import { ScheduleTeamPickerProps } from "./ScheduleTeamPickerUtils";
import { SET_INITIAL_FOCUS_DELAY_MS } from "sh-application/utility/UiUtils";
import { TeamInfoStore } from "sh-stores";
import { TeamStore } from "sh-team-store";

let classNames = require("classnames/bind");
const styles = require("./ScheduleTeamPickerPane.scss");
const showIcons = false;

/**
 * React component that renders the schedule team picker UX
 */
@observer
export default class ScheduleTeamPickerPanel extends React.Component<ScheduleTeamPickerProps, any> {
    private _strings: Map<string, string>;
    private _firstItemRef = React.createRef<HTMLDivElement>();

    constructor(props: ScheduleTeamPickerProps) {
        super(props);
        this._strings = StringsStore().registeredStringModules.get("scheduleTeamPicker").strings;
    }

    async componentDidMount() {
        await ScheduleTeamPickerUtils.loadInitialData(this.props.instrumentationEventName);

        if (this.props.autofocus) {
            // Set initial focus on the first team item
            // setTimeout is needed for setting focus to succeed. One alternative might be to get the child item
            // to set focus.
            setTimeout(() => {
                if (this._firstItemRef && this._firstItemRef.current) {
                    this._firstItemRef.current.focus();
                }
            }, SET_INITIAL_FOCUS_DELAY_MS);
        }
    }

    /**
     * Calculate the schedule teams list data which will be used for rendering
     */
    @computed
    private get calculateScheduleTeamsList(): ITeamInfoEntity[] {
        let teamInfoArray: ITeamInfoEntity[] = [];
        const teamInfos = TeamInfoStore().teams;
        if (teamInfos) {
            teamInfoArray = MobxUtils.MapToArray(teamInfos);
            teamInfoArray = teamInfoArray.sort(ScheduleTeamPickerUtils.scheduleTeamInfoComparator);
        }
        return teamInfoArray;
    }

    /**
     * Renders loading progress state
     */
    private renderLoadingProgress(): JSX.Element {
        return (
            <div className={ styles.progressIndicatorContainer }>
                <Spinner className={ styles.spinner } size={ SpinnerSize.small } label={ this._strings.get("loadingTeamsMessage") } />
            </div>
        );
    }

    /**
     * Render empty state when no teams are available to be listed
     */
    private renderEmptyTeamListState(): JSX.Element {
        // Display nothing. Entrypoints for the schedule team picker should have been disabled in the first place.
        return (
            <></>
        );
    }

    /**
     * Handler for when a View team button is clicked
     */
    private handleViewTeamButtonClicked(teamInfo: ITeamInfoEntity) {
        if (this.props.onTeamSelected) {
            this.props.onTeamSelected(teamInfo);
        }
    }

    /**
     * Render the schedule teams picker list contents
     */
    private renderScheduleTeamsPickerListContents(): JSX.Element {
        const teamInfoList: ITeamInfoEntity[] = this.calculateScheduleTeamsList;
        return (
            ScheduleTeamPickerUtils.isScheduleTeamsDataLoading()
            ?
            this.renderLoadingProgress()
            :
            (
                (teamInfoList && teamInfoList.length > 0)
                ?
                this.renderScheduleTeamsPickerList(teamInfoList)
                :
                this.renderEmptyTeamListState()
            )
        );
    }

    /**
     * Render the schedule teams picker list UX
     */
    private renderScheduleTeamsPickerList(teamInfoList: ITeamInfoEntity[]): JSX.Element {
        return (
            <div className={ styles.list }>
                { this.renderScheduleTeamsListItems(teamInfoList) }
            </div>
        );
    }

    /**
     * Render the list items for the schedule teams list
     */
    private renderScheduleTeamsListItems(teamInfoList: ITeamInfoEntity[]): JSX.Element {
        interface TeamListItemProps {
            item: ITeamInfoEntity;
            index: number;
        }

        const TeamListItem = (props: TeamListItemProps) => {
            // We prevent StaffHub teams from being opened from within the MS Teams application.
            // Users need to visit the old StaffHub Web site in order to view those teams.
            const isFirstItem: boolean = props.index === 0;

            return (
                <>
                    {
                        this.renderScheduleTeamListItemLeft(props.item, isFirstItem)
                    }
                </>);
        };

        const ariaProps: AriaProperties = {
            role: AriaRoles.tablist
        };

        return (
            <ul className={ styles.tabList } { ...generateDomPropertiesForAria(ariaProps) }>
                {
                    teamInfoList.map((item, index) => (
                        <TeamListItem key={ item.id } item={ item } index={ index } />
                    ))
                }
            </ul>
        );
    }

    /**
     * Render the left side of a list item for schedule teams associated with MS Teams
     */
    private renderScheduleTeamListItemLeft(teamInfo: ITeamInfoEntity, isFirstItem: boolean): JSX.Element {
        const handleViewButtonClicked = () => { this.handleViewTeamButtonClicked(teamInfo); };

        const isCurrentTeam = TeamStore().teamId === teamInfo.id;
        const itemClasses = classNames(styles.listItem, { [`${styles.selected}`]: isCurrentTeam });
        const ariaProps: AriaProperties = {
            role: AriaRoles.tab,
            selected: isCurrentTeam
        };
        return (
            <li
                { ...generateDomPropertiesForAria(ariaProps) }
                onClick={ handleViewButtonClicked }
                className={ itemClasses }
                tabIndex={ 0 }
                data-is-focusable={ true }
                ref={ isFirstItem ? this._firstItemRef : null }>
                {
                    // TODO robv - 2/22/2019 - Add team avatar support when available - for now, this is
                    // for testing layout when showIcons is set to true
                    showIcons && <Icon iconName={ "BorderDash" } className={ styles.linkIcon } />
                }
                <div className={ styles.name }>{ teamInfo.name }</div>
            </li>
        );
    }

    render() {
        return (
            <FocusZone>
                <div className={ styles.content }>
                    {
                        this.renderScheduleTeamsPickerListContents()
                    }
                </div>
            </FocusZone>
        );
    }
}