import * as MemoizeUtils from "sh-application/utility/MemoizeUtils";
import * as moment from "moment";
import DateUtils from "sh-application/utility/DateUtils";
import StringsStore from "sh-strings/store";
import { Moment } from "moment";
import { ScheduleCalendarType, ScheduleCalendarTypes } from "sh-models";

/**
 * Date time format types.
 *
 * Note:
 * Please keep the number of date time formats down to a minimum and reuse existing ones as much as possible.
 * Introducing new date time formats incurs costs in code and localization complexity that we should avoid.
 */
export enum DateTimeFormatType {
    // Time only
    Time,                               // 8:00 PM, 8:30 AM, 23:00
    Time_Short,                         // 8 PM, 8:30 AM, 23:00
    Time_HourOnly,                      // 8, 8, 23
    Time_MeridiemOnly,                  // PM, AM, "" (empty string if 24-hour format is being used)

    // Date only
    Date_Numeric,                       // 12/27/1985
    Date_DateMonthNumeric,              // 12/27
    Date_DateMonthShort,                // Dec 27
    Date_DateMonthLong,                 // December 27
    Date_MonthYearLong,                 // December 1985
    Date_MonthLong,                     // December
    Date_DateMonthYearLong,             // December 27, 1985
    Date_DayDateMonthYear,              // Wednesday, Dec 27, 1985
    Date_DayShortDateMonthYear,         // Wed, Dec 25, 1990
    Date_DayDateMonthYearLong,          // Wednesday, December 27, 1985
    Date_DayDateMonthShort,             // Wed Dec 27
    Date_DateNumeric,                   // 27
    Date_YearNumeric,                   // 1985
    Date_Day,                           // Wednesday
    Date_DayShort,                      // Wed
    Date_DayNarrow,                     // We

    // Date and time
    DateTime_DateNumeric_Time,          // 12/27 8 PM
    DateTime_DateShort_Time             // Dec 27, 8 PM
}

// Callback type for formatting a single day all day date time range
type GetAllDayDateTimeAsStringFunction = (dateTime: Moment, useLongFormat: boolean) => string;

// Callback for formatting same day date time ranges as strings
type GetSameDayDateTimeRangeAsStringFunction = (startDate: Moment, endDate: Moment, useLongFormat: boolean) => string;

// Note: Defined format string directly here instead of in string resources to reduce the chance of localization bugs.
const MOMENTJS_MERIDIEM_FORMAT_STRING: string = "A";
const HOURS_IN_DAY = 24;
const MINUTES_IN_HOUR = 60;

/**
 * DateTimeFormatter
 * Utility class for generating localized, formatted date time strings.
 */
export default class DateTimeFormatter {
    // TODO DCoh:  Change this class to a singleton instead of static, and also have this be derived from a base interface to support better testability.

    // Localized date time formatting strings
    private static _dateTimeStrings: Map<string, string> = null;
    // Localized date time formatting strings for MomentJS formatting
    // We keep MomentJS formats in a separate string table for easier maintenance and to help avoid confusion for our localization team
    private static _dateTimeMomentJsStrings: Map<string, string> = null;

    private static _isCurrentLocale12HourTime = false;

    private static _commonStrings: Map<string, string> = null;

    private static _currentLocale: string = null;

    /**
     * Initialize DateTimeFormatter module.
     * @param {string} currentLocale - current locale
     */
    public static initialize(currentLocale: string): void {
        DateTimeFormatter._currentLocale = currentLocale;

        // Initialize the static string resources maps
        DateTimeFormatter._dateTimeStrings = StringsStore().registeredStringModules.get("dateTimeFormats").strings;
        DateTimeFormatter._dateTimeMomentJsStrings = StringsStore().registeredStringModules.get("dateTimeFormatsMomentJs").strings;
        DateTimeFormatter._commonStrings = StringsStore().registeredStringModules.get("common").strings;

        // Use Intl.DateTimeFormat to determine whether the current locale should use 12-hour or 24-hour time format
        const dateTimeFormat: Intl.DateTimeFormat = new Intl.DateTimeFormat(currentLocale, { timeZone: 'UTC', hour: 'numeric' });
        if (dateTimeFormat) {
            const resolveOptions = dateTimeFormat.resolvedOptions();
            DateTimeFormatter._isCurrentLocale12HourTime = resolveOptions && resolveOptions.hour12;
        }
    }

    /**
     * Generates a formatted string for the specified moment.
     * The formatted string may render just the date, time or both date + time for the moment depending on the specified format type.
     *
     * Note:
     * StaffHub design calls for very specific date time display formatting that aren't supported by standard
     * date time library calls (eg, MomentJS, Javascript toLocaleString).
     * This method makes use of MomentJS, Javascript toLocaleString, and custom code to implement these display formats, using
     * the standard libraries' localization support as much as possible.
     * This implementation also tries to minimize the number of custom localized string format resources that our dev and localization
     * teams need to support.
     *
     * @param {Moment} dateTime - moment to format
     * @param {DateTimeFormatType} dateTimeFormatType - format type to use to render the moment
     * @returns {string} formatted date time string
     */
    public static getDateTimeAsString(dateTime: Moment, dateTimeFormatType: DateTimeFormatType): string {
        if (!dateTime) {
            return "";
        }

        let result: string = "";

        // Javascript toLocaleString options:
        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/DateTimeFormat
        // Set this value to use Javascript toLocaleString for date time formatting.
        let localeStringOptions: any = null; // using any as type can be Intl.DateTimeFormatOptions

        // MomentJS date time formats:
        // https://momentjs.com/docs/#/displaying/format/
        // Set this value to use MomentJS for date time formatting.
        let momentFormat: string = null;

        // Note:
        // Please keep the number of date time formats down to a minimum and reuse existing ones as much as possible.
        // Introducing new date time formats incurs costs in code and localization complexity that we should avoid.

        // Compute format string or result for the specified format type
        switch (dateTimeFormatType) {
            // Note:  For consistency, use Javascript toLocaleString for date formats and MomentJS for time formats.

            case DateTimeFormatType.Time:  // 8:00 PM
                momentFormat = DateTimeFormatter.getTimeFormat(dateTime, false /* useShortFormat */);
                break;
            case DateTimeFormatType.Time_Short:  // 8 PM
                momentFormat = DateTimeFormatter.getTimeFormat(dateTime, true /* useShortFormat */);
                break;
            case DateTimeFormatType.Time_HourOnly:  // 8
                momentFormat = DateTimeFormatter.getTimeFormatHourOnly(dateTime);
                break;
            case DateTimeFormatType.Time_MeridiemOnly:  // PM
                momentFormat = DateTimeFormatter.getTimeFormatMeridiemOnly(dateTime);
                break;
            case DateTimeFormatType.Date_Numeric:  // 12/27/1985
                localeStringOptions = { month: "numeric", day: "numeric", year: "numeric" };
                break;
            case DateTimeFormatType.Date_DateMonthNumeric:  // 12/27
                localeStringOptions = { month: "numeric", day: "numeric" };
                break;
            case DateTimeFormatType.Date_DateMonthShort:  //  Dec 27
                localeStringOptions = { month: "short", day: "numeric" };
                break;
            case DateTimeFormatType.Date_DateMonthLong:  // December 27
                localeStringOptions = { month: "long", day: "numeric" };
                break;
            case DateTimeFormatType.Date_MonthYearLong:  // December 1985
                localeStringOptions = { year: "numeric", month: "long" };
                break;
            case DateTimeFormatType.Date_MonthLong:      // December
                localeStringOptions = { month: "long" };
                break;
            case DateTimeFormatType.Date_DayDateMonthYear:  // Wednesday, Dec 27, 1985
                localeStringOptions = { year: "numeric", month: "short", day: "numeric", weekday: "long" };
                break;
            case DateTimeFormatType.Date_DayShortDateMonthYear:  // Wed, Dec 27, 1985
                localeStringOptions = { year: "numeric", month: "short", day: "numeric", weekday: "short" };
                break;
            case DateTimeFormatType.Date_DayDateMonthYearLong:  // Wednesday, December 27, 1985
                localeStringOptions = { year: "numeric", month: "long", day: "numeric", weekday: "long" };
                break;
            case DateTimeFormatType.Date_DayDateMonthShort:  // Wed Dec 27
                localeStringOptions = { month: "short", day: "numeric", weekday: "short" };
                break;
            case DateTimeFormatType.Date_DateMonthYearLong:  // December 27, 1985
                localeStringOptions = { year: "numeric", month: "long", day: "numeric" };
                break;
            case DateTimeFormatType.Date_DateNumeric:  // 27
                localeStringOptions = { day: "numeric" };
                break;
            case DateTimeFormatType.Date_YearNumeric:  // 1985
                localeStringOptions = { year: "numeric" };
                break;
            case DateTimeFormatType.Date_Day:  // Wednesday
                momentFormat = "dddd";
                break;
            case DateTimeFormatType.Date_DayShort:  // Wed
                momentFormat = "ddd";
                break;
            case DateTimeFormatType.Date_DayNarrow:  // We
                // We use MomentJS instead of JS toLocaleString because JS' short versions are too short (eg, Sat and Sun are both rendered as "S")
                momentFormat = "dd";
                break;
            case DateTimeFormatType.DateTime_DateNumeric_Time:  // 12/27 8 PM
                result = DateTimeFormatter.constructDateTimeString(dateTime, DateTimeFormatType.Date_DateMonthNumeric, DateTimeFormatType.Time_Short);
                break;
            case DateTimeFormatType.DateTime_DateShort_Time:  // Dec 27, 8 PM
                result = DateTimeFormatter.constructDateTimeString(dateTime, DateTimeFormatType.Date_DateMonthShort, DateTimeFormatType.Time_Short, true /* useComma */);
                break;
        }

        try {
            if (localeStringOptions) {
                // Use Javascript toLocaleString to generate the formatted date time string
                // Not all browsers support DateTimeFormat options' timeZone property (eg, IE11 only handles local timezone or UTC), so
                // here we convert the date time for the correct timezone ourselves instead.
                const displayDate: Date = DateUtils.convertTimezoneDateTimeToDisplayTime(
                    dateTime.toDate(),
                    moment().tz()
                );
                if (displayDate) {
                    result = displayDate.toLocaleString(
                        DateTimeFormatter._currentLocale,
                        localeStringOptions as Intl.DateTimeFormatOptions
                    );
                }
            } else if (momentFormat) {
                // Use MomentJS to generate the formatted date time string
                result = dateTime.format(momentFormat);
            }
        } catch (error) {
            console.error(error);
            result = "";
        }
        // Otherwise the result should have been assigned a formatted date time string

        return result;
    }

    /**
     * Returns true if the current locale is on 12-hour time. false if the 24-hour time.
     */
    public static isCurrentLocale12HourTime(): boolean {
        return DateTimeFormatter._isCurrentLocale12HourTime;
    }

    /**
     * Generates a formatted date time range string for events that may be multi-day and thus need date info to be
     * included along with the times (eg, timeoffs, shift requests, etc)
     * @param {Moment} startTime
     * @param {Moment} endTime
     * @param {boolean} useLongFormat - set to true to generate the longer/more verbose version of the string
     * @param {GetAllDayDateTimeAsStringFunction} getAllDayDateTimeAsStringFunction
     * @param {GetSameDayDateTimeRangeAsStringFunction} getSameDayDateTimeRangeAsStringFunction
     * @returns {string} formatted date time range string
     */
    private static generateEventDateTimeRangeAsString(
        startTime: Moment,
        endTime: Moment,
        useLongFormat: boolean,
        getAllDayDateTimeAsStringFunction: GetAllDayDateTimeAsStringFunction,
        getSameDayDateTimeRangeAsStringFunction: GetSameDayDateTimeRangeAsStringFunction
    ) {
        let rangeString = "";
        const isAllDayRange = DateUtils.isAllDayTimeRange(startTime, endTime);
        const isSameDay = DateUtils.isTimeRangeForTheSameDay(startTime, endTime);
        if (isAllDayRange) {
            if (isSameDay) {
                // If the range just represents a single full day, then represent the range as a single all day event.
                rangeString = getAllDayDateTimeAsStringFunction(startTime, useLongFormat);
            } else {
                // Get the actual last inclusive day for the date range.
                // If the All Day range ends at the beginning of the next day (ie, midnight), then
                // we want to use the previous day for displaying the end date.
                // eg, "5/1 12am - 5/3 12am" should be rendered as "5/1 - 5/2"
                let lastDay = DateUtils.getLastDayForEndDate(endTime);

                const dateOnlyFormatType: DateTimeFormatType = useLongFormat ? DateTimeFormatType.Date_DateMonthShort : DateTimeFormatType.Date_DateMonthNumeric;
                rangeString = DateTimeFormatter.getDateTimeRangeAsString(startTime, lastDay, dateOnlyFormatType);
            }
        } else {
            let dateTimeFormatType: DateTimeFormatType;
            if (isSameDay) {
                rangeString = getSameDayDateTimeRangeAsStringFunction(startTime, endTime, useLongFormat);
            } else {
                // Otherwise display the dates + times
                dateTimeFormatType = useLongFormat ? DateTimeFormatType.DateTime_DateShort_Time : DateTimeFormatType.DateTime_DateNumeric_Time;
                rangeString = DateTimeFormatter.getDateTimeRangeAsString(startTime, endTime, dateTimeFormatType);
            }
        }
        return rangeString;
    }

    /**
     * Helper for computing the string for when a shift runs through all of a single day
     */
    private static getAllDayDateTimeAsStringForShift(dateTime: Moment, useLongFormat: boolean): string {
        return useLongFormat ? DateTimeFormatter.getDateTimeAsString(dateTime, DateTimeFormatType.Date_DateMonthShort) : DateTimeFormatter.getString("allDayEvent");
    }

    /**
     * Helper for computing the time range for when a shift starts and ends on the same day
     */
    private static getSameDayDateTimeRangeAsStringForShift(startDate: Moment, endDate: Moment, useLongFormat: boolean): string {
        return DateTimeFormatter.getDateTimeRangeAsString(startDate, endDate, DateTimeFormatType.Time_Short);
    }

    /**
     * Generates a formatted date time range string for events that need both date and time info to be rendered (eg, multi-day events such as timeoffs)
     * @param {Moment} startTime
     * @param {Moment} endTime
     * @param {boolean} useLongFormat - set to true to generate the longer/more verbose version of the string
     * @returns {string} formatted date time range string
     */
    public static getEventDateTimeRangeAsString(startTime: Moment, endTime: Moment, useLongFormat?: boolean): string {
        return DateTimeFormatter.generateEventDateTimeRangeAsString(
            startTime,
            endTime,
            useLongFormat,
            DateTimeFormatter.getAllDayDateTimeAsStringForShift,
            useLongFormat
                ? DateTimeFormatter.getSameDayDateTimeRangeAsStringForShiftRequest
                : DateTimeFormatter.getSameDayDateTimeRangeAsStringForShift
        );
    }

    private static _eventDateTimeRangeStringCache: {[key: string]: string} = {};

    public static fastGetEventDateTimeRangeAsString = MemoizeUtils.memoizeUtility(
        DateTimeFormatter.getEventDateTimeRangeAsString,
        DateTimeFormatter._eventDateTimeRangeStringCache,
        (args: any[]) => {
            return `${args[0].valueOf()}:${args[1].valueOf()}:${!!args[2]}`;
        }
    );

    /**
     * Generate a formatted time range string for events that don't need date info and only need the time info to be rendered
     * (eg, shifts, which are only allowed to span a day)
     * Only the times are rendered, and date info is not included, even if the event spans into the
     * next day.
     * @param {Moment} startTime start time
     * @param {Moment} endTime end time
     * @returns {string} time range string
     */
    public static getEventTimeRangeAsString(startTime: Moment, endTime: Moment) {
        return DateTimeFormatter.getDateTimeRangeAsString(startTime, endTime, DateTimeFormatType.Time_Short);
    }

    /**
     * Generate the date range string for the Schedule header
     * @param currentSelectedDate currently selected date in the schedule
     * @param viewStartDate start date for the current schedule view
     * @param viewEndDate end date for the current schedule view
     * @param scheduleType schedule view type (eg, week, month, day)
     * @param isForAriaLabel pass true if this is for an aria label and the output will be a bit more verbose for screen readers
     */
    public static getScheduleHeaderDateRangeAsString(currentSelectedDate: Moment, viewStartDate: Moment, viewEndDate: Moment, scheduleType: ScheduleCalendarType, isForAriaLabel: boolean = false): string {
        let result: string = "";
        switch (scheduleType) {
            case ScheduleCalendarTypes.Day:
                // Day view: display the date of the currentl selected day
                result = DateTimeFormatter.getDateTimeAsString(currentSelectedDate, DateTimeFormatType.Date_DayDateMonthYear);
                break;
            case ScheduleCalendarTypes.Month:
                // Month view: display the month for the current selected date
                result = DateTimeFormatter.getDateTimeAsString(currentSelectedDate, DateTimeFormatType.Date_MonthYearLong);
                break;
            case ScheduleCalendarTypes.Week:
            default:
                // Week view and other date ranges: display the start and end dates for the range
                result = DateTimeFormatter.getDateRangeLongAsString(viewStartDate, viewEndDate, isForAriaLabel);
                break;
        }
        return result;
    }

    /**
     * Generates a date range in long format, which includes month, day and year.
     * If the year/month/day is the same for both start and end dates, then that component is only mentioned once instead of repeated for both start and end dates.
     * Ex: June 25 - Aug 1, 2017 (Different months, same year)
     * Ex: July 2 - 8, 2017 (Same month, same year)
     * Ex: December 25, 2017 - January 2, 2018 (Different years)
     * @param startDate start date for the range
     * @param endDate end date for the range
     * @param isForAriaLabel pass true if this is for an aria label and the output will be a bit more verbose for screen readers
     */
    public static getDateRangeLongAsString(startDate: Moment, endDate: Moment, isForAriaLabel: boolean = false): string {
        let result: string = "";
        if (startDate && endDate) {
            if (startDate.year() !== endDate.year()) {
                // Different years, so use the full date format for both start and end dates
                const startDateString: string = DateTimeFormatter.getDateTimeAsString(startDate, DateTimeFormatType.Date_DateMonthYearLong);
                const endDateString: string = DateTimeFormatter.getDateTimeAsString(endDate, DateTimeFormatType.Date_DateMonthYearLong);
                result = DateTimeFormatter.constructDateTimeRangeString(startDateString, endDateString);
            } else {
                // Same year, so only include the year once
                const yearString: string = DateTimeFormatter.getDateTimeAsString(startDate, DateTimeFormatType.Date_YearNumeric);
                if (!isForAriaLabel && startDate.month() === endDate.month()) {
                    // Same month, so only include the month once for the start date
                    const monthString: string = DateTimeFormatter.getDateTimeAsString(startDate, DateTimeFormatType.Date_MonthLong);
                    const startDateString: string = DateTimeFormatter.getDateTimeAsString(startDate, DateTimeFormatType.Date_DateNumeric);
                    const endDateString: string = DateTimeFormatter.getDateTimeAsString(endDate, DateTimeFormatType.Date_DateNumeric);
                    result = DateTimeFormatter.getString("dateTimeRangeFormatWithSameYearAndMonth").format(monthString, startDateString, endDateString, yearString);
                } else {
                    // Different months, so include the months for both start and end dates
                    const startDateString: string = DateTimeFormatter.getDateTimeAsString(startDate, DateTimeFormatType.Date_DateMonthLong);
                    const endDateString: string = DateTimeFormatter.getDateTimeAsString(endDate, DateTimeFormatType.Date_DateMonthLong);
                    result = DateTimeFormatter.getString("dateTimeRangeFormatWithSameYear").format(startDateString, endDateString, yearString);
                }
            }
        }
        return result;
    }

    /**
     * Helper for computing the time string when a shift request is for all day of a single date
     */
    private static getAllDayDateTimeAsStringForShiftRequest(dateTime: Moment, useLongFormat: boolean): string {
        const dateString: string = DateTimeFormatter.getDateTimeAsString(dateTime, DateTimeFormatType.Date_DateMonthShort);
        return DateTimeFormatter.getString("allDayEventWithDateFormat").format(dateString);
    }

    /**
     * Helper for computing the time range of a shift request when the shift request starts and ends on same day
     * Ex: Mar 14, 10 AM - 4:30 PM
     */
    private static getSameDayDateTimeRangeAsStringForShiftRequest(startDate: Moment, endDate: Moment, useLongFormat: boolean): string {
        const startDateFormat: DateTimeFormatType = useLongFormat ? DateTimeFormatType.DateTime_DateShort_Time : DateTimeFormatType.DateTime_DateNumeric_Time;
        return DateTimeFormatter.getDateTimeRangeAsString(startDate, endDate, startDateFormat, "" /* emptyTimeString */, DateTimeFormatType.Time_Short);
    }

    /**
     * Generates a formatted date time range string for shift requests
     */
    public static getShiftRequestDateTimeRangeAsString(startTime: Moment, endTime: Moment): string {
        return DateTimeFormatter.generateEventDateTimeRangeAsString(startTime, endTime, true /* useLongFormat */,
            DateTimeFormatter.getAllDayDateTimeAsStringForShiftRequest, DateTimeFormatter.getSameDayDateTimeRangeAsStringForShiftRequest);
    }

    /**
     * Constructs a formatted date time range string using the specified format type
     * @param {Moment} startTime
     * @param {Moment} endTime
     * @param {DateTimeFormatType} formatType - date time format type
     * @param {string} emptyTimeString - alternate string to use if a specified time is null
     * @param {DateTimeFormatType} endFormatType - Optionally set a different endFormatType than the start format type
     * @returns {string} formatted date time range
     */
    public static getDateTimeRangeAsString(startTime: Moment, endTime: Moment, formatType: DateTimeFormatType, emptyTimeString: string = "", endFormatType: DateTimeFormatType = formatType): string {
        const startTimeString: string = startTime ? DateTimeFormatter.getDateTimeAsString(startTime, formatType) : emptyTimeString;
        const endTimeString: string = endTime ? DateTimeFormatter.getDateTimeAsString(endTime, endFormatType) : emptyTimeString;
        return DateTimeFormatter.constructDateTimeRangeString(startTimeString, endTimeString);
    }

     /**
     * Use a short format for a time that returns the day of the week.
     * Any Day: Thursday
     * @param date
     */
     public static getDisplayTimeInWeekDayFormat(date: Moment): string {
        const displayTime = DateTimeFormatter.getDateTimeAsString(date, DateTimeFormatType.Date_Day);
        return displayTime;
    }

     /**
     * Use a short format for a time that returns the time of the day.
     * Any Day: Thursday
     * @param date
     */
     public static getDisplayTimeInTimeFormat(date: Moment): string {
        const displayTime = DateTimeFormatter.getDateTimeAsString(date, DateTimeFormatType.Time);
        return displayTime;
    }

    /**
     * Use a short format for a time that is different depending on how far the time is from now.
     * Same Day: 2:30 AM
     * Same Week: Thursday
     * Else: May 4
     * @param date
     */
    public static getDisplayTimeInRelativeFormat(date: Moment): string {
        let displayTime: string = "";
        const currentTime: Moment = moment();
        if (date) {
            if (currentTime.isSame(date, 'day')) {
                displayTime = DateTimeFormatter.getDateTimeAsString(date, DateTimeFormatType.Time);
            } else if (currentTime.isSame(date, 'week')) {
                displayTime = DateTimeFormatter.getDateTimeAsString(date, DateTimeFormatType.Date_Day);
            } else {
                displayTime = DateTimeFormatter.getDateTimeAsString(date, DateTimeFormatType.Date_DateMonthShort);
            }
        }
        return displayTime;
    }

    /**
     * Use a long format for a time that is different depending on how far the time is from now.
     * Same Day: Today 2:30 AM
     * Same Week: Thursday 2:30 AM
     * Else: May 4, 2:30 AM
     * @param date
     */
    public static getDisplayTimeInRelativeLongFormat(dateTime: Moment): string {
        let displayTime: string = "";
        const currentTime: Moment = moment();
        if (dateTime) {
            if (currentTime.isSame(dateTime, 'day')) {
                displayTime = DateTimeFormatter.getString("todayTimeFormat").format(DateTimeFormatter.getDateTimeAsString(dateTime, DateTimeFormatType.Time_Short));
            } else if (currentTime.isSame(dateTime, 'week')) {
                displayTime = DateTimeFormatter.constructDateTimeString(dateTime, DateTimeFormatType.Date_DayShort, DateTimeFormatType.Time_Short, true /* useComma */);
            } else {
                displayTime = DateTimeFormatter.getDateTimeAsString(dateTime, DateTimeFormatType.DateTime_DateShort_Time);
            }
        }
        return displayTime;
    }

    /**
     * It generate time duration string in hours and minutes ( For example: 7 hours 30 minutes / 7 hours / 30 minutes )
     * It calculates time duration  between start time and end time
     * If start and end time gap is 0 then timeDuration return undefined.
     */
    public static getTimeDurationForDisplay(startTime: Moment, endTime: Moment): string {
        let timeDuration: string;
        const hours: number = endTime.diff(startTime, 'hours' , false /** precise */);
        const minutes: number = DateUtils.getDifferenceInMinutesFromMoments(startTime, endTime, false /** precise */) % MINUTES_IN_HOUR;
        const hourStr: string = hours === 1 ? DateTimeFormatter.getString("oneHour") : DateTimeFormatter.getString("numHours").format(hours.toString());
        const minuteStr: string = DateTimeFormatter.getString("numMinutes").format(minutes.toString());

        if (hours && minutes) {
            timeDuration = DateTimeFormatter.getString("timeDuration").format(hourStr, minuteStr);
        } else if (hours) {
            timeDuration = hourStr;
        } else if (minutes) {
            timeDuration = minuteStr;
        }

        return timeDuration;
    }

     /**
     * It generate date time duration string in days, hours and minutes ( For example: 2d 3h / 2 days 3 hours, 7h 30m / 7 hours / 30 minutes )
     * It calculates time duration  between start time and end time
     * If start and end time gap is 0 then timeDuration return undefined.
     * @param startTime start time of the duration
     * @param endTime end time of the duration
     * @param displayShort set true if short display format is required
     */
    public static getDateTimeDurationForDisplay(startTime: Moment, endTime: Moment, displayShort?: boolean, isAllDay?: boolean): string {
        let timeDuration: string;
        const daysDurationFormat: string = displayShort ? "daysDurationShort" : "numDays";
        const hoursDurationFormat: string = displayShort ? "hoursDurationShort" : "numHours";
        const minutesDurationFormat: string = displayShort ? "minutesDurationShort" : "numMinutes";

        if (DateUtils.isAllDayTimeRange(startTime, endTime) || isAllDay) {
            const days: number = Math.ceil(DateUtils.getDifferenceInDaysFromMoments(startTime, endTime));
            const dayStr: string = DateTimeFormatter.getString(daysDurationFormat).format(days.toString());
            timeDuration = dayStr;
        } else {
            const minutes: number = DateUtils.getDifferenceInMinutesFromMoments(startTime, endTime, false) % MINUTES_IN_HOUR;
            const hours: number = DateUtils.getDifferenceInHoursFromMoments(startTime, endTime, false) % HOURS_IN_DAY;
            const days: number = DateUtils.getDifferenceInDaysFromMoments(startTime, endTime, false);
            const dayStr: string = DateTimeFormatter.getString(daysDurationFormat).format(days.toString());
            const hourStr: string = DateTimeFormatter.getString(hoursDurationFormat).format(hours.toString());
            const minuteStr: string = DateTimeFormatter.getString(minutesDurationFormat).format(minutes.toString());

            if (days && hours) {
                timeDuration = DateTimeFormatter.getString("timeDuration").format(dayStr, hourStr);
            } else if (hours && minutes) {
                timeDuration = DateTimeFormatter.getString("timeDuration").format(hourStr, minuteStr);
            } else if (days) {
                timeDuration = dayStr;
            } else if (hours) {
                timeDuration = hourStr;
            } else if (minutes) {
                timeDuration = minuteStr;
            }
        }

        return timeDuration;
    }

    /**
     * Construct a formatted date time range string using the specified start and end date time strings
     * @param {string} startTimeString
     * @param {string} endTimeString
     * @returns {string} date time range string
     */
    public static constructDateTimeRangeString(startTimeString: string, endTimeString: string): string {
        return DateTimeFormatter.getString("dateTimeRangeFormat").format(startTimeString, endTimeString);
    }

    /**
     * Returns the title for a 24Hr shift who's start time is the input parameter
     * Example: 8:00AM (24h)
     * @param startTime start time of the 24hr shift
     * @param dateTimeFormat date time format
     */
    public static get24HrShiftFormat(startTime: Moment, dateTimeFormat?: DateTimeFormatType) {
        if (!startTime) {
            return "";
        }

        return DateTimeFormatter._dateTimeStrings.get("shift24Format").format(
            DateTimeFormatter.getDateTimeAsString(startTime, dateTimeFormat ? dateTimeFormat : DateTimeFormatType.Time),
            DateTimeFormatter._commonStrings.get("24hLabel"));
    }

    /**
     * Returns the specified localized date time resource string
     * @param {string} stringResourceName string resource name
     * @returns {string} localized string resource
     */
    private static getString(stringResourceName: string): string {
        if (DateTimeFormatter._dateTimeStrings) {
            return DateTimeFormatter._dateTimeStrings.get(stringResourceName);
        } else {
            throw Error("DateTimeFormatter._dateTimeStrings not initialized.");
        }
    }

    /**
     * Returns the specified localized date time resource string for MomentJS formatting
     * @param {string} stringResourceName string resource name
     * @returns {string} localized string resource
     */
    private static getStringMomentJs(stringResourceName: string): string {
        if (DateTimeFormatter._dateTimeMomentJsStrings) {
            return DateTimeFormatter._dateTimeMomentJsStrings.get(stringResourceName);
        } else {
            throw Error("DateTimeFormatter._dateTimeMomentJsStrings not initialized.");
        }
    }

    /**
     * Returns a time format string for the specified time
     * @param {Moment} time - time to compute the appropriate time format for
     * @param {boolean} useShortFormat - set to true to use the short version for time display for locale with 12 hour format (eg, "8 AM" instead of "8:00 AM"). For 24 hour format, always show minutes component
     * @returns {string} momentJS time format string
     */
    private static getTimeFormat(time: Moment, useShortFormat: boolean): string {
        let timeFormat: string = "";
        if (time) {
            // For the short version, we omit the minutes portion if the time falls on the hour :00
            // No standard date time formatting libraries support this kind of customized time rendering, so we use custom MomentJS
            // time format strings to achieve this display format.
            // Times should be formatted to 12-hour or 24-hour format depending on the user's language and locale.
            if (DateTimeFormatter.isCurrentLocale12HourTime()) {
                const timeFormatHoursOnlyStringResourceName = "timeFormatHoursOnly12Hour";
                const timeFormatHoursMinutesStringResourceName = "timeFormatHoursMinutes12Hour";
                timeFormat = DateTimeFormatter.getStringMomentJs((useShortFormat && (time.minutes() === 0)) ? timeFormatHoursOnlyStringResourceName : timeFormatHoursMinutesStringResourceName);
            } else {
                timeFormat = DateTimeFormatter.getStringMomentJs("timeFormatHoursMinutes24Hour");
            }
        }
        return timeFormat;
    }

    /**
     * Returns a time format string for the specified time - hour portion only, no minutes or AM/PM
     * @param {Moment} time - time to compute the appropriate time format for
     * @returns {string} momentJS time format string
     */
    private static getTimeFormatHourOnly(time: Moment): string {
        let timeFormat: string = "";
        if (time) {
            timeFormat = DateTimeFormatter.getStringMomentJs(DateTimeFormatter.isCurrentLocale12HourTime() ? "timeFormatHoursOnly12HourNoMeridiem" : "timeFormatHoursOnly24Hour");
        }
        return timeFormat;
    }

    /**
     * Returns a time format string for the specified time - meridiem AM/PM portion only. Returns empty format string if 24-hour time.
     * @param {Moment} time - time to compute the appropriate time format for
     * @returns {string} momentJS time format string
     */
    private static getTimeFormatMeridiemOnly(time: Moment): string {
        let timeFormat: string = "";
        if (time) {
            timeFormat = DateTimeFormatter.isCurrentLocale12HourTime() ? MOMENTJS_MERIDIEM_FORMAT_STRING : "";
        }
        return timeFormat;
    }

    /**
     * Construct a formatted date + time string for the specified moment
     * StaffHub design calls for specific date time formats that don't match up with standard formats from our date time libraries (eg, Moment, Javascript toLocaleString, etc),
     * so this helper is used to customize the date and time components of the string.
     * @param {Moment} dateTime - moment to format
     * @param {DateTimeFormatType} dateFormatType - format type for the date portion
     * @param {DateTimeFormatType} timeFormatType - format type for the time portion
     * @param {boolean} useComma (optional) - separate date and time by a comma
     * @returns {string} formatted date time string
     */
    private static constructDateTimeString(dateTime: Moment, dateFormatType: DateTimeFormatType, timeFormatType: DateTimeFormatType, useComma: boolean = false): string {
        const dateString: string = DateTimeFormatter.getDateTimeAsString(dateTime, dateFormatType);
        const timeString: string = DateTimeFormatter.getDateTimeAsString(dateTime, timeFormatType);
        const dateTimeFormatString: string = useComma ? DateTimeFormatter.getString("dateCommaTimeFormat") : DateTimeFormatter.getString("dateTimeFormat");
        return dateTimeFormatString.format(dateString, timeString);
    }
}