import * as MemoizeUtils from "sh-application/utility/MemoizeUtils";
import { DataServices, InstrumentationService } from "sh-services";
import { orchestrator } from "satcheljs";
import { restartApp, UrlFactory } from "sh-application";

/**
 * Clears all locally stored data and restarts the application
 */
export default orchestrator(restartApp, async actionMessage => {
    // Instrument the event
    InstrumentationService.logEvent(InstrumentationService.events.RestartApp);
    // Clear all app data
    await DataServices.resetAppData();

    // clear memoize caches
    MemoizeUtils.clearMemoizeStores();

    // reload the /app page
    window.location.replace(UrlFactory.getFullAppNavigationUrl());
});