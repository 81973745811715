import { initializeIcons, loadTheme, registerIcons } from "@fluentui/react";
import * as H from "history";
import * as moment from "moment";
import { trace } from "owa-trace";
import { StaffHubHttpError } from "sh-application";
import { BootstrapConfig, bootstrap } from "sh-application/bootstrap";
import { handleBlockingBootstrapError } from "sh-application/components/errorWatcher";
import iconsData from "sh-application/iconsData";
import initializeSatchelMiddleware from "sh-application/initializeSatchelMiddleware";
import initializeStrings from "sh-application/initializeStrings";
import { initializeTracer } from "sh-application/initializeTracer";
import DateTimeFormatter from "sh-application/utility/DateTimeFormatter";
import initializeErrorHandler from "sh-exception-handler/lib/utils/initializeErrorHandler";
import { getGenericEventPropertiesObject } from "sh-instrumentation";
import { UserEntity } from "sh-models";
import {
    ClientVersionService,
    DataServices,
    ECSConfigService,
    InstrumentationService,
    NetworkService,
    SyncService,
} from "sh-services";
import { setHostPlatform, setUser } from "sh-stores";

import { ClientPlatformTypes, ClientTypes } from "../StaffHubConstants";

import { HostClientType } from "./hostsContainers/HostClientType";
import { HostContext } from "./hostsContainers/HostContext";
import { IHostContainer } from "./hostsContainers/IHostContainer";
import { initializeThemes } from "./initializeTheme";

const styles = require("../../Theme.scss");

/**
 * Pre-initializes the application. Setup error handling and initializes Teams SDK.
 * @param appVersion The application version.
 * @param hostContainer The host container (e.g. TeamsHostContainer).
 */
export async function preinitApplication(appVersion: string, hostContainer: IHostContainer): Promise<void> {
    try {
        const initializationCallback = await hostContainer.initialize();

        // **** error handling ****
        // TODO(Performance): Remove verbose logging altogether to improve performance.
        const verboseLogging = false;
        // logging (info, warnings, errors)
        initializeTracer();
        // shows errors within client UI (dev only)
        initializeErrorHandler(verboseLogging);
        // logs satchel actions and stiches to console
        // keeps record of satchel actions (useful for attaching to logs)
        // logs satchel action perf data to aria
        initializeSatchelMiddleware(verboseLogging);

        // Get context from host (e.g. Teams)
        const context = await hostContainer.getContext();

        setUserInfoInState(context.user.id, context.tenant.id);

        // Load the ecs config with value from service + base config
        const ecsConfig = JSON.parse(window.sa.ecsConfigJson);

        // Initialize the Instrumentation service
        await InstrumentationService.initialize(appVersion, context, ecsConfig);

        initializeThemes(context.app.theme);
        // TODO: Rename without Teams to be platform agnostic
        setClientPlatformFromTeamsContext(context);

        ECSConfigService.initializeECSConfig(
            context.tenant.id,
            context.host.ringId,
            context.user.id,
            ecsConfig
        );

        // setting logged in user from teams context
        let dataServicesLoginMarker = "setUserWithinDataServices";
        dataServicesLoginMarker = InstrumentationService.perfMarkerStart(dataServicesLoginMarker);
        await DataServices.setUserLoggedIn(context.user.id, context.tenant.id, context.host.sessionId);
        InstrumentationService.perfMarkerEnd(dataServicesLoginMarker);

        // Give the dataservices a chance to prune unused data (but don't await this)
        setTimeout(async () => {
            await DataServices.pruneData();

            // Start the logging session, if it hasn't already been started
            InstrumentationService.startLogSession();
        }, 5000);

        // **** Client versioning ****
        // Tell the user they need to refresh if a new version of the app is pushed
        ClientVersionService.init(appVersion);

        // **** Localized Strings ****
        // the final step in pre-initalizing the app is to download the localized strings.
        // this is done async and app initialization should not continue until all the strings
        // are downloaded.
        let stringsMarker = "initializeStrings";
        stringsMarker = InstrumentationService.perfMarkerStart(stringsMarker);
        await initializeStrings(context.app.locale);
        InstrumentationService.perfMarkerEnd(stringsMarker);

        // init DateTimeFormatter module with current locale data
        // passing the context for locale info
        DateTimeFormatter.initialize(context.app.locale);

        // Use the browser timezone as a best-guess for the user's timezone and initialize moment with it.
        // This will be overriden with the team timezone if it differs after team bootstrap. Initializing it here
        // is a perf optimization that ensures we do not launch the timeZoneChanged action unnecessarily after initializing
        // a team during cold boot, where we compare the current moment timezone to the team's timezone. Without setting some
        // timezone here, the current timezone will be undefined.
        moment.tz.setDefault(moment.tz.guess());

        // Register icons and pull the fonts from the default SharePoint cdn.
        initializeIcons();

        registerIcons({ icons: iconsData });
        // set our office fabric theme overrides
        loadTheme({
            palette: {
                themeDark: styles.brandDark,
                themeDarkAlt: styles.brandDark,
                themePrimary: styles.brandDefault,
                themeSecondary: styles.brandDark
            },
            fonts: {},
            semanticColors: {}
        });

        const locateUserMarker = InstrumentationService.perfMarkerStart("locateUserRegion");
        const locateUserResponse = await DataServices.UserDataService.locateUserRegion();
        InstrumentationService.perfMarkerEnd(locateUserMarker);

        // End the logging session if the user navigates away
        window.addEventListener("beforeunload", endLoggingSession);

        // Non-blocking login to Shifts service and initializing live updates
        loginAndInitializeLiveUpdates(
            getClientTypeFromTeamsContext(context),
            locateUserResponse.region?.afdServiceUrl
        );

        // Tab load successful
        initializationCallback();
    } catch (loginError) {
        // Tab load failed
        const errorMessage = loginError?.staffHubTopLevelErrorMessage ??
            loginError?.message ??
            "Failed to initialize the app";

        hostContainer.reportError(new Error(errorMessage));

        InstrumentationService.logPerfEvent(InstrumentationService.events.AppPreInitFailed, [
            getGenericEventPropertiesObject(InstrumentationService.properties.Error, errorMessage)
        ]);

        // If there is an api error, it can get marked as handled in handleStaffHubServiceError which handles it as showing a global message
        // But since the app is in preInit stage, we should be handling the error here to show the blocking message.
        // This can be removed once we onboard to extension tab load error ui. But for now, we should force it to show the error message here
        if (loginError && loginError.isHandled) {
            loginError.isHandled = false;
        }
        // something went wrong during login, redirect to the access blocked page
        // which will check the error and show something appropriate
        handleBlockingBootstrapError(loginError, null);
    }
}

const setUserInfoInState = (userId: string, tenantId: string): void => {
    setUser(new UserEntity(
        userId,
        "",
        userId,
        tenantId,
        "",
        "",
        "",
        "",
        "",
        false,
        false,
        "",
        "",
        ""
    ));
};

const loginAndInitializeLiveUpdates = async (clientType: ClientTypes, serviceUrl?: string): Promise<void> => {
    try {
        await loginToShiftsService(clientType);
        await initializeLiveUpdates(serviceUrl);
    } catch (exception) {
        if (exception?.isHandled) {
            exception.isHandled = false;
        }

        handleBlockingBootstrapError(exception, null);
    }
};

const loginToShiftsService = async (clientType: ClientTypes): Promise<void> => {
    const perfMarker = InstrumentationService.perfMarkerStart("loginToService");

    await DataServices.UserDataService.loginToService(clientType);

    InstrumentationService.perfMarkerEnd(perfMarker);
};

const initializeLiveUpdates = async (serviceUrl?: string): Promise<void> => {
    if (serviceUrl) {
        await SyncService.initialize();
    } else {
        trace.warn("Not starting Sync as service direct url not available");
    }
};

/**
 * Ends the logging session.
 */
function endLoggingSession(): void {
    InstrumentationService.endLogSession();
}

/**
 * Start the bootstrap process.
 * @param {string} groupId - the groupId of the team the user is logging in to
 * @param {string} teamId - the team the user is logging in to
 * @param {string} location - the routing location string
 * @param {H.History} history - browser history instance
 */
export async function initializeApplication(
    tenantId: string,
    groupId: string,
    teamId: string,
    location: string,
    history: H.History
): Promise<void> {
    const config: BootstrapConfig = {
        groupId,
        location,
        teamId,
        tenantId
    };

    const bootstrapMarker = InstrumentationService.perfMarkerStart("bootstrap");

    return bootstrap(config)
        .catch((httpError: StaffHubHttpError) => {
            // something went wrong during bootstrap, redirect to the access blocked page
            // which will check the error and show something appropriate
            handleBlockingBootstrapError(httpError, history);
        })
        .then(() => {
            InstrumentationService.perfMarkerEnd(bootstrapMarker);
        });
}

// Return ClientType based on Current Teams Context
// TODO: Use platform agnostic client type
function getClientTypeFromTeamsContext(context: HostContext): ClientTypes {
    return context && context.host.clientType == HostClientType.desktop
        ? ClientTypes.Desktop
        : ClientTypes.Web;
}

// TODO: Use platform agnostic client type
function setClientPlatformFromTeamsContext(context: HostContext) {
    const clientPlatform =
        context && context.host.clientType == HostClientType.desktop
            ? ClientPlatformTypes.TeamsDesktop
            : ClientPlatformTypes.TeamsWeb;
    NetworkService.setClientPlatform(clientPlatform);
    setHostPlatform(context.host.clientType);
}
