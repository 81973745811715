import * as moment from "moment";
import * as React from "react";
import ConflictUtils from "sh-application/utility/ConflictUtils";
import DateUtils from "sh-application/utility/DateUtils";
import groupedRequestDetailsStore, { approveDenyOpenShiftRequests, setUserResponseMessageInStore, toggleRequestSelectionInStore } from "./store/store";
import MemberUtils from "sh-application/utility/MemberUtils";
import OpenShiftRequestDetails from "./openShiftRequest/OpenShiftRequestDetails";
import RequestConflict from "sh-application/components/shiftrequest/conflict/RequestConflict";
import ShiftRequestUtils, { ShiftRequestStatus } from "sh-application/utility/ShiftRequestUtils";
import ShiftUtils from "sh-application/utility/ShiftUtils";
import Spinner, { SpinnerSize } from "sh-application/components/common/Spinner";
import StringsStore from "sh-strings/store";
import StringUtils from "sh-application/utility/StringUtils";
import WarningIndicator from "sh-application/components/common/WarningIndicator";
import { conflictRequestStore } from "sh-requestconflict-store";
import { DataProcessingHelpers, InstrumentationService } from "sh-services";
import { DefaultButton, PrimaryButton, TextField } from "@fluentui/react";
import { getGenericEventPropertiesObject } from "sh-instrumentation";
import {
    IBaseConflictEntity,
    IGroupedOpenShiftRequestEntity,
    IOpenShiftEntity,
    IShiftRequestEntity,
    ShiftRequestStates,
    ShiftRequestTypes
    } from "sh-models";
import { IndividualOpenShiftRequestDetails } from "./store/schema/GroupedRequestDetailsStoreSchema";
import { MAX_SHIFTNOTES_LENGTH } from "sh-application/../StaffHubConstants";
import { observer } from "mobx-react";
import { StaffHubHttpError } from "sh-application";
import { TeamStore } from "sh-stores/sh-team-store";
import { CrossLocationRequestDetails } from "./crossLocationRequest/CrossLocationRequestDetails";
import { fireAccessibilityAlert } from "sh-application/components/accessibilityAlert";
import KeyboardUtils from "../../../utility/KeyboardUtils";

export interface GroupedRequestDetailsProps {
    // callers set this to true in order to make this component render appropriately within a callout
    isCalloutMode?: boolean;

    // Will be called after the action is completed on the request. The OpenShift and Action is needed to select the appropriate item in the panel
    onApproveDenyCallback?: (openShiftId: string, isAccepting: boolean) => void;

    // Error message is displayed diffently on panel vs callout
    onErrorCallback?: (exception: StaffHubHttpError) => void;
}

const styles = require("./GroupedRequestDetails.scss");
const classNames = require("classnames/bind");

/**
 * React component for grouped openshift request details
 */
@observer
export default class GroupedRequestDetails extends React.Component<GroupedRequestDetailsProps, {}> {
    protected _strings: Map<string, string>;
    private _commonStrings: Map<string, string>;
    private _requestHasConflicts: boolean;
    private _accessibilityAlertDelay: number = 2000;

    constructor(props: GroupedRequestDetailsProps) {
        super(props);

        this._strings = StringsStore().registeredStringModules.get("shiftRequests").strings;
        this._commonStrings = StringsStore().registeredStringModules.get("common").strings;
        this._requestHasConflicts = false;
    }

    /**
     * Render request details
     */
    private renderRequestDetails(): JSX.Element {
        const { isCalloutMode } = this.props;

        if (!(groupedRequestDetailsStore && groupedRequestDetailsStore.individualRequestDetails && groupedRequestDetailsStore.openShift && groupedRequestDetailsStore.groupedOpenShiftRequest)) {
            return null;
        }
        const groupedRequestStatus: string = ShiftRequestUtils.getShiftRequestStatus(groupedRequestDetailsStore.groupedOpenShiftRequest);
        const currentUser = TeamStore() && TeamStore().me;
        const isAdmin: boolean = MemberUtils.isAdmin(currentUser);
        const isSelectionEnabled: boolean = isAdmin && (groupedRequestStatus === ShiftRequestStatus.InProgress);

        const countsDisplayedOnHeader: string = this.getRequestsAndSlotsCountForDisplay(isCalloutMode, groupedRequestStatus);

        const selectedRequestsForDisplay: string = this._strings.get("selectedRequestsCountText").format(groupedRequestDetailsStore.selectedRequestsCount.toString());
        return (
            <>
                <div className={ classNames(styles.requestsCount, isCalloutMode ? styles.calloutItemsContainer : styles.panelItemsContainer) } >
                    <div className={ styles.slotsCount }>
                        { countsDisplayedOnHeader }
                    </div>
                    {
                        isSelectionEnabled &&
                            <div className={ styles.selectedRequestsCount }>
                                { selectedRequestsForDisplay }
                            </div>
                    }
                </div>
                {
                    /* Selection should be disabled when saving changes */
                    this.renderRequestList(isSelectionEnabled && !groupedRequestDetailsStore.isSaving)
                }
            </>
        );
    }

    private hasConflicts(conflictEntities: IBaseConflictEntity[], shiftRequest: IShiftRequestEntity): boolean {
        let hasConflicts: boolean = false;
        if (conflictEntities) {
            conflictEntities.forEach((conflictEntity: IBaseConflictEntity) => {
                if (conflictEntity.memberId === shiftRequest.senderMemberId) {
                    hasConflicts = true;
                }
            });
        }
        this._requestHasConflicts = hasConflicts;
        return hasConflicts;
    }

    /**
     * Renders the list of conflict for the current requested shift
     * @param shiftRequest Shift request entity
     */
    private renderRequestConflict(shiftRequest: IShiftRequestEntity): JSX.Element {
        if (!shiftRequest) {
            return;
        }

        // show requestConflict if shifts is in conflictRequestStore and state is pending
        const showRequestConflicts = (shiftRequest.state === ShiftRequestStates.WaitingOnManager || shiftRequest.state === ShiftRequestStates.WaitingOnReceiver);
        // fetch the conflict entities for current specified shift
        const conflictEntities = showRequestConflicts ? ConflictUtils.getRequestConflictEntities(shiftRequest.shiftId, conflictRequestStore().requestShiftIdToConflictsMap) : [];
        // fetch the shift from conflict store
        const shiftWithConflicts = showRequestConflicts && conflictRequestStore().requestShift.get(shiftRequest.shiftId);

        return (
            showRequestConflicts &&
            <div className={ styles.requestConflictDetails }>
                <RequestConflict
                    conflictEntities={ conflictEntities }
                    shiftRequest={ shiftRequest }
                    shiftWithConflict={ shiftWithConflicts }
                    memberId={ shiftRequest.senderMemberId }
                    hasConflicts={ this.hasConflicts(conflictEntities, shiftRequest) } />
            </div>
        );
    }

    /**
     * Renders the section for user action
     * @param shiftRequest
     */
    private renderActions(): JSX.Element {
        if (!groupedRequestDetailsStore) {
            return null;
        }

        const { isCalloutMode } = this.props;
        const isApproving: boolean = groupedRequestDetailsStore.isSaving && groupedRequestDetailsStore.isApproving;
        const isDenying: boolean = groupedRequestDetailsStore.isSaving && !groupedRequestDetailsStore.isApproving;
        const acceptButtonText: string = isApproving ? this._commonStrings.get("approving") : this._commonStrings.get("approve") ;
        const declineButtonText: string = isDenying ? this._commonStrings.get("denying") : this._commonStrings.get("deny");

        const areActionButtonsDisabled = groupedRequestDetailsStore.isSaving || (groupedRequestDetailsStore.selectedRequestsCount === 0);
        const isApproveDisabled: boolean = areActionButtonsDisabled || (groupedRequestDetailsStore.openShift && groupedRequestDetailsStore.selectedRequestsCount > groupedRequestDetailsStore.openShift.openSlots);
        const sharedAndDraftTimesDiffer: boolean = ShiftUtils.sharedAndDraftTimesDiffer(groupedRequestDetailsStore.openShift);

        return (
            <div className={ classNames(styles.responseContainer, isCalloutMode ? styles.calloutItemsContainer : styles.panelItemsContainer) }>
                <div className={ classNames(styles.inputFieldContainer, {[styles.withinCallout]: isCalloutMode}) }>
                    {
                        sharedAndDraftTimesDiffer &&
                            <WarningIndicator className={ styles.conflictIndicator } text={ this._strings.get("openShiftRequestTimeConflictText") }/>
                    }
                    <TextField
                        disabled={ areActionButtonsDisabled }
                        maxLength={ MAX_SHIFTNOTES_LENGTH }
                        placeholder={ this._strings.get("messageInputPlaceholder") }
                        ariaLabel={ this._strings.get("responseEditorAriaLabel") }
                        onChange={ this.onResponseMessageChange } />
                </div>
                <div role = "application" className={ classNames(styles.footer) }>
                    <DefaultButton
                        className={ styles.button }
                        disabled={ areActionButtonsDisabled }
                        onKeyDown= { this.onDenyOpenShiftRequestsOnKeyDown }
                        onClick={ this.onDenyOpenShiftRequests }
                        ariaLabel={ declineButtonText }>
                            { declineButtonText } { isDenying && <span className={ styles.progressDots } /> }
                    </DefaultButton>
                    <PrimaryButton
                        className={ classNames(styles.button, styles.primaryButton) }
                        disabled={ isApproveDisabled }
                        onKeyDown ={ this.onApproveOpenShiftRequestsOnKeyDown}
                        onClick={ this.onApproveOpenShiftRequests }
                        ariaLabel={ acceptButtonText }>
                            { acceptButtonText } { isApproving && <span className={ styles.progressDots } /> }
                    </PrimaryButton>
                </div>
            </div>
        );
    }

    /**
     * Callback when user clicks Approve button
     */
    private onApproveOpenShiftRequests = (event: React.MouseEvent<HTMLButtonElement>): void => {
    // event.detail > 0 on a mouse click event. This is to make sure NVDA won't intercept.
       if (event.detail > 0) {
        this.handleApproveDenyRequestsOnClick(true, InstrumentationService.ShiftRequestActionTaken.OpenShiftApprove);
       }
    }

     /**
     * Callback when user presses Enter/Space to Approve button
     */
     private onApproveOpenShiftRequestsOnKeyDown = (keyboardEvent: React.KeyboardEvent<HTMLButtonElement>): void => {
        if (KeyboardUtils.isActionKeyPressed(keyboardEvent)) {
            this.handleApproveDenyRequestsOnKeyDown(true, InstrumentationService.ShiftRequestActionTaken.OpenShiftApprove, this._commonStrings.get("approving"));
        }
    }

    /**
     * Callback when user clicks Deny button
     */
    private onDenyOpenShiftRequests = (event: React.MouseEvent<HTMLButtonElement>) => {
        // event.detail > 0 on a mouse click event. This is to make sure NVDA won't intercept.
        if (event.detail > 0) {
            this.handleApproveDenyRequestsOnClick(false, InstrumentationService.ShiftRequestActionTaken.OpenShiftDeny);
        }
    }

    /**
     * Callback when user presses Enter/Space to Deny button
     */
    private onDenyOpenShiftRequestsOnKeyDown = (keyboardEvent: React.KeyboardEvent<HTMLButtonElement>) => {
        if (KeyboardUtils.isActionKeyPressed(keyboardEvent)) {
            this.handleApproveDenyRequestsOnKeyDown(false, InstrumentationService.ShiftRequestActionTaken.OpenShiftDeny, this._commonStrings.get("denying"));
        }
    }

    /**
     * Handler method for Keydown event
     */
    private handleApproveDenyRequestsOnKeyDown = (isAccepting: boolean, actionTaken: string, accessibilityAlert: string) => {
        fireAccessibilityAlert(accessibilityAlert);
        this.instrumentAction(actionTaken);
        setTimeout(() => {
            this.approveDenyRequests(isAccepting /* isAccepting */);
        }, this._accessibilityAlertDelay);
    }

     /**
     * Handler method for click event
     */
     private handleApproveDenyRequestsOnClick = (isAccepting: boolean, actionTaken: string) => {
        this.instrumentAction(actionTaken);
        this.approveDenyRequests(isAccepting /* isAccepting */);
    }

    /**
     * Handles approve/deny for the shift request.
     * @param isAccepting True to Approve the request. False to Deny the request.
     */
    private approveDenyRequests = (isAccepting: boolean) => {
        // TODO: Instrumentation

        approveDenyOpenShiftRequests(isAccepting, this.props.onApproveDenyCallback, this.props.onErrorCallback);
    }

    /**
     * Instrument a shift request action
     * @param actionTaken The instrumentation value for the action taken
     */
    private instrumentAction(actionTaken: string) {
        const request: IGroupedOpenShiftRequestEntity = groupedRequestDetailsStore.groupedOpenShiftRequest;
        const openShift: IOpenShiftEntity = groupedRequestDetailsStore.openShift;
        if (request && openShift) {
            const wasReasonProvided: boolean = !!(groupedRequestDetailsStore.userResponseMessage);
            const numberRequests: number = groupedRequestDetailsStore.selectedRequestsCount;
            const numberSlots: number = openShift.openSlots;
            const hasUnsharedEdits: boolean = ShiftUtils.shiftHasUnsharedEdits(openShift);

            let instrumentationProperties = [
                getGenericEventPropertiesObject(InstrumentationService.properties.EventType, InstrumentationService.values.RequestActionClicked),
                getGenericEventPropertiesObject(InstrumentationService.properties.RequestType, InstrumentationService.ShiftRequestTypes.OpenShifts),
                getGenericEventPropertiesObject(InstrumentationService.properties.ActionTaken, actionTaken),
                getGenericEventPropertiesObject(InstrumentationService.properties.WasReasonProvided, wasReasonProvided),
                getGenericEventPropertiesObject(InstrumentationService.properties.NumberRequests, numberRequests),
                getGenericEventPropertiesObject(InstrumentationService.properties.NumberSlot, numberSlots),
                getGenericEventPropertiesObject(InstrumentationService.properties.HasDifferentDraftChanges, hasUnsharedEdits),
                getGenericEventPropertiesObject(InstrumentationService.properties.IsManager, true),
                getGenericEventPropertiesObject(InstrumentationService.properties.HasConflicts, this._requestHasConflicts),
                getGenericEventPropertiesObject(InstrumentationService.properties.IsCrossLocationOpenShift, openShift.isCrossLocationOpenShift)
            ];

            if (actionTaken === InstrumentationService.ShiftRequestActionTaken.OpenShiftApprove) {
                const hoursFromOpenShift: number = DateUtils.getDifferenceInHoursFromMoments(moment(), openShift.startTime, false /* precise */);
                instrumentationProperties.push(
                    getGenericEventPropertiesObject(InstrumentationService.properties.HoursFromOpenShiftApproved, hoursFromOpenShift)
                );
            }

            InstrumentationService.logEvent(InstrumentationService.events.Requests, instrumentationProperties);
        }
    }

    /**
     * Updates the user response message
     * @param message response message
     */
    private onResponseMessageChange(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, message?: string) {
        message = message && message.trim();
        setUserResponseMessageInStore(message);
    }

    /**
     * Returns the slots and requests count for display
     * @param isCalloutMode True if the request details are being shown in a callout. Should show short format for inprogress requests
     * @param groupedRequestState indicates the state of the grouped request. InProgress/Approved/Declined/Cancelled
     */
    private getRequestsAndSlotsCountForDisplay(isCalloutMode: boolean, groupedRequestState: string) {
        let countsDisplayedOnHeader: string = "";
        const requestsCount: number = groupedRequestDetailsStore.individualRequestDetails.size;

        if ((groupedRequestState === ShiftRequestStatus.InProgress)) {
            const requestsCountForDisplay: string = StringUtils.usePluralForm(requestsCount)
                ? this._strings.get("requestsCountInGroup").format(requestsCount.toString())
                : this._strings.get("oneRequestInGroup");
            // For callouts, we only show the requests count
            if (isCalloutMode) {
                countsDisplayedOnHeader = requestsCountForDisplay;
            } else {
                const slotsCount: number = groupedRequestDetailsStore.openShift.openSlots;
                const slotsCountForDisplay: string = StringUtils.usePluralForm(slotsCount)
                        ? this._commonStrings.get("openShiftsSlotLabelFormatString").format(slotsCount.toString(), this._commonStrings.get("openShiftSlotsPlural"))
                        : this._commonStrings.get("openShiftsSlotLabelFormatString").format(slotsCount.toString(), this._commonStrings.get("openShiftSlotsSingular"));

                countsDisplayedOnHeader = this._strings.get("requestsAndSlotsCountText").format(requestsCountForDisplay, slotsCountForDisplay);
            }
        } else if (groupedRequestState === ShiftRequestStatus.Approved) {
            countsDisplayedOnHeader = StringUtils.usePluralForm(requestsCount)
                ? this._strings.get("approvedRequestsCountText").format(requestsCount.toString())
                : this._strings.get("singleRequestApprovedCountText");
        } else if (groupedRequestState === ShiftRequestStatus.Declined) {
            countsDisplayedOnHeader = StringUtils.usePluralForm(requestsCount)
                ? this._strings.get("declinedRequestsCountText").format(requestsCount.toString())
                : this._strings.get("singleRequestDeclinedCountText");
        } else if (groupedRequestState === ShiftRequestStatus.Cancelled) {
            countsDisplayedOnHeader = StringUtils.usePluralForm(requestsCount)
                ? this._strings.get("cancelledRequestsCountText").format(requestsCount.toString())
                : this._strings.get("singleRequestCancelledCountText");
        }

        return countsDisplayedOnHeader;
    }

    /**
     * Render requests list from the store
     * @param isSelectionEnabled True if the rendered requests should be selectable
     */
    // TODO (CLM): Rename function to 'renderRequestDetails'
    private renderRequestList(isSelectionEnabled: boolean): JSX.Element {
        const { isCalloutMode } = this.props;

        if (!(groupedRequestDetailsStore && groupedRequestDetailsStore.individualRequestDetails && groupedRequestDetailsStore.openShift)) {
            return null;
        }

        const { individualRequestDetails, openShift } = groupedRequestDetailsStore;
        const sortedRequestDetailsForDisplay: IndividualOpenShiftRequestDetails[] = DataProcessingHelpers.getArrayFromMap<string, IndividualOpenShiftRequestDetails>(individualRequestDetails)
            .sort(ShiftRequestUtils.openShiftRequestDetailsComparator);

        // Checks if shift is Cross location, or if any of the requests in the group are cross location requests.
        // If so, render the cross location request details.
        // e.g. if there are 2 requests in the group, one is a cross location request (external team) and the other is a regular open shift request (from the current team).
        const isCrossLocationRequest =
            openShift.isCrossLocationOpenShift ||
            Array.from(individualRequestDetails.values()).some(
                details => details.request.requestType === ShiftRequestTypes.CrossLocationOpen
            );

        // Render cross location request details
        if (isCrossLocationRequest) {
            return (
                <>
                    {isSelectionEnabled ? (
                        <div className={classNames(styles.panelItemsContainer, styles.openShiftRequestedText)}>{this._strings.get("openShiftRequested")}</div>
                    ) : null}
                    <CrossLocationRequestDetails
                        requestList={sortedRequestDetailsForDisplay}
                        shift={groupedRequestDetailsStore.openShift}
                        onClickRequestCallback={this.onRequestClickCallback}
                        isSelectionEnabled={isSelectionEnabled}
                    />
                </>
            );
        }

        const requestClassName: string = classNames(isCalloutMode ? styles.calloutItemsContainer : styles.panelItemsContainer);
        return (
            <div className={classNames(styles.requestsListContainer, { [styles.withinCallout]: isCalloutMode })} role={"list"}>
                {
                    sortedRequestDetailsForDisplay.map((requestDetails: IndividualOpenShiftRequestDetails) => {
                        if (requestDetails.request) {
                            return (
                                <>
                                    <OpenShiftRequestDetails
                                        key={requestDetails.request.id}
                                        shiftRequest={requestDetails.request}
                                        managerMember={requestDetails.managerMember}
                                        isSelected={requestDetails.isSelected}
                                        isSelectionEnabled={isSelectionEnabled}
                                        className={requestClassName}
                                        onRequestClickCallback={this.onRequestClickCallback}
                                        isCalloutMode={isCalloutMode}
                                    />
                                    {!isCalloutMode && this.renderRequestConflict(requestDetails.request)}
                                </>
                            );
                        } else {
                            return null;
                        }
                    })
                }
            </div>
        );
    }

    /**
     * Callback when user clicks on a request
     */
    private onRequestClickCallback = (openShiftRequestId: string) => {

        if (!(groupedRequestDetailsStore.individualRequestDetails && groupedRequestDetailsStore.individualRequestDetails.has(openShiftRequestId))) {
            return;
        }

        if (!groupedRequestDetailsStore.isSaving) {
            // TODO: Needed for instrumentation
            // const requestDetails: IndividualOpenShiftRequestDetails = groupedRequestDetailsStore.individualRequestDetails.get(openShiftRequestId);

            toggleRequestSelectionInStore(openShiftRequestId);
        }
    }

    /**
     * Render a full panel message informing the user that the open shift they are looking for cannot be found.
     */
    private renderOpenShiftNotFoundMessage = (): JSX.Element => {
        const openShiftNotFoundMessage: string = this._strings.get("shiftNotFoundMessage");
        return (
            <div className={ styles.openShiftNotFoundMessageContainer }>
                <div className={ styles.openShiftNotFoundMessage }>{ openShiftNotFoundMessage }</div>
            </div>
        );
    }

    render() {
        const { isCalloutMode } = this.props;
        const { groupedOpenShiftRequest, isDataReady, openShift } = groupedRequestDetailsStore;

        const openShiftNotFound = openShift === null;
        if (!(groupedRequestDetailsStore)) {
            return null;
        }

        const groupedRequestStatus: string = ShiftRequestUtils.getShiftRequestStatus(groupedOpenShiftRequest);

        return (
            <div className={ classNames(styles.groupedRequestDetailsContainer, {[styles.withinCallout]: isCalloutMode}) }>
                {
                    !isDataReady
                    ?
                        <div className={ styles.spinner }>
                            <Spinner size={ SpinnerSize.small } />
                        </div>
                    :
                    openShiftNotFound
                    ?
                        this.renderOpenShiftNotFoundMessage()
                    :
                        <>
                            { this.renderRequestDetails() }

                            {
                                groupedRequestStatus === ShiftRequestStatus.InProgress &&
                                    this.renderActions()
                            }
                        </>
                }
            </div>
        );
    }
}