import IShiftrObjectModelBase from "../IShiftrObjectModelBase";
import { IBaseBreakEntity, IBaseSubshiftEntity, EntityCreatedOrUpdatedByInfo } from "sh-models";

type ShiftWorkingType = "Working";
type ShiftAbsenceType = "Absence";
export type ShiftType = ShiftWorkingType | ShiftAbsenceType;

export const ShiftTypes = {
    Working: "Working" as ShiftWorkingType,
    Absence: "Absence" as ShiftAbsenceType
};

type ShiftStateActiveType = "Active";
type ShiftStatePendingType = "Pending";
type ShiftStateDeletedType = "Deleted";
export type ShiftState = ShiftStateActiveType | ShiftStatePendingType | ShiftStateDeletedType;

export const ShiftStates = {
    Active: "Active" as ShiftStateActiveType,
    Pending: "Pending" as ShiftStatePendingType,
    Deleted: "Deleted" as ShiftStateDeletedType
};

/**
 * Interface to represent Core Shift Entity with a generic DateTime type - will be used by both service and client models of Shifts and OpenShifts
 */
export default interface IBaseShiftData<DateTimeType> extends IShiftrObjectModelBase  {
    /** Start time for the shift */
    startTime: DateTimeType;
    /** End time for the shift */
    endTime: DateTimeType;
    /** The shift type "Working" | "Absence" */
    shiftType: ShiftType;
    /** Current state of the shift */
    state: ShiftState;
    /** Title for the shift (optional) */
    title?: string;
    /** Member id associated with the shift (optional) */
    memberId?: string;
    /** Notes for the shift (optional) */
    notes?: string;
    /** Theme associated with the shift (optional) */
    theme?: string;
    /** List of breaks in the shift (optional) */
    breaks?: IBaseBreakEntity<DateTimeType>[];
    /** List of subshifts in the shift (optional) */
    subshifts?: IBaseSubshiftEntity<DateTimeType>[];
    /**  Time of reason request id (optional) */
    timeOffReasonId?: string;
    /** The IDs of the tags assigned to the shift (optional) */
    tagIds?: string[];
    /** Is shift published (optional) */
    isPublished?: boolean;
    /** Team Id (optional) */
    teamId?: string;
    /** Tenant Id (optional) */
    tenantId?: string;
    /** Will identify shifts request, if the shift is of shift request type */
    shiftRequestId?: string;
    /**
     * The info about the user and time when the shift was last modified.
     */
    lastModifiedBy?: EntityCreatedOrUpdatedByInfo;
}