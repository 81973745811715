import * as moment from "moment";
import * as React from "react";
import AutomationUtil from "sh-application/utility/AutomationUtil";
import DateTimeFormatter, { DateTimeFormatType } from "sh-application/utility/DateTimeFormatter";
import DateUtils from "sh-application/utility/DateUtils";
import ScheduleDateNavigator from "./ScheduleDateNavigator";
import ScheduleHeaderCell from "./ScheduleHeaderCell";
import schedulesViewStateStore from "sh-application/components/schedules/lib/store/store";
import StringsStore from "sh-strings/store";
import { action } from "satcheljs/lib/legacy";
import { AriaProperties, AriaRoles } from "owa-accessibility";
import { observable } from "mobx";
import { DayOfWeek } from "@fluentui/react";
import {
    DayViewFormatBoldInterval,
    DayViewFormatCellInterval,
    DayViewFormatEndHours,
    DayViewFormatStartHours
    } from "../../../../StaffHubConstants";
import { FlexiRow, FlexiRowCellSettings } from "sh-flexigrid";
import { GroupedShiftsData } from "sh-application/components/schedule/ScheduleData";
import { Moment } from "moment";
import { observer } from "mobx-react";
import { ScheduleCalendarType, ScheduleCalendarTypes, TeamSettingEntity } from "sh-models";
import { ScrollOffsetInjectedProps } from "sh-application/components/common/withScrollOffset";
import { TeamSettingsStore } from "sh-teamsettings-store";

const classNames = require("classnames/bind");
const styles = require("./ScheduleHeaderRow.scss");

// The base css class of a header cell.
const CELL_BASE_CLASS = classNames(styles.headerCell, styles.shiftViewSummaryCell);
const CELL_COMMON_CLASS = styles.baseContent;
const CELL_HIDDEN_CLASS = styles.headerCellHidden;

// The class of the body content of the cell.
const WEEK_CELL_BODY_CONTENT_CLASS = styles.weekCellBodyContent;
const MONTH_CELL_BODY_CONTENT_CLASS = styles.monthCellBodyContent;
const DAY_CELL_BODY_CONTENT_CLASS = styles.dayCellBodyContent;
const DAY_CELL_BODY_EMPTY_CONTENT_CLASS = styles.dayCellBodyEmptyContent;
const DAY_CELL_BOLD_CONTENT_CLASS = styles.dayCellBoldContent;

// The title and subtitle CSS classes of the header cell.
// The header row always has a title and subtitle.
// For week view. The title is the day of the month (1-31) and the subtitle is the day of the week (Mon-Sun)
// For day view. The title is the hours (1-12) and the subtitle is am/pm.
// For month view. The title is the day of the month (1-31) and the subtitle is short day of week (M T W T F S S).
const TITLE_CONTENT_CLASS = styles.cellTitle;
const CELL_SUBTITLE_CLASS = styles.cellSubtitle;
const CELL_HOURS_CLASS = styles.cellHours;
const DAY_CELL_SUBTITLE_CLASS = styles.dayCellSubtitle;

// Base class of the header row.
const ROW_CLASS = styles.headerRow;

const TITLE_CELL_CLASS = classNames(styles.headerCellEmpty, "peopleColumnCell");

const MaxDaysInMonth = 31;

export interface ScheduleHeaderRowProps extends ScrollOffsetInjectedProps {
    selectedDate: Moment;
    viewStartDate: Moment;
    viewEndDate: Moment;
    scheduleType: ScheduleCalendarType;
    className?: string;
    hideCells?: boolean;
    hideHours?: boolean;
    onHeaderCellClick?: (date: Moment) => void;
    showNavArrows?: boolean;
    isPrinting?: boolean;
    adjustHorizontalPosition?: boolean;
    groupedShiftsData: GroupedShiftsData;
    showCurrentDate?: boolean;
    showBoxShadowShadow?: boolean;
    isProgressiveRendering: boolean;
}

interface ScheduleHeaderCellContentItem {
    cellDate: moment.Moment;
    cellTitle: string;
    cellSubTitle: string;
    bodyCssClass: string;
    titleCssClass: string;
    subtitleCssClass: string;
    showHours: boolean;
    hoursCssClass: string;
    hoursTotal: string;
    peopleCount?: number;
    ariaLabelledBy?: string;
}

/**
 * Calculate the element id for a schedule date header cell.
 * This is used for Aria accessibility label references
 * @param cellColumnIndex column index within the header row
 */
export function calculateScheduleHeaderCellElementId(cellColumnIndex: number) {
    return `sh-shc-${cellColumnIndex}`;
}

interface SummaryCellContentItem {
    hoursTotal: number;
    isTitleCell: boolean;
}

/**
 * The flexi row at the bottom of the header which displays the dates and days of week.
 */
@observer
export default class ScheduleHeaderRow extends React.Component<ScheduleHeaderRowProps, any> {
    private _strings: Map<string, string>;
    private _commonStrings: Map<string, string>;
    private _currLocale: string;
    private _numDaysInWeek: number;

    // The cell settings for day view
    @observable private _dayCellSettings: FlexiRowCellSettings[] = [];
    // The cell settings for week view
    @observable private _weekCellSettings: FlexiRowCellSettings[] = [];
    // The cell settings for month view
    @observable private _monthCellSettings: FlexiRowCellSettings[] = [];

    constructor(props: ScheduleHeaderRowProps) {
        super(props);

        this._strings = StringsStore().registeredStringModules.get("schedulePage").strings;
        this._commonStrings = StringsStore().registeredStringModules.get("common").strings;

        this._currLocale = StringsStore().currentLocale;
        this._numDaysInWeek = moment.localeData(this._currLocale).weekdays().length;

        this.setUpDayCellSettings();
        this.setupMonthCellSettings();
        this.setupWeekCellSettings();
    }

    renderScheduleDateNavigator() {
        const timeZoneOlsonCode = TeamSettingEntity.getValueOrDefault(TeamSettingsStore().timeZoneOlsonCode);
        const startDayOfWeekStoreValue: string = TeamSettingEntity.getValueOrDefault(TeamSettingsStore().startingDayOfWeek);
        const firstDayOfWeek: DayOfWeek = DayOfWeek[startDayOfWeekStoreValue as keyof typeof DayOfWeek];

        return (
            <ScheduleDateNavigator
                viewState={ schedulesViewStateStore() }
                timeZoneOlsonCode={ timeZoneOlsonCode }
                firstDayOfWeek={ firstDayOfWeek }
                containerClassName={ styles.scheduleDateNavigator }
                forceMonthDateFormat={ true } />
        );
    }

    /**
     * Renders the current selected date/month for Day/Month/Year views respectively.
     * This appears when header is collapsed.
     */
    renderCurrentDate() {
        return <div className={ styles.monthAndYear }>
                {
                    DateTimeFormatter.getDateTimeAsString(schedulesViewStateStore().viewSelectedDate,
                        schedulesViewStateStore().scheduleCalendarType != ScheduleCalendarTypes.Day
                            ? DateTimeFormatType.Date_MonthYearLong
                            : DateTimeFormatType.Date_DayShortDateMonthYear)
                }
            </div>;
    }

    /**
     * When the header row is scrolled horizontally and has been wrapped with the withScrollOffset HOC,
     * it will receive an injectetd xOffset prop. Because this component is sometimes wrapped in a sticky
     * component, it is inside a "position: fixed" div and will not scroll horizontally with the page. In order
     * to keep the header cells aligned with the cells of the grid, we adjust the left and right margins of the
     * header row to shift the cells.
     */
    private getInjectedStyle() {
        const headerRowInjectedStyle =
            (this.props.xOffset && this.props.adjustHorizontalPosition)
            ? {
                left: -this.props.xOffset,
                right: this.props.xOffset
            }
            : null;
        return headerRowInjectedStyle;
    }

    render() {
        const cellSettings = this.getCellSettings();
        const headerRowContainerClasses: string = classNames(
            styles.headerRowContainer,
            { [styles.headerRowContainerSticky]: this.props.showNavArrows },
            { [this.props.className]: this.props.className },
            { dayViewScheduleHeader: this.props.scheduleType === ScheduleCalendarTypes.Day },
            { [styles.headerRowContainerBoxShadow]: this.props.showBoxShadowShadow }
        );

        const headerRowAriaProps: AriaProperties = {
            role: AriaRoles.row,
            label: this._strings.get("scheduleHeaderAriaLabel")
        };

        const dateInfo = ((this.props.showNavArrows) || this.props.isPrinting)
            ? this.renderScheduleDateNavigator()
            : null;

        return (
            <div style={ this.getInjectedStyle() }
                data-automation-id={ AutomationUtil.getAutomationId("header", "QAIDDateRangeArrow") }
                className={ headerRowContainerClasses }>
                { this.props.showCurrentDate ? this.renderCurrentDate() : dateInfo }
                <FlexiRow
                    rowClass={ ROW_CLASS }
                    cellBaseClass={ CELL_BASE_CLASS }
                    cellSettings={ cellSettings }
                    ariaPropsForRow={ headerRowAriaProps } />
            </div>
        );
    }

    private getCellSettings() {
        if (this.props.hideCells) {
            return [];
        } else {
            switch (this.props.scheduleType) {
                case ScheduleCalendarTypes.Day:
                    this.updateDayCellSettings();
                    return this._dayCellSettings;
                case ScheduleCalendarTypes.Week:
                    this.updateWeekCellSettings();
                    return this._weekCellSettings;
                case ScheduleCalendarTypes.Month:
                    this.updateMonthCellSettings();
                    return this._monthCellSettings;
                default:
                    // default to returning empty cells
                    return [];
            }
        }
    }

    private getTitleCellSettings() {
        const { groupedShiftsData } = this.props;

        // Add the empty cell for the first column (member column). This is needed to match column headers with schedule cells for accessibility
        const ariaPropsForEmptyCell: AriaProperties = {
            role: AriaRoles.columnheader
        };

        // add title cell
        const titleCellContentItem: SummaryCellContentItem = { hoursTotal: groupedShiftsData.hours, isTitleCell: true };
        const titleCellSettings: FlexiRowCellSettings = {
            cellElementKey: "summary-row-cell-title",
            cellContentsItem: titleCellContentItem,
            cellClass: TITLE_CELL_CLASS,
            onRenderCellContents: this.renderTitleCellContents,
            ariaPropsForCell: ariaPropsForEmptyCell
        };

        return titleCellSettings;
    }

    // Currently settings up 18hr
    // TODO ravalib/cowuertz: Make this configurable. Bring in team day view settings when those are ready
    private setUpDayCellSettings = action("setUpDayCellSettings")(() => {
        this._dayCellSettings.push(this.getTitleCellSettings());

        this.setUpParameterizedDayFormat(DayViewFormatStartHours, DayViewFormatEndHours, DayViewFormatCellInterval, DayViewFormatBoldInterval);
    });

    /**
     * Set up the day view format with the given start and end time
     * @param startTime - startTime of the day view in 24 hr time. Ex: 5am would be 5, 5 pm would be 17
     * @param endTime - endTime of the day view in 24 hr time: Ex: 11pm would be 23
     * @param cellDuration - duration of each cell
     * The format : 6am 7am 8am 9am 10am 11am 12pm 1pm 2pm 3pm 4pm 5pm 6pm 7pm 8pm 9pm 10pm 11pm 12am
     */
    @action("setUpParameterizedDayFormat")
    private setUpParameterizedDayFormat(startTime: number, endTime: number, cellDuration: number, formatBoldInterval: number) {
        let time = moment().startOf('day').add(startTime, 'hours');
        let index = 0;
        for (let hours = startTime; hours < endTime; hours += cellDuration) {
            const elementId: string = calculateScheduleHeaderCellElementId(index);
            const ariaLabel: string = DateTimeFormatter.getDateTimeAsString(time, DateTimeFormatType.Time);

            this.addDayViewCell(time, hours, formatBoldInterval, elementId, ariaLabel);
            time.add(cellDuration, 'hours');
            index++;
        }
    }

    /**
     * Adds the day view cell to the settings array.
     */
    @action("addDayViewCell")
    private addDayViewCell(time: moment.Moment, hours: number, formatBoldInterval: number, elementId: string, ariaLabel: string) {
        let title: string = "";
        // only whole hour cells will receive a title: ex: 3 will receive a title string, but 3:30 (which is stored here as 3.5) will not
        if (hours % 1 === 0) {
            title = DateTimeFormatter.getDateTimeAsString(time, DateTimeFormatType.Time_HourOnly);
        }

        const subtitle: string = DateTimeFormatter.getDateTimeAsString(time, DateTimeFormatType.Time_MeridiemOnly);

        const now = moment();
        now.set("minutes", now.minutes() > 30 ? 30 : 0);
        now.set("seconds", 0);
        now.set("milliseconds", 0);

        const titleClasses = classNames(TITLE_CONTENT_CLASS, { [styles.pastDate]: (time.isBefore(now, "day") || time.isBefore(now, "hour")) });
        const subtitleCssClass = classNames(hours % formatBoldInterval ? CELL_SUBTITLE_CLASS : DAY_CELL_SUBTITLE_CLASS, { [styles.pastDate]: (time.isBefore(now, "day") || time.isBefore(now, "hour")) });

        const bodyClass = classNames(hours % formatBoldInterval ? DAY_CELL_BODY_CONTENT_CLASS : DAY_CELL_BOLD_CONTENT_CLASS, { [DAY_CELL_BODY_EMPTY_CONTENT_CLASS]: !title });
        const bodyCellContentItem: ScheduleHeaderCellContentItem = {
            cellDate: null,
            cellTitle: title,
            cellSubTitle: hours % formatBoldInterval ? "" : subtitle,
            bodyCssClass: bodyClass,
            titleCssClass: titleClasses,
            subtitleCssClass: subtitleCssClass,
            showHours: false,
            hoursCssClass: "",
            hoursTotal: null, // not used in day view
            ariaLabelledBy: elementId
        };

        const ariaPropsForCell: AriaProperties = {
            role: AriaRoles.columnheader,
            label: ariaLabel
        };

        const bodyCellSettings: FlexiRowCellSettings = {
            cellElementId: elementId,
            cellClass: CELL_COMMON_CLASS,
            isSelected: false,
            cellFlexGrow: 1,
            cellFlexBasis: "0",
            cellContentsItem: bodyCellContentItem,
            ariaPropsForCell: ariaPropsForCell,
            onRenderCellContents: this.renderCellContents
        };

        this._dayCellSettings.push(bodyCellSettings);
    }

    /**
     * Update the day cell settings according to the date selected by the user.
     */
    @action("updateDayCellSettings")
    updateDayCellSettings() {
        let time = this.props.viewStartDate.clone().startOf('day').add(DayViewFormatStartHours, 'hours');
        const now = moment();
        now.set("minutes", now.minutes() > 30 ? 30 : 0);
        now.set("seconds", 0);
        now.set("milliseconds", 0);

        let index = 1;
        for (let hours = DayViewFormatStartHours; hours < DayViewFormatEndHours; hours += DayViewFormatCellInterval) {
            const isBefore = time.isBefore(now);
            const titleClasses = classNames(TITLE_CONTENT_CLASS, { [styles.pastDate]: isBefore });
            const subtitleCssClass = classNames(hours % DayViewFormatBoldInterval
                ? CELL_SUBTITLE_CLASS
                : DAY_CELL_SUBTITLE_CLASS, { [styles.pastDate]: isBefore });

            // for dates in the past, we add the pastDate class so we can style the text
            this._dayCellSettings[index].cellContentsItem.titleCssClass = titleClasses;
            this._dayCellSettings[index].cellContentsItem.subtitleCssClass = subtitleCssClass;

            index++;
            time.add(DayViewFormatCellInterval, "hours");
        }

        this._dayCellSettings[0].cellContentsItem.hoursTotal = DateUtils.getHoursRoundedForDisplay(this.props.groupedShiftsData.hours);
    }

    /**
     * Set up the month cell settings
     */
    @action("setupMonthCellSettings")
    private setupMonthCellSettings() {
        this._monthCellSettings.push(this.getTitleCellSettings());

        const startOfMonth = this.props.viewStartDate.clone().startOf("month");
        const today = moment();

        for (let cellIndex = 0; cellIndex < MaxDaysInMonth; cellIndex++) {
            const cellDate = startOfMonth.clone().add(cellIndex, "days");
            // for dates in the past, we add the pastDate class so we can style the text
            const titleClasses = classNames(TITLE_CONTENT_CLASS, { [styles.pastDate]: cellDate.isBefore(today, "day") });
            const subtitleCssClass = classNames(CELL_SUBTITLE_CLASS, { [styles.pastDate]: cellDate.isBefore(today, "day") });

            const showHours = typeof this.props.hideHours === "boolean" ? !this.props.hideHours : true;
            const bodyCellContentItem: ScheduleHeaderCellContentItem = {
                cellDate: null,
                cellTitle: "",
                cellSubTitle: "",
                bodyCssClass: MONTH_CELL_BODY_CONTENT_CLASS,
                titleCssClass: titleClasses,
                subtitleCssClass: subtitleCssClass,
                showHours: showHours,
                hoursCssClass: classNames(CELL_HOURS_CLASS, {[`${styles.progressiveLoading}`]: this.props.isProgressiveRendering}),
                hoursTotal: this.props.isProgressiveRendering ? "" : String(this.props.groupedShiftsData.hours)
            };

            const ariaPropsForCell: AriaProperties = {
                role: AriaRoles.columnheader
            };

            const bodyCellSettings: FlexiRowCellSettings = {
                cellClass: CELL_COMMON_CLASS,
                isSelected: false,
                cellFlexGrow: 1,
                cellFlexBasis: "0",
                cellContentsItem: bodyCellContentItem,
                ariaPropsForCell: ariaPropsForCell,
                onRenderCellContents: this.renderCellContents
            };

            this._monthCellSettings.push(bodyCellSettings);
        }
    }

    /**
     * Set up the week cell settings
     */
    @action("setupWeekCellSettings")
    private setupWeekCellSettings() {
        this._weekCellSettings.push(this.getTitleCellSettings());

        const startOfWeek = this.props.viewStartDate.clone().startOf("week");
        const today = moment();

        for (let cellIndex = 0; cellIndex < this._numDaysInWeek; cellIndex++) {
            const cellDate = startOfWeek.clone().add(cellIndex, "days");
            const dateIndex = DateUtils.fastCalculateDateIndex(cellDate);

            // for dates in the past, we add the pastDate class so we can style the text
            const titleClasses = classNames(TITLE_CONTENT_CLASS, { [styles.pastDate]: cellDate.isBefore(today, "day") });
            const subtitleCssClass = classNames(CELL_SUBTITLE_CLASS, { [styles.pastDate]: cellDate.isBefore(today, "day") });
            const showHours = typeof this.props.hideHours === "boolean" ? !this.props.hideHours : true;
            const bodyCellContentItem: ScheduleHeaderCellContentItem = {
                cellDate: null,
                cellTitle: "",
                cellSubTitle: "",
                bodyCssClass: WEEK_CELL_BODY_CONTENT_CLASS,
                titleCssClass: titleClasses,
                subtitleCssClass: subtitleCssClass,
                showHours: showHours,
                peopleCount: this.props.groupedShiftsData.peopleCount[dateIndex]?.count ?? 0,
                hoursCssClass: classNames(CELL_HOURS_CLASS, {[`${styles.progressiveLoading}`]: this.props.isProgressiveRendering}),
                hoursTotal: this.props.isProgressiveRendering ? "" : this._commonStrings.get("hoursShortFormat").format(DateUtils.getHoursRoundedForDisplay(this.props.groupedShiftsData.hours))
            };

            const ariaPropsForCell: AriaProperties = {
                role: AriaRoles.columnheader
            };

            const bodyCellSettings: FlexiRowCellSettings = {
                cellClass: CELL_COMMON_CLASS,
                isSelected: false,
                cellFlexGrow: 1,
                cellFlexBasis: "0",
                cellContentsItem: bodyCellContentItem,
                ariaPropsForCell: ariaPropsForCell,
                onRenderCellContents: this.renderCellContents
            };

            this._weekCellSettings.push(bodyCellSettings);
        }
    }

    /**
     * Update the month cell settings according to the current month/date selected by the user/
     */
    @action("updateMonthCellSettings")
    updateMonthCellSettings() {
        let startOfMonth = this.props.viewStartDate.clone();
        let daysInCurrentMonth = startOfMonth.daysInMonth();
        let currDate = startOfMonth;
        const today = moment();

        // Fill up the contents for the current month.
        let dateIndex = 0;
        for (let cellIndex = 1; cellIndex <= daysInCurrentMonth; cellIndex++) {
            let isSelected = currDate.date() === this.props.selectedDate.date();

            dateIndex = DateUtils.fastCalculateDateIndex(currDate);

            let dayHoursTotal = 0;
            if (this.props.groupedShiftsData.hoursByDate.has(dateIndex)) {
                dayHoursTotal = this.props.groupedShiftsData.hoursByDate.get(dateIndex);
            }

            this._monthCellSettings[cellIndex].cellContentsItem.cellDate = currDate.clone();
            this._monthCellSettings[cellIndex].cellContentsItem.cellTitle = this.getFormattedDateOfMonth(currDate);
            this._monthCellSettings[cellIndex].cellContentsItem.cellSubTitle = this.getFormattedShortDayOfWeek(currDate, true /* useNarrowFormat */);
            this._monthCellSettings[cellIndex].cellContentsItem.hoursTotal = this.props.isProgressiveRendering ? "" : DateUtils.getHoursRoundedForDisplay(dayHoursTotal);

            this._monthCellSettings[cellIndex].isSelected = isSelected;
            const isToday = currDate.isSame(today, 'day');
            this._monthCellSettings[cellIndex].cellClass = classNames(CELL_COMMON_CLASS, { [`${styles.today}`]: isToday });

            this._monthCellSettings[cellIndex].cellElementId = calculateScheduleHeaderCellElementId(cellIndex - 1);  // index for the header date cells starts from 0. It doesn't take the empty cell in first column into account
            this._monthCellSettings[cellIndex].ariaPropsForCell.label = this.calculateDateHeaderCellAriaLabel(currDate);

            // for dates in the past, we add the pastDate class so we can style the text
            this._monthCellSettings[cellIndex].cellContentsItem.titleCssClass = classNames(TITLE_CONTENT_CLASS, { [styles.pastDate]: currDate.isBefore(today, "day") });
            this._monthCellSettings[cellIndex].cellContentsItem.subtitleCssClass = classNames(CELL_SUBTITLE_CLASS, { [styles.pastDate]: currDate.isBefore(today, "day") });
            this._monthCellSettings[cellIndex].cellContentsItem.hoursCssClass = classNames(CELL_HOURS_CLASS, { [styles.pastDate]: currDate.isBefore(today, "day") }, {[`${styles.progressiveLoading}`]: this.props.isProgressiveRendering});

            currDate = DateUtils.startOfNextDay(currDate);
        }

        // Clear out the rest of the cells that are unused for this month
        for (let cellIndex = daysInCurrentMonth + 1; cellIndex <= MaxDaysInMonth; cellIndex++) {
            this._monthCellSettings[cellIndex].isSelected = false;
            this._monthCellSettings[cellIndex].cellClass = CELL_HIDDEN_CLASS;
        }

        this._monthCellSettings[0].cellContentsItem.hoursTotal = this.props.groupedShiftsData.hours;
    }

    /**
     * Update the week cell settings according to the week/date selected by the user.
     */
    @action("updateWeekCellSettings")
    updateWeekCellSettings() {
        let possibleDate: Moment;
        if (this.props.viewStartDate) {
            possibleDate = this.props.viewStartDate.clone();
        } else {
            // If view start date is not specified, fallback to using the current date.
            const startDayOfWeekStoreValue: string = TeamSettingEntity.getValueOrDefault(TeamSettingsStore().startingDayOfWeek);
            const startDayOfWeek: number = DayOfWeek[startDayOfWeekStoreValue as keyof typeof DayOfWeek];
            possibleDate = DateUtils.getWeekStartEndForDate(moment(), startDayOfWeek, true /* doGetWeekStart */);
        }
        const today = moment();
        let dateIndex = 0;
        // Fill up the contents of the current week.
        for (let cellIndex = 1; cellIndex <= this._numDaysInWeek; cellIndex++) {
            let isSelected = possibleDate.date() === this.props.selectedDate.date();

            dateIndex = DateUtils.fastCalculateDateIndex(possibleDate);

            let dayHoursTotal = 0;
            if (this.props.groupedShiftsData.hoursByDate.has(dateIndex)) {
                dayHoursTotal = this.props.groupedShiftsData.hoursByDate.get(dateIndex);
            }

            this._weekCellSettings[cellIndex].cellContentsItem.peopleCount = this.props.groupedShiftsData.peopleCount[dateIndex]?.count ?? 0;
            this._weekCellSettings[cellIndex].cellContentsItem.cellDate = possibleDate.clone();
            this._weekCellSettings[cellIndex].cellContentsItem.cellTitle = this.getFormattedDateOfMonth(possibleDate);
            this._weekCellSettings[cellIndex].cellContentsItem.cellSubTitle = this.getFormattedShortDayOfWeek(possibleDate, false /* useNarrowFormat */);
            this._weekCellSettings[cellIndex].cellContentsItem.hoursTotal = this.props.isProgressiveRendering ? "" :  this._commonStrings.get("hoursShortFormat").format(DateUtils.getHoursRoundedForDisplay(dayHoursTotal));

            // for dates in the past, we add the pastDate class so we can style the text
            this._weekCellSettings[cellIndex].cellContentsItem.titleCssClass = classNames(TITLE_CONTENT_CLASS, { [styles.pastDate]: possibleDate.isBefore(today, "day") });
            this._weekCellSettings[cellIndex].cellContentsItem.subtitleCssClass = classNames(CELL_SUBTITLE_CLASS, { [styles.pastDate]: possibleDate.isBefore(today, "day") });
            this._weekCellSettings[cellIndex].cellContentsItem.hoursCssClass = classNames(CELL_HOURS_CLASS, { [styles.pastDate]: possibleDate.isBefore(today, "day") }, {[`${styles.progressiveLoading}`]: this.props.isProgressiveRendering});

            this._weekCellSettings[cellIndex].cellContentsItem.cellSubTitle = this.getFormattedShortDayOfWeek(possibleDate, false /* useNarrowFormat */);

            this._weekCellSettings[cellIndex].isSelected = isSelected;
            const isToday = possibleDate.isSame(today, 'day');
            this._weekCellSettings[cellIndex].cellClass = classNames(CELL_COMMON_CLASS, { [`${styles.today}`]: isToday });

            this._weekCellSettings[cellIndex].cellElementId = calculateScheduleHeaderCellElementId(cellIndex - 1); // index for the header date cells starts from 0. It doesn't take the empty cell in first column into account
            this._weekCellSettings[cellIndex].ariaPropsForCell.label = this.calculateDateHeaderCellAriaLabel(possibleDate);

            possibleDate = DateUtils.startOfNextDay(possibleDate);
        }

        this._weekCellSettings[0].cellContentsItem.hoursTotal = this.props.groupedShiftsData.hours;
    }

    /**
     * Calculate the Aria accessibility label for the date header cell
     * @param time datetime value for the date header cell
     */
    private calculateDateHeaderCellAriaLabel(time: Moment): string {
        return DateTimeFormatter.getDateTimeAsString(time, DateTimeFormatType.Date_DayDateMonthYearLong);
    }

    /**
     * Gets the date of month
     * For example: 1, 2 .. 31
     * @param date Date whose day of month is returned as a string
     */
    private getFormattedDateOfMonth(date: Moment) {
        return DateTimeFormatter.getDateTimeAsString(date, DateTimeFormatType.Date_DateNumeric);
    }

    /**
     * Gets the short day of the week
     * For example: Mon Tue Wed Thu
     * @param date Date whose short day of week is returned as a string.
     * @param useNarrowFormat true to use the narrow version. false for the short version.
     */
    private getFormattedShortDayOfWeek(date: Moment, useNarrowFormat: boolean) {
        return DateTimeFormatter.getDateTimeAsString(date, useNarrowFormat ? DateTimeFormatType.Date_DayNarrow : DateTimeFormatType.Date_DayShort);
    }

    /**
     * Call back to render the cell contents
     * @param cellContentItem The cell settings for each cell
     */
    private renderCellContents = (cellContentItem: ScheduleHeaderCellContentItem) => {
        return (
            <ScheduleHeaderCell
                cellTitle={ cellContentItem.cellTitle }
                cellSubTitle={ cellContentItem.cellSubTitle }
                bodyCssClass={ cellContentItem.bodyCssClass }
                titleCssClass={ cellContentItem.titleCssClass }
                subtitleCssClass={ cellContentItem.subtitleCssClass }
                onClick={ this.props.onHeaderCellClick }
                cellDate={ cellContentItem.cellDate }
                peopleCount={cellContentItem.peopleCount}
                showHours={ cellContentItem.showHours }
                hoursCssClass={ cellContentItem.hoursCssClass }
                hoursTotal={ cellContentItem.hoursTotal }
                labelledBy={cellContentItem.ariaLabelledBy}/>
        );
    }

    private renderTitleCellContents = (cellContentItem: SummaryCellContentItem) => {
        // month view needs a custom class on the non-title cells so that we can center the hours in the cell
        const cellClassName = classNames(
            (cellContentItem && cellContentItem.isTitleCell) ? styles.hoursTitleCell : styles.hoursCell,
            (this.props.scheduleType === ScheduleCalendarTypes.Month && !(cellContentItem && cellContentItem.isTitleCell)) ? styles.contentMonthView : styles.content
        );

        let prefixString = "";
        switch (this.props.scheduleType) {
            case ScheduleCalendarTypes.Day:
                prefixString = this._strings.get("dateHeaderHoursDayLabel");
                break;
            case ScheduleCalendarTypes.Week:
                prefixString = this._strings.get("dateHeaderHoursWeekLabel");
                break;
            case ScheduleCalendarTypes.Month:
                prefixString = this._strings.get("dateHeaderHoursMonthLabel");
                break;
            default:
                prefixString = "";
                break;
        }
        const hoursString = this._commonStrings.get("hoursShortFormat").format(DateUtils.getHoursRoundedForDisplay(cellContentItem.hoursTotal));
        return (this.props.isProgressiveRendering
            ?
            <div className={ styles.progressiveLoading }>
                { prefixString.format(this._commonStrings.get("calculatingLabel")) }
            </div>
            : <div className={ cellClassName }>
                {
                    (cellContentItem && cellContentItem.isTitleCell)
                        ? prefixString.format(hoursString)
                        : hoursString
                }
            </div>
        );
    }
}
