import DateUtils from "sh-application/utility/DateUtils";
import InstrumentationUtils from "sh-application/utility/InstrumentationUtils";
import MemberUtils from "sh-application/utility/MemberUtils";
import setEmployeeView from "../mutators/setEmployeeView";
import showConfirmDialog from "sh-confirm-dialog/lib/components/ConfirmDialog";
import TagUtils from "sh-application/utility/TagUtils";
import {
    clearScheduleDataInView,
    ScheduleGridUtils,
    setfeatureClickedBeforeDataFetched,
    setGlobalMessageForScheduleLoaded,
    setGroupedView,
    setGroupInView,
    setIsProgressiveRendering,
    setIsViewStatePopulated,
    setMemberInView,
    setScheduleCalendarType,
    setScheduleOverViewType,
    setScheduleSelectedDate,
    setScheduleSelectedRange,
    setupConflicts,
    setupScheduleViewStates,
    updateIsDataInDateRangeLoaded,
    updateNotesInView,
    updateOpenShiftsInView,
    updateScheduleViewDimensions,
    updateShiftsInView
    } from "../index";
import { DataFilter, UserStorageService } from "sh-services";
import { DayOfWeek } from "@fluentui/react";
import { DEFAULT_START_OF_WEEK } from "sh-application/../StaffHubConstants";
import {
    ECSConfigKey,
    ECSConfigService,
    InstrumentationService,
    TeamDataService
    } from "sh-services";
import {
    EmployeeViewType,
    EmployeeViewTypes,
    IDataInDateRange,
    INoteEntity,
    IOpenShiftEntity,
    IShiftEntity,
    LastViewedSchedule,
    ScheduleCalendarType,
    ScheduleCalendarTypes,
    ScheduleOverViewType,
    TeamSettingEntity,
    UserStorageKeys
    } from "sh-models";
import { getGenericEventPropertiesObject } from "sh-instrumentation";
import { Moment } from "moment";
import { orchestrator } from "satcheljs";
import { restartApp } from "sh-application";
import { runInAction } from "mobx";
import { setGlobalMessageFromException } from "sh-application";
import { setIsConflictCalculationInProgress } from "sh-application/components/schedules/lib";
import { TeamSettingsStore } from "sh-teamsettings-store";
import { TeamStore } from "sh-team-store";
import { trace } from "owa-trace";

/**
 * When view dimensions like selected date, overview type, or calendar type are changed, this orchestrator is run.
 * It derives the new schedule range, detects diffs in the view dimensions, fetches schedule data from global cache/service if needed,
 * and then batches updates to the view dimensions so that only one mobx reaction is triggered.
 *
 * Callers triggering the action should pass in null for schedule view dimensions that they do not want to change.
 */

    /**
     * @param viewState - schedule view state
     * @param isTeamBootstrap - set to true if we are bootstrapping a team (eg, during initial app load or switching to a team)
     * @param scheduleCalendarType - schedule calendar type
     * @param scheduleOverViewType - schedule overview type
     * @param selectedDate - current date to view in the schedule view
     * @param isViewGrouped - set to true if the schedule view should be displayed in Grouped mode
     */

export const updateScheduleViewDimensionsOrchestrator =  orchestrator(updateScheduleViewDimensions, async actionMessage => {
        const { viewState, isTeamBootstrap, employeeViewType, scheduleCalendarType, scheduleOverViewType, selectedDate, isViewGrouped } = actionMessage;

        if (!viewState) {
            return;
        }

        // Store previous view dimensions
        const previousViewStart: Moment = viewState.viewStartDate ? viewState.viewStartDate : null;
        const previousViewEnd: Moment = viewState.viewEndDate ? viewState.viewEndDate : null;
        const previousSelectedDate: Moment = viewState.viewSelectedDate ? viewState.viewSelectedDate : null;
        const previousCalendarType: ScheduleCalendarType = viewState.scheduleCalendarType;
        const previousEmployeeViewType: EmployeeViewType = viewState.employeeViewType;
        const previousOverViewType: ScheduleOverViewType = viewState.scheduleOverViewType;
        const previousIsViewGrouped: boolean = viewState.isViewGrouped;

        // Calculate updated view dimensions. Use incoming overrides from parameters or previous values
        const updatedCalendarType: ScheduleCalendarType = scheduleCalendarType || previousCalendarType;
        const updatedEmployeeViewType: EmployeeViewType = employeeViewType || previousEmployeeViewType;
        const updatedOverViewType: ScheduleOverViewType = scheduleOverViewType || previousOverViewType;
        const updatedSelectedDate: Moment = selectedDate || previousSelectedDate;
        const updatedIsViewGrouped: boolean = (isViewGrouped !== null) ? isViewGrouped : previousIsViewGrouped;

        // The updated view start and end are derived from the updated selected date and the schedule calendar type
        const { updatedViewStart, updatedViewEnd } = getUpdatedDateRange(updatedCalendarType, updatedSelectedDate);
        const shouldUseMyShiftsApi = (updatedEmployeeViewType === EmployeeViewTypes.YourShiftsView) && ECSConfigService.isFeatureEnabled(ECSConfigKey.EnableMyShiftsApi);
        let progressiveRendingCompleted = true;
        let isProgressiveRenderingEnabled = false;

        // If the last viewed schedule stored in local storage has changed, save the updated schedule, so that on refresh, the same viewState will be used to initialize
        if (hasLastViewedScheduleChanged(previousSelectedDate, previousCalendarType, updatedSelectedDate, updatedCalendarType)) {
            saveAsLastViewedSchedule(updatedSelectedDate, updatedCalendarType);
            // it's important to set the schedule range here because TeamDataService.getDataInDateRange will trigger a schedule render and if
            // we don't have the date range set, the shifts may be filtered for the wrong date range. When progressive rendering starts working,
            // that can lead to an incorrect render.
            // ROBV NOTE - This causes a number of additional Schedules render (however it is before we have the data so nothing actually renders)
            setScheduleSelectedRange(viewState, updatedViewStart, updatedViewEnd);
        }

        try {
            let notes: INoteEntity[] = [];
            let shifts: IShiftEntity[] = [];
            let openShifts: IOpenShiftEntity[] = [];

            const isScheduleRangeChanged = hasScheduleRangeChanged(previousViewStart, previousViewEnd, updatedViewStart, updatedViewEnd);
            const isEmployeeViewChanged = previousEmployeeViewType !== updatedEmployeeViewType;

            // Fetch schedule data from the service if the schedule view range has changed or the employee view has changed
            const shouldLoadScheduleData: boolean = isScheduleRangeChanged || !viewState.isDataInDateRangeLoaded || isEmployeeViewChanged;
            // Determine if we need to force a network call based on the view change
            const forceLoadFromNetwork = isEmployeeViewChanged;

            if (shouldLoadScheduleData) {
                // Clear data loaded flag while the schedule data is being fetched
                updateIsDataInDateRangeLoaded(viewState, false);
                // If we are loading schedule data, set the conflict calculation flag to true to make sure the counting component
                // will show that calculations are in progress. We want to make sure we don't show the count of the previous schedule.
                // This will get set back to false once the calculations are complete.
                setIsConflictCalculationInProgress(true);
            }

            runInAction(() => {
                // setup all the schedule dimensions - this has to happen even if we aren't loading schedule data
                if (hasCalendarTypeChanged(previousCalendarType, updatedCalendarType)) {
                    setScheduleCalendarType(viewState, updatedCalendarType);
                }

                if (hasEmployeeViewTypeChanged(previousEmployeeViewType, updatedEmployeeViewType)) {
                    setEmployeeView(viewState, updatedEmployeeViewType);
                }

                if (hasOverViewTypeChanged(previousOverViewType, updatedOverViewType)) {
                    setScheduleOverViewType(viewState, updatedOverViewType);
                }

                if (hasSelectedDateChanged(previousSelectedDate, updatedSelectedDate)) {
                    setScheduleSelectedDate(viewState, updatedSelectedDate);
                }

                if (hasIsViewGroupedChanged(previousIsViewGrouped, updatedIsViewGrouped)) {
                    setGroupedView(viewState, updatedIsViewGrouped); // TODO scheduleFilters: remove as this is does not seem to be used
                }
            });

            if (shouldLoadScheduleData && ScheduleGridUtils.isProgressiveRenderingEnabled() && !shouldUseMyShiftsApi) {
                // this flag is to ensure we calculate conflicts correctly when progressive rendering is enabled
                isProgressiveRenderingEnabled = true;

                // ===========================
                // progressive rendering
                // ===========================

                // we page by tags if the view is grouped
                const pageByTag = updatedIsViewGrouped;
                progressiveRendingCompleted = false;

                try {
                    setIsProgressiveRendering(true);

                    let firstRenderMarker = "progressiveRendingFirstTagRender";
                    firstRenderMarker = InstrumentationService.perfMarkerStart(firstRenderMarker);

                    let didComplete = true;
                    let firstDataSet = true;
                    let sortedArray = [];
                    let dataFilter: DataFilter = {};

                    if (pageByTag) {
                        // page by tags
                        sortedArray = TagUtils.getAllTagIds(true /* sortByIndex */);

                        // initialize the DataFilter
                        dataFilter = {
                            tagIds: sortedArray
                        };
                    } else {
                        // page by members
                        sortedArray = MemberUtils.getAllMemberIds(true /* sortByIndex */);

                        // initialize the DataFilter
                        dataFilter = {
                            memberIds: sortedArray
                        };
                    }

                    if (sortedArray && sortedArray.length) {
                        didComplete = await TeamDataService.getDataInDateRangeFiltered(
                            TeamStore().teamId,
                            updatedViewStart,
                            updatedViewEnd,
                            dataFilter,
                            (dataLoaded: DataFilter) => {
                                // this is a callback for each set (tags or memebers) that has been loaded
                                if (dataLoaded.tagIds && dataLoaded.tagIds.length) {
                                    dataLoaded.tagIds.forEach(tagId => {
                                        setGroupInView(tagId);
                                    });
                                } else if (dataLoaded.memberIds && dataLoaded.memberIds.length) {
                                    dataLoaded.memberIds.forEach(memberId => {
                                        setMemberInView(memberId);
                                    });
                                }

                                if (firstDataSet) {
                                    firstDataSet = false;
                                    InstrumentationService.perfMarkerEnd(firstRenderMarker);
                                    updateIsDataInDateRangeLoaded(viewState, true);
                                    if (isTeamBootstrap) {
                                        setIsViewStatePopulated(viewState, true);
                                    }
                                }
                            },
                            forceLoadFromNetwork /* forceLoadFromNetwork */
                        );
                    }

                    if (didComplete) {
                        // only mark as completed if getDataInDateRangeByTags completed, otherwise it was probably canceled and started again.
                        setIsProgressiveRendering(false);
                        setGlobalMessageForScheduleLoaded();

                        // reset the flag for feature clicked irrespective of the data fetched
                        setfeatureClickedBeforeDataFetched(false);
                        progressiveRendingCompleted = true;
                    }
                } catch (error) {
                    // Show the error message. Do not re-throw the error
                    // This shouldn't block setting up the ScheduleViewDimensions which will break date navigations and user will never see any schedule data
                    // Should render the empty schedules page
                    setIsProgressiveRendering(false, false /* didComplete */);
                    updateIsDataInDateRangeLoaded(viewState, true);

                    showConfirmDialog(
                        ScheduleGridUtils.getLoadingFailedErrorDialogTitle() /* error dialog title to show when an error occurs */,
                        ScheduleGridUtils.getLoadingFailedErrordialogSubTitle() /* error dialog sub title to show when an error occurs */,
                        {
                            okText: ScheduleGridUtils.getRefreshButtonText() /* error dialog button to show when an error occurs  OK button is primary button */,
                            hideCancelButton: true,
                            isBlocking: true
                        },
                        () => restartApp() /* on Ok callback */,
                        null,
                        false /* should disable ok button */,
                        false /* show close button on the top right corner  */,
                        false /* call cancel callback on dismiss */
                    );

                    InstrumentationService.logEvent(InstrumentationService.events.LargeTeamsErrors, [
                        getGenericEventPropertiesObject(InstrumentationService.values.SomethingWentWrongError, error),
                        getGenericEventPropertiesObject(
                            InstrumentationService.properties.CurrentView,
                            InstrumentationUtils.getCurrentViewForInstrumentation(scheduleCalendarType)
                        )
                    ]);

                    if (isTeamBootstrap) {
                        // Setup final schedule viewstate settings before marking the schedule viewstate as ready for UX consumption
                        setupScheduleViewStates();
                    }
                }
            } else if (shouldLoadScheduleData) {
                // ===========================
                // not progressive rendering
                // ===========================

                try {
                    let dataInRange: IDataInDateRange = null;

                    // if user is in Your shifts view
                    if (shouldUseMyShiftsApi) {
                        dataInRange = await TeamDataService.getDataInDateRangeMyShifts(
                            TeamStore().teamId,
                            updatedViewStart,
                            updatedViewEnd,
                            forceLoadFromNetwork // forceLoadFromNetwork
                        );
                    }else {
                        dataInRange = await TeamDataService.getDataInDateRange(
                            TeamStore().teamId,
                            updatedViewStart,
                            updatedViewEnd,
                            forceLoadFromNetwork // forceLoadFromNetwork
                        );
                    }

                    notes = dataInRange.notes;
                    shifts = dataInRange.shifts;
                    openShifts = dataInRange.openShifts;
                } catch (error) {
                    // Show the error message. Do not re-throw the error
                    // This shouldn't block setting up the ScheduleViewDimensions which will break date navigations and user will never see any schedule data
                    // Should render the empty schedules page
                    setGlobalMessageFromException(error, false /* autoDismiss */);
                }

                runInAction(() => {
                    // if our new schedule range is not contiguous with the old, we will clear the existing schedule data
                    if (!DateUtils.isRangeContiguous(previousViewStart, previousViewEnd, updatedViewStart, updatedViewEnd)) {
                        clearScheduleDataInView(false);
                    }

                    // Enable the data loaded flag since schedule data should now be loaded
                    updateIsDataInDateRangeLoaded(viewState, true);

                    // setScheduleSelectedRange(viewState, updatedViewStart, updatedViewEnd);
                    updateNotesInView(notes);
                    updateShiftsInView(shifts);
                    updateOpenShiftsInView(openShifts);
                });
            }

            if (isTeamBootstrap) {
                // Setup final schedule viewstate settings before marking the schedule viewstate as ready for UX consumption
                setupScheduleViewStates();
            }
        } catch (e) {
            // GDIDR errors are handled earlier. There shouldn't be any other errors happening at this stage
            trace.warn(`updateScheduleViewDimensions is failing with error: ${e}`);
        } finally {
            const shouldForceCalculate = isProgressiveRenderingEnabled && progressiveRendingCompleted;
            // We need to check if conflict calculation needs to be updated or
            // newly calculated for a different set of shifts, after any date range changes
            if (progressiveRendingCompleted) {
                setupConflicts(
                    null /* shiftsAdded */,
                    null /* shiftsDeleted */,
                    null /* shiftsUpdated */,
                    viewState.viewStartDate /* updatedViewStartTime */,
                    viewState.viewEndDate /* updatedViewEndTime */,
                    shouldForceCalculate /* force calculate */,
                    false /* non-PR mode */,
                    viewState.showShiftConflicts /* user settings enabled */);
            }
        }
    }
);

// Helpers
function saveAsLastViewedSchedule(selectedDate: Moment, scheduleCalendarType: ScheduleCalendarType) {
    // Save date and calendar type so that the same schedule date range can be restored on page load

    // Convert the current selectedDate to UTC when persisting in LastViewedSchedule.
    // We want the current schedule view selection to be the same regardless of the team's timezone.
    // If the user was viewing a specific date (eg, Dec 6 12am) and switched to a team with a different timezone, the same
    // date (eg, Dec 6 12am, not converted to the new timezone) should still be used for viewing the other team.
    // This is accomplished by "flattening" the selectedDate to UTC when persisting to local storage.
    // TODO DCoh:  Create a model for LastViewedSchedule with methods to convert for persisting to UserStorage.  Example: INoteDbEntity and toDbModel()
    const selectedDateUtc: Moment = DateUtils.cloneTimezoneMomentToUtcMoment(selectedDate);
    UserStorageService.setItem<LastViewedSchedule>(UserStorageKeys.LastViewedSchedule,
        {
            selectedDate: selectedDateUtc,
            calendarType: scheduleCalendarType
        });
}

function getUpdatedDateRange(updatedCalendarType: ScheduleCalendarType, updatedSelectedDate: Moment): { updatedViewStart: Moment, updatedViewEnd: Moment} {
    // The updated view start and end are derived from the updated selected date and the schedule calendar type
    let updatedViewStart: Moment = null;
    let updatedViewEnd: Moment = null;

    // Update the start and end of selection based on the previous selected date and the overview type
    switch (updatedCalendarType) {
        case ScheduleCalendarTypes.Day: {
            updatedViewStart = updatedSelectedDate.clone().startOf("day");
            updatedViewEnd = updatedSelectedDate.clone().endOf("day");
            break;
        }
        case ScheduleCalendarTypes.Week: {
            // Compute the previous week range for the selected date, taking the start day of week setting into account.
            const startDayOfWeekStoreValue: string = TeamSettingEntity.getValueOrDefault(TeamSettingsStore().startingDayOfWeek) || DEFAULT_START_OF_WEEK;
            const startDayOfWeek: number = DayOfWeek[startDayOfWeekStoreValue as keyof typeof DayOfWeek];
            updatedViewStart = DateUtils.getWeekStartEndForDate(updatedSelectedDate, startDayOfWeek, true /* doGetWeekStart */);
            updatedViewEnd = DateUtils.getWeekStartEndForDate(updatedSelectedDate, startDayOfWeek, false /* doGetWeekStart */);
            break;
        }
        case ScheduleCalendarTypes.Month: {
            updatedViewStart = updatedSelectedDate.clone().startOf("month");
            updatedViewEnd = updatedSelectedDate.clone().endOf("month");
            break;
        }
    }

    return { updatedViewStart: updatedViewStart, updatedViewEnd: updatedViewEnd };
}

function hasSelectedDateChanged(previousSelectedDate: Moment, updatedSelectedDate: Moment): boolean {
    return !previousSelectedDate.isSame(updatedSelectedDate) || previousSelectedDate.tz() !== updatedSelectedDate.tz();
}

function hasScheduleRangeChanged(previousViewStart: Moment, previousViewEnd: Moment, updatedViewStart: Moment, updatedViewEnd: Moment): boolean {
    return !previousViewStart.isSame(updatedViewStart) || !previousViewEnd.isSame(updatedViewEnd) || previousViewStart.tz() !== updatedViewStart.tz() || previousViewEnd.tz() !== updatedViewEnd.tz();
}

function hasCalendarTypeChanged(previousCalendarType: ScheduleCalendarType, updatedCalendarType: ScheduleCalendarType): boolean {
    return previousCalendarType !== updatedCalendarType;
}

function hasEmployeeViewTypeChanged(previousEmployeeViewType: EmployeeViewType, updatedEmployeeViewType: EmployeeViewType): boolean {
    return previousEmployeeViewType !== updatedEmployeeViewType;
}

function hasOverViewTypeChanged(previousOverViewType: ScheduleOverViewType, updatedOverViewType: ScheduleOverViewType): boolean {
    return previousOverViewType !== updatedOverViewType;
}

function hasLastViewedScheduleChanged(previousSelectedDate: Moment, previousCalendarType: ScheduleCalendarType, updatedSelectedDate: Moment, updatedCalendarType: ScheduleCalendarType): boolean {
    return hasSelectedDateChanged(previousSelectedDate, updatedSelectedDate) || previousCalendarType !== updatedCalendarType;
}

function hasIsViewGroupedChanged(previousIsViewGrouped: boolean, updatedIsViewGrouped: boolean): boolean {
    return previousIsViewGrouped !== updatedIsViewGrouped;
}