import { HostClientType } from "@microsoft/teams-js";
import { action } from "satcheljs";

const setHostPlatform = action(
    "setHostPlatform",
    (
        hostPlatform: HostClientType
    ) => ({
        hostPlatform: hostPlatform
    })
);

export default setHostPlatform;