
import { createStore } from "satcheljs";

/* Register Orchestrators */
import "sh-stores/sh-hostPlatform-store/orchestrators/initializeHostPlatform";

import { HostPlatformStoreSchema } from "./schema/HostPlatformStore";
import { HostClientType } from "@microsoft/teams-js";

let HostPlatformStore = createStore<HostPlatformStoreSchema>(
    "hostplatformstore",
    {
        hostPlatform: HostClientType.web
    });

export default HostPlatformStore;