import { trace } from "owa-trace";
import { orchestrator } from "satcheljs";
import StaffHubHttpErrorUtils from "sh-application/utility/StaffHubHttpErrorUtils";
import {
    IBaseShiftEntity,
    IMemberEntity,
    IOpenShiftEntity,
    IShiftRequestEntity,
    MemberEntity
    } from "sh-models";
import { setRequestShiftInConflictStore } from "sh-requestconflict-store";
import { DataServices, DataProcessingHelpers , MemberDataService, ShiftDataService } from "sh-services";
import { TeamStore } from "sh-stores";

import { setShiftRequestRead } from "../../";
import { IndividualOpenShiftRequestDetails } from "../store/schema/GroupedRequestDetailsStoreSchema";
import {
    loadDataForGroupedRequest,
    setGroupedRequestInStore,
    setIsDataReadyInStore,
    setOpenShiftInStore,
    updateOpenShiftRequestDetailsInStore
    } from "../store/store";
import { UserInfo } from "@microsoft/shifts-models-view";
import StringsStore from "sh-strings/store";

export const loadDataForGroupedRequestOrchestrator = orchestrator(loadDataForGroupedRequest, async actionMessage => {
        const { groupedRequest } = actionMessage;
        try {
            // Load the open shift
            try {
                const shift: IBaseShiftEntity = await ShiftDataService.getOpenShiftById(
                    groupedRequest.shiftId,
                    groupedRequest.openShiftTeamId
                );
                const openShift = shift as IOpenShiftEntity;
                setOpenShiftInStore(openShift);
                // udpate request open shift in conflict request store
                setRequestShiftInConflictStore(openShift.id, openShift);
            } catch (error) {
                if (StaffHubHttpErrorUtils.isNotFoundError(error)) {
                    // if the open shift cannot be found, set the open shift to null but don't error
                    setOpenShiftInStore(null);
                } else {
                    throw(error);
                }
            }

            // Load the requests information. The sender/manager members should already be available in the team store
            const individualRequestDetails: IndividualOpenShiftRequestDetails[] = [];
            if (groupedRequest.shiftRequests) {

                const loadDisplayDataForMemberFromUserId = async (userId: string, member: IMemberEntity): Promise<void> => {
                    const setDataInState = (users: UserInfo[]): void => {
                        if (users?.length) {
                            const user = users[0];
                            member.userId = user.id;
                            member.displayName = user.name;
                        }
                    };
                    if (userId) {
                        try {
                            const refreshedUsers = await DataServices.UserProfileDataService.getUsersProfiles([userId], setDataInState);
                            if (member.displayName !== refreshedUsers[0].name) {
                                // When user profile is fetched from memory,
                                // the state should be updated because the display name is set as "Employee" by default.
                                setDataInState(refreshedUsers);
                            }

                        } catch (error) {
                            // Ignore the error and continue with the default display name
                            trace.warn("loadDisplayDataForMemberFromUserId: Error in getting user profile for user id: " + userId);
                        }
                    }
                };

                const shiftRequests: IShiftRequestEntity[] = DataProcessingHelpers.getArrayFromMap<string, IShiftRequestEntity>(groupedRequest.shiftRequests);
                const loadDisplayDataPromises = [];
                for (let i = 0; i < shiftRequests.length; i++) {
                    const shiftRequest: IShiftRequestEntity = shiftRequests[i];
                    if (shiftRequest) {
                        const { senderMemberId, senderUserId, managerMemberId, managerUserId, senderTeamId, openShiftTeamId } = shiftRequest;
                        const { teamId: currentTeamId } = TeamStore();

                        // Load the sender information
                        let senderMember: IMemberEntity = MemberEntity.createEmptyObject();
                        senderMember.teamId = senderTeamId || currentTeamId;
                        senderMember.id = senderMemberId || senderUserId;
                        senderMember.userId = senderUserId;
                        senderMember.displayName = StringsStore().registeredStringModules.get("common").strings.get("employeeDefaultName");
                        if (senderTeamId && senderTeamId !== currentTeamId) {
                            // External team
                            loadDisplayDataPromises.push(loadDisplayDataForMemberFromUserId(senderUserId, senderMember));
                        } else if (senderMemberId) {
                            // Current team
                            senderMember = await MemberDataService.getMemberByIdFromCurrentTeam(senderMemberId);
                        }

                        // Load the manager information
                        let managerMember: IMemberEntity = MemberEntity.createEmptyObject();
                        managerMember.teamId = currentTeamId;
                        managerMember.id = managerMemberId || managerUserId;
                        managerMember.userId = managerUserId;
                        managerMember.displayName = StringsStore().registeredStringModules.get("common").strings.get("managerDefaultName");
                        if (openShiftTeamId && openShiftTeamId !== currentTeamId) {
                            // External team
                            loadDisplayDataPromises.push(loadDisplayDataForMemberFromUserId(managerUserId, managerMember));
                        } else if (managerMemberId) {
                            // Current team
                            managerMember = await MemberDataService.getMemberByIdFromCurrentTeam(managerMemberId);
                        }

                        individualRequestDetails.push(
                            {
                                request: shiftRequest,
                                isSelected: false,
                                senderMember: senderMember,
                                managerMember: managerMember
                            }
                        );
                    }
                }
                await Promise.all(loadDisplayDataPromises);
                updateOpenShiftRequestDetailsInStore(individualRequestDetails);
            }

            setGroupedRequestInStore(groupedRequest);
            setIsDataReadyInStore(true);
            setShiftRequestRead(groupedRequest);
        } catch (error) {
            trace.warn("loadDataForGroupedRequest: Error in getting details for openshift request");
            throw error;
        }
    }
);