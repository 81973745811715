import AvailabilityUtils from "./AvailabilityUtils";
import MemberUtils from "sh-application/utility/MemberUtils";
import ShiftUtils from "sh-application/utility/ShiftUtils";
import { BLOCKED_INSTRUMENTING_DOMAINS_REGEX } from "../../StaffHubConstants";
import { FlexiGroupedGridGroupSettings } from "sh-flexigrid";
import {
    FlightKeys,
    IAvailabilityEntity,
    IBaseShiftEntity,
    IMemberEntity,
    IShiftEntity,
    IShiftRequestEntity,
    ISubshiftEntity,
    ScheduleCalendarType,
    ScheduleCalendarTypes,
    ShiftRequestTypes
    } from "sh-models";
import { FlightSettingsService, InstrumentationService } from "sh-services";
import { getGenericEventPropertiesObject, InstrumentationEventPropertyInterface } from "sh-instrumentation";

/**
 * Utilities for Instrumentation
 */
export default class InstrumentationUtils {
    /**
     * Shared function that logs the deletion of an activity
     * @param scheduleCalendarType
     * @param actionType
     * @param numActivities
     * @param isOpenShift
     */
    public static logActivityDeleted(scheduleCalendarType: string, actionType: string, numActivities: number, isOpenShift: boolean = false) {
        InstrumentationService.logEvent(InstrumentationService.events.Activities_ActivityDeleted,
            [ getGenericEventPropertiesObject(InstrumentationService.properties.CurrentView, scheduleCalendarType),
              getGenericEventPropertiesObject(InstrumentationService.properties.DeleteActionType, actionType),
              getGenericEventPropertiesObject(InstrumentationService.properties.IsOpenShift, isOpenShift),
              getGenericEventPropertiesObject(InstrumentationService.properties.NumActivities, numActivities)]);
    }

    /**
     * Shared function that logs the deletion of an activity
     * @param activity
     * @param shift
     * @param scheduleCalendarType
     * @param actionType
     */
    public static logActivityModificationOrCreation(activity: ISubshiftEntity, shift: IShiftEntity, scheduleCalendarType: ScheduleCalendarType, actionType: string) {
        InstrumentationService.logEvent(InstrumentationService.events.Activities_CreatedOrModified,
            [getGenericEventPropertiesObject(InstrumentationService.properties.CurrentView, InstrumentationUtils.getCurrentViewForInstrumentation(scheduleCalendarType)),
             getGenericEventPropertiesObject(InstrumentationService.properties.ActionType, actionType),
             getGenericEventPropertiesObject(InstrumentationService.properties.ActivityTitleLength, activity.title ? activity.title.length : 0),
             getGenericEventPropertiesObject(InstrumentationService.properties.ActivityCodeEntered, !!activity.code),
             getGenericEventPropertiesObject(InstrumentationService.properties.ActivityCodeLength, activity.code ? activity.code.length : 0),
             getGenericEventPropertiesObject(InstrumentationService.properties.ActivityColor, activity.theme),
             getGenericEventPropertiesObject(InstrumentationService.properties.ShiftColor, activity.theme === shift.theme), // TODO: ask PM why InstrumentationService is called ShiftColor
             getGenericEventPropertiesObject(InstrumentationService.properties.ActivityStartTime, activity.startTime.format("HH:mm")),
             getGenericEventPropertiesObject(InstrumentationService.properties.ActivityEndTime, activity.endTime.format("HH:mm")),
             getGenericEventPropertiesObject(InstrumentationService.properties.IsOpenShift, ShiftUtils.isOpenShift(shift)),
             getGenericEventPropertiesObject(InstrumentationService.properties.ActivityPaid, ShiftUtils.subshiftIsPaidActivity(activity))]);
    }

    /**
     * Shared function that maps ScheduleCalendarTypes to instrumentation values for CurrentView
     * @returns string representing the current view for instrumentation
     */
    public static getCurrentViewForInstrumentation(scheduleCalendarType: ScheduleCalendarType): string {
        switch (scheduleCalendarType) {
            case ScheduleCalendarTypes.Day:
                return InstrumentationService.values.DayView;
            case ScheduleCalendarTypes.Week:
                return InstrumentationService.values.WeekView;
            case ScheduleCalendarTypes.Month:
                return InstrumentationService.values.MonthView;
        }
    }

    /**
     * Shared function that logs the launch of the add edit shift panel
     * @param scheduleCalendarType
     * @param isNewEvent
     * @param alreadyPublished
     * @param actionType
     */
    public static logAddEditShiftPanelLaunch(scheduleCalendarType: ScheduleCalendarType, isNewEvent: boolean, alreadyPublished: boolean, actionType: string, isOpenShift: boolean) {
        InstrumentationService.logEvent(InstrumentationService.events.ShiftAndActivities_DialogBoxTriggered,
            [getGenericEventPropertiesObject(InstrumentationService.properties.CurrentView, InstrumentationUtils.getCurrentViewForInstrumentation(scheduleCalendarType)),
             getGenericEventPropertiesObject(InstrumentationService.properties.IsNewEvent, isNewEvent),
             getGenericEventPropertiesObject(InstrumentationService.properties.AlreadyPublished, alreadyPublished),
             getGenericEventPropertiesObject(InstrumentationService.properties.IsOpenShift, isOpenShift),
             getGenericEventPropertiesObject(InstrumentationService.properties.ActionType, actionType) ]);
    }

    /**
     * Maps shift to an appropriate value for the TypeOfShift property
     * @param shift
     */
    public static getTypeOfShiftForInstrumentation(shift: IBaseShiftEntity): string {
        let typeOfShift: string = null;
        const isOpenShift: boolean = ShiftUtils.isOpenShift(shift);
        const isWorkingShift: boolean = ShiftUtils.isWorkingShift(shift);
        const isTimeOffEvent: boolean = ShiftUtils.isTimeOffEvent(shift);

        if (isWorkingShift && !isOpenShift) {
            typeOfShift = InstrumentationService.shiftTypes.Shift;
        } else if (isTimeOffEvent && !isOpenShift) {
            typeOfShift = InstrumentationService.shiftTypes.TimeOff;
        } else if (isWorkingShift && isOpenShift) {
            typeOfShift = InstrumentationService.shiftTypes.OpenShift;
        } else if (isTimeOffEvent && isOpenShift) {
            typeOfShift = InstrumentationService.shiftTypes.OpenTimeOff;
        }
        return typeOfShift;
    }

    /**
     * Returns the availabiltyType instrumentation value for a member being assigned an open shift
     * @param shift
     * @param member
     * @returns availability type for instrumentation based on memember's availability. Ex. All day, Unavailable or Time slots
     */
    private static getAvailabilityForInstrumentation( availability: IAvailabilityEntity): string {
        let typeOfAvailability: string = InstrumentationService.availabilityTypes.Unspecified;
        if (availability) {
            if (AvailabilityUtils.isUnavailable(availability)) {
                typeOfAvailability = InstrumentationService.availabilityTypes.Unavailable;
            } else if (availability.allDay) {
                typeOfAvailability = InstrumentationService.availabilityTypes.FullyAvailable;
            } else if (AvailabilityUtils.hasTimeSlots(availability)) {
                typeOfAvailability = InstrumentationService.availabilityTypes.PartiallyAvailable;
            }
        }

        return typeOfAvailability;
    }

    /**
     * Returns the RequestType instrumentation value for the shiftrequest
     * @param shift
     */
    public static getTypeOfShiftRequestForInstrumentation(shiftRequest: IShiftRequestEntity): string {
        let shiftRequestType: string = null;

        if (shiftRequest) {
            if (shiftRequest.requestType === ShiftRequestTypes.TimeOff) {
                shiftRequestType = InstrumentationService.ShiftRequestTypes.TimeOff;
            } else if (shiftRequest.requestType === ShiftRequestTypes.HandOff) {
                shiftRequestType = InstrumentationService.ShiftRequestTypes.Offer;
            } else if (shiftRequest.requestType === ShiftRequestTypes.Swap) {
                shiftRequestType = InstrumentationService.ShiftRequestTypes.Swap;
            } else if (shiftRequest.requestType === ShiftRequestTypes.Open) {
                shiftRequestType = InstrumentationService.ShiftRequestTypes.OpenShifts;
            } else if (shiftRequest.requestType === ShiftRequestTypes.CrossLocationOpen) {
                shiftRequestType = InstrumentationService.ShiftRequestTypes.CrossLocationOpen;
            }
        }

        return shiftRequestType;
    }

    /**
     * Gets the shift request user type (Sender/Receiver/Manager) for ViewedBy property
     * @param shift
     */
    public static getShiftRequestUserTypeForInstrumentation(shiftRequest: IShiftRequestEntity, member: IMemberEntity): string {
        let shiftRequestUserType: string = null;

        // we only check the receiver and sender member ids to get the type.
        // a user can be sender and the manager for the request, but sender takes priority.
        // its the same check as mobile and we need to keep these in sync
        if (shiftRequest && member) {
            if (shiftRequest.receiverMemberId === member.id) {
                shiftRequestUserType = InstrumentationService.ShiftRequestUsertype.Receiver;
            } else if (shiftRequest.senderMemberId === member.id) { // sender takes priority over manager type
                shiftRequestUserType = InstrumentationService.ShiftRequestUsertype.Sender;
            } else if (MemberUtils.isAdmin(member)) {
                shiftRequestUserType = InstrumentationService.ShiftRequestUsertype.Manager;
            }
        }

        return shiftRequestUserType;
    }

    /**
     * Gets the value for shift request ActionTaken property for instrumentation for Receiver and Manager cases
     * Sender can only cancel the request, so that value can be used directly. This method doesn't include the Sender case
     * @param isAccepting Indicates if the user is accepting or declining the request
     * @param isReceiver True if user is the receiver of the request
     * @param isAdmin True if user is admin on the team
     */
    public static getShiftRequestActionTakenForInstrumentation(isAccepting: boolean, isReceiver: boolean, isAdmin: boolean): string {
        let actionTaken: string = null;

        if (isReceiver && isAdmin) {
            actionTaken = isAccepting ? InstrumentationService.ShiftRequestActionTaken.AcceptAndApprove : InstrumentationService.ShiftRequestActionTaken.DeclineAndDeny;
        } else if (isReceiver) {
            actionTaken = isAccepting ? InstrumentationService.ShiftRequestActionTaken.Accept : InstrumentationService.ShiftRequestActionTaken.Decline;
        } else { // Manager
            actionTaken = isAccepting ? InstrumentationService.ShiftRequestActionTaken.Approve : InstrumentationService.ShiftRequestActionTaken.Deny;
        }
        return actionTaken;
    }

    /**
     * Logged when a shift is assigned to a person
     * @param shift
     * @param scheduleCalendarType
     */
    public static logAssignPeople(shift: IBaseShiftEntity, scheduleCalendarType: ScheduleCalendarType, availability: IAvailabilityEntity) {
        const eventDataArray: InstrumentationEventPropertyInterface[] = [getGenericEventPropertiesObject(InstrumentationService.properties.EventType, InstrumentationService.values.AccessibleCompleted),
            getGenericEventPropertiesObject(InstrumentationService.properties.CurrentView,  InstrumentationUtils.getCurrentViewForInstrumentation(scheduleCalendarType)),
            getGenericEventPropertiesObject(InstrumentationService.properties.TypeOfShift, InstrumentationUtils.getTypeOfShiftForInstrumentation(shift))];

        // Only log availability instrumentation if flight is turned on
        if (FlightSettingsService.isFlightEnabled(FlightKeys.EnableScheduleAvailability)) {
                eventDataArray.push(getGenericEventPropertiesObject(InstrumentationService.properties.Availability, InstrumentationUtils.getAvailabilityForInstrumentation( availability)));
        }
        InstrumentationService.logEvent(InstrumentationService.events.AssignPeople, eventDataArray);
    }

    /**
     * Add a custom dimension for the number of rows being rendered
     * @param gridGroupsSettings grid group settings
     */
    public static setCustomDimensionForScheduleRowCount(gridGroupsSettings: FlexiGroupedGridGroupSettings[]) {
        if (gridGroupsSettings && gridGroupsSettings.length) {
            let totalRowCount: number = 0;
            for (let i: number = 0; i < gridGroupsSettings.length; i++) {
                const flexiGroupedGridGroupSettings: FlexiGroupedGridGroupSettings = gridGroupsSettings[i];
                totalRowCount += 2; // Add one row for each header and footer
                if (flexiGroupedGridGroupSettings && flexiGroupedGridGroupSettings.rowSettingsBody && flexiGroupedGridGroupSettings.rowSettingsBody.length) {
                    totalRowCount += flexiGroupedGridGroupSettings.rowSettingsBody.length;
                }
            }
            InstrumentationService.setCustomDimension(getGenericEventPropertiesObject(InstrumentationService.properties.ScheduleGridRowCount, totalRowCount), false /* isGlobal */);
        }
    }

    /**
     * Clear the custom dimension for the number of rows being rendered
     */
    public static clearCustomDimensionForScheduleRowCount() {
        InstrumentationService.clearCustomDimension(InstrumentationService.properties.ScheduleGridRowCount);
    }

    /**
     * Removes EUII and EUPI information from a URL
     * @param url URL to normalize
     */
    public static NormalizeUrl(url: string): string {
        if (url) {
            // force URL to lower case
            url = url.toLowerCase();

            let urlParser = document.createElement('a');
            urlParser.href = url;

            // Use custom sanitization for StaffHub Website and Service URLs
            if (urlParser.hostname.endsWith("staffhub.ms") || urlParser.hostname.endsWith("staffhub.office.com")) {
                if (urlParser.hostname.indexOf('api') != -1) {
                    // Normalize the StaffHub service URL
                    url = urlParser.protocol + '//' + urlParser.hostname + InstrumentationUtils.NormalizeStaffHubServicePathName(urlParser.pathname) + InstrumentationUtils.SanitizeQueryString(urlParser.search);
                } else {
                    // Normalize the StaffHub website URL
                    url = InstrumentationUtils.NormalizeStaffHubWebsiteUrl(url);
                }
            } else {
                // For other domains, only instrument hostname and path to avoid including user content
                url = urlParser.protocol + '//' + urlParser.hostname + urlParser.pathname;
            }

            url = InstrumentationUtils.ScrubIdsFromUrl(url);
        }
        return url;
    }

    /**
     * The normalized query key to value
     * These values needs to be replaced in query strings to prevent sending sensitive information to Instrumentation platforms
     */
    private static readonly SensitiveQueryStringKeyToNormalizedValue: { [id: string]: string } = {
        "authorization": "token",
        "auth_upn": "upn"
    };

    /**
     * Removes sensitive values (like auth tokens) from a query string
     * @param queryString Query string to process
     */
    private static SanitizeQueryString(queryString: string): string {
        if (queryString) {
            if (queryString.startsWith('?')) {
                // Remove '?' from query string
                queryString = queryString.substring(1);
            }

            let queryStringParts = queryString.split('&');
            let newQueryString = "";
            for (let i = 0; i < queryStringParts.length; i++) {
                let queryKeyValue = queryStringParts[i].split('=');
                if (queryKeyValue && queryKeyValue.length == 2) {
                    const queryKey = queryKeyValue[0];
                    let queryValue = queryKeyValue[1];
                    if (InstrumentationUtils.SensitiveQueryStringKeyToNormalizedValue[queryKey] !== undefined) {
                        queryValue = InstrumentationUtils.SensitiveQueryStringKeyToNormalizedValue[queryKey];
                    }

                    if (newQueryString.length > 0) {
                        // Add '&' between query string parameters
                        newQueryString += "&";
                    }

                    newQueryString += queryKey + "=" + queryValue;
                }
            }
            return newQueryString ? "?" + newQueryString : "";
        } else {
            return '';
        }
    }

    /**
     * Scrubs GUID IDs from the url string.
     * @param url
     */
    public static ScrubIdsFromUrl(url: string) {
        if (url) {
            return url.replace(/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/gi, "id");
        }

        return "";
    }

    /**
     * Normalize the StaffHub Website URL
     * @param url Url
     */
    public static NormalizeStaffHubWebsiteUrl(url: string): string {
        if (url) {
            // force URL to lower case
            url = url.toLowerCase();

            let urlParser = document.createElement('a');
            urlParser.href = url;

            // Generate a sanitized URL by sanitizing the subcomponents and dropping the port and hash
            url = urlParser.protocol + '//' + urlParser.hostname +  InstrumentationUtils.NormalizeStaffHubWebsitePathName(urlParser.pathname) + InstrumentationUtils.SanitizeQueryString(urlParser.search);

            if ((!!urlParser.hash) && urlParser.hash.indexOf("id_token=") !== -1) {
                url += "/adalredirect";
            } else if ((!!urlParser.hash) && urlParser.hash.indexOf("error=") !== -1) {
                url += "/adalerror";
            }

        }
        return url;
    }

    /**
     * The REGEX for detecting Team Handle in a StaffHub website url
     */
    private static readonly WEBSITE_TEAM_HANDLE_REGEX = /(TEAM_)\w+/i;

    /**
     * The REGEX for detecting an InviteCode in a StaffHub website url
     */
    private static readonly INVITE_URL_REGEX = /\/i\/\w+/i;

    /**
     * Normalize the Website Path so it has a consisitent name
     * @param pathname Path
     */
    public static NormalizeStaffHubWebsitePathName(pathname: string): string {
        if (pathname) {
            pathname = pathname.toLowerCase();

            // Remove the team handle
            pathname = pathname.replace(InstrumentationUtils.WEBSITE_TEAM_HANDLE_REGEX, "teamid");

            // Remove any GUIDs
            pathname = InstrumentationUtils.ScrubIdsFromUrl(pathname);

            // Remove any invite codes
            pathname = pathname.replace(InstrumentationUtils.INVITE_URL_REGEX, "/i/inviteCode");

            return pathname;
        } else {
            return '';
        }
    }

    /**
     * Dictionary of values that follow the typical pattern for the given URL scheme
     * e.g. "tenantId/{TenantId}/teams/{TeamId}"
     */
    private static readonly StaffHubServiceUrlNormalizedValues: { [id: string]: string; } = {
        "tenants": "tenantid",
        "teams": "teamid",
        "conversations": "convid",
        "files": "itemid",
        "createLink": "fileaccesstype",
        "uploadSession": "filename",
        "checkCode": "code",
        "locate": "code",
        "users": "userId"
    };

    /**
     * Dictionary of values that don't follow the typical pattern for the given URL scheme and should not be normalized.
     * When a value is in the StaffHubServiceUrlNormalizedValues dictionary above, but doesn't always follow the pattern given, it belongs in here.
     * An example would be "tenantId/{tenantId}/teams/{teamId}/files/edit". Typically it's /files/{ItemId} as above, but not always.
     */
    private static readonly StaffHubServiceUrlIrregularSchemes: { [id: string]: string[] } = {
        "users": ["fetch", "searchgal", "searchusers"]
    };

    /**
     * Normalizes the name of a StaffHub API Service Path for instrumentation
     */
    public static NormalizeStaffHubServicePathName(pathname: string, telemetryContextProperties?: { [id: string]: string }): string {
        if (pathname) {
            pathname = pathname.toLowerCase();
            pathname = InstrumentationUtils.ScrubIdsFromUrl(pathname);
            let newOperationPath = "";
            const requestComponents = pathname.split('/');
            for (let i = 0; i < requestComponents.length; i++) {
                const requestComponent = requestComponents[i];
                if (requestComponent) {
                    const normalizedRequestComponent = requestComponent.toLowerCase();
                    const nextRequestComponent = i + 1 < requestComponents.length ? requestComponents[i + 1] : null;

                    if (InstrumentationUtils.StaffHubServiceUrlNormalizedValues[normalizedRequestComponent] !== undefined && nextRequestComponent != null)  {
                        // Only attempt to add a normalized key-value pair if we find a component who's following parameter is well-defined, and if another component actually exists.
                        newOperationPath += "/" + normalizedRequestComponent;

                        // Check for irregular schemes that should be skipped here and normalized on a different value.
                        if (InstrumentationUtils.StaffHubServiceUrlIrregularSchemes[normalizedRequestComponent] !== undefined &&
                            InstrumentationUtils.StaffHubServiceUrlIrregularSchemes[normalizedRequestComponent].indexOf(nextRequestComponent) != -1) {
                            // Skip adding a normalized value here since the value was found to be irregular.
                            continue;
                        }

                        let normalizedValue = InstrumentationUtils.StaffHubServiceUrlNormalizedValues[normalizedRequestComponent];

                        // Since we are taking this value out of the request, embed it as a property.
                        if (telemetryContextProperties) {
                            telemetryContextProperties[normalizedValue] = nextRequestComponent;
                        }
                        newOperationPath += "/" + normalizedValue;

                        // Since we've already added a normalized value to represent
                        // the upcoming requestComponent, we should just skip it.
                        i++;
                    } else {
                        newOperationPath += "/" + requestComponent;
                    }
                }
            }

            return newOperationPath.toLowerCase();
        } else {
            return '';
        }
    }

    /**
     * Returns if we should instrument AJAX requests to the specific domain
     */
    public static ShouldInstrumentAjaxRequest(domain: string): boolean {

        if (BLOCKED_INSTRUMENTING_DOMAINS_REGEX.test(domain)) {
            return false;
        }

        return true;
    }

    /**
     * Get the instrumentation cell type given a shift
     * @param shift
     */
    public static getInstrumentationCellType(shift: IBaseShiftEntity): string {
        return shift ? ShiftUtils.isWorkingShift(shift) ? InstrumentationService.values.Shift : InstrumentationService.values.TimeOff : InstrumentationService.values.Empty;
    }
}
