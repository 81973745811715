import confirm from "sh-confirm-dialog/lib/actions/confirm";
import MemberUtils from "sh-application/utility/MemberUtils";
import setGlobalMessages from "sh-application/actions/setGlobalMessages";
import StringsStore from "sh-strings/store";
import { appViewState } from "../../../store";
import { fireAccessibilityAlert } from "sh-application/components/accessibilityAlert";
import { getGenericEventPropertiesObject } from "sh-instrumentation";
import { IMemberEntity, ITagEntity, TagEntity } from "sh-models";
import { InstrumentationService, TagDataService } from "sh-services";
import { MessageBarType } from "@fluentui/react";
import { TagStore } from "sh-tag-store";
import { trace } from "owa-trace";
import { decrementTagPeopleCount } from "../../../../sh-stores/sh-tag-store/mutators/decrementTagPeopleCount";

/**
 * Method that handles member delete from a group in the schedules page
 * Shows a confirmation dialog before deleting the member.
 * @param member - member to be deleted
 * @param tagName - name of the tag from which the member needs to be removed
 * @param tagId - id of the tag from which the member needs to be removed
 * @param hasActiveShifts - boolean that indicates whether the team member has active shifts in the current view of the schedule
 * @param onCancel - onCancel callback
 */
export default function removeTagMember(member: IMemberEntity, tagName: string, tagId: string, hasActiveShifts: boolean, onCancel: () => void) {
    trace.info(`Removing user from tag, has active shifts: ${hasActiveShifts}`);
    const memberDisplayName = MemberUtils.getDisplayNameForMember(member);
    const strings = StringsStore().registeredStringModules.get("schedulePage").strings;
    const commonStrings = StringsStore().registeredStringModules.get("common").strings;

    const confirmationDialogTitle = strings.get("removeFromGroup");

    if (hasActiveShifts) {
        // user has active shifts, show shift exists error
        confirm(
            confirmationDialogTitle,
            strings.get("cannotRemoveMemberFromGroupError").format(memberDisplayName),
            false, /* resolveImmediately */
            { hideCancelButton: true, callCancelOnDismiss: true }
        ).finally(() => {
            if (onCancel) {
                // we need to call the onCancel handler to control focus for accessibility
                onCancel();
            }
        });
    } else {
        // user has no active shifts, show delete member confirmation dialog
        confirm(
            confirmationDialogTitle,
            strings.get("removeMemberFromGroupConfirmationMessage").format(memberDisplayName, tagName),
            false, /* resolveImmediately */
            { okText: commonStrings.get("remove"), isBlocking: true, callCancelOnDismiss: true }
        )
        .then(() => {
            // remove member from the tag and update tag
            const tag = TagStore().tags.get(tagId);
            let updatedTag = TagEntity.clone(tag);
            const currentIndex = tag.memberIds.indexOf(member.id);

            InstrumentationService.logEvent(InstrumentationService.events.MemberCommitDeleteClicked,
                [getGenericEventPropertiesObject(InstrumentationService.properties.EntryPoint, InstrumentationService.entryPoints.Schedule)]);

            if (currentIndex > -1) {
                updatedTag.memberIds.splice(currentIndex, 1);
                TagDataService.saveTag(updatedTag, false /*isNewTag*/)
                    .then((tag: ITagEntity) => {
                        if (tag) {
                                fireAccessibilityAlert(strings.get("accessibilityMemberRemovedFromGroupAlert").format(memberDisplayName, tagName));
                                decrementTagPeopleCount(tagId);
                            } else {
                                setGlobalMessages(appViewState().globalMessageViewState, [strings.get("memberRemoveFromGroupFailedAlert").format(memberDisplayName, tagName)], MessageBarType.error, null /* action button title */, null /* action button callback */, false /* auto dismiss */, true /* is multiline */);
                            }
                    });
            } else {
                trace.warn(`Unable to delete member: ${member.id} not found in the member ids`);
                setGlobalMessages(appViewState().globalMessageViewState, [strings.get("memberRemoveFromGroupFailedAlert").format(memberDisplayName, tagName)], MessageBarType.error, null /* action button title */, null /* action button callback */, false /* auto dismiss */, true /* is multiline */);
            }
        }).finally(() => {
            if (onCancel) {
                // we need to call the onCancel handler to control focus for accessibility
                onCancel();
            }
        });
    }
}