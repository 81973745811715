import { orchestrator } from "satcheljs";
import { IOpenShiftEntity, IShiftRequestResponseEntity, ShiftRequestTypes } from "sh-models";
import { ShiftRequestDataService } from "sh-services";

import { setErrorMessageFromException, setIsSavingInStore } from "../";
import { actionShiftRequest } from "../actions/actionShiftRequest";
import { IActionShiftRequestParams } from "../common/IActionShiftRequestParams";
import { ShiftRequestAction } from "../common/ShiftRequestAction";

/**
 * Approves or denies given request by calling the corresponding data service.
 */
export const onActionShiftRequest = orchestrator(actionShiftRequest, async (params) => {
    const { request, onFinish } = params;

    setIsSavingInStore(true);

    try {
        switch (request.requestType) {
            case ShiftRequestTypes.CrossLocationOpen:
            case ShiftRequestTypes.Open:
                await actionOpenShiftRequest(params);
                break;
            default:
                // TODO: Supports all request types.
                throw new Error(`onActionShiftRequest: Request type '${request.requestType}' is not supported.`);
        }

        onFinish();
    } catch (error) {
        // TODO: Investigate when 'error.isHandled' is set and if no other action are needed in this case.
        // workflow integration error would be handled earlier
        if (!error?.isHandled) {
            // TODO: Telemetry when error has not been handled
            setErrorMessageFromException(error);
        }

        onFinish(error);
    } finally {
        setIsSavingInStore(false);
    }
});

const actionOpenShiftRequest = async (params: IActionShiftRequestParams): Promise<void> => {
    const { action, note, request, teamId } = params;
    const openShift: IOpenShiftEntity = { id: request.shiftId } as unknown as IOpenShiftEntity;

    const response: IShiftRequestResponseEntity = {
        eTag: request.eTag,
        id: request.id,
        isAccepting: action == ShiftRequestAction.Approve,
        message: note
    };

    // TODO: Pass 'request.shiftId' instead of 'openShift' to avoid having to cast or find the open shift record from the state in other files.
    await ShiftRequestDataService.approveDeclineOpenShiftRequestsBulk(teamId, openShift, [response]);
};