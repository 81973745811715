import * as Combokeys from "combokeys";
import * as React from "react";
import ActivityFlexContainer from "../../activity/ActivityFlexContainer";
import AutomationUtils from "sh-application/utility/AutomationUtil";
import AvailabilityUtils from "sh-application/utility/AvailabilityUtils";
import ConflictDetailsCallout from "sh-application/components/shift/conflictDetails/ConflictDetailsCallout";
import ConflictUtils from "sh-application/utility/ConflictUtils";
import EditStatusIndicator from "sh-application/components/common/EditStatusIndicator";
import EmptyCellAvailability from "./EmptyCellAvailability";
import IBaseScheduleCellProps from "./IBaseScheduleCellProps";
import InstrumentationUtils from "sh-application/utility/InstrumentationUtils";
import MemberUtils from "sh-application/utility/MemberUtils";
import OpenShiftRequestApprovalCallout from "sh-application/components/shiftrequest/approvalCallout/OpenShiftRequestApprovalCallout";
import PrintComponent from "sh-application/components/common/PrintComponent";
import schedulesViewStateStore from "sh-application/components/schedules/lib/store/store";
import Shift from "../../shift/Shift";
import ShiftContextualMenu from "../../shift/ShiftContextualMenu";
import ShiftDetailsCallout from "../../shift/shiftDetails/ShiftDetailsCallout";
import ShiftRequestsIndicator from "sh-application/components/shift/shiftRequestsIndicator/ShiftRequestsIndicator";
import ShiftRequestUtils from "sh-application/utility/ShiftRequestUtils";
import ShiftThemeContainer from "../../shift/ShiftThemeContainer";
import ShiftUtils from "sh-application/utility/ShiftUtils";
import StringsStore from "sh-strings/store";
import TimeOffRequestApprovalCallout from "sh-application/components/shift/TimeOffRequestApprovalCallout";
import TimeOffRequestShift from "../../shift/TimeOffRequestShift";
import TimeOffShift from "../../shift/TimeOffShift";
import { calculateRequestConflicts } from "sh-requestconflict-store";
import { default as KeyboardUtils } from "sh-application/utility/KeyboardUtils";
import {
    ECSConfigKey,
    ECSConfigService,
    FlightSettingsService,
    InstrumentationService
    } from "sh-services";
import {
    FlightKeys,
    IBaseConflictEntity,
    IBaseShiftEntity,
    IGroupedOpenShiftRequestEntity,
    IMemberEntity,
    IOpenShiftEntity,
    IShiftEntity,
    IUniqueShiftEntity,
    ScheduleCalendarType,
    ScheduleCalendarTypes,
    ShiftRequestStates
    } from "sh-models";
import { getGenericEventPropertiesObject } from "sh-instrumentation";
import { IButton, IconButton } from "@fluentui/react";
import { Moment } from "moment";
import { ScheduleCellRenderSize } from "sh-application/../StaffHubConstants";
import { ScheduleGridCssClasses, ScheduleGridUtils } from "sh-application/components/schedules/lib";
import { SlotBarProps } from "sh-application/components/shift/SlotBar";
import { StaffHubTourMarker } from "sh-application/components/tour/sh-tour/StaffHubTourMarker";
import { TeamStore } from "sh-stores/sh-team-store";

const classNames = require("classnames/bind");
const styles = require("./ScheduleCell.scss");

interface PrintBackgroundProps {
    shift: IBaseShiftEntity;
}

const PrintBackground = function(props: PrintBackgroundProps) {
    const { shift } = props;
    if (!shift) {
        return null;
    }
    const theme = ShiftUtils.getShiftThemeClass(shift);
    return (
        <span className={ styles.printBackgroundContainer }>
            <span className={ classNames(styles.printBackground, styles[theme]) }></span>
        </span>
    );
};

interface ScheduleCellState {
    isMoreMenuCalloutVisible: boolean;              // true when the ... (more menu) is open
    isShiftLookupCalloutVisible: boolean;           // true when the shift lookup menu is open
    isOpenShiftRequestCalloutVisible: boolean;      // true when open shift request approve/deny callout is open
    instrumentationAction: string;                  // action to log in instrumentation
    triggerKey: string;                             // the key that opened the last callout
    isConflictCalloutVisible: boolean;              // true when the conflict callout is open
    renderExtras: boolean;
}

/**
 * Callbacks for operations that can be performed for a schedule cell (eg, copy + paste, delete, etc)
 */
export interface ScheduleCellActionCallbacks {
    cellCopyCallback: (() => void) | null;      // Copy the cell
    cellPasteCallback: (() => void) | null;     // Paste onto the cell
    cellShareCallback: ((shift: IBaseShiftEntity) => void) | null;     // Share the shift in the cell
    cellAssignOpenShiftCallback: ((openShift: IOpenShiftEntity, member: IMemberEntity) => void); // assign the open shift in the cell to a member
    cellMoveToOpenShiftsCallback: ((shift: IBaseShiftEntity, shiftMember: IMemberEntity, targetDate: Moment, targetGroupTagId: string) => void); // move the shift to open shifts
    canPerformPasteCallback: (() => boolean);   // Determine if a paste can be performed
    cellDeleteCallback: (() => void) | null;    // Delete the cell's contents
    applyUniqueShiftCallback: ApplyUniqueShiftCallbackFunction;
}

export type ApplyUniqueShiftCallbackFunction =  ((                // Apply a unique shift to the selection
    uniqueShift: IUniqueShiftEntity,
    tenantId: string,
    teamId: string) => void) | null;

const SCHEDULE_CELL_BASE_CSSCLASS = "scheduleCell";

/**
 * ScheduleCell component
 * This renders the contents of a schedule cell (eg, shift, timeoff)
 */
class ScheduleCell extends React.Component<IBaseScheduleCellProps, ScheduleCellState> {
    private _cellElement = React.createRef<HTMLDivElement>();
    private _shiftLookupElement = React.createRef<IButton>();

    private _combokeys: Combokeys.Combokeys;
    private static _containerClasses = classNames(SCHEDULE_CELL_BASE_CSSCLASS, styles.scheduleCell);
    private static _emptyCellContainerClasses = "";
    private static _emptyCellOpenShiftContainerClasses = "";
    private static _lastCalendarType: ScheduleCalendarType = null;
    private static _contentClasses = classNames(styles.innerContainer, "shiftContent");
    private static _contextMenuButtonAriaLabel = "";
    private static _contextMenuButtonTooltip = "";
    private static _shiftLookupContextMenuButtonAriaLabel = "";
    private static _shiftLookupContextMenuButtonTooltip = "";

    constructor(props: IBaseScheduleCellProps) {
        super(props);

        if (!ScheduleCell._lastCalendarType || (ScheduleCell._lastCalendarType !== props.scheduleCalendarType)) {
            // set the last calendar type so we can keep track of when it changes
            ScheduleCell._lastCalendarType = props.scheduleCalendarType;
            // if the calendar type is changing, clear the calculated empty cell container classes
            ScheduleCell._emptyCellContainerClasses = null;
            ScheduleCell._emptyCellOpenShiftContainerClasses = null;
        }

        if (!ScheduleCell._contextMenuButtonAriaLabel) {
            ScheduleCell._contextMenuButtonAriaLabel = StringsStore().registeredStringModules.get("common").strings.get("openContextMenuButtonAriaLabel");
            ScheduleCell._contextMenuButtonTooltip = StringsStore().registeredStringModules.get("common").strings.get("openContextMenuButtonTooltip");
            ScheduleCell._shiftLookupContextMenuButtonAriaLabel = StringsStore().registeredStringModules.get("common").strings.get("openShiftLookupMenuButtonAriaLabel");
            ScheduleCell._shiftLookupContextMenuButtonTooltip = StringsStore().registeredStringModules.get("common").strings.get("openShiftLookupMenuButtonTooltip");
        }

        this.state = {
            isMoreMenuCalloutVisible: false,
            isShiftLookupCalloutVisible: false,
            isOpenShiftRequestCalloutVisible: false,
            instrumentationAction: "",
            triggerKey: "",
            isConflictCalloutVisible: false,
            renderExtras: false
        };
    }

    componentDidUpdate(prevProps: IBaseScheduleCellProps, prevState: ScheduleCellState) {
        const { instrumentScheduleEvent, shift, currentUserMemberId } = this.props;
        const { isMoreMenuCalloutVisible, isShiftLookupCalloutVisible, isOpenShiftRequestCalloutVisible, instrumentationAction } = this.state;

        // if re-render is happening because of Time off request approval, old shift and new shift will differ by shift type.
        // If thats the case, dismiss the context menu ( in case it is still open )
        // Hide conflict menu if its open while re-render
        if (prevProps.shift && this.props.shift && ShiftUtils.isTimeOffRequestEvent(prevProps.shift) && ShiftUtils.isTimeOffEvent(this.props.shift)) {
            this.hideMoreMenu();
            this.hideConflictMenu();
            this.hideOpenShiftRequestApprovalCallout();
        }

        // Attach/detach the keyboard listener that invokes the contextual menu when the schedule cell
        // acquires/loses focus
        // TODO (DCoh): Implement a scenario test that verifies keyboard-based focus handling and contextual menu invoking for schedule cells.
        // Verify:
        // - Tab forward to next schedule cell, hit enter to invoke context menu
        // - Tab backward (shift tab) to previous schedule cell, hit enter to invoke context menu (bug 589797)
        if (this.props.cellHasFocus && !prevProps.cellHasFocus) {  // on focus
            this.attachKeyboardListeners();
        } else if (!this.props.cellHasFocus && prevProps.cellHasFocus) {  // on blur
            this.detachKeyboardListeners();
        }

        // protect against instrumenting the same action multiple times - only instrument if a callout/menu was opened that
        // previously was not open
        if (instrumentScheduleEvent
            && (isMoreMenuCalloutVisible && !prevState.isMoreMenuCalloutVisible)
            || (isShiftLookupCalloutVisible && !prevState.isShiftLookupCalloutVisible)
            || (isOpenShiftRequestCalloutVisible && !prevState.isOpenShiftRequestCalloutVisible)) {
                if (this.shouldShowTimeOffRequestApprovalCallout(shift)) {
                    instrumentScheduleEvent(InstrumentationService.events.Requests, [
                        getGenericEventPropertiesObject(InstrumentationService.properties.EventType, InstrumentationService.values.RequestsEntryPointClicked),
                        getGenericEventPropertiesObject(InstrumentationService.properties.Location, InstrumentationService.values.Flyout),
                        getGenericEventPropertiesObject(InstrumentationService.properties.RequestType, InstrumentationService.ShiftRequestTypes.TimeOff),
                        getGenericEventPropertiesObject(InstrumentationService.properties.EntryPoint, instrumentationAction)
                    ]);
                } else if (this.shouldShowShiftContextMenu(shift)) {
                    instrumentScheduleEvent(isShiftLookupCalloutVisible ? InstrumentationService.events.ShiftLookupOpened : InstrumentationService.events.ContextMenuOpened, [
                        getGenericEventPropertiesObject(InstrumentationService.properties.CellType, InstrumentationUtils.getInstrumentationCellType(shift)),
                        getGenericEventPropertiesObject(InstrumentationService.properties.ActionType, instrumentationAction)
                    ]);
                } else if (this.shouldShowShiftDetailsCallout(shift)) {
                    // opening the shift details callout will trigger a requests instrumentation if the shift is an open shift
                    // it will also trigger shift details instrumentation for assigned and open shifts
                    if (ShiftUtils.isOpenShift(shift)) {
                        instrumentScheduleEvent(InstrumentationService.events.Requests, [
                            getGenericEventPropertiesObject(InstrumentationService.properties.EventType, InstrumentationService.values.RequestsEntryPointClicked),
                            getGenericEventPropertiesObject(InstrumentationService.properties.Location, InstrumentationService.values.Flyout),
                            getGenericEventPropertiesObject(InstrumentationService.properties.RequestType, InstrumentationService.ShiftRequestTypes.EmployeeOpenShift),
                            getGenericEventPropertiesObject(InstrumentationService.properties.EntryPoint, instrumentationAction)
                        ]);
                    }
                    instrumentScheduleEvent(InstrumentationService.events.EmployeeShiftDetails, [
                        getGenericEventPropertiesObject(InstrumentationService.properties.OwnShift, shift.memberId === currentUserMemberId)]);
                }
        }
    }

    /**
     * Return true if Shift Context Menu should be shown.
     * This is the case if selection is enabled, the contextual menu is enabled, the menu
     * is visible through more button click or right click, and this is either an empty cell or
     * a working shift cell (open or assigned shift) or a time off shift cell. Time off request cells
     * should never show the contextual menu.
     * @param shift
     */
    private shouldShowShiftContextMenu(shift: IBaseShiftEntity): boolean {
        const { selectionEnabled, enableContextualMenu } = this.props;
        return ((this.state.isMoreMenuCalloutVisible || this.state.isShiftLookupCalloutVisible) && selectionEnabled && enableContextualMenu &&
                (!shift || ShiftUtils.isWorkingShift(shift) || ShiftUtils.isTimeOffEvent(shift)));
    }

    /**
     * Returns true if the extras (more icon, search icon, context menus) should be rendered. For performance it is VERY IMPORTANT we
     * don't render the extras unless absolutely needed (such as when the cell is focused or hovered).
     * @returns boolean
     */
    private shouldRenderExtras(): boolean {
        return (this.props.cellHasFocus || this.state.renderExtras);
    }

    /**
     * Returns true if Time off request callout should be shown.
     * This is the case if selection is enabled, the callout is visible through more button click or right click,
     * and the shift in the cell is a timeoff request event.
     * @param shift
     */
    private shouldShowTimeOffRequestApprovalCallout(shift: IBaseShiftEntity): boolean {
        return shift && this.props.selectionEnabled && (this.state.isMoreMenuCalloutVisible || this.state.isShiftLookupCalloutVisible) && ShiftUtils.isTimeOffRequestEvent(shift);
    }

    /**
     * Returns true if conflict callout should be shown.
     * This is the case if selection is enabled, the callout is visible on conflict icon click
     * @param shift
     */
    private shouldShowConflictDetailsCallout(shift: IBaseShiftEntity): boolean {
        // never render conflict callout for empty cell
        if (!shift) {
            return false;
        }
        const currentUser = TeamStore() && TeamStore().me;
        return ConflictUtils.isConflictEnabledForAdminInDateRange(MemberUtils.isAdmin(currentUser), schedulesViewStateStore().viewEndDate) && (this.state.isConflictCalloutVisible);
    }

    /**
     * Returns true if the shift details callout should be shown.
     * @param shift
     */
    private shouldShowShiftDetailsCallout(shift: IBaseShiftEntity): boolean {
        return FlightSettingsService.isFlightEnabled(FlightKeys.EnableOpenShiftRequestsOnWeb)
                    && ECSConfigService.isECSFeatureEnabled(ECSConfigKey.EnableOpenShiftRequests)
                    && shift
                    && this.props.showShiftDetails
                    && (this.state.isMoreMenuCalloutVisible || this.state.isShiftLookupCalloutVisible);
    }

    /**
     * Returns true if the open shift request approval callout should be shown
     */
    private shouldShowOpenShiftRequestCallout(): boolean {
        return this.state.isOpenShiftRequestCalloutVisible && FlightSettingsService.isFlightEnabled(FlightKeys.EnableOpenShiftRequestsOnWeb);
    }

    private attachKeyboardListeners() {
        if (!this._combokeys) {
            // Note: Keyboard handling should be attached to an element inside the schedule grid rather than the document level.
            // This would help us to avoid triggering this keypress handler for popups and modals.
            // Another alternative would be to attach a key event listener to each cell. However, having a listener for each cell
            // might be expensive, and also the listener would actually need to be attached to the FlexiCell parent, since that's
            // what actually gets selection cell focus. (also, the base context menu setup should also be moved up to FlexiCell)
            // Currently as a workaround, we use ScheduleGridUtils.isFocusInScheduleGridCellOrShiftMenu() to avoid handling keypresses
            // when focus is not inside a schedule cell.
            this._combokeys = new Combokeys(document.documentElement);
            this._combokeys.bind(['any-character', 'enter', 'space', 'tab'], this.onKeyDown);
        }
    }

    private detachKeyboardListeners() {
        if (this._combokeys) {
            this._combokeys.detach();
            this._combokeys = null;
        }
    }

    /**
     * Handles opening the default context menu (more menu), when a key is pressed on a schedule cell.
     * @param e - KeyboardEvent
     */
    private onKeyDown = (e: KeyboardEvent) => {
        const target = e.target as HTMLElement;
        const nonActionableTarget = target && target.nodeName.toLowerCase() !== "button";
        const { getNumCellsSelected, showShiftDetails } = this.props;

        // Adding prevent default and stop propagation to prevent Selection Zone from handling "space" keypress to toggle it's focus.
        if (KeyboardUtils.isSpacePressed(e) && nonActionableTarget) {
            e.preventDefault();
            e.stopPropagation();
        }

        // Prevent losing the More Icons menu when pressing TAB and temporarily losing the cell focus
        if (KeyboardUtils.isTabPressed(e)) {
            this.setState({ renderExtras: true });
        }

        if (target?.classList?.contains("requestsIndicator")) {
            // if the keydown was on the requests indicator, don't handle it here
            return;
        }

        if ((!this.isAnyCalloutOpen())   // none of the context menus are open
            && !KeyboardUtils.isCtrlOrCommandPressed(e)                                         // the ctrl or command key is not pressed
            && !KeyboardUtils.isTabPressed(e)                                                   // the tab key is not pressed
            && ScheduleGridUtils.isFocusInScheduleGridCellOrShiftMenu()                         // focus is in the grid or one of the context menus
            && (getNumCellsSelected() === 1 || showShiftDetails)) {                             // only 1 cell is selected
                // if this is not an action key (enter or space), show the shift lookup menu and pass the key
                // that triggered the menu so it can be passed to the search control
                // regression #510477
                const showShiftLookupMenu = !KeyboardUtils.isActionKeyPressed(e);
                this.showCellCallout(InstrumentationService.actionTypes.Type, showShiftLookupMenu, false /*isConflictCallout*/, e.key);  // show the context menu
        }
    }

    /**
     * Callback for when the shift lookup context menu button is activated via click
     */
    private onShiftLookupIconClicked = () => {
        this.showCellCallout(InstrumentationService.actionTypes.Mouse, true, false /*isConflictCallout*/);
    }

    /**
     * Callback for when the context menu button is activated via keypress
     * @param key
     */
    private onShiftLookupKeyPressed = (key: React.KeyboardEvent<HTMLDivElement>) => {
        if (KeyboardUtils.isActionKeyPressed(key)) {
            key.preventDefault();
            key.stopPropagation();
            this.showCellCallout(InstrumentationService.actionTypes.EnterKey, true, false /*isConflictCallout*/ );
        }
    }

    /**
     * Callback for when the conflict icon button is activated via click
     */
    private onConflictIconClicked = () => {
        this.showCellCallout(InstrumentationService.actionTypes.EnterKey, false, true /*isConflictCallout*/ );
        this.instrumentConflictIconClicked();
    }

    /**
     * Instrument a click on conflict Icon
     */
    private instrumentConflictIconClicked() {
        InstrumentationService.logEvent(InstrumentationService.events.ConflictBadgeIconClicked,
           [getGenericEventPropertiesObject(InstrumentationService.properties.CurrentView, InstrumentationUtils.getCurrentViewForInstrumentation(schedulesViewStateStore().scheduleCalendarType))]);
   }

    /**
     * Callback for when the context menu button is activated via click
     */
    private onMoreIconClicked = () => {
        this.showCellCallout(InstrumentationService.actionTypes.Mouse, false, false /*isConflictCallout*/);
    }

    /**
     * Callback for when the context menu button is activated via keypress
     * @param key
     */
    private onMoreIconKeyPressed = (key: React.KeyboardEvent<HTMLDivElement>) => {
        if (KeyboardUtils.isActionKeyPressed(key)) {
            key.preventDefault();
            key.stopPropagation();
            this.showCellCallout(InstrumentationService.actionTypes.EnterKey, false, false /*isConflictCallout*/);
        }
    }

    /**
     * Handler when the cell is right clicked
     * @param e - React.MouseEvent
     */
    private onRightClick = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        const { selectionEnabled, enableContextualMenu } = this.props;

        // Right clicks should only attempt to open a callout if selection and the contextual menu are enabled
        if (selectionEnabled && enableContextualMenu) {
            this.showCellCallout(InstrumentationService.actionTypes.Mouse, false, false /*isConflictCallout*/);
        }
    }

    /**
     * Handler when the cell is left clicked
     * @param e - React.MouseEvent
     */
    private onLeftClick = (e: React.MouseEvent<HTMLElement>) => {
        const { selectionEnabled, showShiftDetails } = this.props;

        // Left clicks should only attempt to open a callout if selection is disabled and showShiftDetails is true
        // This is because when selection is enabled left clicks act to select the cell
        // Also open the callout if it is a time off request.
        if ((!selectionEnabled && showShiftDetails) || ShiftUtils.isTimeOffRequestEvent(this.props.shift))  {
            this.showCellCallout(InstrumentationService.actionTypes.Mouse, false, false /*isConflictCallout*/);
        }
    }

    /**
     * Callback that displays the contextual menu, the time off request callout,
     * or the shift details callout
     * @param actionType (InstrumentationService.actionTypes) The instumentation action
     * @param isShiftLookup Pass true to show the shift lookup menu, otherwise the ... more menu is shown
     * @param isConflictCallout Boolean to show conflict callout menu
     * @param triggerKey (optional) The key that triggered the callout. Is used by the shift lookup menu to pass as the initial value for the search field.
     */
    private showCellCallout(actionType: string, isShiftLookup: boolean, isConflictCallout: boolean, triggerKey?: string) {

        if (this._cellElement) {
            // Set the main cell callout visible and hide the open shift request approval callout in case it was open
            if (isShiftLookup) {
                // pass the key that triggered (opened) the shift lookup menu so that it can be passed
                // to the search control
                this.setShiftLookupMenuVisible(true, triggerKey);
                this.setConflictCalloutVisible(false);
            } else if (isConflictCallout) {
                this.setConflictCalloutVisible(true);
                this.setMoreMenuVisible(false);
            } else {
                this.setConflictCalloutVisible(false);
                this.setMoreMenuVisible(true);
            }

            this.hideOpenShiftRequestApprovalCallout();

            // set the actionType in state so it will be logged in instrumentation
            this.setState({ instrumentationAction: actionType });
        }
    }

    private getSlotBarProps(): SlotBarProps {
        const slotBarProps: SlotBarProps = this.props.showSlotBar ? ShiftUtils.getSlotBarProps(this.props.shift, this.props.scheduleCellRenderSize) : null;

        return slotBarProps;
    }

    /**
     * Function that will set this schedule cell's selection state in the Selection object contained within the schedule grid
     * @param isSelected
     */
    private setGridCellSelected = (isSelected: boolean) => {
        if (this.props.setGridCellSelected && this.props.dataSelectionIndexVal) {
            this.props.setGridCellSelected(this.props.dataSelectionIndexVal, isSelected);
        }
    }

    /**
     * Cells in open shift rows will use the openshifts tour marker, while other cells will use the moreIcon marker.
     * This is used by the TourGuide to find elements in the DOM to attach tour stop bubbles to.
     */
    private getStaffHubTourMarker() {
        return this.props.inOpenShiftRow ? null : StaffHubTourMarker.assignedshifts;
    }

    /**
     * Opens or closes the more menu context menu
     */
    private setMoreMenuVisible = (visible: boolean) => {
        if (visible) {
            // make sure the shift lookup menu is closed
            this.setState({ isShiftLookupCalloutVisible: false });
        }
        this.setState({ isMoreMenuCalloutVisible: visible });
    }

    /**
     * Opens or closes conflict callout menu
     */
    private setConflictCalloutVisible = (visible: boolean) => {
        if (visible) {
            // make sure the shift lookup menu is closed
            this.setState({ isShiftLookupCalloutVisible: false, isMoreMenuCalloutVisible: false });
        }
        this.setState({ isConflictCalloutVisible: visible });
    }

    // The conflict callout menu is hidden when the user 'clicks' elsewhere on the screen
    private hideConflictMenu = () => {
        this.setConflictCalloutVisible(false);
    }

    /**
     * Opens or closes the shift lookup context menu
     * @param visible True to show the callout, false to hide it
     * @param key The key that triggered the callout. Is used by the shift lookup menu to pass as the initial value for the search field.
     */
    private setShiftLookupMenuVisible = (visible: boolean, key: string) => {
        if (visible) {
            // make sure the more menu is closed
            this.setState({ isMoreMenuCalloutVisible: false });
        }
        this.setState({ isShiftLookupCalloutVisible: visible, triggerKey: key });
    }

    // The contextual menu is hidden when the user 'clicks' elsewhere on the screen
    private hideMoreMenu = () => {
        this.setMoreMenuVisible(false);
    }

    // The contextual menu is hidden when the user 'clicks' elsewhere on the screen
    private hideShiftLookupMenu = () => {
        this.setShiftLookupMenuVisible(false, null /*key*/);
    }

    /**
     * Returns true if the shift has been newly edited or added
     */
    private isShiftNewlyEdited() {
        return this.props.isEntityNewlyEdited && this.props.shift && this.props.isEntityNewlyEdited(this.props.shift.id);
    }

    /**
     * Renders the ... more icon in the cell. Positioned in the center for day cells and on the right for
     * other cells.
     */
    private renderMoreIcon() {
        const { shift, enableContextualMenu, selectionEnabled, scheduleCalendarType, hasConflicts } = this.props;

        let locationStyle;
        const isConflictingShift = hasConflicts;

        if ((scheduleCalendarType === ScheduleCalendarTypes.Day || this.doRenderEmptyCell()) && scheduleCalendarType !== ScheduleCalendarTypes.Week) {
            locationStyle = styles.center;
        } else if (scheduleCalendarType === ScheduleCalendarTypes.Month || isConflictingShift && scheduleCalendarType === ScheduleCalendarTypes.Week) {
            // When there is a conflict, the more menu will be similar to as displayed in month view
            locationStyle = styles.bottom;
        } else {
            locationStyle = styles.right;
        }

        const moreIconStyles = classNames(
             ScheduleGridCssClasses.moreIcon,
            { [`${styles.visible}`]: this.state.isMoreMenuCalloutVisible },
            locationStyle);

        return (
               // Context menu button is rendered if selection and the contextual menu are enabled (enabledContextualMenu is also used to enable time off request callouts)
                enableContextualMenu && selectionEnabled &&
                <div
                    data-automation-id={ AutomationUtils.getAutomationId("scheduler", "QAIDMoreScheduleElement") }
                    className={ classNames(moreIconStyles, { [`${styles.visible}`]: this.props.cellHasFocus }) }>
                    <IconButton
                        componentRef= { this._shiftLookupElement }
                        title={ ScheduleCell._shiftLookupContextMenuButtonTooltip }
                        ariaLabel={ ScheduleCell._shiftLookupContextMenuButtonAriaLabel }
                        onKeyPress={ this.onShiftLookupKeyPressed }
                        onClick={ this.onShiftLookupIconClicked }
                        className={ classNames(styles.iconButton, { [`${styles.visibilityHidden}`]: ShiftUtils.isTimeOffRequestEvent(shift) }) }
                        data-automation-id={ AutomationUtils.getAutomationId("scheduler", "QAIDSearchIconButton") }
                        iconProps={ { styles: { root: styles.panelCloseButtonIcon }, iconName: "Search" } } />
                    <IconButton
                        title={ ScheduleCell._contextMenuButtonTooltip }
                        ariaLabel={ ScheduleCell._contextMenuButtonAriaLabel }
                        onKeyPress={ this.onMoreIconKeyPressed }
                        className={ classNames(styles.iconButton, this.getStaffHubTourMarker()) }
                        onClick={ this.onMoreIconClicked }
                        data-automation-id={ AutomationUtils.getAutomationId("scheduler", "QAIDMoreIconButton") }
                        iconProps={ { styles: { root: styles.panelCloseButtonIcon }, iconName: "More" } } />
                </div>
        );
    }

    private shouldRenderUnsharedStatus(): boolean {
        const { shift } = this.props;
        const isTimeOffShift = ShiftUtils.isTimeOffRequestEvent(shift);

        return !isTimeOffShift && this.props.showUnsharedStatus && ShiftUtils.shiftHasUnsharedEdits(shift);
    }

    /**
     * Renders the asterisk on unshared shifts.
     */
    private renderUnsharedStatus(): JSX.Element {
        const { shift } = this.props;

        const renderUnsharedStatus = this.shouldRenderUnsharedStatus();

        return renderUnsharedStatus ? (
            <EditStatusIndicator
                themeClass={ styles[ShiftUtils.getShiftThemeClass(shift)] }
                className={ classNames(styles.asterisk, { [styles.dayView]: this.props.scheduleCellRenderSize === ScheduleCellRenderSize.Large }) }
                isNewlyEdited={ false }
                dataAutomationId={ AutomationUtils.getAutomationId("scheduler", "QAIDShiftUnsharedIndicator") } />
        ) : null;
    }

    private renderShift(): JSX.Element {
        const { shift, scheduleCellRenderSize, showUnsharedStatus, showIcons, showNotes, inOpenShiftRow, selectionEnabled, showActivities, isViewGrouped, show24HrShift, hideTitleFor24HrShift, hasConflicts, isSharedScheduleView } = this.props;
        const shiftThemeClass = ShiftUtils.getShiftThemeClass(shift);
        const isWorkingShift = ShiftUtils.isWorkingShift(shift);
        const isTimeOffShift = ShiftUtils.isTimeOffEvent(shift);
        const showConflictIcon = hasConflicts && !isSharedScheduleView && ConflictUtils.showConflictIcon(shift);

        return (
            <>
                { this.renderUnsharedStatus() }
                <ShiftThemeContainer
                    theme={ shiftThemeClass }
                    slotBarProps={ this.getSlotBarProps() }
                    nonClickable={ !selectionEnabled }
                    isTimeOff={ isTimeOffShift }>

                    <div className={ ScheduleCell._contentClasses }>
                        {
                            isWorkingShift &&
                                <Shift
                                    shift={ shift }
                                    isNewlyEdited={ this.isShiftNewlyEdited() }
                                    showUnsharedStatus={ showUnsharedStatus }
                                    scheduleCellRenderSize={ scheduleCellRenderSize }
                                    doShowIcons={ showIcons }
                                    doShowNotes={ showNotes }
                                    doShow24HrShift={ show24HrShift }
                                    showGroupName={ !isViewGrouped }
                                    hideTitleFor24HrShift={ hideTitleFor24HrShift }
                                    onConfictIconBadgeClick={ this.onConflictIconClicked }
                                    doShowConflictIcon={ showConflictIcon }
                                    crossLocationTeamId={ ShiftUtils.getCrossLocationTeamId(this.props.shift, this.props.isAdmin) } />
                        }
                        {
                            !isWorkingShift && isTimeOffShift &&
                                <TimeOffShift
                                    isNewlyEdited={ this.isShiftNewlyEdited() }
                                    shift={ shift }
                                    scheduleCellRenderSize={ scheduleCellRenderSize }
                                    onConfictIconBadgeClick={ this.onConflictIconClicked }
                                    doShowConflictIcon={ showConflictIcon } />
                        }
                        {
                            !isWorkingShift && !isTimeOffShift &&
                                <TimeOffRequestShift
                                    shift={ shift }
                                    scheduleCellRenderSize={ scheduleCellRenderSize } />
                        }
                        { this.shouldRenderExtras() && this.renderMoreIcon() }
                        {
                            showActivities &&
                                <ActivityFlexContainer
                                    shift={ shift }
                                    hideActivityCodes={ inOpenShiftRow }
                                    selectionEnabled={ selectionEnabled }
                                    viewStart={ this.props.viewStart }
                                    viewEnd={ this.props.viewEnd }
                                    setFlexItemSelected={ this.props.setFlexItemSelected }
                                    getFlexItemSelected={ this.props.getFlexItemSelected }
                                    setGridCellSelected={ this.setGridCellSelected }/>
                        }
                    </div>
                </ShiftThemeContainer>
            </>
            );
    }

    /**
     * Renders any active callout that should open on the cell
     */
    private renderCallout() {
        const { shift, navigateToRequestPage, instrumentScheduleEvent, isAdmin, scheduleCalendarType } = this.props;
        const groupedRequest: IGroupedOpenShiftRequestEntity = this.getGroupedShiftRequestForThisCell();
        const shiftRequestId: string = ShiftRequestUtils.getFirstShiftRequestIdFromGroupedRequest(groupedRequest);

        let conflictEntities: IBaseConflictEntity[] = [];
        // make sure you handle empty cell

        const currentUser = TeamStore() && TeamStore().me;
        if (ConflictUtils.isConflictEnabledForAdminInDateRange(MemberUtils.isAdmin(currentUser), schedulesViewStateStore().viewEndDate) && shift) {
            const shiftIdToConflictsMap = schedulesViewStateStore().conflictsInView;
            conflictEntities = shiftIdToConflictsMap && shiftIdToConflictsMap.get(shift.id);
        }

        return (
            <>
                { this.renderContextualMenu(this.getCellStartOfDay(), shift) }
                {
                    this.shouldShowTimeOffRequestApprovalCallout(shift) &&
                        <TimeOffRequestApprovalCallout
                            navigateToRequestPage={ navigateToRequestPage }
                            targetElement={ this._cellElement.current }
                            onDismissCallback={ this.hideMoreMenu }
                            shift={ shift }
                            instrumentScheduleEvent= { this.props.instrumentScheduleEvent }/>
                }
                {
                    this.shouldShowConflictDetailsCallout(shift) &&
                        <ConflictDetailsCallout
                            targetElement={ this._cellElement.current }
                            onDismissCallback={ this.hideConflictMenu }
                            shiftId={ shift.id }
                            conflictEntities={ conflictEntities }
                            scheduleCalendarType={ scheduleCalendarType }
                            elementToFocusOnDismiss={ this._shiftLookupElement } />
                }
                {
                    this.shouldShowShiftDetailsCallout(shift) &&
                        <ShiftDetailsCallout
                            onDismissCallback={ this.hideMoreMenu }
                            targetElement={ this._cellElement.current }
                            setInitialFocus={ true }
                            className={ AutomationUtils.getAutomationId("scheduler", "QAIDShiftDetailsCallout") }
                            shiftDetailsProps={ {
                                shift: shift,
                                requestId: shiftRequestId,
                                navigateToRequestPage: navigateToRequestPage,
                                instrumentScheduleEvent: instrumentScheduleEvent,
                                isAdmin: isAdmin,
                                scheduleCalendarType: scheduleCalendarType
                            } }/>
                }
                {
                    this.shouldShowOpenShiftRequestCallout() &&
                        <OpenShiftRequestApprovalCallout
                            navigateToRequestPage={ navigateToRequestPage }
                            groupedRequest={ groupedRequest }
                            onDismissCallback={ this.hideOpenShiftRequestApprovalCallout }
                            targetElement={ this._cellElement.current }
                            />
                }
            </>
        );
    }

    /**
     * Returns a moment to be used as the start of the day for the current cell. If we receive
     * a shift via props, we use the start of the day for that shift, otherwise we use the
     * startOfDay prop.
     */
    private getCellStartOfDay(): Moment {
        const { startOfDay, shift } = this.props;
        return shift ? shift.startTime.clone().startOf("date") : startOfDay;
    }

    /**
     * Renders an empty cell
     */
    private renderEmptyCell() {
        const { scheduleCalendarType, shift } = this.props;

        return (
            <>
                {
                    this.props.availability &&
                        <EmptyCellAvailability
                            availability={ this.props.availability }
                            scheduleCalendarType={ scheduleCalendarType }/>
                }
                { /** Deleted shifts with active shared changes will be rendered as empty cells with an edit status indicator */
                    ShiftUtils.isUnsharedDeletedShift(shift) &&
                        <EditStatusIndicator
                            className={ styles.deletedShiftWithSharedChangesIndicator }
                            isNewlyEdited={ this.isShiftNewlyEdited() }
                            ariaLabel={ ShiftUtils.getUnsharedChangesShiftIndicatorAriaLabel() }
                            dataAutomationId={ AutomationUtils.getAutomationId("scheduler", "QAIDDeletedShiftUnsharedIndicator") } />
                }
                {
                   this.shouldRenderExtras() && this.renderMoreIcon()
                }
            </>
        );
    }

    /**
     * Renders the contextual menu, if a date has been provided and the contextual menu should be shown
     */
    private renderContextualMenu(date: Moment, shift: IShiftEntity) {
        const { scheduleCalendarType, inOpenShiftRow, member, tagId, scheduleCellActionCallbacks, disableShare } = this.props;
        // Use either the tagId specified by props (grouped view), the tagId from the shift (ungrouped view), or an empty string
        const tagIdForMenu = tagId || (shift && ShiftUtils.getTagIdFromShift(shift)) || "";
        const shouldShowShiftContextMenu = this.shouldShowShiftContextMenu(shift);
        return (
            shouldShowShiftContextMenu && date &&
                <ShiftContextualMenu
                    isShiftLookup={ this.state.isShiftLookupCalloutVisible }
                    triggerKey={ this.state.triggerKey }
                    member={ member }
                    groupTagId ={ tagIdForMenu }
                    shift={ shift }
                    isOpenShift={ inOpenShiftRow }
                    disableShare={ disableShare }
                    date={ date }
                    target={ this._cellElement.current }
                    onDismissCallback={ this.state.isShiftLookupCalloutVisible ? this.hideShiftLookupMenu : this.hideMoreMenu }
                    scheduleCellActionCallbacks={ scheduleCellActionCallbacks }
                    scheduleCalendarType={ scheduleCalendarType } />
        );
    }

    shouldComponentUpdate(nextProps: IBaseScheduleCellProps, nextState: ScheduleCellState): boolean {
        // optimzation for empty cells
        if ((!this.props.shift && !nextProps.shift)                                         // no shift change
            && this.props.scheduleCalendarType === nextProps.scheduleCalendarType           // no calendar type change
            && this.state.isMoreMenuCalloutVisible === nextState.isMoreMenuCalloutVisible   // context menu not visible
            && this.state.isOpenShiftRequestCalloutVisible === nextState.isOpenShiftRequestCalloutVisible   // context menu not visible
            && this.state.isShiftLookupCalloutVisible === nextState.isShiftLookupCalloutVisible   // context menu not visible
            && this.props.cellHasFocus === nextProps.cellHasFocus                           // no focus change
            && this.state.isConflictCalloutVisible === nextState.isConflictCalloutVisible   // conflict callout not visible
            && this.state.renderExtras === nextState.renderExtras
            && AvailabilityUtils.areEqual(this.props.availability, nextProps.availability)  // no change to availabilities
            ) {
                return false;
            }

        return true;
    }

    /**
     * This function will render an indicator component to display the presence of open shift requests
     */
    private renderShiftRequestsIndicator(): JSX.Element {
        const { scheduleCellRenderSize, shift } = this.props;

        const groupedShiftRequest: IGroupedOpenShiftRequestEntity = this.getGroupedShiftRequestForThisCell();

        // only render the requests indicator is the open shift is published
        const isPublished = shift && shift.isPublished;
        return isPublished
            ?
                <ShiftRequestsIndicator
                    groupedShiftRequest={ groupedShiftRequest }
                    onIndicatorClick={ this.onShiftRequestIndicatorClick }
                    cellSize={ scheduleCellRenderSize }/>
            :
                null;
    }

    /**
     * Helper function to get the associated with this cell
     */
    private getGroupedShiftRequestForThisCell(): IGroupedOpenShiftRequestEntity {
        const { getGroupedShiftRequestForShiftId, shift } = this.props;
        return getGroupedShiftRequestForShiftId && shift && getGroupedShiftRequestForShiftId(shift.id, ShiftRequestStates.WaitingOnManager);
    }

    /**
     * Callback fired upon click of the shift request indicator. Opens the open shift request approval callout.
     * @param isClick
     */
    private onShiftRequestIndicatorClick = (isClick: boolean) => {
        // trigger request conflict calculations before opening the callout
        const groupedRequest: IGroupedOpenShiftRequestEntity = this.getGroupedShiftRequestForThisCell();
        calculateRequestConflicts(groupedRequest, true /* isOpenShiftRequest */);
        this.showOpenShiftRequestApprovalCallout();
        if (this.props.instrumentScheduleEvent) {
            this.props.instrumentScheduleEvent(InstrumentationService.events.Requests, [
                getGenericEventPropertiesObject(InstrumentationService.properties.EventType, InstrumentationService.values.RequestsEntryPointClicked),
                getGenericEventPropertiesObject(InstrumentationService.properties.Location, InstrumentationService.values.Flyout),
                getGenericEventPropertiesObject(InstrumentationService.properties.RequestType, InstrumentationService.ShiftRequestTypes.AdminOpenShift),
                getGenericEventPropertiesObject(InstrumentationService.properties.EntryPoint, isClick ? InstrumentationService.actionTypes.Mouse : InstrumentationService.actionTypes.EnterKey)
            ]);
        }
    }

    /**
     * Sets the visibility of the open shift request approval callout
     * @param isVisible
     */
    private setIsOpenShiftRequestCalloutVisible(isVisible: boolean) {
        this.setState({ isOpenShiftRequestCalloutVisible: isVisible });
    }

    /**
     * Displays the open shift request approval callout. Hides the other callouts.
     */
    private showOpenShiftRequestApprovalCallout() {
        this.setIsOpenShiftRequestCalloutVisible(true);
        this.hideMoreMenu();
    }

    /**
     * Hides the open shift request approval callout.
     */
    private hideOpenShiftRequestApprovalCallout = () => {
        this.setIsOpenShiftRequestCalloutVisible(false);
    }

    /**
     * Returns true if this component should render an emty cell, as opposed to a regular schedule cell with
     * a normal shift object inside. This is the case if we don't have a shift to render, or if the shift
     * is a deleted shift with active shared changes
     */
    private doRenderEmptyCell(): boolean {
        return !this.props.shift || ShiftUtils.isUnsharedDeletedShift(this.props.shift);
    }

    private isAnyCalloutOpen = (): boolean => {
        return this.state.isMoreMenuCalloutVisible
            || this.state.isOpenShiftRequestCalloutVisible
            || this.state.isShiftLookupCalloutVisible
            || this.state.isConflictCalloutVisible;
    }

    /**
     * Callback fire onMouseEnter event. Displays the tooltip
     */
     private onMouseEnter = () => {
        this.setState({ renderExtras: true });
    }

    /**
     * Callback fire onMouseExit event. Hides the tooltip
     */
    private onMouseLeave = () => {
        if (!this.isAnyCalloutOpen()) {
            this.setState({ renderExtras: false });
        }
    }

    render() {
        const { inOpenShiftRow, shift, showShiftRequestsIndicator, scheduleCalendarType } = this.props;

        if (!ScheduleCell._emptyCellContainerClasses) {
            // _emptyCellContainerClasses is cleared in the ctor when the calendar type changes
            ScheduleCell._emptyCellContainerClasses = classNames(SCHEDULE_CELL_BASE_CSSCLASS,
                styles.emptyContent,
                {[styles.hideBorder]: scheduleCalendarType === ScheduleCalendarTypes.Day});
        }
        if (!ScheduleCell._emptyCellOpenShiftContainerClasses) {
            // _emptyCellContainerClasses is cleared in the ctor when the calendar type changes
            ScheduleCell._emptyCellOpenShiftContainerClasses = classNames(SCHEDULE_CELL_BASE_CSSCLASS,
                styles.emptyContent,
                styles.openCell,
                {[styles.hideBorder]: scheduleCalendarType === ScheduleCalendarTypes.Day});
        }

        const renderEmptyCell = this.doRenderEmptyCell();

        return (
            <div className={ renderEmptyCell ? (inOpenShiftRow ? ScheduleCell._emptyCellOpenShiftContainerClasses : ScheduleCell._emptyCellContainerClasses) : ScheduleCell._containerClasses }
                onContextMenu={ this.onRightClick }
                onClick={ this.onLeftClick }
                onMouseEnter={ this.onMouseEnter }
                onMouseLeave={ this.onMouseLeave }
                ref={ this._cellElement }>
                {
                    !renderEmptyCell &&
                        <PrintComponent>
                            <PrintBackground shift={ shift } />
                        </PrintComponent>
                }
                { renderEmptyCell ? this.renderEmptyCell() : this.renderShift() }
                {
                    shift && showShiftRequestsIndicator &&
                        this.renderShiftRequestsIndicator()
                }
                { this.renderCallout() }
            </div>
        );
    }
}

export default ScheduleCell;