import { UserProfile } from "@microsoft/shifts-models-data";
import { action } from "satcheljs";

const userProfilesUpdated = action(
    "userProfilesUpdated",
    (
        userProfiles: UserProfile[]
    ) => ({
        userProfiles: userProfiles
    })
);

export default userProfilesUpdated;