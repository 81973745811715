type MemberActiveStateType = "Active";
type MemberInvitedStateType = "Invited";
type MemberDeletedStateType = "Deleted";
type MemberAutoDeletedStateType = "AutoDeleted";
type MemberInviteRejectedStateType = "InviteRejected";

export type MemberStatesType = MemberActiveStateType | MemberInvitedStateType | MemberDeletedStateType | MemberAutoDeletedStateType | MemberInviteRejectedStateType;

const MemberStates = {
    Active: "Active" as MemberActiveStateType,                   // Member is part of the team and has already accepted their invite
    Invited: "Invited" as MemberInvitedStateType,                 // Invited but has not yet accepted their invite
    Deleted: "Deleted" as MemberDeletedStateType,                 // When user deleted from the team and the tenant
    AutoDeleted: "AutoDeleted" as MemberAutoDeletedStateType,         // When user is not in the team but in the tenant
    InviteRejected: "InviteRejected" as MemberInviteRejectedStateType    // Currently unused by Service
};

export default MemberStates;