import * as H from "history";
import * as React from "react";
import setGlobalMessages from "sh-application/actions/setGlobalMessages";
import StringsStore from "sh-strings/store";
import TeamPickerPanel from "./stepComponents/TeamPickerPanel";
import TeamSetupMSTeamPicker from "./stepComponents/TeamSetupMSTeamPicker";
import teamSetupPickerStore, { setErrorMessage, setShowStepIndicator, setTeamSetupPickerSequence } from "./store/store";
import TeamSetupScheduleTeamPicker from "./stepComponents/TeamSetupScheduleTeamPicker";
import TeamSetupStartTour from "./stepComponents/TeamSetupStartTour";
import TeamSetupTimezonePicker from "./stepComponents/TeamSetupTimezonePicker";
import { appViewState, launchTeamSetupPicker } from "sh-application/store";
import { MessageBar, MessageBarType, Modal } from "@microsoft/shifts-ui-core";
import { observer } from "mobx-react";
import { TeamSetupPickerStepProps } from "./TeamSetupPickerStepProps";
import { TeamSetupPickerStepState } from "./store/schema/TeamSetupPickerStore";
import { TeamSetupPickerViewState } from "sh-application/store/schema/TeamSetupPickerViewState";
import { IButton } from "@fluentui/react";
import { SET_INITIAL_FOCUS_DELAY_MS } from "../../utility/UiUtils";

const styles = require("./TeamSetupPicker.scss");

/**
 * The items needed to render a step in the workflow
 */
interface StepComponent {
    component: React.ComponentFactory<TeamSetupPickerStepProps, React.Component<TeamSetupPickerStepProps, {}>>; // The component to render the step
}

/**
 * TeamSetupPicker mode
 * Used for designating what setup step sequence to use
 */
export enum TeamSetupPickerMode {
    SwitchTeams = "SwitchTeams",        // Team switcher, which also has an entrypoint to provision a new team
    ProvisionTeam = "ProvisionTeam",    // Provision new team
    NewTeamWelcome = "NewTeamWelcome"   // Show Welcome UX before the Tour begins
}

export interface TeamSetupPickerProps {
    teamSetupPickerViewState: TeamSetupPickerViewState;

    history: H.History;

    /* Callback for closing the UX */
    onClose: () => void;
}

/**
 * Team onboarding setup + team picker UX
 * For use with the first run experience and also team switching UX
 */
@observer
export default class TeamSetupPicker extends React.Component<TeamSetupPickerProps, {}> {
    private _stepComponents: Map<TeamSetupPickerStepState, StepComponent>; // Maintains the map of the Step and the details needed to render the step
    private _isOpenLastKnownState: boolean = false; // Last known state for the isOpen property. Used to determine if the UX has just been triggered to launch.

    /**
     * {@link _newScheduleBtnRef} passed to new schedule button created in {@link TeamPickerPanel.onRenderFooterContent} which will allow set focus on
     * new schedule button
     * {@link _focusOnNewScheduleBtn} if true focus will be assigned on new schedule button
     *
     * when team picker UX rendered first time {@link _focusOnNewScheduleBtn} will be false hence focus
     * will not be assigned on new scheule button.
     *
     * When new schedule creation modal window will be canceled {@link handleCancel}
     * it will set {@link _focusOnNewScheduleBtn}=true and when react will finish rendering it will call
     * {@link componentDidUpdate} on which focus will be assigned on `new schedule` button and {@link _focusOnNewScheduleBtn} will be set false
     */
    private _newScheduleBtnRef = React.createRef<IButton>(); /** refers to new schedule button in  **/
    private _focusOnNewScheduleBtn = false; // if true focus will be assigned on new schedule button

    private _newTeamSetupSequenceBase: TeamSetupPickerStepState[] = [
        TeamSetupPickerStepState.MSTeamPicker,
        TeamSetupPickerStepState.TimeZonePicker
    ];

    /**
     * Check if the TeamSetupPicker has just been triggered to be opened, and
     * initialize the UX for launch if so.
     */
    private initializeTeamSetupPickerIfOpened() {
        const isOpenLastKnownState: boolean = this._isOpenLastKnownState;
        const isOpenNewState: boolean = this.isOpen();

        // Update the last known state with the new state
        this._isOpenLastKnownState = isOpenNewState;

        // If we've switched from closed to open, perform the launch handling
        if (isOpenNewState && !isOpenLastKnownState) {
            this.handleLaunchTeamSetupPicker();
        }
    }

    /**
     * Initialize the UX for when it is launched
     */
    private handleLaunchTeamSetupPicker() {
        this.initializeSequence(this.teamSetupPickerMode());
        this.initializeStepComponents();
    }

    /**
     * Returns the setup step sequence for creating a new team
     */
    private getNewTeamSetupSequence(): TeamSetupPickerStepState[] {
        // Copy the base setup sequence
        let sequence: TeamSetupPickerStepState[] = this._newTeamSetupSequenceBase.slice(0);
        return sequence;
    }

    /**
     * Initialize the setup step sequence using the designated mode
     */
    private initializeSequence(teamSetupPickerMode: TeamSetupPickerMode) {
        let stepsSequence: TeamSetupPickerStepState[];
        let showStepIndicator = true;

        switch (teamSetupPickerMode) {
            case TeamSetupPickerMode.ProvisionTeam: {
                stepsSequence = this.getNewTeamSetupSequence();
                showStepIndicator = false;  // we don't show it by default until we know there are teams to provision
                break;
            }
            case TeamSetupPickerMode.NewTeamWelcome: {
                stepsSequence = [TeamSetupPickerStepState.StartTour];
                showStepIndicator = false;
                break;
            }
            case TeamSetupPickerMode.SwitchTeams:
            default: {
                stepsSequence = [TeamSetupPickerStepState.ScheduleTeamPicker];
                break;
            }
        }

        setTeamSetupPickerSequence(stepsSequence);
        setShowStepIndicator(showStepIndicator);
    }

    /**
     * Initialize setup step components
     */
    private initializeStepComponents() {
        // Initialize the map for all Steps
        this._stepComponents = new Map<TeamSetupPickerStepState, StepComponent>();
        // IE bug: set values in map separately. Initializing in Map constructor doesn't work
        this._stepComponents.set(TeamSetupPickerStepState.ScheduleTeamPicker, { component: React.createFactory(TeamSetupScheduleTeamPicker) });
        this._stepComponents.set(TeamSetupPickerStepState.MSTeamPicker, { component: React.createFactory(TeamSetupMSTeamPicker) });
        this._stepComponents.set(TeamSetupPickerStepState.TimeZonePicker, { component: React.createFactory(TeamSetupTimezonePicker) });
        this._stepComponents.set(TeamSetupPickerStepState.StartTour, { component: React.createFactory(TeamSetupStartTour) });
    }

    /**
     * Returns the state enum for the current setup step
     */
    private getCurrentStep(): TeamSetupPickerStepState {
        if (teamSetupPickerStore.setupSequence
            && teamSetupPickerStore.currentSetupStepIndex >= 0
            && teamSetupPickerStore.currentSetupStepIndex < teamSetupPickerStore.setupSequence.length ) {
                return teamSetupPickerStore.setupSequence[teamSetupPickerStore.currentSetupStepIndex];
        } else {
            return TeamSetupPickerStepState.Invalid;
        }
    }

    /**
     * Handles closing the modal
     */
    private closeModal = () => {
        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    private closeAndOpenTeamSelectionPanel = () => {
        if (this.teamSetupPickerMode() === TeamSetupPickerMode.ProvisionTeam) {
            // If the UX is in provision team mode, just close out the modal if setup is canceled.
            launchTeamSetupPicker(
                appViewState().teamSetupPickerViewState,
                false /* isOnboarding */,
                TeamSetupPickerMode.SwitchTeams,
                true /* isUserDismissable */,
                true /* useTeamPickerPanel */);
            this._focusOnNewScheduleBtn = true;
        } else {
            this.closeModal();
        }
    }

    /**
     * Handler for when setup is cancelled
     */
    private handleCancel = () => {
        if (this.teamSetupPickerMode() === TeamSetupPickerMode.ProvisionTeam) {
            this.closeAndOpenTeamSelectionPanel();
        } else {
            // Otherwise we're in Schedule Team picker mode, where we can either be choosing a schedule team
            // to switch to, or creating a new team.
            if ((teamSetupPickerStore.currentSetupStepIndex === 0) &&
                (teamSetupPickerStore.setupSequence[0] === TeamSetupPickerStepState.ScheduleTeamPicker)) {
                // If we're on the initial schedule team picker step, cancel should close the modal
                this.closeModal();
            } else {
                // If we're in the provision team flow, cancel should bring the user back to the schedule team picker step
                this.initializeSequence(TeamSetupPickerMode.SwitchTeams);
            }
        }
    }

    /**
     * Handler for closing the setup UX
     */
    private handleClose = () => {
        this.closeAndOpenTeamSelectionPanel();
    }

    /**
     * Handler for when the user chooses to create a new team
     * This can happen while we're in SwitchTeams mode, where the user is given the option to either switch teams or create a new team.
     */
    private handleGotoProvisionTeamFlow = () => {
        const isFromTeamPickerPanel = this.props.teamSetupPickerViewState && this.props.teamSetupPickerViewState.isTeamPickerPanel;

        if (isFromTeamPickerPanel) {
            // if the user clicked the new schedule button in the switch team panel, we need to launch
            // the team setup picker in the provision team mode. We wrap it in a setTimeout to ensure
            // the team picker panel has time to close.
            setTimeout(() => {
                launchTeamSetupPicker(
                    appViewState().teamSetupPickerViewState,
                    false /* isOnboarding */,
                    TeamSetupPickerMode.ProvisionTeam,
                    true /* isUserDismissable */,
                    false /* userTeamPickerPanel */
                );
            }, 0);
        } else {
            this.initializeSequence(TeamSetupPickerMode.ProvisionTeam);
        }
    }

    componentDidUpdate() {
        setTimeout(() => {
            if (this._newScheduleBtnRef?.current && this._focusOnNewScheduleBtn) {
                /**
                 * set the focus to new schedule button created in {@link TeamPickerPanel.onRenderFooterContent}
                 * if new schedule modal window was closed
                 * set {@link _focusOnNewScheduleBtn} to false to not move focus on new schedule button on reopen
                 */
                this._newScheduleBtnRef.current.focus();
            }
            this._focusOnNewScheduleBtn = false;
        }, SET_INITIAL_FOCUS_DELAY_MS);
    }

    /**
     * Dismiss error message handler
     */
    private handleDismissErrorMessage = () => {
        // Shows the modal error and global messages. So clear both
        setErrorMessage(null);
        setGlobalMessages(appViewState().globalMessageViewState);
    }

    private isOpen(): boolean {
        return this.props.teamSetupPickerViewState && this.props.teamSetupPickerViewState.isOpen;
    }

    private teamSetupPickerMode(): TeamSetupPickerMode {
        return this.props.teamSetupPickerViewState ? this.props.teamSetupPickerViewState.teamSetupPickerMode : TeamSetupPickerMode.SwitchTeams;
    }

    private isUserDismissable(): boolean {
        return this.props.teamSetupPickerViewState && this.props.teamSetupPickerViewState.isUserDismissable;
    }

    private isOnboarding(): boolean {
        return this.props.teamSetupPickerViewState && this.props.teamSetupPickerViewState.isOnboarding;
    }

    /**
     * Renders the error messages
     * Shows the modal error and global messages
     */
    private renderMessages(): JSX.Element[] {
        let index = 0;
        let messages: JSX.Element[] = [];
        if (teamSetupPickerStore.errorMessage) {
            messages.push(
                <span key={ index++ }>
                    { teamSetupPickerStore.errorMessage }
                </span>
            );
        }
        const globalMessages: string[] = (appViewState().globalMessageViewState && appViewState().globalMessageViewState.messages);
        if (globalMessages) {
            messages = messages.concat(globalMessages.map((msg) => {
                return (
                    <span key={ index++ }>
                        { msg }
                    </span>
                );
            }));
        }
        return messages.length > 0 ? messages : null;
    }

    /**
     * Main render function of the Team Setup Page.
     */
    render() {
        this.initializeTeamSetupPickerIfOpened();

        const currentSetupStep: TeamSetupPickerStepState = this.getCurrentStep();
        if (!this._stepComponents || !this._stepComponents.has(currentSetupStep)) {
            return null;
        }

        const isOnboarding: boolean = this.isOnboarding();
        const isUserDismissable: boolean = this.isUserDismissable();
        const component: StepComponent = this._stepComponents.get(currentSetupStep);
        const isProvisionTeamOnlyMode: boolean = (this.teamSetupPickerMode() === TeamSetupPickerMode.ProvisionTeam);
        // Don't allow the user to cancel out of the setup UX if either a) we're in modal mode or b) team creation/provisioning is in progress
        const isBlocking: boolean = !isUserDismissable || teamSetupPickerStore.isProvisionTeamInProgress;
        const showCreateNewScheduleTeamEntrypoints: boolean = this.props.teamSetupPickerViewState && this.props.teamSetupPickerViewState.showCreateNewScheduleTeamEntrypoints;
        const useTeamPickerPanel = this.props.teamSetupPickerViewState && this.props.teamSetupPickerViewState.isTeamPickerPanel;
        const errorMessageInModal: boolean = (!!teamSetupPickerStore.errorMessage || (appViewState().globalMessageViewState && appViewState().globalMessageViewState.messages && appViewState().globalMessageViewState.messages.length > 0));

        // For the TeamPickerPanel as a panel mode, we disable autofocus to allow the default focus behavior where the close button gets
        // initial focus
        return (
            useTeamPickerPanel
            ?
            <TeamPickerPanel
                isOpen={ this.isOpen() }
                newScheduleBtnRef={ this._newScheduleBtnRef}
                isOnboarding={ isOnboarding }
                isSetupUserDismissable={ isUserDismissable }
                isProvisionTeamOnlyMode={ isProvisionTeamOnlyMode }
                showCreateNewScheduleTeamEntrypoints={ showCreateNewScheduleTeamEntrypoints }
                onClose={ this.handleClose }
                onCancel={ this.handleCancel }
                onGotoProvisionTeamFlow={ this.handleGotoProvisionTeamFlow }
                history={ this.props.history }
                disableAutofocus={ true } />
            :
            <Modal
                className={ styles.teamPickerModal }
                onDismiss={ !isBlocking ? this.closeAndOpenTeamSelectionPanel : null }
                isOpen={ this.isOpen() } >
                <div className={ styles.main }>
                    {
                        errorMessageInModal &&
                            <MessageBar
                                className={ styles.messageBar }
                                isMultiline={ false }
                                messageBarType={ MessageBarType.error }
                                onDismiss={ this.handleDismissErrorMessage }
                                dismissButtonAriaLabel={ StringsStore().registeredStringModules.get("common").strings.get("closeMessageAriaLabel") }
                                 >
                                { this.renderMessages() }
                            </MessageBar>
                    }
                    {
                        component.component({
                            isOnboarding: isOnboarding,
                            isSetupUserDismissable: isUserDismissable,
                            isProvisionTeamOnlyMode: isProvisionTeamOnlyMode,
                            showCreateNewScheduleTeamEntrypoints: showCreateNewScheduleTeamEntrypoints,
                            onClose: this.handleClose,
                            onCancel: this.handleCancel,
                            onGotoProvisionTeamFlow: this.handleGotoProvisionTeamFlow,
                            history: this.props.history } )
                    }
                </div>
            </Modal>
        );
    }
}