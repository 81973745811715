import * as moment from "moment";
import CurrentTeamDataProvider from "sh-services/dataproviders/CurrentTeamDataProvider";
import RestClient from "sh-rest-client";
import setGlobalMessages from "sh-application/actions/setGlobalMessages";
import StringsStore from "sh-strings/store";
import { appViewState } from "sh-application/store";
import { ECSConfigKey, ECSConfigService, DataFilter, InstrumentationService } from "sh-services";
import { getGenericEventPropertiesObject, InstrumentationEventPropertyInterface } from "sh-instrumentation";
import { IFilteredDataProvider } from "./IFilteredDataProvider";
import { GetDataInDateRangeOptions } from "sh-rest-client/GetDataInDateRangeOptions";
import { INoteDatabase } from "../data/INoteDatabase";
import { IOpenShiftDatabase } from "../data/IOpenShiftDatabase";
import { IShiftDatabase } from "../data/IShiftDatabase";
import { MessageBarType } from "@fluentui/react";
import { Moment } from "moment";
import { NotesInDateRangeDataProvider } from "./NotesInDateRangeDataProvider";
import { OpenShiftsInDateRangeDataProvider } from "./OpenShiftsInDateRangeDataProvider";
import { ShiftsInDateRangeDataProvider } from "./ShiftsInDateRangeDataProvider";
import { ShiftStoreTypes } from "sh-application/../StaffHubConstants";
import { runInAction } from "mobx";
import {
    IBaseShiftEntity,
    IDataInDateRange,
    IDataInDateRangePage,
    IDataInDateRangeWithSyncState,
    IDataWithSyncState,
    IDateRange,
    IItemsInTimeRangeSyncState,
    INoteEntity,
    IOpenShiftEntity,
    IShiftEntity
} from "sh-models";
import { setMemberIdForCrossLocationShift, setTagIdForCrossLocationShift } from "../../sh-application/utility/ShiftCLSUtils";

export const DEFAULT_MAX_PAGES = 50;

/**
 * Shifts/Note/OpenShifts in DateRange Data Provider for the current team
 */
export class DataInDateRangeDataProvider
    extends CurrentTeamDataProvider<IDataInDateRangeWithSyncState>
    implements IFilteredDataProvider<IDataInDateRangeWithSyncState>{
    private tenantId: string;
    protected fetchStartTime: Moment;
    protected fetchEndTime: Moment;
    protected shiftsDateRangeDataProvider: ShiftsInDateRangeDataProvider;
    protected notesDateRangeDataProvider: NotesInDateRangeDataProvider;
    protected openShiftsInDateRangeDataProvider: OpenShiftsInDateRangeDataProvider;
    protected shiftStoreType: ShiftStoreTypes;

    constructor(
        shiftDatabase: IShiftDatabase,
        noteDatabase: INoteDatabase,
        openShiftDatabase: IOpenShiftDatabase,
        tenantId: string,
        teamId: string,
        sessionId: string,
        fetchStartTime: Moment,
        fetchEndTime: Moment,
        dontClearCache: boolean,
        shiftStoreType: ShiftStoreTypes
    ) {
        super(teamId);
        this.tenantId = tenantId;
        this.fetchStartTime = fetchStartTime;
        this.fetchEndTime = fetchEndTime;

        this.shiftsDateRangeDataProvider = new ShiftsInDateRangeDataProvider(
            shiftDatabase,
            tenantId,
            teamId,
            sessionId,
            fetchStartTime,
            fetchEndTime,
            dontClearCache,
            shiftStoreType
        );

        this.notesDateRangeDataProvider = new NotesInDateRangeDataProvider(
            noteDatabase,
            tenantId,
            teamId,
            sessionId,
            fetchStartTime,
            fetchEndTime,
            dontClearCache
        );

        this.openShiftsInDateRangeDataProvider = new OpenShiftsInDateRangeDataProvider(
            openShiftDatabase,
            tenantId,
            teamId,
            sessionId,
            fetchStartTime,
            fetchEndTime,
            dontClearCache
        );

        this.shiftStoreType = shiftStoreType;
    }

    /**
     * Return data if it's found in memory (otherwise return undefined)
     */
    async getDataFromMemoryForCurrentTeam(dataFilter?: DataFilter, isInSync?: boolean) {
        const shiftsWithSyncState: IDataWithSyncState<IShiftEntity[]> =
            await this.shiftsDateRangeDataProvider.getDataFromMemoryForCurrentTeam(dataFilter, isInSync);
        const notesWithSyncState: IDataWithSyncState<INoteEntity[]> = await this.notesDateRangeDataProvider.getDataFromMemoryForCurrentTeam(
            dataFilter,
            isInSync
        );
        const openShiftsWithSyncState: IDataWithSyncState<IOpenShiftEntity[]> =
            await this.openShiftsInDateRangeDataProvider.getDataFromMemoryForCurrentTeam(dataFilter, isInSync);
        if (shiftsWithSyncState && notesWithSyncState && openShiftsWithSyncState) {
            // We only return data if ALL the items are found, because according to the contract the dataservice
            // will only fetch data from the network if the object returned from the memory is undefined
            return { shiftsWithSyncState, notesWithSyncState, openShiftsWithSyncState };
        } else {
            return undefined;
        }
    }

    /**
     * Return data if it's found in the database (otherwise return undefined)
     */
    async getDataFromDatabase() {
        const shiftsWithSyncState: IDataWithSyncState<IShiftEntity[]> = await this.shiftsDateRangeDataProvider.getDataFromDatabase();
        const notesWithSyncState: IDataWithSyncState<INoteEntity[]> = await this.notesDateRangeDataProvider.getDataFromDatabase();
        const openShiftsWithSyncState: IDataWithSyncState<IOpenShiftEntity[]> =
            await this.openShiftsInDateRangeDataProvider.getDataFromDatabase();

        if (shiftsWithSyncState && notesWithSyncState && openShiftsWithSyncState) {
            // return the data if we find ANY items. This will let the UI render this items quickly
            // while the dataservice will asynchronously refresh the data from the network
            return { shiftsWithSyncState, notesWithSyncState, openShiftsWithSyncState };
        } else {
            // Return undefined, which will force an immediate fetch from the network
            return undefined;
        }
    }

    private dataFilterToNetworkOptions(dataFilter: DataFilter): GetDataInDateRangeOptions {
        if (!dataFilter) {
            return null;
        }

        return {
            filters: {
                tagIds: dataFilter.tagIds,
                memberIds: dataFilter.memberIds
            },
            networkFetchOptions: {
                includeNotes: dataFilter.networkFetchOptions && dataFilter.networkFetchOptions.includeNotes,
                includeOpenShifts: dataFilter.networkFetchOptions && dataFilter.networkFetchOptions.includeOpenShifts,
                includeUngroupedShifts: dataFilter.networkFetchOptions && dataFilter.networkFetchOptions.includeUngroupedShifts,
                includeTimeOffs: dataFilter.networkFetchOptions && dataFilter.networkFetchOptions.includeTimeOffs
            }
        };
    }

    /**
     * Make an HTTP request to fetch the data from the network
     * @param {DataFilter} dataFilter (optional) filter for fetched data. Only data within the passed filter is fetched.
     * @param {boolean} isInSync (optional if not filtering by tagId) When filtering by tag, pass true if this is the final tag and all other tags have been fetched.
     */
    async getDataFromNetwork(dataFilter?: DataFilter, isInSync?: boolean): Promise<IDataInDateRangeWithSyncState> {
        const shouldUseMyShiftsApi =
            this.shiftStoreType === ShiftStoreTypes.MyShiftStore && ECSConfigService.isECSFeatureEnabled(ECSConfigKey.EnableMyShiftsApi);

        // Instrumentation items
        let numOfPages: number = 0;
        let numOfItems: number = 0;
        let numOfItemsByPage: string = ""; // eg "500;150;425;"
        let gotError: boolean = false;
        let hasMorePagesBeyondLimit: boolean = false;

        const maxPages: number = ECSConfigService.getECSFeatureSetting(ECSConfigKey.GDIDRMaxPages) || DEFAULT_MAX_PAGES;

        let marker = "getDataInDateRangePaginated";
        marker = InstrumentationService.perfMarkerStart(marker);

        try {
            let dataInDateRange: IDataInDateRangePage = null;
            if (shouldUseMyShiftsApi) {
                dataInDateRange = await RestClient.getDataInDateRangePaginatedForMyShifts(
                    this.teamId,
                    this.fetchStartTime,
                    this.fetchEndTime
                );

                const crossLocationShifts = dataInDateRange.shifts.filter(shift => shift.isCrossLocationShift && shift.teamId !== this.teamId);

                setMemberIdForCrossLocationShift(crossLocationShifts);
                setTagIdForCrossLocationShift(crossLocationShifts);
            } else {
                dataInDateRange = await RestClient.getDataInDateRangePaginated(
                    this.teamId,
                    this.fetchStartTime,
                    this.fetchEndTime,
                    this.dataFilterToNetworkOptions(dataFilter)
                );
            }
            numOfPages++;
            const numOfItemsOnFirstPage: number = this.getNumOfItems(dataInDateRange);
            numOfItems += numOfItemsOnFirstPage;
            numOfItemsByPage += numOfItemsOnFirstPage + ";"; // append the count to the numOfItemsByPage string

            let nextPageCursorShifts: string = dataInDateRange ? dataInDateRange.skipToken : null;
            let nextPageLinkMyShifts: string = dataInDateRange ? dataInDateRange.nextLink : null;
            let nextPageCursor = shouldUseMyShiftsApi ? nextPageLinkMyShifts : nextPageCursorShifts;

            // Already fetched one page, get next maxPages-1 pages as long as we have nextLink in response
            for (let currentPageCount: number = 1; currentPageCount < maxPages && !!nextPageCursor; currentPageCount++) {
                let dataInDateRangePage: IDataInDateRangePage = null;
                if (shouldUseMyShiftsApi) {
                    dataInDateRangePage = await RestClient.executeGetDataInDateRangeLinkForMyShifts(nextPageCursor);
                } else {
                    dataInDateRangePage = await RestClient.getDataInDateRangePaginated(
                        this.teamId,
                        this.fetchStartTime,
                        this.fetchEndTime,
                        this.dataFilterToNetworkOptions(dataFilter),
                        nextPageCursor
                    );
                }

                numOfPages++;
                const numOfItemsOnPage: number = this.getNumOfItems(dataInDateRangePage);
                numOfItems += numOfItemsOnPage;
                numOfItemsByPage += numOfItemsOnPage + ";";

                nextPageCursor = null;
                if (dataInDateRangePage) {
                    nextPageCursor = shouldUseMyShiftsApi ? dataInDateRangePage.nextLink : dataInDateRangePage.skipToken;

                    if (dataInDateRangePage.shifts) {
                        dataInDateRange.shifts = dataInDateRange.shifts.concat(dataInDateRangePage.shifts);
                    }
                    if (dataInDateRangePage.notes) {
                        dataInDateRange.notes = dataInDateRange.notes.concat(dataInDateRangePage.notes);
                    }
                    if (dataInDateRangePage.openShifts) {
                        dataInDateRange.openShifts = dataInDateRange.openShifts.concat(dataInDateRangePage.openShifts);
                    }
                }
            }
            if (nextPageCursor) {
                hasMorePagesBeyondLimit = true;
            }
            // TODO: need to decide on the max number of pages to fetch and how the error should be surfaced
            // Currently, showing only a banner message that can be dismissed. Users can continue working on
            // the shifts and won't see the extra shifts
            if (hasMorePagesBeyondLimit) {
                setGlobalMessages(
                    appViewState().globalMessageViewState,
                    [StringsStore().registeredStringModules.get("common").strings.get("errorLoadingScheduleData")],
                    MessageBarType.error
                );
            }

            const isDataInSync = isInSync !== undefined && !!dataFilter ? isInSync : true;

            return {
                shiftsWithSyncState: {
                    data: dataInDateRange.shifts,
                    isDataInSync: isDataInSync,
                    isPartialInSync: !!dataFilter
                },
                notesWithSyncState: {
                    data: dataInDateRange.notes,
                    isDataInSync: isDataInSync,
                    isPartialInSync: !!dataFilter
                },
                openShiftsWithSyncState: {
                    data: dataInDateRange.openShifts,
                    isDataInSync: isDataInSync,
                    isPartialInSync: !!dataFilter
                }
            };
        } catch (error) {
            gotError = true;
            throw error;
        } finally {
            // Instrumentation
            InstrumentationService.perfMarkerEnd(marker);

            // In case of error, add the results for the pages that were fetched successfully

            const eventProperties: Array<InstrumentationEventPropertyInterface> = [];
            eventProperties.push(
                getGenericEventPropertiesObject(InstrumentationService.properties.MultiTeamApiForMyShiftCalled, shouldUseMyShiftsApi)
            );
            eventProperties.push(
                getGenericEventPropertiesObject(
                    InstrumentationService.properties.ViewStartDate,
                    this.fetchStartTime && this.fetchStartTime.toISOString()
                )
            );
            eventProperties.push(
                getGenericEventPropertiesObject(
                    InstrumentationService.properties.ViewEndDate,
                    this.fetchEndTime && this.fetchEndTime.toISOString()
                )
            );
            eventProperties.push(getGenericEventPropertiesObject(InstrumentationService.properties.NumPages, numOfPages));
            eventProperties.push(getGenericEventPropertiesObject(InstrumentationService.properties.NumOfItems, numOfItems));
            eventProperties.push(getGenericEventPropertiesObject(InstrumentationService.properties.NumOfItemsByPage, numOfItemsByPage));
            eventProperties.push(
                getGenericEventPropertiesObject(
                    InstrumentationService.properties.NumOfTagFilters,
                    dataFilter && dataFilter.tagIds ? dataFilter.tagIds.length : 0
                )
            );
            eventProperties.push(
                getGenericEventPropertiesObject(
                    InstrumentationService.properties.NumOfMemberFilters,
                    dataFilter && dataFilter.memberIds ? dataFilter.memberIds.length : 0
                )
            );
            if (gotError) {
                eventProperties.push(getGenericEventPropertiesObject(InstrumentationService.properties.Error, true));
            }
            InstrumentationService.logPerfEvent(InstrumentationService.events.GetDataInDateRangePage, eventProperties);
        }
    }

    /**
     * Returns the count of items in the GDIDR response. Used for both pages and unpaged api response
     */
    private getNumOfItems(dataInDateRange: IDataInDateRangePage | IDataInDateRange): number {
        let numOfItems: number = 0;
        if (dataInDateRange) {
            numOfItems += dataInDateRange.shifts ? dataInDateRange.shifts.length : 0;
            numOfItems += dataInDateRange.notes ? dataInDateRange.notes.length : 0;
            numOfItems += dataInDateRange.openShifts ? dataInDateRange.openShifts.length : 0;
        }
        return numOfItems;
    }

    /**
     * Set data in memory
     */
    async setDataInMemoryForCurrentTeam(data: IDataInDateRangeWithSyncState) {
        // wrap everything in an action to prevent multiple render reactions from mobx
        runInAction(() => {
            if (data && data.shiftsWithSyncState && data.notesWithSyncState && data.openShiftsWithSyncState) {
                // set shifts in memory
                this.shiftsDateRangeDataProvider.setDataInMemory(data.shiftsWithSyncState);
                // set notes in memory
                this.notesDateRangeDataProvider.setDataInMemory(data.notesWithSyncState);
                // set OpenShifts in memory
                this.openShiftsInDateRangeDataProvider.setDataInMemory(data.openShiftsWithSyncState);
            }
        });
    }

    /**
     * Set data in the database
     */
    async setDataInDatabase(data: IDataInDateRangeWithSyncState) {
        if (data) {
            const { shiftsWithSyncState, notesWithSyncState, openShiftsWithSyncState } = data;

            // set shifts in db
            await this.shiftsDateRangeDataProvider.setDataInDatabase(shiftsWithSyncState);

            // set notes in db
            await this.notesDateRangeDataProvider.setDataInDatabase(notesWithSyncState);

            // set openshifts in db
            await this.openShiftsInDateRangeDataProvider.setDataInDatabase(openShiftsWithSyncState);
        }
    }

    /**
     * Make a network call to update the data
     */
    async saveDataToNetwork(data: IDataInDateRangeWithSyncState): Promise<IDataInDateRangeWithSyncState> {
        throw new Error("DateRangeDataProvider.saveDataToNetwork not implemented");
    }

    /**
     * Function that determines if a note or a shift is in the fetch time range
     */
    public static isEntityInFetchRange(entity: IBaseShiftEntity | INoteEntity, fetchStart: Moment, fetchEnd: Moment): boolean {
        // Find any shifts or notes that overlap the fetch time range.
        // Shifts/notes overlaps with the specified time range if shift/note end is after fetch start and shift/note
        // start is before fetch end.
        return entity.endTime.isAfter(fetchStart) && entity.startTime.isBefore(fetchEnd);
    }

    /**
     * Whether to skip refreshing data from network if it was only in the database and not in memory.
     * This method gets called when we don't find the data in memory, and find something in the database.
     * Because we can't always trust that the data from the database was in-sync and not missing changes from the service,
     * this method allows the provider to inspect the state of data from the db and
     * determine if we need to make a network call to refresh this data asynchronously.
     */
    public async skipRefreshFromNetworkIfNotInMemory(dataFromDatabase: IDataInDateRangeWithSyncState): Promise<boolean> {
        if (!ECSConfigService.isECSFeatureEnabled(ECSConfigKey.EnableSyncStateInIndexedDb)) {
            // force refresh from network if the sync state isn't allowed to be stored in IndexedDb
            return false;
        }

        // check if all the items are in sync
        const shiftsCanSkipNetworkRefresh =
            dataFromDatabase && dataFromDatabase.shiftsWithSyncState && dataFromDatabase.shiftsWithSyncState.isDataInSync;
        const notesCanSkipNetworkRefresh =
            dataFromDatabase && dataFromDatabase.notesWithSyncState && dataFromDatabase.notesWithSyncState.isDataInSync;
        const openShiftsCanSkipNetworkRefresh =
            dataFromDatabase && dataFromDatabase.openShiftsWithSyncState && dataFromDatabase.openShiftsWithSyncState.isDataInSync;

        return shiftsCanSkipNetworkRefresh && notesCanSkipNetworkRefresh && openShiftsCanSkipNetworkRefresh;
    }

    /**
     * Returns the fetch time range that overlaps with the current db range
     */
    public async getTimeRangeCurrentlyInSync(): Promise<IDateRange> {
        const shiftsDbSyncState: IItemsInTimeRangeSyncState = await this.shiftsDateRangeDataProvider.getItemsSyncStateFromDatabase();
        const notesDbSyncState: IItemsInTimeRangeSyncState = await this.notesDateRangeDataProvider.getItemsSyncStateFromDatabase();
        const openShiftsDbSyncState: IItemsInTimeRangeSyncState =
            await this.openShiftsInDateRangeDataProvider.getItemsSyncStateFromDatabase();

        // If the start or end time is not available in any of the DBs, return null to not fetch any data
        if (
            !(
                shiftsDbSyncState &&
                notesDbSyncState &&
                openShiftsDbSyncState &&
                shiftsDbSyncState.syncStateStartTimestamp &&
                shiftsDbSyncState.syncStateEndTimestamp &&
                notesDbSyncState.syncStateStartTimestamp &&
                notesDbSyncState.syncStateEndTimestamp &&
                openShiftsDbSyncState.syncStateStartTimestamp &&
                openShiftsDbSyncState.syncStateEndTimestamp
            )
        ) {
            return null;
        }

        // Get the max range of the DBs. Ideally these DBs should have same ranges
        let startTime: number = shiftsDbSyncState.syncStateStartTimestamp;
        if (startTime > notesDbSyncState.syncStateStartTimestamp) {
            startTime = notesDbSyncState.syncStateStartTimestamp;
        }
        if (startTime > openShiftsDbSyncState.syncStateStartTimestamp) {
            startTime = openShiftsDbSyncState.syncStateStartTimestamp;
        }

        let endTime: number = shiftsDbSyncState.syncStateEndTimestamp;
        if (endTime < notesDbSyncState.syncStateEndTimestamp) {
            endTime = notesDbSyncState.syncStateEndTimestamp;
        }
        if (endTime < openShiftsDbSyncState.syncStateEndTimestamp) {
            endTime = openShiftsDbSyncState.syncStateEndTimestamp;
        }

        // Now that we have the max range of the DBs, check for overlap
        if (!(this.fetchStartTime.isBefore(endTime) && this.fetchEndTime.isAfter(startTime))) {
            // There is no overlap. Don't fetch any data
            return null;
        } else {
            // Reduce the range
            const reducedStartTime: Moment = this.fetchStartTime.isBefore(startTime) ? moment(startTime) : this.fetchStartTime;
            const reducedEndTime: Moment = this.fetchEndTime.isAfter(endTime) ? moment(endTime) : this.fetchEndTime;

            return {
                startTime: reducedStartTime,
                endTime: reducedEndTime
            };
        }
    }
}
