import { IShiftEntity } from "../../sh-models";
import { TeamStore } from "../../sh-stores";
import { TagStore } from "../../sh-stores/sh-tag-store";

 /**
 * Rewrite memberId for cross location shifts to be a member in the current team based on userId.
 * @param crossLocationShifts Cross location shifts.
 */
export function setMemberIdForCrossLocationShift(crossLocationShifts: IShiftEntity[]): void {
    // Add the mapping of userId to memberId
    const userIdToMemberIdMap = new Map<string, string>();
    TeamStore().members.forEach(member => userIdToMemberIdMap.set(member.userId, member.id));

    crossLocationShifts.forEach(shift => {
        const memberIdInCurrentTeam = userIdToMemberIdMap.get(shift.userId);
        if (memberIdInCurrentTeam) {
            shift.memberId = memberIdInCurrentTeam;
        }
    });
}

/**
 * Rewrite tagId for cross location shifts to be a tag in the current team based on tag code or tag name.
 * @param crossLocationShifts Cross location shifts.
 */
export function setTagIdForCrossLocationShift(crossLocationShifts: IShiftEntity[]): void {
    // Add the mappings of tagCode and tagName to tagIds in the current team
    const tagCodeToTagIdMap = new Map<string, string>();
    const tagNameToTagIdMap = new Map<string, string>();
    TagStore().tags.forEach(tag => {
        if (tag.code) {
            tagCodeToTagIdMap.set(tag.code.toUpperCase(), tag.id);
        }
        if (tag.name) {
            tagNameToTagIdMap.set(tag.name.toUpperCase(), tag.id);
        }
    });

    crossLocationShifts.forEach(shift => {
        if (shift.tagIds?.length > 0) {
            let tagIdUpdated = false;
            const originalShiftTagId = shift.tagIds[0];
            if (shift.tagsInfo?.length > 0) {
                shift.tagsInfo.forEach(tagInfo => {
                    if (!tagIdUpdated && tagInfo.id === originalShiftTagId) {
                        // Find the corresponding tag in the current team based on tag code or tag name
                        if (tagInfo.code) {
                            const currentTeamTagId = tagCodeToTagIdMap.get(tagInfo.code.toUpperCase());
                            if (currentTeamTagId) {
                                shift.tagIds[0] = currentTeamTagId;
                                tagInfo.id = currentTeamTagId;
                                tagIdUpdated = true;
                            }
                        }
                        if (tagInfo.name && !tagIdUpdated) {
                            const currentTeamTagId = tagNameToTagIdMap.get(tagInfo.name.toUpperCase());
                            if (currentTeamTagId) {
                                shift.tagIds[0] = currentTeamTagId;
                                tagInfo.id = currentTeamTagId;
                                tagIdUpdated = true;
                            }
                        }
                    }
                });
            }

            // If we can't find a matching tag in the current team, remove the tagId so the shift renders into the "Other" Scheduling Group
            if (!tagIdUpdated) {
                shift.tagIds = [];
                shift.tagsInfo = [];
            }
        }
    });
}