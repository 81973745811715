import "react-dates/initialize";
//  IMPORTANT - import "react-dates/initialize" MUST COME FIRST

import * as moment from "moment";
import * as React from "react";
import AutomationUtils from "sh-application/utility/AutomationUtil";
import CalendarUtils from "sh-application/utility/CalendarUtils";
import DateTimeFormatter, { DateTimeFormatType } from "sh-application/utility//DateTimeFormatter";
import EventUtils from "sh-application/utility/EventUtils";
import KeyboardUtils from "sh-application/utility/KeyboardUtils";
import StringsStore from "sh-strings/store";
import { AriaProperties, AriaRoles, generateDomPropertiesForAria } from "owa-accessibility";
import {
    Callout,
    DirectionalHint,
    DayOfWeek,
    FocusTrapZone,
    Icon,
    IconButton,
    TextField
    } from "@fluentui/react";
import { DayPickerSingleDateController } from "react-dates";
import {
    getDateHasShiftsMapKeyFromDate,
    setIsCalloutShowingInStore,
    setMonth,
    ShiftHintDatePickerStoreSchema
    } from "./store";
import { IMemberEntity } from "sh-models";
import { Moment } from "moment";
import { observer } from "mobx-react";
import { SingleDatePickerPhrases } from "react-dates";

const styles = require("./ShiftHintDatePicker.scss");
const classNames = require("classnames/bind");

export interface ShiftHintDatePickerProps {
    className?: string;
    date: Moment;
    localStore: ShiftHintDatePickerStoreSchema;
    onDateChange: (date: Moment) => void;
    ariaLabel: string;
    startDayOfWeek: DayOfWeek;
    member: IMemberEntity;
    label?: string;
    isDayBlocked?: (day: Moment) => boolean;
}

/**
 * This class is used to create a date picker with indicators for which dates a member has shifts on.
 */
@observer
export default class ShiftHintDatePicker extends React.Component<ShiftHintDatePickerProps, {}> {
    private _textfieldContainer = React.createRef<HTMLDivElement>();
    private _strings: Map<string, string>;
    private _commonStrings: Map<string, string>;
    private _calendarStrings: Map<string, string>;
    private _datepickerDateFormat: string; // eg. "dddd, MMMM D, YYYY" for "Tuesday, November 13, 2018"

    constructor(props: ShiftHintDatePickerProps) {
        super(props);
        this._strings = StringsStore().registeredStringModules.get("shiftHintDatePicker").strings;
        this._commonStrings = StringsStore().registeredStringModules.get("common").strings;
        if (props.date) {
            this.onMonthChange(props.date);
        }
        this._calendarStrings = StringsStore().registeredStringModules.get("calendarDatePickerPhrases").strings;
        this._datepickerDateFormat = StringsStore().registeredStringModules.get("dateTimeFormats").strings.get("dateFormatUsedInAriaLabelForCalendar");
    }

    /**
     * Listen for updates to the props and set the month if the date changes
     * @param prevProps
     */
    componentDidUpdate(prevProps: ShiftHintDatePickerProps) {
        const { date } = this.props;
        const oldDate: Moment = prevProps.date;
        if (oldDate && date && !oldDate.isSame(date, "month")) {
            this.onMonthChange(date);
        }
    }

    /**
     * Callback for onBlur on date picker which will be called when Escape key is pressed
     * It should close the callout and set focus to the input element
     */
    private onBlurDatePicker = () => {
        this.closeCallout();
    }

    render() {
        const { localStore, ariaLabel, className, label, date, startDayOfWeek, isDayBlocked } = this.props;
        if (!localStore) {
            return null;
        }

        // labelId required by TextField component to link the label and the field labelled by it.
        const labelId = `${label}-labelId`;

        const ariaProps: AriaProperties = {
            role: AriaRoles.button,
            expanded: localStore.isCalloutShowing,
            labelledBy: labelId
            // label doesn't work here. TextField uses ariaLabel prop
        };

        const datePickerPhrases: SingleDatePickerPhrases = CalendarUtils.getSingleDatePickerPhrases();
        // Override the default methods for calendar when messages should indicate if there are shifts on that day
        datePickerPhrases.dateIsSelected = this.dateIsSelectedMessage;
        datePickerPhrases.chooseAvailableDate = this.chooseAvailableDateMessage;

        return (
            <div className={ classNames(styles.dateInputContainer, className, AutomationUtils.getAutomationId("components", "QAIDShiftHintDatePicker")) }
                ref={ this._textfieldContainer }>
                <TextField
                    value={ this.getTextFieldValue() }
                    placeholder={`${this._strings.get("selectADate")} ${date ? `Selected ${date.format("DD MMM YYYY")}` : ""}`}
                    onClick={ this.onTextFieldClick }
                    onKeyDown={ this.onTextFieldKeyDown }
                    onFocus={ this.onTextFieldFocus }
                    iconProps={ { iconName: 'Calendar' } }
                    readOnly={ true }
                    ariaLabel={ ariaLabel }
                    onRenderLabel={() => this.customLabelRender(label, labelId, ariaLabel) }
                    aria-placeholder={ this.getTextFieldValue() }
                    { ...generateDomPropertiesForAria(ariaProps) }/>
                { localStore.isCalloutShowing
                    && <Callout
                        directionalHint={ DirectionalHint.bottomLeftEdge }
                        isBeakVisible={ false }
                        doNotLayer={ false }
                        setInitialFocus={ true }
                        onDismiss={ this.onCalloutDismiss }
                        target={ this._textfieldContainer }>
                        <FocusTrapZone isClickableOutsideFocusTrap={ true } focusPreviouslyFocusedInnerElement={ true }>
                            <div className={ styles.datePickerCalloutContent } >
                                <DayPickerSingleDateController
                                    date={ date }
                                    onDateChange={ this.onDateChange }
                                    /* When focus is true, the user can select dates. We don't need this to ever be false for our use case. */
                                    focused={ true }
                                    onBlur={ this.onBlurDatePicker }
                                    onFocusChange={ () => {} }
                                    onNextMonthClick={ this.onMonthChange }
                                    onPrevMonthClick={ this.onMonthChange }
                                    firstDayOfWeek={ startDayOfWeek }
                                    hideKeyboardShortcutsPanel={ true }
                                    /* This approach of two functions is used to trigger a re-render when new shifts are fetched */
                                    renderDayContents={ localStore.areShiftsFetched ? this.renderDayWithShiftsFetched : this.renderDayWithoutShiftsFetched }
                                    isDayBlocked={ isDayBlocked }
                                    navPrev={ this.renderPreviousIcon() }
                                    navNext={ this.renderNextIcon() }
                                    dayAriaLabelFormat={ this._datepickerDateFormat }
                                    phrases={ datePickerPhrases } />
                                { /* The close button is needed to keep focus within the callout on hitting tab key. This is an issue with airbnb date picker */}
                                <IconButton
                                    title={ this._commonStrings.get("close") }
                                    ariaLabel={ this._commonStrings.get("close") }
                                    onClick={ this.onCalloutDismiss }
                                    className={ styles.closeButton }
                                    iconProps={ { styles: { root: styles.closeButtonIcon }, iconName: "Cancel" } } />
                            </div>
                        </FocusTrapZone>
                    </Callout>
                }
            </div>
        );
    }

    private customLabelRender = (label: string, labelId: string, ariaLabel: string) => {
        return <span id={labelId} aria-label={ ariaLabel }>{label}</span>;
    }

    /**
     * Returns the aria label for a date that can be selected on a single date picker calendar
     * This is to override the default to indicate if there are shifts on the day
     */
    private chooseAvailableDateMessage = (currentDate: any): string => {
        if (currentDate && currentDate.date) {
            const day: Moment = moment(currentDate.date, this._datepickerDateFormat);
            if (day && this.hasShiftsForDay(day)) {
                return this._calendarStrings.get("chooseAvailableDateWithShifts").format(currentDate.date, this._calendarStrings.get("shiftsForTheDayLabel"));
            } else {
                return currentDate.date;
            }
        } else {
            return "";
        }
    }

    /**
     * Returns the aria label for a selected date on the calendar
     * This is to override the default to indicate if there are unshared shifts on the day
     */
    private dateIsSelectedMessage = (currentDate: any): string => {
        if (currentDate && currentDate.date) {
            const day: Moment = moment(currentDate.date, this._datepickerDateFormat);
            if (day && this.hasShiftsForDay(day)) {
                return this._calendarStrings.get("dateIsSelectedWithShifts").format(currentDate.date, this._calendarStrings.get("shiftsForTheDayLabel"));
            } else {
                return this._calendarStrings.get("dateIsSelected").format(currentDate.date);
            }
        } else {
            return "";
        }
    }

    /**
     * Get the value of the text field that is serving as the input
     */
    private getTextFieldValue = (): string => {
        const { date } = this.props;
        return date
            ? DateTimeFormatter.getDateTimeAsString(date, DateTimeFormatType.Date_Numeric)
            : "";
    }

    /**
     * Click listener for text field serving as input
     */
    private onTextFieldClick = () => {
        this.toggleIsCalloutShowing();
    }

    /**
     * keyDown listener for the TextField serving as the input. Text input is disabled but this checks for enter or space press for accessibility.
     * @param e Keyboard event
     */
    private onTextFieldKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
        if (KeyboardUtils.isActionKeyPressed(e)) {
            this.toggleIsCalloutShowing();
            e.preventDefault();
            e.stopPropagation();
        }
    }
    /**
     * Focus listener for the TextField serving as the input
     * @param e Focus event
     */
    private onTextFieldFocus = (e: React.FocusEvent<HTMLElement>) => {
        this.toggleIsCalloutShowing();
    };
    /**
     * Open or close the callout and handle data initialization
     */
    private toggleIsCalloutShowing = () => {
        const { localStore, date } = this.props;
        if (localStore) {
            if (localStore.isCalloutShowing) {
                this.closeCallout();
            } else {
                setIsCalloutShowingInStore(localStore, true /* isCalloutShowing */);
                // on first open, date may be null, if so, set it immediately to today
                if (!date) {
                    // use the callback but not the local function which will close the date picker
                    if (this.props.onDateChange) {
                        this.props.onDateChange(moment());
                        this.onMonthChange(moment());
                    }
                }
            }
        }
    }

    /**
     * Handle the closing of the callout. Return focus to input element.
     */
    private closeCallout = () => {
        setIsCalloutShowingInStore(this.props.localStore, false /* isCalloutShowing */);
        const textFieldElement: HTMLElement = this.getTextFieldElement();
        if (textFieldElement) {
            textFieldElement.focus();
        }
    }

    /**
     * AirBnB's react picker sets off a number of focus events that Fabric's Callout will pick up for onDismiss.
     * We ignore these focus events for the onDismiss but will still dismiss for a click event or an outside scroll.
     * @param event
     */
    private onCalloutDismiss = (event: any) => {
        if (!EventUtils.isFocusEvent(event)) {
            this.closeCallout();
        }
    }

    /**
     * Get the input element which is a sub element of the inputContainer
     */
    private getTextFieldElement = (): HTMLElement => {
        let textFieldElement: HTMLElement = null;
        if (this._textfieldContainer) {
            const element = this._textfieldContainer.current.getElementsByClassName("ms-TextField-field");
            if (element && element.item && element.item.length > 0) {
                textFieldElement = element.item(0) as HTMLElement;
            }
        }
        return textFieldElement;
    }

    /**
     * When the user changes the selected date for the picker
     * @param date
     */
    private onDateChange = (newDate: Moment, secondParam?: any) => {
        if (this.props.onDateChange) {
            this.props.onDateChange(newDate);
        }
        this.closeCallout();
    }

    /**
     * When the calendar month that is currently in view changes.
     * @param month
     */
    private onMonthChange = (month: Moment) => {
        setMonth(this.props.localStore, month, this.props.member);
    }

    /**
     * render function for calendar days when we don't have information on shifts for the member
     * @param day
     */
    private renderDayWithoutShiftsFetched(day: Moment): JSX.Element {
        return (
            <span className={ styles.calendarDayContainer }>
                <span className={ styles.calendarDay }>{ DateTimeFormatter.getDateTimeAsString(day, DateTimeFormatType.Date_DateNumeric) }</span>
            </span>
        );
    }

    /**
     * Render function for a calendar day when we have shift information for the member
     * @param day
     */
    private renderDayWithShiftsFetched = (day: Moment): JSX.Element => {
        return (
            <span className={ styles.calendarDayContainer }>
                <span className={ styles.calendarDay }>{ DateTimeFormatter.getDateTimeAsString(day, DateTimeFormatType.Date_DateNumeric) }</span>
                {
                   this.hasShiftsForDay(day) &&
                        <span className={ styles.shiftIndication }>•</span>
                }
            </span>
        );
    }

    /**
     * Does the member who this component was made for have shifts for a specific day
     * @param day
     */
    private hasShiftsForDay = (day: Moment): boolean => {
        const { localStore } = this.props;
        let hasShifts: boolean = false;
        if (day && localStore && localStore.dateHasShiftsMap) {
            hasShifts = localStore.dateHasShiftsMap.get(getDateHasShiftsMapKeyFromDate(day));
        }
        return hasShifts;
    }

    /**
     * Custom render method for the date range picker previous month control
     */
    private renderPreviousIcon(): JSX.Element {
        return (
            <Icon iconName={ "ChevronLeftMed" } title={ this._strings.get("goToNextMonth") }/>
        );
    }

    /**
     * Custom render method for the date range picker next month control
     */
    private renderNextIcon(): JSX.Element {
        return (
            <Icon iconName={ "ChevronRightMed" } title={ this._strings.get("goToPreviousMonth") }/>
        );
    }
}