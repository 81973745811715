import StaffHubHttpErrorUtils from "sh-application/utility/StaffHubHttpErrorUtils";
import ThemeUtils from "sh-application/utility/ThemeUtils";
import {
    IBaseShiftEntity,
    IMemberEntity,
    IShiftRequestEntity,
    ShiftEntity,
    ShiftRequestTypes,
    ShiftTypes
    } from "sh-models";
import {MemberDataService, ShiftDataService } from "sh-services";
import { orchestrator } from "satcheljs";
import { setErrorMessageFromException, setShiftRequestRead } from "sh-application/components/shiftrequest";
import {
    setIsDataReadyInStore,
    setManagerMemberInStore,
    setOtherShiftInStore,
    setOtherShiftNotFoundInStore,
    setReceiverMemberInStore,
    setSenderMemberInStore,
    setShiftInStore,
    setShiftNotFoundInStore,
    loadDataForRequest
    } from "../../store/store";
import { setRequestShiftInConflictStore } from "sh-requestconflict-store";
import { StaffHubHttpError } from "sh-application";
import { trace } from "owa-trace";
import { TeamStore } from "sh-team-store";

/**
 * This orchestrator will fetch the shifts and members associated with a shift request
 * and write that information to the local store
 */
export const loadDataForRequestOrchestrator = orchestrator(loadDataForRequest, async actionMessage => {
        const { shiftRequest } = actionMessage;
        if (shiftRequest) {
            let promises: any[] = [];
            const shiftRequestEntity: IShiftRequestEntity = shiftRequest as IShiftRequestEntity;
            if (shiftRequestEntity.requestType === ShiftRequestTypes.TimeOff) {
                // For Timeoff, the unpublished shifts are not accessible to non-admins.
                // We create an empty shift object with data from requests
                let shift: IBaseShiftEntity = ShiftEntity.createEmptyObject("" /* tenantId */, "" /* teamId */, "" /* memberId */, ShiftTypes.Absence, ThemeUtils.timeOffDefaultTheme);
                shift.startTime = shiftRequestEntity.startTime;
                shift.endTime = shiftRequestEntity.endTime;
                shift.timeOffReasonId = shiftRequestEntity.timeOffReasonId;
                shift.isPublished = true; // Show as published always for the timeoff request

                // Set this temporary shift object in the ShiftRequests UI store. Should not be stored anywhere else that can cause it to get saved
                setShiftInStore(shift);
                // udpate request shift in conflict request store
                setRequestShiftInConflictStore(shift.id, shift);
            } else {
                let getShiftPromise: Promise<IBaseShiftEntity>;
                if (shiftRequest.requestType === ShiftRequestTypes.Open) {
                    getShiftPromise = ShiftDataService.getOpenShiftById(shiftRequestEntity.shiftId);
                } else if (shiftRequest.requestType === ShiftRequestTypes.CrossLocationOpen) {
                    getShiftPromise = ShiftDataService.getOpenShiftById(shiftRequestEntity.shiftId, shiftRequestEntity.openShiftTeamId);
                } else {
                    getShiftPromise = ShiftDataService.getShiftById(shiftRequestEntity.shiftId);
                }
                promises.push(getShiftPromise
                    .then((shift: IBaseShiftEntity) => {
                        setShiftInStore(shift);
                        // udpate request shift in conflict request store
                        setRequestShiftInConflictStore(shift.id, shift);
                    })
                    .catch((error: StaffHubHttpError) => {
                        if (StaffHubHttpErrorUtils.isNotFoundError(error)) {
                            setShiftNotFoundInStore(true);
                        } else {
                            throw(error);
                        }
                    })
                );

                if (shiftRequestEntity.otherShiftInfo && shiftRequestEntity.otherShiftInfo.shiftId) {
                    promises.push(ShiftDataService.getShiftById(shiftRequestEntity.otherShiftInfo.shiftId)
                        .then((otherShift: IBaseShiftEntity) => {
                            setOtherShiftInStore(otherShift);
                            // udpate request shift in conflict request store
                            setRequestShiftInConflictStore(otherShift.id, otherShift);
                        })
                        .catch((error: StaffHubHttpError) => {
                            if (StaffHubHttpErrorUtils.isNotFoundError(error)) {
                                setOtherShiftNotFoundInStore(true);
                            } else {
                                throw(error);
                            }
                        })
                    );
                }
            }

            const { receiverMemberId, senderMemberId, managerMemberId, senderTeamId, openShiftTeamId } = shiftRequestEntity;
            const currentTeamId = TeamStore().teamId;
            if (receiverMemberId) {
                promises.push(MemberDataService.getMemberByIdFromCurrentTeam(receiverMemberId)
                    .then((receiverMember: IMemberEntity) => {
                        setReceiverMemberInStore(receiverMember);
                    })
                );
            }
            if (senderMemberId) {
                if (senderTeamId && senderTeamId != currentTeamId) {
                    // External team member
                    promises.push(MemberDataService.getMemberByIdFromSpecifiedTeam(senderMemberId, senderTeamId).then((senderMember: IMemberEntity) => {
                        setSenderMemberInStore(senderMember);
                    }));
                } else {
                    // Current team member
                    promises.push(MemberDataService.getMemberByIdFromCurrentTeam(senderMemberId)
                        .then((senderMember: IMemberEntity) => {
                            setSenderMemberInStore(senderMember);
                        })
                    );
                }
            }
            if (managerMemberId) {
                if (openShiftTeamId && openShiftTeamId !== currentTeamId) {
                    // External team
                    promises.push(MemberDataService.getMemberByIdFromSpecifiedTeam(managerMemberId, openShiftTeamId)
                    .then((managerMember: IMemberEntity) => {
                        setManagerMemberInStore(managerMember);
                    })
                );
                } else {
                    promises.push(MemberDataService.getMemberByIdFromCurrentTeam(managerMemberId)
                        .then((managerMember: IMemberEntity) => {
                            setManagerMemberInStore(managerMember);
                        })
                    );
                }
            }

            try {
                await Promise.all(promises)
                    .then(() => {
                        // if everything succeeds, set the data to ready, set the shift as read
                        setIsDataReadyInStore(true);
                        setShiftRequestRead(shiftRequest);
                    });
            } catch (error) {
                setErrorMessageFromException(error);
                trace.warn("loadDataForRequest: Error in getting details for shift request");
            }
        }
    }
);