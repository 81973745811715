import * as moment from "moment";
import ConflictUtils from "./ConflictUtils";
import DateTimeFormatter, { DateTimeFormatType } from "sh-application/utility/DateTimeFormatter";
import DateUtils from "sh-application/utility/DateUtils";
import MemberUtils from "./MemberUtils";
import StringsStore from "sh-strings/store";
import TagUtils from "./TagUtils";
import ThemeUtils from "sh-application/utility/ThemeUtils";
import {
    ACTIVITY_MINIMUM_SEPARATION_IN_MINUTES,
    DEFAULT_ACTIVITY_DURATION_IN_MINUTES,
    DEFAULT_SHIFT_DURATION,
    DEFAULT_START_TIME,
    MAX_ACTIVITY_TITLE_LENGTH,
    ScheduleCellRenderSize
} from "sh-application/../StaffHubConstants";
import {
    BreakEntity,
    BreakTypes,
    IBaseShiftEntity,
    IBreakEntity,
    IMemberEntity,
    INoteEntity,
    IOpenShiftEntity,
    IShiftEntity,
    ISubshiftEntity,
    ITagEntity,
    ITimeOffReasonEntity,
    IUniqueShiftEntity,
    IUniqueSubshiftEntity,
    OpenShiftEntity,
    ShiftFilterType,
    ShiftStates,
    ShiftTypes,
    SubshiftEntity
} from "sh-models";
import { default as ShiftEntity } from "sh-models/shift/ShiftEntity";
import { isMoment, Moment } from "moment";
import { ScheduleGridUtils } from "sh-application/components/schedules/lib";
import { ShiftStore } from "sh-stores/sh-shift-store";
import { SlotBarProps } from "sh-application/components/shift/SlotBar";
import { SubshiftTypes } from "sh-models/subshift/ISubshiftEntity";
import { TagStore } from "sh-tag-store";
import { TimeOffReasonsStore } from "sh-timeoffreasons-store";
import { TimeRange } from "sh-application/utility/DateUtils";
import { TeamStore } from "../../sh-stores";

/**
 * Utilities for Shifts
 */
export default class ShiftUtils {
    /**
     * Comparator for sorting shifts and subshifts by their start times
     */
    public static shiftComparator(firstItem: IBaseShiftEntity | ISubshiftEntity, secondItem: IBaseShiftEntity | ISubshiftEntity): number {
        let startTimeComparison = firstItem.startTime.diff(secondItem.startTime);

        if (startTimeComparison == 0) {
            return firstItem.endTime.diff(secondItem.endTime);
        } else {
            return startTimeComparison;
        }
    }

    /**
     * Comparator for sorting shifts, unique shifts and subshifts by their start times.
     * This considers only the time (not the date) for the comparison
     */
    public static shiftComparatorTimeOfDay(
        firstItem: IShiftEntity | ISubshiftEntity | IUniqueShiftEntity,
        secondItem: IShiftEntity | ISubshiftEntity | IUniqueShiftEntity
    ): number {
        let firstItemStartTime = firstItem.startTime.clone();
        let secondItemStartTime = secondItem.startTime.clone();

        // set the year/month/date on the first item to be same as the second item
        firstItemStartTime.year(secondItemStartTime.year()).month(secondItemStartTime.month()).date(secondItemStartTime.date());
        let startTimeComparison = firstItemStartTime.diff(secondItemStartTime);

        if (startTimeComparison == 0) {
            let firstItemEndTime = firstItem.endTime.clone();
            let secondItemEndTime = secondItem.endTime.clone();

            // set the year/month/date on the first item to be same as the second item
            firstItemEndTime.year(secondItemEndTime.year()).month(secondItemEndTime.month()).date(secondItemEndTime.date());
            return firstItemEndTime.diff(secondItemEndTime);
        } else {
            return startTimeComparison;
        }
    }

    /**
     * Returns true if the given shift is valid and not malformed
     * @param shift
     */
    public static isValidShift(shift: IBaseShiftEntity): boolean {
        // not null or undefined
        // start time before end time
        return !!shift && isMoment(shift.startTime) && isMoment(shift.endTime) && shift.startTime.isBefore(shift.endTime);
    }

    /**
     * Marks a shift/note/openshift as being hard deleted without any published changes, so it doesn't render in the Schedule View
     */
    public static markItemAsHardDeleted(
        item: IShiftEntity | INoteEntity | IOpenShiftEntity
    ): IShiftEntity | INoteEntity | IOpenShiftEntity {
        // extract the shared changes (if any)
        const resultItem = item.sharedChanges ? item.sharedChanges : item;

        // mark the resultant shift as deleted
        resultItem.state = ShiftStates.Deleted;
        resultItem.isPublished = false;
        return resultItem;
    }

    /**
     * Returns true if the specified shift should be allowed to be displayed in the Schedule View
     */
    public static isShiftDisplayableForScheduleView(shift: IBaseShiftEntity): boolean {
        const sharedChanges: IBaseShiftEntity = ShiftUtils.getSharedChanges(shift);
        const isDisplayable: boolean =
            ShiftUtils.isValidShift(shift) &&
            ((sharedChanges && !ShiftUtils.isDeletedShift(sharedChanges)) || // A shift with a shared version that is not deleted should be displayed. If the saved version is deleted, UI will reflect this
                shift.state === ShiftStates.Active || // Active
                (shift.state === ShiftStates.Pending && shift.shiftType === ShiftTypes.Absence)); // Pending shift for time off request

        return isDisplayable;
    }

    /**
     * Returns true if the specified open shift should be allowed to be displayed in the Schedule View
     */
    public static isOpenShiftDisplayableForScheduleView(shift: IOpenShiftEntity): boolean {
        const sharedChanges = ShiftUtils.getSharedChanges(shift) as IOpenShiftEntity;
        let isDisplayable =
            ShiftUtils.isShiftDisplayableForScheduleView(shift) && (shift.openSlots > 0 || (sharedChanges && sharedChanges.openSlots > 0)); // open shifts with zero slots are only visible if the shift has shared changes with slots > 0
        return isDisplayable;
    }

    /**
     * Returns true if the shift fits into the given range and is displayable for the schedule
     * @param shift
     * @param viewStart
     * @param viewEnd
     */
    public static isDisplayableShiftInScheduleRange(shift: IBaseShiftEntity, viewStart: Moment, viewEnd: Moment): boolean {
        return (
            ShiftUtils.shiftOverlapsStartsOrEndsBetween(shift, viewStart, viewEnd, false /*includeEdges*/) &&
            ShiftUtils.isShiftDisplayableForScheduleView(shift)
        );
    }

    /**
     * Returns true if the open shift fits into the given range and is displayable for the schedule
     * @param shift
     * @param viewStart
     * @param viewEnd
     */
    public static isDisplayableOpenShiftInScheduleRange(openShift: IOpenShiftEntity, viewStart: Moment, viewEnd: Moment): boolean {
        return (
            ShiftUtils.shiftOverlapsStartsOrEndsBetween(openShift, viewStart, viewEnd, false /*includeEdges*/) &&
            ShiftUtils.isOpenShiftDisplayableForScheduleView(openShift)
        );
    }

    /**
     * Returns true if the shift event is a working shift. Used for assigned and open shifts.
     */
    public static isWorkingShift(shift: IBaseShiftEntity): boolean {
        return shift && shift.shiftType === ShiftTypes.Working;
    }

    /**
     * Returns true if the shift event is an active working shift. Used for assigned and open shifts.
     */
    public static isActiveWorkingShift(shift: IBaseShiftEntity): boolean {
        return ShiftUtils.isWorkingShift(shift) && ShiftUtils.isActiveShift(shift);
    }

    /**
     * Returns true if the shift is in active state
     * @param shift
     */
    public static isActiveShift(shift: IBaseShiftEntity): boolean {
        return shift && shift.state === ShiftStates.Active;
    }

    /**
     * Returns true if the shift is deleted
     * @param shift
     */
    public static isDeletedShift(shift: IBaseShiftEntity): boolean {
        return shift && shift.state === ShiftStates.Deleted;
    }

    /**
     * Returns true if shift event is a time off event
     */
    public static isTimeOffEvent(shift: IShiftEntity): boolean {
        let isTimeOff = shift && shift.shiftType === ShiftTypes.Absence && shift.state !== ShiftStates.Pending;
        return isTimeOff;
    }

    /**
     * Returns true if shift event is a time off request
     */
    public static isTimeOffRequestEvent(shift: IShiftEntity): boolean {
        let isTimeOffRequest = shift && shift.shiftType === ShiftTypes.Absence && shift.state === ShiftStates.Pending;
        return isTimeOffRequest;
    }

    /**
     * Get TimeOff reason for the specified shift.
     */
    public static getTimeOffReasonFromShift(shift: IShiftEntity, timeOffReasons: Array<ITimeOffReasonEntity>) {
        let timeOffReason: ITimeOffReasonEntity = null;
        if (shift && shift.timeOffReasonId) {
            timeOffReason = ShiftUtils.getTimeOffReasonForId(shift.timeOffReasonId, timeOffReasons);
        }
        return timeOffReason;
    }

    /*
     * Return timeOff reason for id
     * @param {} timeOffReasonId
     * @returns {} timeOff reason
     */
    public static getTimeOffReasonForId(timeOffReasonId: string, timeOffReasons: Array<ITimeOffReasonEntity>) {
        let timeOffReasonFound: ITimeOffReasonEntity = null;
        if (timeOffReasonId) {
            for (let i = 0; i < timeOffReasons.length; i++) {
                const timeOffReason = timeOffReasons[i];
                if (timeOffReason.id === timeOffReasonId) {
                    timeOffReasonFound = timeOffReason;
                    break;
                }
            }
        }
        return timeOffReasonFound;
    }

    /**
     * Get the tag group ID for the specified shift.
     * Returns null if the shift has no tag.
     */
    public static getTagIdFromShift(shift: IBaseShiftEntity) {
        let tagId: string = null;

        // Service sends TagIds as an array of tagIds. We currently allow only one tagId to be assigned with shift.
        if (shift.tagIds && shift.tagIds.length > 0) {
            tagId = shift.tagIds[0];
        } else {
            tagId = null;
        }

        return tagId;
    }

    /**
     * Get the duration in hours of the shift
     */
    public static getDurationInHours(shift: IShiftEntity) {
        return shift.endTime.diff(shift.startTime, "hours", true);
    }

    /**
     * Get the duration in minutes of the shift
     */
    public static getDurationInMinutes(shift: IShiftEntity | ISubshiftEntity) {
        return shift.endTime.diff(shift.startTime, "minutes", true);
    }

    /**
     * Returns the shift times adjusted so as not to start before viewStart or end before viewEnd.
     * @param shift
     * @param viewStart
     * @param viewEnd
     */
    public static getShiftTimesInView(shift: IBaseShiftEntity, viewStart: Moment, viewEnd: Moment): TimeRange {
        return {
            startTime: DateUtils.getTimeLimitedByLowerBound(shift.startTime, viewStart),
            endTime: DateUtils.getTimeLimitedByUpperBound(shift.endTime, viewEnd)
        };
    }

    /**
     * Adjusts the start and end time of the given shift and its activities such that they do not spill out of the
     * view range. Activities not visible in the view are removed.
     * @param shift
     * @param viewStart
     * @param viewEnd
     */
    public static adjustShiftToFitInRange(shift: IBaseShiftEntity, viewStart: Moment, viewEnd: Moment) {
        const { startTime, endTime } = ShiftUtils.getShiftTimesInView(shift, viewStart, viewEnd);
        shift.startTime = startTime;
        shift.endTime = endTime;
        shift.subshifts = shift.subshifts
            .filter(subshift =>
                DateUtils.overlapsStartsOrEndsBetween(
                    subshift.startTime,
                    subshift.endTime,
                    viewStart,
                    viewEnd,
                    false /*includeStartEdge*/,
                    false /*includeEndEdge*/
                )
            )
            .map(subshift => {
                subshift.startTime = DateUtils.getTimeLimitedByLowerBound(subshift.startTime, viewStart);
                subshift.endTime = DateUtils.getTimeLimitedByUpperBound(subshift.endTime, viewEnd);
                return subshift;
            });
        // After shortening a shift, it is possible that the unpaid duration is longer than the shift duration. If this is the case we shorten or remove the unpaid breaks
        // in the shift. We don't modify unpaid activities any further because they have already been limited to the view bounds like the shift itself.
        const totalMin: number = ShiftUtils.getDurationInMinutes(shift);
        const unpaidMin: number = ShiftUtils.getUnpaidMinutesForShift(shift);

        if (unpaidMin > totalMin) {
            // track the remaining overflowing unpaid time. for each break in the shift, reduce its duration and track how much overflow remains.
            let overflowingUnpaidTime: number = unpaidMin - totalMin;
            for (let i = shift.breaks.length - 1; i >= 0; i--) {
                let shiftBreak = shift.breaks[i];
                if (shiftBreak.breakType === BreakTypes.Unpaid && overflowingUnpaidTime > 0) {
                    const reduction: number = Math.min(shiftBreak.duration, overflowingUnpaidTime);
                    shiftBreak.duration -= reduction;
                    overflowingUnpaidTime -= reduction;

                    // if a shift break's duration is now 0, remove the break
                    if (shiftBreak.duration === 0) {
                        shift.breaks.splice(i, 1);
                    }
                }
            }
        }
    }

    /**
     * Returns true if the shift overlaps, ends, or begins within the start and end time provided
     * @param shift
     * @param start
     * @param end
     * @param includeEdges
     */
    public static shiftOverlapsStartsOrEndsBetween(
        shift: IShiftEntity | INoteEntity,
        start: Moment,
        end: Moment,
        includeEdges: boolean = true
    ) {
        return DateUtils.overlapsStartsOrEndsBetween(shift.startTime, shift.endTime, start, end, includeEdges, includeEdges);
    }

    /*
     * Compare two unique shifts and check if they are similar
     * comparision is based on title, shift start and end times (not Date), theme and break durations
     * @param {IUniqueShiftEntity} firstShift
     * @param {IUniqueShiftEntity} secondShift
     * @returns {Boolean} true if given shifts are similar, false otherwise
     */
    public static areSimilarUniqueShifts(firstShift: IUniqueShiftEntity, secondShift: IUniqueShiftEntity) {
        // compare title , theme, startTime, endTime, shift breaks
        return (
            ((!firstShift.title && !secondShift.title) || firstShift.title === secondShift.title) &&
            ((!firstShift.theme && !secondShift.theme) || firstShift.theme === secondShift.theme) &&
            firstShift.startTime.hours() == secondShift.startTime.hours() &&
            firstShift.startTime.minutes() == secondShift.startTime.minutes() &&
            firstShift.endTime.hours() == secondShift.endTime.hours() &&
            firstShift.endTime.minutes() == secondShift.endTime.minutes() &&
            ShiftUtils.getUnPaidShiftBreakDurationInUniqueShift(firstShift) ===
                ShiftUtils.getUnPaidShiftBreakDurationInUniqueShift(secondShift)
        );
    }

    /*
     * Compare two unique sub shifts and check if they are similar
     * comparision is based on title, code and theme. If both are null, return true. If one is null, return false
     * @param {IUniqueSubshiftEntity} firstSubshift
     * @param {IUniqueSubshiftEntity} secondSubshift
     * @returns {Boolean} true if given sub shifts are similar, false otherwise
     */
    public static areSimilarUniqueSubshifts(firstSubshift: IUniqueSubshiftEntity, secondSubshift: IUniqueSubshiftEntity) {
        if (!firstSubshift && !secondSubshift) {
            return true;
        }

        if (!firstSubshift || !secondSubshift) {
            return false;
        }

        // compare title, code and theme
        return (
            firstSubshift.title === secondSubshift.title &&
            firstSubshift.code === secondSubshift.code &&
            firstSubshift.theme === secondSubshift.theme
        );
    }

    /**
     * Compares two shifts and check if they are similar for open shifts usage.
     * @param firstShift
     * @param secondShift
     */
    public static areSimilarShiftsForOpenShifts(firstShift: IBaseShiftEntity, secondShift: IBaseShiftEntity) {
        let isSimilar: boolean = false;
        if (firstShift && secondShift) {
            // cowuertz - we define similarity between shifts as mapping to the same unique shift. This may change and include activities as well
            isSimilar = ShiftUtils.areSimilarUniqueShifts(firstShift as IUniqueShiftEntity, secondShift as IUniqueShiftEntity);
        }
        return isSimilar;
    }

    /**
     * Returns true if the activities are similar
     * @param activity
     * @param otherActivity
     */
    public static areSimilarActivities(activity: ISubshiftEntity, otherActivity: ISubshiftEntity): boolean {
        // compare title , theme, code, and subshiftType
        // none of these items will be null, so null === null should not be a problem
        return (
            activity.title === otherActivity.title &&
            activity.theme === otherActivity.theme &&
            activity.code === otherActivity.code &&
            activity.subshiftType === otherActivity.subshiftType
        );
    }

    /**
     * Returns true if the two breaks are identical
     * @param firstBreak
     * @param otherBreak
     */
    public static areIdenticalBreaks(firstBreak: IBreakEntity, otherBreak: IBreakEntity): boolean {
        let areIdentical: boolean = true;

        if (
            firstBreak.id !== otherBreak.id ||
            firstBreak.duration !== otherBreak.duration ||
            firstBreak.breakType !== otherBreak.breakType ||
            !DateUtils.areTimesIdentical(firstBreak.startTime, otherBreak.startTime)
        ) {
            areIdentical = false;
        }
        return areIdentical;
    }

    /**
     * Set the inital values of the shift being eddited using a uniqueShift
     * @param uniqueShift the unique shift to use as initial values
     * @param shift the shift that is passed to assign the values from the unique shift itself
     * @param startDate Momemt startDate that needs to be assigned to the shift object
     */
    public static setShiftValuesFromUniqueShift(uniqueShift: IUniqueShiftEntity, shift: IBaseShiftEntity, startDate: Moment) {
        const targetStartDate: Moment = startDate.clone().startOf("day");
        shift.startTime = DateUtils.applyTimeOfDayToDate(targetStartDate, uniqueShift.startTime);
        const uniqueShiftStartEndDaysDifference: number = uniqueShift.endTime
            .clone()
            .startOf("day")
            .diff(uniqueShift.startTime.clone().startOf("day"), "days");
        shift.endTime = DateUtils.applyTimeOfDayToDate(targetStartDate, uniqueShift.endTime).add(uniqueShiftStartEndDaysDifference, "days");

        shift.theme = uniqueShift.theme;
    }

    /**
     * Set the startTime and endTime to default values to the shift
     * @param shift IBaseShiftEntity that needs to be set a default startDate value
     * @param startDate Momemt startDate that needs to be assigned to the shift object
     */
    public static setDefaultShiftValues(shift: IBaseShiftEntity, startDate: Moment) {
        shift.startTime = startDate.clone().hours(DEFAULT_START_TIME);
        shift.endTime = shift.startTime.clone().add(DEFAULT_SHIFT_DURATION, "hours");
    }

    /**
     * Returns true if the two activites are identical
     * @param activity
     * @param otherActivity
     */
    public static areIdenticalActivities(activity: ISubshiftEntity, otherActivity: ISubshiftEntity): boolean {
        let areIdentical: boolean = true;

        if (
            activity.id !== otherActivity.id ||
            activity.subshiftType !== otherActivity.subshiftType ||
            activity.state !== otherActivity.state ||
            activity.title !== otherActivity.title ||
            activity.theme !== otherActivity.theme ||
            activity.code !== otherActivity.code ||
            !DateUtils.areTimesIdentical(activity.startTime, otherActivity.startTime) ||
            !DateUtils.areTimesIdentical(activity.endTime, otherActivity.endTime)
        ) {
            areIdentical = false;
        }
        return areIdentical;
    }

    /**
     * Return true if the two shifts are identical. By default, consider subshifts, the isPublished flag, and shift state.
     * @param shift
     * @param otherShift
     * @param considerSubshifts
     * @param considerIsPublished
     * @param considerShiftState
     * @returns {boolean} true if the shifts are identical, false otherwise
     */
    public static areIdenticalShifts(
        shift: IShiftEntity,
        otherShift: IShiftEntity,
        considerSubshifts: boolean = true,
        considerIsPublished: boolean = true,
        considerShiftState: boolean = true
    ): boolean {
        if ((shift && !otherShift) || (otherShift && !shift)) {
            return false;
        }

        if (!shift && !otherShift) {
            return true;
        }

        let areIdentical: boolean = true;

        // normalize some values such that empty strings and null aren't considered different
        const shiftTitle = shift.title || "";
        const shiftNotes = shift.notes || "";
        const otherShiftTitle = otherShift.title || "";
        const otherShiftNotes = otherShift.notes || "";

        if (
            !shift.startTime.isSame(otherShift.startTime) ||
            !shift.endTime.isSame(otherShift.endTime) ||
            shift.shiftType !== otherShift.shiftType ||
            shiftTitle !== otherShiftTitle ||
            shift.memberId !== otherShift.memberId ||
            shiftNotes !== otherShiftNotes ||
            shift.theme !== otherShift.theme ||
            shift.timeOffReasonId !== otherShift.timeOffReasonId ||
            shift.teamId !== otherShift.teamId ||
            shift.tenantId !== otherShift.tenantId ||
            shift.shiftRequestId !== otherShift.shiftRequestId
        ) {
            return false;
        }

        if (considerIsPublished && shift.isPublished !== otherShift.isPublished) {
            return false;
        }

        if (considerShiftState && shift.state !== otherShift.state) {
            return false;
        }

        if (shift.breaks.length !== otherShift.breaks.length) {
            return false;
        }

        for (let i = 0; i < shift.breaks.length; i++) {
            if (!ShiftUtils.areIdenticalBreaks(shift.breaks[i], otherShift.breaks[i])) {
                return false;
            }
        }

        if (considerSubshifts) {
            if (shift.subshifts.length !== otherShift.subshifts.length) {
                return false;
            }

            for (let i = 0; i < shift.subshifts.length; i++) {
                if (!ShiftUtils.areIdenticalActivities(shift.subshifts[i], otherShift.subshifts[i])) {
                    return false;
                }
            }
        }

        if (shift.tagIds.length !== otherShift.tagIds.length) {
            return false;
        }

        for (let i = 0; i < shift.tagIds.length; i++) {
            let contains = false;
            for (let k = 0; k < otherShift.tagIds.length; k++) {
                if (shift.tagIds[i] === otherShift.tagIds[k]) {
                    contains = true;
                    break;
                }
            }
            if (!contains) {
                return false;
            }
        }

        return areIdentical;
    }

    /**
     * Return true if the two open shifts are identical. By default, consider subshifts, state, and the isPublished flag.
     * @param shift
     * @param otherShift
     * @param considerSubshifts
     * @param considerIsPublished
     * @param considerShiftState
     * @returns {boolean} true if the shifts are identical, false otherwise
     */
    public static areIdenticalOpenShifts(
        shift: IOpenShiftEntity,
        otherShift: IOpenShiftEntity,
        considerSubshifts: boolean = true,
        considerIsPublished: boolean = true,
        considerShiftState: boolean = true
    ): boolean {
        let areIdentical: boolean = true;
        areIdentical = ShiftUtils.areIdenticalShifts(
            shift as IShiftEntity,
            otherShift as IShiftEntity,
            considerSubshifts,
            considerIsPublished,
            considerShiftState
        );
        if (areIdentical) {
            if (shift.openSlots !== otherShift.openSlots) {
                return false;
            }

            if (shift.isCrossLocationOpenShift !== otherShift.isCrossLocationOpenShift) {
                return false;
            }
        }

        return areIdentical;
    }

    /**
     * Return Duration of unpaid breaks in minutes for the unique shift
     * @param {IUniqueShiftEntity} shift
     * @returns {number} Shift Breaks duration in minutes
     */
    public static getUnPaidShiftBreakDurationInUniqueShift(shift: IUniqueShiftEntity): number {
        let duration = 0;
        if (shift.breaks) {
            shift.breaks.forEach(shiftBreak => {
                if (shiftBreak.breakType === BreakTypes.Unpaid) {
                    duration += shiftBreak.duration;
                }
            });
        }
        return duration;
    }

    /**
     * Return Duration of unpaid breaks in minutes for the shift
     * @param {IShiftEntity} shift
     * @returns {number} Shift Breaks duration in minutes
     */
    public static getUnPaidShiftBreakDurationInShift(shift: IShiftEntity): number {
        let duration = 0;
        if (shift.breaks) {
            shift.breaks.forEach(shiftBreak => {
                if (shiftBreak.breakType === BreakTypes.Unpaid) {
                    duration += shiftBreak.duration;
                }
            });
        }
        return duration;
    }

    /**
     * Return the duration of unpaid activities in minutes for the shift
     * @param {IShiftEntity} shift
     * @returns {number} duration in minutes
     */
    public static getUnPaidActivityDurationInShift(shift: IShiftEntity): number {
        let duration = 0;
        for (let i = 0; i < shift.subshifts.length; i++) {
            const activity = shift.subshifts[i];
            if (activity.subshiftType === SubshiftTypes.UnpaidBreak) {
                duration += ShiftUtils.getDurationInMinutes(activity);
            }
        }
        return duration;
    }

    /**
     * Return the total duration of unpaid activities and breaks in hours for a shift
     * @param {IShiftEntity} shift
     * @returns {number} duration in hours
     */
    public static getUnpaidHoursForShift(shift: IShiftEntity): number {
        return ShiftUtils.getUnpaidMinutesForShift(shift) / 60;
    }

    /**
     * Return the total duration of unpaid activities and breaks in minutes for a shift
     * @param {IShiftEntity} shift
     * @returns {number} duration in minutes
     */
    public static getUnpaidMinutesForShift(shift: IShiftEntity): number {
        const breakDuration = ShiftUtils.getUnPaidShiftBreakDurationInShift(shift);
        const unpaidActivityDuration = ShiftUtils.getUnPaidActivityDurationInShift(shift);
        return breakDuration + unpaidActivityDuration;
    }

    /**
     * Get paid duration of the shift in hours
     * @param {IShiftEntity} shift
     * @returns {number} Shift paid duration
     */
    public static getPaidHoursForShift(shift: IShiftEntity) {
        // for non working shifts or deleted shifts, count as 0 paid hours
        if (!ShiftUtils.isWorkingShift(shift) || ShiftUtils.isDeletedShift(shift)) {
            return 0;
        }
        const unpaidHours = ShiftUtils.getUnpaidHoursForShift(shift);
        const duration = ShiftUtils.getDurationInHours(shift);

        return duration - unpaidHours;
    }

    /**
     * Get paid duration of the shift in minutes
     * @param {IShiftEntity} shift
     * @returns {number} Shift paid duration
     */
    public static getPaidMinutesForShift(shift: IShiftEntity): number {
        if (!ShiftUtils.isWorkingShift(shift)) {
            return 0;
        }
        const unpaidDurationInMinutes = ShiftUtils.getUnpaidMinutesForShift(shift);
        const duration = ShiftUtils.getDurationInMinutes(shift);

        return duration - unpaidDurationInMinutes;
    }

    /**
     * Returns true if the subshift is a paid activity
     */
    public static subshiftIsPaidActivity(subshift: ISubshiftEntity): boolean {
        return subshift && subshift.subshiftType !== SubshiftTypes.UnpaidBreak;
    }

    /**
     * Returns true if the shift has paid activities
     */
    public static shiftHasPaidActivities(shift: IShiftEntity): boolean {
        let hasPaidActivities: boolean = false;
        if (shift && shift.subshifts) {
            for (let i = 0; i < shift.subshifts.length; i++) {
                if (ShiftUtils.subshiftIsPaidActivity(shift.subshifts[i])) {
                    hasPaidActivities = true;
                    break;
                }
            }
        }
        return hasPaidActivities;
    }

    /**
     * Convert Shift data fetched from the Service to display version for usage with the Scheduler UX
     * Service stores times in actual time, but the Scheduler UX represents times as display versions for the team timezone.
     * @param {Array} shiftsFromDataService
     * @param {string} timezone - timezone olson code
     * @returns {Array} Shifts with display versions of times
     */
    public static convertShiftsFromDataServiceToDisplayTime(
        shiftsFromDataService: Array<IShiftEntity>,
        timezone: string
    ): Array<IShiftEntity> {
        let shiftsInDisplayTime = new Array<IShiftEntity>();
        if (shiftsFromDataService) {
            for (let i = 0; i < shiftsFromDataService.length; i++) {
                let shift = shiftsFromDataService[i];
                shift.startTime = shift.startTime
                    ? moment(DateUtils.convertTimezoneDateTimeToDisplayTime(shift.startTime.toDate(), timezone))
                    : null;
                shift.endTime = shift.endTime
                    ? moment(DateUtils.convertTimezoneDateTimeToDisplayTime(shift.endTime.toDate(), timezone))
                    : null;
                if (shift.subshifts) {
                    for (let i = 0; i < shift.subshifts.length; i++) {
                        let subshift = shift.subshifts[i];
                        subshift.startTime = subshift.startTime
                            ? moment(DateUtils.convertTimezoneDateTimeToDisplayTime(subshift.startTime.toDate(), timezone))
                            : null;
                        subshift.endTime = subshift.endTime
                            ? moment(DateUtils.convertTimezoneDateTimeToDisplayTime(subshift.endTime.toDate(), timezone))
                            : null;
                    }
                }
                shiftsInDisplayTime.push(shift);
            }
        }
        return shiftsInDisplayTime;
    }
    /**
     * Copy fields unrelated to the member over from one shift to another.
     * Note: if should copy date is false, then the startTime of fromShift needs to be non null
     * @param fromShift the shift to copy data from
     * @param toShift the shift to copy data to
     * @param includeSubshifts true if we want to copy subshifts
     */
    public static copyNonMemberFieldsFromShiftToShift(
        fromShift: IShiftEntity,
        toShift: IShiftEntity,
        shouldCopyDate?: boolean,
        includeSubshifts: boolean = true
    ) {
        toShift.theme = fromShift.theme;
        if (shouldCopyDate) {
            toShift.startTime = fromShift.startTime.clone();
        } else {
            toShift.startTime.hours(fromShift.startTime.hours());
            toShift.startTime.minutes(fromShift.startTime.minutes());
        }
        toShift.endTime = toShift.startTime.clone();
        toShift.endTime.add(this.getDurationInHours(fromShift), "hours");
        toShift.title = fromShift.title;
        toShift.notes = fromShift.notes;
        toShift.shiftType = fromShift.shiftType;
        toShift.tagIds = fromShift.tagIds ? fromShift.tagIds.concat([]) : toShift.tagIds;
        this.copyBreaksFromShiftToShift(fromShift, toShift);

        if (includeSubshifts) {
            this.copyActivitiesFromShiftToShift(fromShift, toShift);
        }
    }

    /**
     * Clones breaks from fromShift and assigns them to toShift
     * @param fromShift shift to copy breaks from
     * @param toShift shift to copy breaks to
     */
    public static copyBreaksFromShiftToShift(fromShift: IShiftEntity, toShift: IShiftEntity) {
        toShift.breaks = fromShift.breaks.map(BreakEntity.clone);
    }

    /**
     * Clones activities from fromShift and assigns them to toShift
     * @param fromShift shift to copy activities from
     * @param toShift shift to copy activities to
     */
    public static copyActivitiesFromShiftToShift(fromShift: IShiftEntity, toShift: IShiftEntity) {
        toShift.subshifts = fromShift.subshifts.map(SubshiftEntity.clone);
    }

    /**
     * If the provided activity is part of the given shift, remove that activity from the shift.
     * @param shift
     * @param activity
     */
    public static removeActivityFromShift(shift: IShiftEntity, activity: ISubshiftEntity) {
        if (shift && activity) {
            const removalId = activity.id;
            shift.subshifts = shift.subshifts.filter(activity => {
                return activity.id != removalId;
            });
        }
    }

    /**
     * Returns an array of TimeRanges representing the periods in the shift that coincide with none of the shift's activities. Optionally accpets a
     * list of activities to ignore.
     * @param shift
     * @param ignoredActivities
     * @param considerMinimumActivitySeparation
     */
    public static getOpenRangesInShift(
        shift: IShiftEntity,
        ignoredActivities?: ISubshiftEntity[],
        considerMinimumActivitySeparation?: boolean
    ): TimeRange[] {
        let openRanges: TimeRange[] = [];
        const shiftStartTime = shift.startTime.clone();
        const shiftEndTime = shift.endTime.clone();
        let currRangeStart = shiftStartTime.clone();
        let consideredActivities = shift.subshifts.slice().sort(ShiftUtils.shiftComparator);
        if (ignoredActivities) {
            consideredActivities = consideredActivities.filter(activity => {
                return !ignoredActivities.includes(activity);
            });
        }

        while (currRangeStart.isBefore(shiftEndTime)) {
            if (consideredActivities.length) {
                let consideredActivity: ISubshiftEntity = consideredActivities[0];
                const consideredStart = consideredActivity.startTime.clone();
                let consideredEnd = consideredActivity.endTime.clone();
                // We may want to limit the open ranges to respect the ACTIVITY_MINIMUM_SEPARATION_IN_MINUTES, so we check if the considered
                // activity has a duration shorter than the minimum separation and adjust the range accordingly
                if (considerMinimumActivitySeparation) {
                    const consideredDuration = consideredEnd.diff(consideredStart, "minutes");
                    if (consideredDuration < ACTIVITY_MINIMUM_SEPARATION_IN_MINUTES) {
                        consideredEnd = consideredStart.clone().add(ACTIVITY_MINIMUM_SEPARATION_IN_MINUTES, "minutes");
                    }
                }
                if (consideredStart.isSame(currRangeStart)) {
                    consideredActivities.shift();
                    currRangeStart = consideredEnd.clone();
                } else {
                    openRanges.push({ startTime: currRangeStart.clone(), endTime: consideredStart });
                    currRangeStart = consideredStart.clone();
                }
            } else {
                openRanges.push({ startTime: currRangeStart.clone(), endTime: shiftEndTime });
                currRangeStart = shiftEndTime.clone();
            }
        }

        return openRanges;
    }

    /**
     * Returns an array of TimeRanges representing the periods in the shift that coincide with the shift's activities. Optionally accepts a
     * list of activities to ignore.
     * @param shift
     * @param ignoredActivities
     */
    public static getClosedRangesInShift(shift: IShiftEntity, ignoredActivities?: ISubshiftEntity[]): TimeRange[] {
        let closedRanges: TimeRange[] = [];
        let consideredActivities = shift.subshifts.slice().sort(ShiftUtils.shiftComparator);
        if (ignoredActivities) {
            consideredActivities = consideredActivities.filter(activity => {
                return !ignoredActivities.includes(activity);
            });
        }

        consideredActivities.map(activity => {
            closedRanges.push({ startTime: activity.startTime, endTime: activity.endTime });
        });

        return closedRanges;
    }

    /**
     * Returns an array of TimeRanges representing valid times for the endTime of the given activity in the shift. This is the range of times after
     * the start time but before the end of the shift or start of the next activity. Accounts for all existing
     * activities in the shift, with the option to ignore a subset of them in ignoredActivities.
     * @param shift
     * @param activity
     * @param ignoredActivities
     */
    public static getOpenRangesInShiftForActivityEnd(
        shift: IShiftEntity,
        activity: ISubshiftEntity,
        ignoredActivities?: ISubshiftEntity[]
    ): TimeRange[] {
        const openRanges: TimeRange[] = ShiftUtils.getOpenRangesInShift(shift, ignoredActivities ? ignoredActivities : [activity]);
        let openRangesForActivityEnd: TimeRange[] = [];

        for (let i = 0; i < openRanges.length; i++) {
            let range = openRanges[i];
            // special case to account for the ignored activity or empty shifts
            if (range.startTime.isBefore(activity.startTime) && range.endTime.isSameOrAfter(activity.endTime)) {
                range.startTime = activity.startTime.clone();
            }
            if (range.startTime.isSame(activity.startTime)) {
                range.startTime.add(DEFAULT_ACTIVITY_DURATION_IN_MINUTES, "minutes");
                openRangesForActivityEnd.push(range);
                break;
            }
        }

        return openRangesForActivityEnd;
    }

    /**
     * Returns an array of TimeRanges representing valid times for the startTime of the given activity in the shift, such that the activity
     * could be modified to start at the time while keeping its duration constant without intersecting with another activity. Accounts for all existing
     * activities in the shift, with the option to ignore a subset of them in ignoredActivities.
     * @param shift
     * @param activity
     * @param ignoredActivities
     */
    public static getOpenRangesInShiftForActivityStart(
        shift: IShiftEntity,
        activity: ISubshiftEntity,
        ignoredActivities?: ISubshiftEntity[]
    ): TimeRange[] {
        let openRanges: TimeRange[] = [];
        // Retrieve all the open ranges in the shift
        openRanges = ShiftUtils.getOpenRangesInShift(
            shift,
            ignoredActivities ? ignoredActivities : [activity],
            true /* considerMinimumActivitySeparation */
        );

        const activityDuration = DateUtils.getDifferenceInHoursFromMoments(activity.startTime, activity.endTime);

        // The activity has a duration, so we trim the open ranges to account for the duration of the shift and eliminate ranges that are too short
        openRanges = openRanges.filter((range: TimeRange) => {
            return DateUtils.getDifferenceInHoursFromMoments(range.startTime, range.endTime) >= activityDuration;
        });
        openRanges.map((range: TimeRange) => {
            range.endTime.subtract(activityDuration, "hours");
        });

        return openRanges;
    }

    /**
     * Returns an array of TimeRanges representing invalid times for the startTime of the given activity in the shift. Accounts for all existing
     * activities in the shift, with the option to ignore a subset of them in ignoredActivities.
     * @param shift
     * @param activity
     * @param ignoredActivities
     */
    public static getClosedRangesInShiftForActivityStart(
        shift: IShiftEntity,
        activity: ISubshiftEntity,
        ignoredActivities?: ISubshiftEntity[],
        openRangesForActivity?: TimeRange[]
    ): TimeRange[] {
        let closedRanges: TimeRange[] = [];
        let openRanges = openRangesForActivity
            ? openRangesForActivity.slice()
            : ShiftUtils.getOpenRangesInShift(shift, ignoredActivities || [activity]);

        let currRangeStart = shift.startTime.clone();
        while (currRangeStart.isBefore(shift.endTime)) {
            if (openRanges.length) {
                let consideredRange: TimeRange = openRanges[0];
                const consideredStart = consideredRange.startTime.clone();
                const consideredEnd = consideredRange.endTime.clone();
                if (consideredStart.isSame(currRangeStart)) {
                    openRanges.shift();
                    currRangeStart = consideredEnd.clone();
                } else {
                    closedRanges.push({ startTime: currRangeStart.clone(), endTime: consideredStart });
                    currRangeStart = consideredStart.clone();
                }
            } else {
                closedRanges.push({ startTime: currRangeStart.clone(), endTime: shift.endTime.clone() });
                currRangeStart = shift.endTime.clone();
            }
        }

        return closedRanges;
    }

    /**
     * Get className that represents theme of shift when rendering
     */
    public static getShiftThemeClass(shift: IShiftEntity) {
        if (ShiftUtils.isTimeOffRequestEvent(shift)) {
            return ThemeUtils.getTimeOffRequestThemeClass();
        } else {
            return shift ? shift.theme : "";
        }
    }

    /**
     * Returns true if the shift has unshared edits.
     * @param shift
     */
    public static shiftHasUnsharedEdits(shift: IBaseShiftEntity) {
        let hasUnsharedEdits = true;

        // if the isPublished flag is true, then this shift is shared and no competing draft version exists
        if (shift.isPublished) {
            hasUnsharedEdits = false;
            // Otherwise, we compare the properties of the current draft state and the last published state
        } else {
            const sharedChanges: IShiftEntity = shift.sharedChanges;
            if (!sharedChanges) {
                hasUnsharedEdits = true;
            } else {
                if (ShiftUtils.isOpenShift(shift)) {
                    hasUnsharedEdits = !ShiftUtils.areIdenticalOpenShifts(
                        shift as IOpenShiftEntity,
                        sharedChanges as IOpenShiftEntity,
                        true /* considerSubshifts */,
                        false /* considerIsPublished */,
                        true /* considerShiftState */
                    );
                } else {
                    hasUnsharedEdits = !ShiftUtils.areIdenticalShifts(
                        shift as IShiftEntity,
                        sharedChanges as IShiftEntity,
                        true /* considerSubshifts */,
                        false /* considerIsPublished */,
                        true /* considerShiftState */
                    );
                }
            }
        }

        return hasUnsharedEdits;
    }

    /**
     * Returns true if a shared version of the shift exists
     * @param shift
     */
    public static hasSharedChanges(shift: IShiftEntity): boolean {
        return !!ShiftUtils.getSharedChanges(shift);
    }

    /**
     * Returns true if the shift is deleted but still has shared changes. This is the case for shifts that are shared and then deleted,
     * but the deletion is yet to be shared.
     * @param shift
     */
    public static isUnsharedDeletedShift(shift: IBaseShiftEntity): boolean {
        return !!shift && ShiftUtils.isDeletedShift(shift) && ShiftUtils.hasSharedChanges(shift);
    }

    /**
     * Returns true if the shift has time differences between its draft and shared states. Returns false if the shift is null
     * or has no shared changes
     * @param shift
     */
    public static sharedAndDraftTimesDiffer(shift: IBaseShiftEntity): boolean {
        const sharedChanges: IBaseShiftEntity = ShiftUtils.getSharedChanges(shift);
        return Boolean(
            shift && sharedChanges && (!sharedChanges.startTime.isSame(shift.startTime) || !sharedChanges.endTime.isSame(shift.endTime))
        );
    }

    /*
     * Returns the last shared version of the shift, or null if no shared version of the shift exists.
     * @param shift
     */
    public static getSharedChanges(shift: IBaseShiftEntity): IBaseShiftEntity {
        let sharedChanges: IBaseShiftEntity = null;
        if (!shift) {
            return sharedChanges;
        }
        const isShiftEntity: boolean = !!(shift instanceof ShiftEntity);
        // If the shift is shared, the current shift model is equivalent to the last sharedChanges
        if (shift.isPublished) {
            sharedChanges = isShiftEntity ? ShiftEntity.clone(shift as IShiftEntity) : OpenShiftEntity.clone(shift as IOpenShiftEntity);
        } else if (isShiftEntity && (shift as IShiftEntity).sharedChanges) {
            // If the shift is not shared and has a sharedChanges field, this field is equivalent to the last sharedChanges
            sharedChanges = ShiftEntity.clone((shift as IShiftEntity).sharedChanges);
        } else if (!isShiftEntity && (shift as IOpenShiftEntity).sharedChanges) {
            sharedChanges = OpenShiftEntity.clone((shift as IOpenShiftEntity).sharedChanges);
        }
        // Otherwise no shared version of the shift exists, so we will return null

        return sharedChanges;
    }

    /**
     * Creates a Shift object from UniqueShift object
     * @param uniqueShift
     * @returns IShiftEntity
     */
    public static getShiftFromUniqueShift(uniqueShift: IUniqueShiftEntity): IShiftEntity {
        let shift = ShiftEntity.createEmptyObject("", "", "");
        shift.theme = uniqueShift.theme;
        shift.title = uniqueShift.title;
        shift.breaks = uniqueShift.breaks ? uniqueShift.breaks.map((currentBreak: IBreakEntity) => BreakEntity.clone(currentBreak)) : null;
        shift.startTime = uniqueShift.startTime.clone();
        shift.endTime = uniqueShift.endTime.clone();
        shift.shiftType = ShiftTypes.Working;
        return shift;
    }

    /**
     * Creates a Shift object from UniqueShift object
     * @param uniqueShift
     * @returns IShiftEntity
     */
    public static getOpenShiftFromUniqueShift(
        uniqueShift: IUniqueShiftEntity,
        tenantId: string,
        teamId: string,
        tagIds: string[]
    ): IOpenShiftEntity {
        return new OpenShiftEntity({
            id: null,
            eTag: null,
            tenantId: tenantId,
            teamId: teamId,
            memberId: null,
            shiftType: ShiftTypes.Working,
            startTime: uniqueShift.startTime.clone(),
            endTime: uniqueShift.endTime.clone(),
            state: ShiftStates.Active,
            title: uniqueShift.title,
            notes: null,
            theme: uniqueShift.theme,
            tagIds: tagIds,
            breaks: uniqueShift.breaks ? uniqueShift.breaks.map((currentBreak: IBreakEntity) => BreakEntity.clone(currentBreak)) : null,
            shiftRequestId: null,
            timeOffReasonId: null,
            isPublished: false,
            sharedChanges: null,
            isCrossLocationOpenShift: false,
            openSlots: null
        });
    }

    /**
     * Helper method for cloning a shift list.
     * @param shifts list of shifts to clone
     * @param generateNewIds - optional parameter to generate new ids
     */
    public static cloneShiftList(shifts: Array<IBaseShiftEntity>, generateNewIds: boolean = false): Array<IBaseShiftEntity> {
        let clonedShifts = new Array<IBaseShiftEntity>();

        for (let i = 0; i < shifts.length; i++) {
            const isOpenShift = ShiftUtils.isOpenShift(shifts[i]);
            let clonedShift = isOpenShift ? OpenShiftEntity.clone(shifts[i] as IOpenShiftEntity) : ShiftEntity.clone(shifts[i]);
            if (generateNewIds) {
                clonedShift.id = isOpenShift ? OpenShiftEntity.generateNewOpenShiftId() : ShiftEntity.generateNewShiftId();
            }
            clonedShifts.push(clonedShift);
        }
        return clonedShifts;
    }

    /**
     * Returns true if the provided shift is an open shift.
     * @param shift
     */
    public static isOpenShift(shift: IBaseShiftEntity) {
        return shift instanceof OpenShiftEntity;
    }

    /**
     * @param shift Base shift entity
     * @returns Returns true if the provided shift is a cross location shift (open or regular).
     */
    public static isCrossLocationShift(shift: IBaseShiftEntity): boolean {
        // TODO: Find alternative to casting
        const openShift: IOpenShiftEntity = shift as IOpenShiftEntity;
        const regularShift: IShiftEntity = shift as IShiftEntity;
        return openShift.isCrossLocationOpenShift || regularShift.isCrossLocationShift;
    }

    /**
     * Gets slot bar props for open shift rendering
     * @param shift
     * @param scheduleCellRenderSize
     * @param slotsOverrideString
     */
    public static getSlotBarProps(
        shift: IBaseShiftEntity,
        scheduleCellRenderSize: ScheduleCellRenderSize,
        slotsOverrideString?: string
    ): SlotBarProps {
        let slotBarProps: SlotBarProps = null;
        if (shift) {
            const openShift: IOpenShiftEntity = shift as IOpenShiftEntity;
            slotBarProps = {
                theme: openShift.theme,
                scheduleCellRenderSize: scheduleCellRenderSize,
                openSlots: openShift.openSlots,
                slotsOverrideString: slotsOverrideString,
                isCrossLocationShift: ShiftUtils.isCrossLocationShift(shift)
            };
        }

        return slotBarProps;
    }

    /**
     * Returns Cross location team name for the shift for non-admin user when viewing remote shift
     * @param shift Shift to check
     * @param isAdmin Admin or not
     * @returns string | undefined
     */
    public static getCrossLocationTeamId(shift: IBaseShiftEntity, isAdmin: boolean): string | undefined {
        const regularShift: IShiftEntity = shift as IShiftEntity;
        const currentTeamId = TeamStore().teamId;
        // We need to show Team Name in the Shift Cell only when FLW viewing the remote shift, in this case senderTeamId and teamId will be same
        if (
            regularShift &&
            regularShift.isCrossLocationShift &&
            !isAdmin &&
            regularShift.senderTeamId == currentTeamId &&
            regularShift.teamId != currentTeamId
        ) {
            return regularShift.teamId;
        }
        return undefined;
    }

    /**
     * Gets the number of open slots the shift has
     * @param shift
     */
    public static getOpenShiftSlotsFromShift(shift: IBaseShiftEntity): number {
        let openSlots = 0;
        if (shift) {
            const openShift: IOpenShiftEntity = shift as IOpenShiftEntity;
            openSlots = openShift.openSlots;
        }

        return openSlots;
    }

    public static calculateUniqueShiftTitleAriaLabel(uniqueShift: IUniqueShiftEntity) {
        if (!uniqueShift) {
            return "";
        }

        const strings: Map<string, string> = StringsStore().registeredStringModules.get("common").strings;
        const shiftTimeRangeLabel: string = uniqueShift.title
            ? uniqueShift.title
            : DateTimeFormatter.getEventTimeRangeAsString(uniqueShift.startTime, uniqueShift.endTime);
        const shiftThemeLabel: string = uniqueShift.theme;

        const ariaLabelFormat: string = strings.get("ariaLabelMultipleLines");
        const ariaLabel = ariaLabelFormat.format(shiftTimeRangeLabel, shiftThemeLabel);
        return ariaLabel;
    }

    /*
     * Calculate the Aria accessibility label for a shift event's (shift, time off, time off request) title
     * @param shift
     * @param addGroupName True to include the group name in the aria label
     * @param isReadOnly Adds the text regarding action on ar
     */
    public static calculateShiftTitleAriaLabel(shift: IShiftEntity, addGroupName: boolean = false, isReadOnly: boolean = false): string {
        /*
            note: if the aria label ever needs to return something regarding if a shift is newly edited or not,
            then the places in the code that cache this label, may need to be updated to reflect the change.
        */
        let ariaLabel: string = "";
        if (shift) {
            const strings: Map<string, string> = StringsStore().registeredStringModules.get("shifts").strings;
            const commonStrings = StringsStore().registeredStringModules.get("common").strings;

            const isWorkingShift: boolean = ShiftUtils.isWorkingShift(shift);
            const isTimeOffShift: boolean = ShiftUtils.isTimeOffEvent(shift);
            const isOpenShift: boolean = ShiftUtils.isOpenShift(shift);
            const hasConflict: boolean = ScheduleGridUtils.hasConflicts(shift);
            const isCrossLocationShift: boolean = ShiftUtils.isCrossLocationShift(shift);

            // Shift type
            let shiftTypeLabel: string;
            if (isWorkingShift) {
                if (isOpenShift && isCrossLocationShift) {
                    shiftTypeLabel = strings.get("shiftTypeOpenShiftAcrossLocationsAriaLabel");
                } else if (isOpenShift) {
                    shiftTypeLabel = strings.get("shiftTypeOpenShiftAriaLabel");
                } else if (hasConflict) {
                    shiftTypeLabel = strings.get("conflictingShiftTypeShiftAriaLabel");
                } else {
                    shiftTypeLabel = strings.get("shiftTypeShiftAriaLabel");
                }
            } else {
                // For Time off types, we'll also include the time off reason
                const timeOffReason: ITimeOffReasonEntity = TimeOffReasonsStore().timeOffReasons.get(shift.timeOffReasonId);
                const timeOffReasonName: string = timeOffReason
                    ? strings.get("shiftTypeTimeOffAriaLabel") + ", " + timeOffReason.name
                    : strings.get("timeOffReasonFallbackName");
                if (isTimeOffShift) {
                    shiftTypeLabel = hasConflict ? strings.get("conflictingShiftTypeTimeOffAriaLabel") : timeOffReasonName;
                } else {
                    shiftTypeLabel = strings.get("shiftTypeTimeOffRequestAriaLabel") + " " + timeOffReasonName;
                }
            }

            // Add the shift label when available. Otherwise, add the time range
            // For time range description, use long format so that the dates info is also read out in addition to times.
            const shiftTimeRangeLabel: string =
                this.getShiftDayOfWeek(shift.startTime) + // Day of the week
                ", " +
                DateTimeFormatter.fastGetEventDateTimeRangeAsString(shift.startTime, shift.endTime, true /* useLongFormat */) + // day, month, time range
                " " +
                (shift.title ?? "");

            const shiftThemeLabel: string = shift.theme;
            const shiftNotesLabel: string = shift.notes ? shift.notes : "";

            const ariaLabelFormat: string = strings.get("shiftTitleAriaLabelFormat");
            ariaLabel = ariaLabelFormat.format(shiftTypeLabel, shiftTimeRangeLabel, shiftThemeLabel, shiftNotesLabel);

            // show group name
            if (addGroupName) {
                const tagId: string = this.getTagIdFromShift(shift);
                const tag = TagStore().tags.get(tagId);
                const tagName: string = tag && tag.name;
                if (tagName) {
                    ariaLabel = commonStrings.get("ariaLabelMultipleLines").format(ariaLabel, tagName);
                }
            }

            // shift is actionable
            if (!isReadOnly) {
                ariaLabel = commonStrings.get("ariaLabelMultipleLines").format(ariaLabel, strings.get("shiftActionableTitleAriaLabel"));
            }
        }
        return ariaLabel;
    }

    /**
     * Get the Aria accessibility label for unshared changes indicator for shift events
     */
    public static getUnsharedChangesShiftIndicatorAriaLabel(): string {
        return StringsStore().registeredStringModules.get("shifts").strings.get("unsharedShiftIndicatorAriaLabel");
    }

    /**
     * Get the default activity title
     */
    public static getDefaultActivityTitle(): string {
        return StringsStore().registeredStringModules.get("common").strings.get("defaultActivityTitle");
    }

    /**
     * Check the shift list for any active working shifts that occur within the start date and the end date inclusive.
     */
    public static areActivWorkingShiftsInDateRange(shifts: IShiftEntity[], startDate: Moment, endDate: Moment): boolean {
        return shifts.some((shift: IShiftEntity) => {
            return (
                shift.state == ShiftStates.Active &&
                ShiftUtils.isWorkingShift(shift) &&
                shift.startTime.isBetween(startDate, endDate, "day", "[]")
            );
        });
    }

    /**
     * Returns true if the provided shiftList contains a shift with the id of the provided shift;
     * @param shiftList
     * @param shift
     */
    public static isShiftInList(shiftList: IBaseShiftEntity[], shift: IBaseShiftEntity): boolean {
        if (!shift || !shiftList) {
            return false;
        }

        return !!shiftList.find(candidateShift => {
            return shift.id === candidateShift.id;
        });
    }

    /**
     * Returns the index of the provided shift in the provided shift list, or -1 if it is not in the list
     * @param shiftList
     * @param shift
     */
    public static getShiftIndexInList(shiftList: IBaseShiftEntity[], shift: IBaseShiftEntity): number {
        if (!shift || !shiftList) {
            return -1;
        }

        return shiftList.findIndex(candidateShift => {
            return shift.id === candidateShift.id;
        });
    }

    /**
     * Splits the provided shift list and returns it as a list of open shifts and a list of assigned shifts.
     * @param shiftList
     */
    public static splitShiftListIntoOpenAndAssigned(shiftList: IBaseShiftEntity[]): {
        assignedShifts: IShiftEntity[];
        openShifts: IOpenShiftEntity[];
    } {
        let assignedShifts: IShiftEntity[] = [];
        let openShifts: IOpenShiftEntity[] = [];

        if (shiftList) {
            for (let i = 0; i < shiftList.length; i++) {
                const candidateShift = shiftList[i];
                if (ShiftUtils.isOpenShift(candidateShift)) {
                    openShifts.push(candidateShift as IOpenShiftEntity);
                } else {
                    assignedShifts.push(candidateShift);
                }
            }
        }

        return { assignedShifts, openShifts };
    }

    /**
     * Returns true if the given shift is displayable for schedule view, is not a time off request, and starts in the given range.
     * @param shift
     * @param rangeStart
     * @param rangeEnd
     */
    public static isDisplayableNonTimeoffRequestShiftInRange(shift: IBaseShiftEntity, rangeStart: Moment, rangeEnd: Moment): boolean {
        const isShiftDisplayableForScheduleView = !ShiftUtils.isOpenShift(shift)
            ? ShiftUtils.isShiftDisplayableForScheduleView(shift)
            : ShiftUtils.isOpenShiftDisplayableForScheduleView(shift as IOpenShiftEntity);
        return (
            !ShiftUtils.isTimeOffRequestEvent(shift) &&
            isShiftDisplayableForScheduleView &&
            DateUtils.isStartTimeInView(shift.startTime, rangeStart, rangeEnd)
        );
    }

    /**
     * Returns true if the given shift is displayable for schedule view, is not a time off request, starts in the given range, and has unshared edits
     * @param shift
     * @param rangeStart
     * @param rangeEnd
     */
    public static isDisplayableNonTimeoffRequestShiftInRangeWithUnsharedEdits(
        shift: IBaseShiftEntity,
        rangeStart: Moment,
        rangeEnd: Moment
    ): boolean {
        return (
            ShiftUtils.isDisplayableNonTimeoffRequestShiftInRange(shift, rangeStart, rangeEnd) && ShiftUtils.shiftHasUnsharedEdits(shift)
        );
    }

    /**
     * Returns true if the given shift is displayable for schedule view, is not a time off request, starts in the given range, and has shared changes
     * @param shift
     * @param rangeStart
     * @param rangeEnd
     */
    public static isDisplayableNonTimeoffRequestShiftInRangeWithSharedChanges(
        shift: IBaseShiftEntity,
        rangeStart: Moment,
        rangeEnd: Moment
    ): boolean {
        return ShiftUtils.isDisplayableNonTimeoffRequestShiftInRange(shift, rangeStart, rangeEnd) && ShiftUtils.hasSharedChanges(shift);
    }

    /**
     * Check if the start time of a shift overlaps with a day.  This is the check we use for our pickers.
     * @param shift
     * @param day
     */
    public static doesShiftOccurOnDay(shift: IBaseShiftEntity, day: Moment): boolean {
        let occursOnDay: boolean = false;
        if (day && shift && shift.startTime) {
            occursOnDay = day.isSame(shift.startTime, "day");
        }
        return occursOnDay;
    }

    /**
     * Return true if the shift has subshifts
     * @param shift
     */
    public static hasActivities(shift: IBaseShiftEntity): boolean {
        return shift && shift.subshifts && shift.subshifts.length > 0;
    }

    /**
     * Returns true if the provided shift is multi-day, i.e. has a duration longer than 24 hours
     * @param shift
     */
    public static isMultiDayShift(shift: IBaseShiftEntity): boolean {
        return !!shift && DateUtils.getDifferenceInHoursFromMoments(shift.startTime, shift.endTime) > 24;
    }

    /**
     * Returns a validated version of the given activity title
     * @param title
     */
    public static getValidatedActivityTitle(title: string): string {
        let validatedTitle: string = "";
        if (title) {
            validatedTitle = title.substr(0, MAX_ACTIVITY_TITLE_LENGTH);
        }

        return validatedTitle;
    }

    /**
     * Returns if a shift/note/openshift has been modified (via a service request)
     * When an item is modified its etag should change. Except the service doesn't alway change the etag when a shift is published/unpublished.
     * @param originalItem Original Shift/OpenShift/Note
     * @param updatedItem Updated Shift/OpenShift/Note
     */
    public static hasItemBeenModifiedByService(originalItem: IBaseShiftEntity | INoteEntity, updatedItem: IBaseShiftEntity | INoteEntity) {
        return (
            originalItem.id !== updatedItem.id ||
            originalItem.eTag !== updatedItem.eTag ||
            originalItem.isPublished !== updatedItem.isPublished
        );
    }

    /**
     * Returns true if the given shift belongs to the current user
     * @param shift
     */
    public static isCurrentUserShift(shift: IBaseShiftEntity) {
        const currentUserMemberId = MemberUtils.getCurrentUserMemberId();
        return shift && shift.memberId && shift.memberId === currentUserMemberId;
    }

    /**
     * Returns the list of members that are selected from the given list
     * @param allMembers Given list of members to be filtered
     * @param filteredMembers List of members selected in the filter
     */
    public static matchFilterMembers(allMembers: IMemberEntity[], filteredMembers: IMemberEntity[]): IMemberEntity[] {
        let matchedMembers = [];

        for (let i = 0; i < allMembers.length; i++) {
            if (filteredMembers.some(filteredMember => filteredMember.id === allMembers[i].id)) {
                matchedMembers.push(allMembers[i]);
            }
        }

        return matchedMembers;
    }

    /**
     * Given a list of all tags, selected members and selected tags in the filter,
     * this function returns the list of distinct tags that need to be displayed after applying the filter.
     * The return list will contain all the selected tags and the tags of which the selected members are part of.
     * For example,
     *      allTags: A B C D E
     *      filteredMembers: 1 2 (1 is part of A, B)(2 is part of B, C)
     *      filteredTags: B, E
     *      return list will be: A, B, C, E as all of these need to be displayed in the schedule.
     * @param tags
     * @param member
     */
    public static getTagsForFilter(
        allTags: ITagEntity[],
        filteredMembers: IMemberEntity[],
        filteredTags: ITagEntity[],
        shifts: IShiftEntity[]
    ): ITagEntity[] {
        // This map is for maintaining the set of distinct tags indexed by their tag id.
        let tagMap = new Map<string, ITagEntity>();
        filteredTags.forEach(tag => tagMap.set(tag.id, tag));

        for (let i = 0; i < filteredMembers.length; i++) {
            const memberTags = TagUtils.getTagsForMember(allTags, filteredMembers[i], shifts);
            memberTags.forEach(memberTag => {
                tagMap.set(memberTag.id, memberTag);
            });
        }

        let tagArray: ITagEntity[] = [];
        tagMap.forEach(value => {
            tagArray.push(value);
        });

        return tagArray;
    }

    /**
     * Returns true if a shift is matched for the conflict filer, so that it is shown in the schedule, else returns false
     * @param filteredTags - list of selected groups in the filter
     * @param filteredMembers - list of selected members in the filter
     * @param selectedShiftFilters - list of selected shift filters
     * @param shift - shift for which we are checking if the filter is matching
     */
    public static isDisplayableShiftForConflictFilter(
        filteredTags: Array<ITagEntity>,
        filteredMembers: Array<IMemberEntity>,
        shift: IShiftEntity
    ): boolean {
        const currentMemberId = shift && shift.memberId;
        const isFilteredMembersEmpty = !filteredMembers || filteredMembers.length === 0;
        const isFilteredTagsEmpty = !filteredTags || filteredTags.length === 0;
        const tagId = ShiftUtils.getTagIdFromShift(shift);
        if (isFilteredMembersEmpty && isFilteredTagsEmpty) {
            return true;
        } else {
            // If the conflicting shifts belongs to one of the selected groups, then show the shift
            if (!isFilteredTagsEmpty && filteredTags.find(tag => tag.id === tagId)) {
                return true;
            }

            // if the shifts is timeOff shifts, it may not have tag data, hence check if the member is part of tag
            if (
                shift.shiftType === ShiftTypes.Absence &&
                !isFilteredTagsEmpty &&
                filteredTags.find(tag => tag.memberIds.some(memberId => memberId === currentMemberId))
            ) {
                return true;
            }

            // If the conflicting shift belongs to one of the seleced members, then show the shift
            if (!isFilteredMembersEmpty && filteredMembers.find(member => member.id === shift.memberId)) {
                return true;
            }
            return false;
        }
    }

    /**
     * Returns true if a shift is matched for a given filer, so that it is shown in the schedule
     * Else, returns false and is not shown in the schedule
     * @param filteredTags The list of selected groups in the filter
     * @param filteredMembers The list of selected members in the filter
     * @param selectedShiftFilters List of selected shift filters
     * @param shift The shift for which we are checking if the filter is matching
     */
    public static isDisplayableShiftForFilter(
        filteredTags: Array<ITagEntity>,
        filteredMembers: Array<IMemberEntity>,
        selectedShiftFilters: Array<ShiftFilterType>,
        shift: IShiftEntity
    ): boolean {
        const currentMemberId = shift && shift.memberId;
        const tagId = ShiftUtils.getTagIdFromShift(shift);
        const isFilteredMembersEmpty = !filteredMembers || filteredMembers.length === 0;
        const isFilteredTagsEmpty = !filteredTags || filteredTags.length === 0;
        const isShiftFiltersEmpty = !selectedShiftFilters || selectedShiftFilters.length === 0;
        const isTimeOffEvent = ShiftUtils.isTimeOffEvent(shift);
        const hasConflict = ScheduleGridUtils.hasConflicts(shift);

        // If there are no filters, show the shift.
        if (isFilteredMembersEmpty && isFilteredTagsEmpty && isShiftFiltersEmpty) {
            return true;
        }

        // if Shift filter is selected, show shift only if it has the right filter. For example, show Time off shift only if Time off filter is selected
        if (!isShiftFiltersEmpty) {
            if (hasConflict && ScheduleGridUtils.isConflictFilterSelected(selectedShiftFilters)) {
                return this.isDisplayableShiftForConflictFilter(filteredTags, filteredMembers, shift);
            }
            // return false if the current event is Timeoff event and time off filter is not selected or
            // return false if current event is shift and active shifts is not selected
            if (
                (isTimeOffEvent && !ScheduleGridUtils.isTimeoffsShiftFilterSelected(selectedShiftFilters)) ||
                (!isTimeOffEvent && !ScheduleGridUtils.isActiveShiftsShiftFilterSelected(selectedShiftFilters))
            ) {
                return false;
            } else if (isFilteredMembersEmpty && isFilteredTagsEmpty) {
                // else, if none of the other filters exist, just return true
                return true;
            }
        }

        // If the shifts belongs to one of the selected groups, then show the shift
        if (!isFilteredTagsEmpty && filteredTags.find(tag => tag.id === tagId)) {
            return true;
        }

        // if the shifts is timeOff shifts, it may not have tag data, hence check if the member is part of tag
        if (
            shift.shiftType === ShiftTypes.Absence &&
            !isFilteredTagsEmpty &&
            filteredTags.find(tag => tag.memberIds.some(memberId => memberId === currentMemberId))
        ) {
            return true;
        }

        // If the shift belongs to one of the seleced members, then show the shift
        if (!isFilteredMembersEmpty && filteredMembers.find(member => member.id === shift.memberId)) {
            return true;
        }

        // Otherwise don't show the shift
        return false;
    }

    /**
     * Returns true if two shifts have overlapping time range.
     * @param shift1
     * @param shift2
     * @returns true is two shifts are conflicting, else false
     */
    public static areConflictingShifts(shift1: IShiftEntity, shift2: IShiftEntity): boolean {
        if (!shift1 || !shift2) {
            return false;
        }

        return ConflictUtils.areTwoDatetimesConflicting(shift1.startTime, shift1.endTime, shift2.startTime, shift2.endTime);
    }

    public static getShiftByShiftId(shiftId: string): IBaseShiftEntity {
        if (!shiftId) {
            return null;
        }
        return ShiftStore().shifts && ShiftStore().shifts.get(shiftId);
    }

    /**
     * returns true if the shift Id is the id of a working shift
     * @param shiftId shiftId
     */
    public static isWorkingShiftByShiftId(shiftId: string): boolean {
        if (!shiftId) {
            return false;
        }

        const shift = ShiftUtils.getShiftByShiftId(shiftId);
        return ShiftUtils.isWorkingShift(shift);
    }

    /**
     * Returns the shift day of the week in lowercase ie 'monday'
     * @shiftStartTime - the start time of the shift
     */
    public static getShiftDayOfWeek(shiftStartTime: Moment): string {
        return shiftStartTime ? DateTimeFormatter.getDateTimeAsString(shiftStartTime, DateTimeFormatType.Date_Day).toLocaleLowerCase() : "";
    }
}
