import * as moment from "moment";
import ConflictUtils from "sh-application/utility/ConflictUtils";
import getDownloadErrorFile from "sh-application/components/importSchedule/lib/actions/getDownloadErrorFile";
import IMemberServiceEntity from "sh-models/member/IMemberServiceEntity";
import MemberUtils from "sh-application/utility/MemberUtils";
import NoteEntity from "sh-models/note/NoteEntity";
import processCopyScheduleSyncNotification from "sh-application/components/copyDateRange/lib/actions/processCopyScheduleSyncNotification";
import processUndoSyncNotification from "sh-application/components/copyDateRange/lib/actions/processUndoSyncNotification";
import RestClient from "sh-rest-client";
import schedulesViewStateStore from "sh-application/components/schedules/lib/store/store";
import setGlobalMessage from "sh-application/actions/setGlobalMessage";
import ShiftEntity from "sh-models/shift/ShiftEntity";
import StringsStore from "sh-strings/store";
import TimeClockUtils from "sh-application/utility/TimeClockUtils";
import { appViewState } from "sh-application/store/index";
import { AxiosResponse } from "axios";
import {
    ConflictDismissEntity,
    IConflictDismissEntity,
    IImportScheduleJobResult,
    IJobEntity,
    IMemberEntity,
    ImportJobStatus,
    INoteServiceEntity,
    IOpenShiftServiceEntity,
    IShiftRequestEntity,
    IShiftServiceEntity,
    ITagEntity,
    ITeamInfoEntity,
    ITeamSettingEntity,
    ITimeClockEntity,
    ITimeOffReasonEntity,
    IUserSettingsEntity,
    JobStatus,
    MemberEntity,
    OpenShiftEntity,
    ScheduleServiceModel,
    ShiftRequestEntity,
    ShiftRequestTypes,
    TagEntity,
    TeamDetailsResponse,
    TeamSettingEntity,
    TimeClockEntity,
    UserSettingsEntity
    } from "sh-models";
import {
    DataServices,
    ECSConfigKey,
    ECSConfigService,
    InstrumentationService,
    MemberDataService,
    NoteDataService,
    ShiftDataService,
    TagDataService,
    TeamDataService,
    TeamSettingDataService,
    TimeClockDataService,
    TimeOffReasonDataService,
    UserDataService
    } from "sh-services";
import { getGenericEventPropertiesObject, InstrumentationEventPropertyInterface } from "sh-instrumentation";
import { importScheduleDialogViewState, setImportScheduleJob, setImportScheduleJobResult } from "sh-application/components/importSchedule/lib";
import { MessageBarType } from "@fluentui/react";
import { Moment } from "moment";
import { refreshConflictsInView, setupConflicts } from "sh-application/components/schedules/lib";
import { removeDismissedConflictEntity, setDismissConflicts, syncCreateConflictDismissal } from "sh-stores/sh-conflict-store";
import { setGlobalMessageFromException, StaffHubHttpError } from "sh-application";
import { StaffHubErrorCodes } from "./StaffHubErrorCodes";
import { TeamStore } from "sh-team-store";
import { TimeClockEntryStore } from "sh-stores/sh-timeclock-store";
import { trace } from "owa-trace";
import { updateShiftRequestsInStore } from "sh-shiftrequests-store";
import { userSettingsUpdated } from "sh-stores";

const SYNC_METHODS = {
        /// <summary>
        /// The users locale has been changed
        /// </summary>
        UpdateUserLocale: "update_user_locale",

        /// <summary>
        /// User settings were updated
        /// </summary>
        UpdateUserSettings: "update_user_settings",

        /// <summary>
        /// A team setting was updated
        /// </summary>
        UpdateTeamSetting: "update_team_setting",

        /// <summary>
        /// New team settings were created/updated
        /// </summary>
        UpdateTeamSettings: "team_settings_updated",

        /// <summary>
        /// A new user conversation was created
        /// </summary>
        NewUserConversation: "new_user_conversation",

        /// <summary>
        /// A new message was created
        /// </summary>
        NewUserMessage: "new_user_message",

        /// <summary>
        /// Participants were added to the conversation
        /// </summary>
        NewUserParticipants: "new_user_participants",

        /// <summary>
        /// A participant left the conversation
        /// </summary>
        LeaveUserConversation: "user_participant_left",

        /// <summary>
        /// The user has read the conversation
        /// </summary>
        UserConversationRead: "user_conversation_read",

        /// <summary>
        /// The user has updated their conversation options
        /// </summary>
        UserConversationOptionsUpdated: "user_conversation_options_updated",

        /// <summary>
        /// The user has updated the title of the conversation
        /// </summary>
        UserConversationTitleUpdated: "user_conversation_title_updated",

        /// <summary>
        /// User was invited to a team
        /// </summary>
        NewInvite: "new_invite",

        /// <summary>
        /// A new team was created
        /// </summary>
        NewTeam: "new_team",

        /// <summary>
        /// A new role was created
        /// </summary>
        NewRole: "new_role",

        /// <summary>
        /// A new tag was created
        /// </summary>
        NewTag: "new_tag",

        /// <summary>
        /// A tag was updated
        /// </summary>
        TagUpdated: "tag_updated",

        /// <summary>
        /// A tag was deleted
        /// </summary>
        TagDeleted: "tag_deleted",

        /// <summary>
        /// A new member was created
        /// </summary>
        NewMember: "new_member",

        /// <summary>
        /// A new shift was created
        /// </summary>
        NewShift: "new_shift",

        /// <summary>
        /// New shifts were created
        /// </summary>
        NewShifts: "new_shifts",

        /// <summary>
        /// A shift was deleted
        /// </summary>
        ShiftDeleted: "shift_deleted",

        /// <summary>
        /// Shift were deleted
        /// </summary>
        ShiftsDeleted: "shifts_deleted",

        /// <summary>
        /// A shift was updated
        /// </summary>
        ShiftUpdated: "shift_updated",

        /// <summary>
        /// Shift were updated
        /// </summary>
        ShiftsUpdated: "shifts_updated",

        /// <summary>
        /// A new schedule was created
        /// </summary>
        NewSchedule: "new_schedule",

        /// <summary>
        /// A schedule was updated
        /// </summary>
        ScheduleUpdated: "schedule_updated",

        /// <summary>
        /// A schedule was deleted
        /// </summary>
        ScheduleDeleted: "schedule_deleted",

        /// <summary>
        /// A new schedule has been published
        /// </summary>
        SchedulePublished: "schedule_published",

        /// <summary>
        /// Schedule is withdrawn
        /// </summary>
        DatesWithdrawn: "dates_withdrawn",

        /// <summary>
        /// Swap or Handoff a shift
        /// </summary>
        CreateSwapHandoffShift: "create_swap_handoff",

        /// <summary>
        /// Swap or Handoff a shift
        /// </summary>
        TimeOffRequest: "time_off_request",

        /// <summary>
        /// A team was updated
        /// </summary>
        TeamUpdated: "team_updated",

        /// <summary>
        /// A role was updated
        /// </summary>
        RoleUpdated: "role_updated",

        /// <summary>
        /// A member was updated
        /// </summary>
        MemberUpdated: "member_updated",

        /// <summary>
        /// Members were updated
        /// </summary>
        MembersUpdated: "members_updated",

        /// <summary>
        /// A team was deleted
        /// </summary>
        TeamDeleted: "team_deleted",

        /// <summary>
        /// A team's file was updated
        /// </summary>
        FilesUpdated: "team_files_updated",

        /// <summary>
        /// A role was deleted
        /// </summary>
        RoleDeleted: "role_deleted",

        /// <summary>
        /// A member was deleted
        /// </summary>
        MemberDeleted: "member_deleted",

        /// <summary>
        /// A receiver accepted a shift Swap or handoff
        /// </summary>
        SwapHandoffReceiverAccept: "swap_handoff_receiver_accepted",

        /// <summary>
        /// A receiver declined a shift Swap or handoff
        /// </summary>
        SwapHandoffReceiverDecline: "swap_handoff_receiver_declined",

        /// <summary>
        /// A manager accepted a shift Swap or handoff
        /// </summary>
        SwapHandoffManagerAccept: "swap_handoff_manager_accepted",

        /// <summary>
        /// A manager declined a shift Swap or handoff
        /// </summary>
        SwapHandoffManagerDecline: "swap_handoff_manager_declined",

        /// <summary>
        /// Shift requests were deleted
        /// </summary>
        ShiftRequestsDeleted: "shift_requests_deleted",

        /// <summary>
        /// Shift request created
        /// </summary>
        NewShiftRequest: "new_shift_request",

        /// <summary>
        /// Shift request updated
        /// </summary>
        ShiftRequestUpdated: "shift_request_updated",

        /// <summary>
        /// Shift request was deleted
        /// </summary>
        ShiftRequestDeleted: "shift_request_deleted",

        /// <summary>
        /// The user has read the shift request
        /// </summary>
        ShiftRequestRead: "shift_request_read",

        /// <summary>
        /// A new note was created
        /// </summary>
        NewNote: "new_note",

        /// <summary>
        /// A note was deleted
        /// </summary>
        NoteDeleted: "note_deleted",

        /// <summary>
        /// New notes were created
        /// </summary>
        NewNotes: "new_notes",

        /// <summary>
        /// A note was deleted
        /// </summary>
        NoteUpdated: "note_updated",

        /// <summary>
        /// Application settings updated
        /// </summary>
        UpdateAppSettings: "app_settings_updated",

        /// <summary>
        /// WorkPreferencesDays updated
        /// </summary>
        UpdateWorkPreferences: "work_preferences_updated",

        /// <summary>
        /// TimeOffReasons update
        /// </summary>
        UpdateTimeOffReasons: "time_off_reasons_updated",

        /// <summary>
        /// Push notification ping
        /// </summary>
        PushNotificationPing: "push_notification_ping",

        /// <summary>
        /// ActivityFeedItem read
        /// </summary>
        ActivityFeedItemRead: "activity_feed_item_read",

        /// <summary>
        /// The yammer announcement message
        /// </summary>
        YammerAnnouncementMessage: "yammer_announcement_msg",

        /// <summary>
        /// ActivityFeedItem unseen count update
        /// </summary>
        ActivityFeedSeen: "activity_feed_seen",

        /// <summary>
        /// New Announcement Message
        /// </summary>
        AnnouncementMessage: "announcement_msg",

        /// <summary>
        /// Announcement deleted
        /// </summary>
        AnnouncementDeletedMessage: "announcement_deleted",

        /// <summary>
        /// New Conversation
        /// </summary>
        NewConversation: "new_conversation",

        /// <summary>
        /// New Message
        /// </summary>
        NewMessage: "new_message",

        /// <summary>
        /// New Participants
        /// </summary>
        NewParticipants: "new_participants",

        /// <summary>
        /// Participant left the conversation
        /// </summary>
        ParticipantLeft: "participant_left",

        /// <summary>
        /// Conversation read
        /// </summary>
        ConversationRead: "conversation_read",

        /// <summary>
        /// Conversation Options Updated
        /// </summary>
        ConversationOptionsUpdated: "conversation_options_updated",

        /// <summary>
        /// Conversation Title Updated
        /// </summary>
        ConversationTitleUpdated: "conversation_title_updated",

        /// <summary>
        /// Timeclock Created
        /// </summary>
        TimeClockCreated: "timeclock_created",

        /// <summary>
        /// Timeclock Updated
        /// </summary>
        TimeClockUpdated: "timeclock_updated",

        /// <summary>
        /// Timeclock Enabled
        /// </summary>
        TimeClockEnabled: "timeclock_enabled",

        /// <summary>
        /// Task Created
        /// </summary>
        TaskCreated: "task_created",

        /// <summary>
        /// Task Updated
        /// </summary>
        TaskUpdated: "task_updated",

        /// <summary>
        /// Task Deleted
        /// </summary>
        TaskDeleted: "task_deleted",

        /// <summary>
        /// New Open shift created
        /// </summary>
        OpenShiftCreated: "open_shift_created",

        /// <summary>
        /// New Open shifts created
        /// </summary>
        OpenShiftsCreated: "open_shifts_created",

        /// <summary>
        /// Open shift updated
        /// </summary>
        OpenShiftUpdated: "open_shift_updated",

        // <summary>
        /// Open shifts updated
        /// </summary>
        OpenShiftsUpdated: "open_shifts_updated",

        /// <summary>
        /// Open shift deleted
        /// </summary>
        OpenShiftDeleted: "open_shift_deleted",

        /// <summary>
        /// Open shifts deleted
        /// </summary>
        OpenShiftsDeleted: "open_shifts_deleted",

        /// <summary>
        /// A new open shift request was created
        /// </summary>
        OpenShiftRequestCreated: "open_shift_request_created",

        /// <summary>
        /// Manager approved an open shift request
        /// </summary>
        OpenShiftRequestManagerAccept: "open_shift_request_manager_accept",

        /// <summary>
        /// A manager declined an open shift request
        /// </summary>
        OpenShiftRequestManagerDecline: "open_shift_request_manager_declined",

        /// <summary>
        /// Import job result status from large import
        /// </summary>
        ImportStatus: "import_status",

        /// <summary>
        /// Copy schedule job status from large copy
        /// </summary>
        CopyScheduleStatus: "copyschedule_status",

        /// <summary>
        /// The notification type for Undo Copy Schedule
        /// </summary>
        UndoCopyScheduleStatus: "undocopyschedule_status",

        /// <summary>
        /// The notification type for Conflict Management conflict created
        /// </summary>
        CreateConflictDismissal: "create_conflict_dismissal",

        /// <summary>
        /// The notification type for Conflict Management conflict dismissal deleted
        /// </summary>
        DeleteConflictDismissals: "delete_conflict_dismissals"
};

interface StaffHubNotificationParams {
    member?: IMemberServiceEntity;
    shift?: IShiftServiceEntity;
    shifts?: Array<IShiftServiceEntity>;
    isShiftPublished?: boolean;
    team?: ITeamInfoEntity;
    activityType?: string;
    tag?: ITagEntity;
    teamSetting?: ITeamSettingEntity;
    teamSettings?: Array<ITeamSettingEntity>;
    timeOffReasonsAdded?: Array<ITimeOffReasonEntity>;
    timeOffReasonsDeleted?: Array<ITimeOffReasonEntity>;
    timeOffReasonsUpdated?: Array<ITimeOffReasonEntity>;
    note?: INoteServiceEntity;
    userSettings?: IUserSettingsEntity;
    shiftRequest?: IShiftRequestEntity;
    updatedShiftRequest?: IShiftRequestEntity;
    updatedShiftRequests?: Array<IShiftRequestEntity>;
    schedule?: ScheduleServiceModel;
    startTime?: Moment;
    endTime?: Moment;
    openShift?: IOpenShiftServiceEntity;
    openShifts?: Array<IOpenShiftServiceEntity>;
    membersToAdd: Array<IMemberServiceEntity>;
    membersToUpdate: Array<IMemberServiceEntity>;
    membersToDelete: Array<IMemberServiceEntity>;
    status?: JobStatus;
    result?: IImportScheduleJobResult;
    jobId?: string;
    timeClockV2Entry: ITimeClockEntity;
    conflictDismissal?: IConflictDismissEntity;
    conflictDismissals?: Array<IConflictDismissEntity>;
}

interface StaffHubNotification {
    method?: string;
    params?: StaffHubNotificationParams;
    activityFeedId?: string;
    tenantId?: string;
    teamId?: string;
}

class SyncService {
    private _pendingSync = false;
    private _doExtraSync = false;
    private _resetNextSync = false;
    private _syncTimer: any = undefined;
    private _lastSyncTimestamp: number = 0;
    private readonly MIN_SYNC_TIMER_DURATION = 60 * 1000; // 1 minute
    private notificationsToDeDuplicate: Map<string, boolean> = new Map<string, boolean>(); // Maintain the list of notifications that need to checked to de-duplicate

    /**
     * Returns true if the notification team id is the same as the users current team
     *
     * @param teamId Team id of notification
     * @returns {boolean}
     */
    private isCurrentTeam(teamId: string): boolean {
        return !!TeamStore().team && (TeamStore().team.id === teamId);
    }

    private async handleNotifications(notifications: Array<string>) {
        const currentUser: IMemberEntity = TeamStore() && TeamStore().me;
        const conflictManagementDismissalEnabled: boolean = ConflictUtils.isConflictEnabledForAdminInDateRange(MemberUtils.isAdmin(currentUser))
                            && ECSConfigService.isECSFeatureEnabled(ECSConfigKey.EnableConflictsDismissal);

        let notificationHandlerPromises: Promise<any>[] = [];
        for (let i = 0; i < notifications.length; i++) {
            const notification: StaffHubNotification = JSON.parse(notifications[i]);
            trace.info("Processing Sync Notification: " + notification.method + ", activityFeedId = " + notification.activityFeedId);
            switch (notification.method) {

                case SYNC_METHODS.MembersUpdated:
                    if (notification.params) {
                        let updatedMembers: IMemberEntity[] = [];
                        if (notification.params.membersToAdd && notification.params.membersToAdd.length) {
                            for (let i = 0; i < notification.params.membersToAdd.length; i++) {
                                updatedMembers.push(MemberEntity.fromJson(notification.params.membersToAdd[i]));
                            }
                        }
                        if (notification.params.membersToUpdate && notification.params.membersToUpdate.length) {
                            for (let i = 0; i < notification.params.membersToUpdate.length; i++) {
                                updatedMembers.push(MemberEntity.fromJson(notification.params.membersToUpdate[i]));
                            }
                        }
                        if (notification.params.membersToDelete && notification.params.membersToDelete.length) {
                            for (let i = 0; i < notification.params.membersToDelete.length; i++) {
                                updatedMembers.push(MemberEntity.fromJson(notification.params.membersToDelete[i]));
                            }
                        }
                        await MemberDataService.onUpdateMembers(updatedMembers);
                    }
                    break;

                case SYNC_METHODS.MemberUpdated:
                case SYNC_METHODS.NewMember:
                    if (notification.params && notification.params.member) {
                        let member = MemberEntity.fromJson(notification.params.member);
                        await MemberDataService.onUpdateMembers([member]);
                    }
                    break;

                case SYNC_METHODS.MemberDeleted:
                    if (notification.params && notification.params.member) {
                        let member = MemberEntity.fromJson(notification.params.member);
                        await MemberDataService.onUpdateMembers([member]);
                    } else {
                        trace.warn("Member deleted sync action called without member data");
                    }
                    break;

                case SYNC_METHODS.UpdateTeamSetting:
                    if (notification.params && notification.params.teamSetting) {
                        const teamSetting = TeamSettingEntity.fromJson(notification.params.teamSetting);
                        await TeamSettingDataService.onUpdateTeamSetting(teamSetting);
                    }
                    break;
                case SYNC_METHODS.ImportStatus:
                    const viewState = importScheduleDialogViewState();
                    const reachedMaxRetries: boolean = viewState.reachedMaxRetries; // indicates if we have stopped polling for a job status
                    const dialogOpen: boolean = viewState.dialogOpen;
                    const importStrings = StringsStore().registeredStringModules.get("importScheduleDialog").strings;
                    let alertString = "";
                    if (this.isCurrentTeam(notification.teamId) && (reachedMaxRetries || !dialogOpen)) {
                        let eventProperties: Array<InstrumentationEventPropertyInterface> = [];
                        const job: IJobEntity = {
                            jobId: notification.params.jobId,
                            status: notification.params.status,
                            result: notification.params.result
                        };
                        setImportScheduleJob(job);
                        setImportScheduleJobResult(notification.params.result);
                        eventProperties.push(getGenericEventPropertiesObject(InstrumentationService.properties.ImportExcelSyncNotificationReceived, true));
                        // if dialog is closed and we dont have a job status show message bar with job status otherwise dialog is open and will update because job status is set above
                        if (!dialogOpen && viewState.importScheduleInProgress) {
                            // display message bar based on import job status
                            if (job.status === JobStatus.Success) {
                                alertString = importStrings.get("importSuccess");
                                setGlobalMessage(appViewState().globalMessageViewState, alertString, MessageBarType.success, null, null, false, false);
                            }
                            if (job.status === JobStatus.Failure) {
                                alertString = job.result.message ? job.result.message : importStrings.get("importFailure");
                                setGlobalMessage(appViewState().globalMessageViewState, alertString, MessageBarType.error, null, null, false, false);
                            }
                            if ( job.result.importStatus === ImportJobStatus.PartialSuccess ) {
                                alertString = importStrings.get("importPartialSuccess");
                                const actionButtonTitle: string = importStrings.get("importMessageBarDownloadLinkText");
                                setGlobalMessage(appViewState().globalMessageViewState, alertString, MessageBarType.severeWarning, actionButtonTitle, this.downloadFile, false, false);
                            }
                            eventProperties.push(getGenericEventPropertiesObject(InstrumentationService.properties.ImportExcelBannerMessageReceived, true));
                            InstrumentationService.logEvent(InstrumentationService.events.ImportExcel, eventProperties);
                        }
                    }
                    break;
                case SYNC_METHODS.CopyScheduleStatus:
                    if (this.isCurrentTeam(notification.teamId)) {
                        const job: IJobEntity = {
                            jobId: notification.params.jobId,
                            status: notification.params.status,
                            result: notification.params.result
                        };
                        processCopyScheduleSyncNotification(job);
                    }
                    break;
                case SYNC_METHODS.UndoCopyScheduleStatus:
                    if (this.isCurrentTeam(notification.teamId)) {
                        const job: IJobEntity = {
                            jobId: notification.params.jobId,
                            status: notification.params.status,
                            result: notification.params.result
                        };
                        processUndoSyncNotification(job);
                    }
                    break;
                case SYNC_METHODS.UpdateTeamSettings:
                    if (notification.params && notification.params.teamSettings && notification.params.teamSettings.length) {
                        for (const teamSettingJson of notification.params.teamSettings) {
                            // This notification is rarely triggered (only when a new setting type is added to a team)
                            // So we're going to handle this sequentially for simplicity
                            const teamSetting = TeamSettingEntity.fromJson(teamSettingJson);
                            await TeamSettingDataService.onUpdateTeamSetting(teamSetting);
                        }
                    }
                    break;

                case SYNC_METHODS.NewTeam:
                    if (notification.params && notification.params.team) {
                        const teamDetails = TeamDetailsResponse.fromJson(notification.params);
                        await TeamDataService.onNewTeam(teamDetails);
                    }
                    break;

                case SYNC_METHODS.TeamUpdated:
                    await TeamDataService.onUpdateTeam(notification.params.team);
                    break;

                case SYNC_METHODS.TeamDeleted:
                    await TeamDataService.onDeleteTeam(notification.teamId);
                    break;

                case SYNC_METHODS.UpdateTimeOffReasons:
                    if (notification.params) {
                        await TimeOffReasonDataService.onUpdateTimeOffReasons(notification.params.timeOffReasonsAdded,
                            notification.params.timeOffReasonsUpdated,
                            notification.params.timeOffReasonsDeleted);
                    }
                    break;

                case SYNC_METHODS.NewShift:
                case SYNC_METHODS.ShiftUpdated:
                    if (notification.params && notification.params.shift) {
                        const shift = ShiftEntity.fromJson(notification.params.shift);
                        ShiftDataService.updateShiftsInStorage([shift], false /* isOptimisticUpdate */);
                    } else {
                        trace.warn( notification.method + " sync action called without shift data");
                    }
                    break;

                case SYNC_METHODS.NewShifts:
                case SYNC_METHODS.ShiftsUpdated:
                    if (notification.params && notification.params.shifts) {
                        const shiftsToUpdate = notification.params.shifts.map((shiftJson) => ShiftEntity.fromJson(shiftJson));
                        ShiftDataService.updateShiftsInStorage(shiftsToUpdate, false /* isOptimisticUpdate */);
                    } else {
                        trace.warn(notification.method + " sync action called without shift data");
                    }
                    break;

                case SYNC_METHODS.ShiftDeleted:
                    if (notification.params && notification.params.shift) {
                        const shift = ShiftEntity.fromJson(notification.params.shift);
                        ShiftDataService.deleteShiftsInStorage([shift], false /* isOptimisticUpdate */);
                    } else {
                        trace.warn("Delete Shift sync action called without shift data");
                    }
                    break;

                case SYNC_METHODS.ShiftsDeleted:
                    if (notification.params && notification.params.shifts) {
                        const shiftsToDelete = notification.params.shifts.map((shiftJson) => ShiftEntity.fromJson(shiftJson));
                        ShiftDataService.deleteShiftsInStorage(shiftsToDelete, false /* isOptimisticUpdate */);
                    } else {
                        trace.warn("Delete Shifts sync action called without shift data");
                    }
                    break;

                case SYNC_METHODS.NewNote:
                case SYNC_METHODS.NoteUpdated:
                    if (notification.params && notification.params.note) {
                        NoteDataService.updateNotesInStorage([NoteEntity.fromJson(notification.params.note)], false /* isOptimisticUpdate */);
                    } else {
                        trace.warn("Update note sync action called without note data");
                    }
                    break;

                case SYNC_METHODS.NoteDeleted:
                    if (notification.params && notification.params.note) {
                        NoteDataService.deleteNotesInStorage([NoteEntity.fromJson(notification.params.note)], false /* isOptimisticUpdate */);
                    } else {
                        trace.warn("Delete note sync action called without note data");
                    }
                    break;

                case SYNC_METHODS.NewNotes:
                    // TODO: figure if this is still relevant
                    break;

                case SYNC_METHODS.NewTag:
                case SYNC_METHODS.TagUpdated:
                    if (notification.params && notification.params.tag) {
                        await TagDataService.saveTag(TagEntity.fromJson(notification.params.tag), /*isNewTag*/ false, /*optimistic*/ false, /*setIsFocused*/ false, /*updateCacheOnly*/ true);
                    } else {
                        trace.warn("Update tag sync action called without tag data");
                    }
                    break;

                case SYNC_METHODS.TagDeleted:
                    if (notification.params && notification.params.tag) {
                        await TagDataService.saveTag(TagEntity.fromJson(notification.params.tag), /*isNewTag*/ false, /*optimistic*/ false, /*setIsFocused*/ false, /*updateCacheOnly*/ true);
                    } else {
                        trace.warn("Delete note sync action called without note data");
                    }
                    break;

                case SYNC_METHODS.UpdateUserSettings:
                    if (notification.params && notification.params.userSettings) {
                        userSettingsUpdated(UserSettingsEntity.fromJson(notification.params.userSettings));
                    } else {
                        trace.warn("User Settings sync action called without settings data");
                    }
                    break;

                case SYNC_METHODS.CreateSwapHandoffShift:
                case SYNC_METHODS.SwapHandoffReceiverAccept:
                case SYNC_METHODS.SwapHandoffReceiverDecline:
                case SYNC_METHODS.SwapHandoffManagerDecline:
                case SYNC_METHODS.ShiftRequestRead:
                case SYNC_METHODS.OpenShiftRequestCreated:
                case SYNC_METHODS.OpenShiftRequestManagerDecline:
                if (this.isCurrentTeam(notification.teamId)) {
                    if (notification.params && notification.params.shiftRequest) {
                        const shiftRequestToAddOrUpdate: IShiftRequestEntity = ShiftRequestEntity.fromJson(notification.params.shiftRequest);
                        updateShiftRequestsInStore([shiftRequestToAddOrUpdate]);
                    } else {
                        trace.warn("ShiftRequest create/update sync action called without shift request data");
                    }
                }
                break;
                case SYNC_METHODS.OpenShiftRequestManagerAccept:
                case SYNC_METHODS.NewShiftRequest:
                case SYNC_METHODS.ShiftRequestUpdated:
                case SYNC_METHODS.ShiftRequestDeleted: // Delete sets the state to cancelled, doesn't delete the entity. Should be handled same as update
                    if (notification.params && notification.params.updatedShiftRequest) {
                        const shiftRequestToAddOrUpdate: IShiftRequestEntity = ShiftRequestEntity.fromJson(notification.params.updatedShiftRequest);
                        if (this.isCurrentTeam(notification.teamId) || this.isCrossLocationRequestSenderTeam(shiftRequestToAddOrUpdate)) {
                            updateShiftRequestsInStore([shiftRequestToAddOrUpdate]);
                        }
                    }else {
                        trace.warn("ShiftRequest update sync action called without shift request data");
                    }
                    break;
                case SYNC_METHODS.SwapHandoffManagerAccept:
                case SYNC_METHODS.ShiftRequestsDeleted: // Delete sets the state to cancelled, doesn't delete the entity. Should be handled same as update
                    if (notification.params && notification.params.updatedShiftRequests) {
                        let shiftRequestsToAddOrUpdate: IShiftRequestEntity[] = notification.params.updatedShiftRequests.map(shiftRequestJson => ShiftRequestEntity.fromJson(shiftRequestJson));
                        if (this.isCurrentTeam(notification.teamId)) {
                            updateShiftRequestsInStore(shiftRequestsToAddOrUpdate);
                        }else {   // Check if requests are from cross location team
                            shiftRequestsToAddOrUpdate?.forEach((request) => {
                                if (this.isCurrentTeam(request.senderTeamId) && request.requestType === ShiftRequestTypes.CrossLocationOpen) {
                                    updateShiftRequestsInStore([request]);
                                }
                            });
                        }
                    }else {
                        trace.warn("ShiftRequest delete/update sync action called without shift requests data");
                    }
                    break;
                case SYNC_METHODS.SchedulePublished:
                    if (notification.params && notification.params.schedule && notification.params.schedule.startTime && notification.params.schedule.endTime) {
                        const scheduleStart: Moment =  moment(notification.params.schedule.startTime);
                        const scheduleEnd: Moment = moment(notification.params.schedule.endTime);

                        // Create a unique key for the publish/withdraw notifications
                        const notificationKeyForDeDuplication: string = SYNC_METHODS.SchedulePublished + "-" + notification.teamId + "-" + scheduleStart.valueOf().toString() + "-" + scheduleEnd.valueOf().toString();

                        // Do not fetch data for duplicate notifications that call GDIDR in the current list of notifications
                        if (!this.notificationsToDeDuplicate.has(notificationKeyForDeDuplication)) {

                            // Get data in date range for schedule duration (with forceLoadFromNetwork = true)
                            notificationHandlerPromises.push(
                                TeamDataService.syncDataInDateRange(notification.teamId, scheduleStart, scheduleEnd)
                                    .catch(error => {
                                        setGlobalMessageFromException(error, false /* autoDismiss */, true /* showRefreshAction */);
                                    })
                            );

                            this.notificationsToDeDuplicate.set(notificationKeyForDeDuplication, true);
                        }
                    }
                    break;

                case SYNC_METHODS.DatesWithdrawn:
                    if (notification.params && notification.params.startTime && notification.params.endTime) {
                        const scheduleStart: Moment =  moment(notification.params.startTime);
                        const scheduleEnd: Moment = moment(notification.params.endTime);

                        // Create a unique key for the publish/withdraw notification
                        const notificationKeyForDeDuplication: string = SYNC_METHODS.SchedulePublished + "-" + notification.teamId + "-" + scheduleStart.valueOf().toString() + "-" + scheduleEnd.valueOf().toString();

                        // Do not fetch data for duplicate notifications that call GDIDR in the current list of notifications
                        if (!this.notificationsToDeDuplicate.has(notificationKeyForDeDuplication)) {

                            // Get data in date range for schedule duration (with forceLoadFromNetwork = true)
                            notificationHandlerPromises.push(
                                TeamDataService.syncDataInDateRange(notification.teamId, scheduleStart, scheduleEnd)
                                .catch(error => {
                                    setGlobalMessageFromException(error, false /* autoDismiss */, true /* showRefreshAction */);
                                })
                            );
                        }
                    }
                    break;

                case SYNC_METHODS.OpenShiftsCreated:
                case SYNC_METHODS.OpenShiftsUpdated:
                    if (notification.params && notification.params.openShifts) {
                        ShiftDataService.updateOpenShiftsInStorage(notification.params.openShifts.map((openShift: IOpenShiftServiceEntity) => OpenShiftEntity.fromJson(openShift)), false /* isOptimisticUpdate */);
                    } else {
                        trace.warn( notification.method + " sync action called without openshift data");
                    }
                    break;

                case SYNC_METHODS.OpenShiftCreated:
                case SYNC_METHODS.OpenShiftUpdated:
                    if (notification.params && notification.params.openShift) {
                        ShiftDataService.updateOpenShiftsInStorage([OpenShiftEntity.fromJson(notification.params.openShift)], false /* isOptimisticUpdate */);
                    } else {
                        trace.warn( notification.method + " sync action called without openshift data");
                    }
                    break;

                case SYNC_METHODS.OpenShiftsDeleted:
                    if (notification.params && notification.params.openShifts) {
                        ShiftDataService.deleteOpenShiftsInStorage(notification.params.openShifts.map((openShift: IOpenShiftServiceEntity) => OpenShiftEntity.fromJson(openShift)), false /* isOptimisticUpdate */);
                    } else {
                        trace.warn( notification.method + " sync action called without openshift data");
                    }
                break;

                case SYNC_METHODS.OpenShiftDeleted:
                    if (notification.params && notification.params.openShift) {
                        ShiftDataService.deleteOpenShiftsInStorage([OpenShiftEntity.fromJson(notification.params.openShift)], false /* isOptimisticUpdate */);
                    } else {
                        trace.warn( notification.method + " sync action called without openshift data");
                    }
                    break;

                case SYNC_METHODS.TimeClockUpdated:
                case SYNC_METHODS.TimeClockCreated:
                    const enableTimeClockOnWeb = ECSConfigService.isECSFeatureEnabled(ECSConfigKey.EnableTimeClockOnWeb);
                    if (enableTimeClockOnWeb) {
                        InstrumentationService.logEvent(
                            InstrumentationService.events.TimeClockWeb,
                            [getGenericEventPropertiesObject(InstrumentationService.properties.EventType, InstrumentationService.values.TimeClockSyncNotificationRecieved )]);
                        const currentTimeClockEntryTime =  TimeClockEntryStore().timeClockEntry && TimeClockEntryStore().timeClockEntry.clockInEvent && TimeClockEntryStore().timeClockEntry.clockInEvent.time;
                        const newTimeClockEntryTime = notification.params.timeClockV2Entry && notification.params.timeClockV2Entry.clockInEvent && notification.params.timeClockV2Entry.clockInEvent.time;
                        const isLatestEntry = TimeClockUtils.isLatestTimeClockEntry(moment(currentTimeClockEntryTime), moment(newTimeClockEntryTime));
                        if (isLatestEntry) {
                            if (notification.params && notification.params.timeClockV2Entry) {
                                // update or add the latest time clock entry from sync notification
                                const teamId = TeamStore().teamId;
                                const timeClockEntryToAddOrUpdate: ITimeClockEntity = TimeClockEntity.fromJson(notification.params.timeClockV2Entry);
                                TimeClockDataService.updateLatestTimeClockEntry(timeClockEntryToAddOrUpdate, teamId);
                                InstrumentationService.logEvent(
                                    InstrumentationService.events.TimeClockWeb,
                                    [getGenericEventPropertiesObject(InstrumentationService.properties.EventType, InstrumentationService.values.TimeClockCreatedOrUpdated )]);
                            } else {
                                trace.warn("TimeClockUpdated create/update sync action called without time clock entry data");
                            }
                        } else {
                            trace.warn("TimeClockUpdated - entry to update is not the latest, skipping update");
                        }
                    }
                    break;
                case SYNC_METHODS.CreateConflictDismissal:
                    if (conflictManagementDismissalEnabled && notification.params) {
                        const conflictDismissal = ConflictDismissEntity.fromJson(notification.params.conflictDismissal);

                        if (conflictDismissal) {
                            // Add to dismissedConflictStore
                            setDismissConflicts([conflictDismissal] /* conflict dismissals */);

                            // remove conflict from conflict store
                            syncCreateConflictDismissal(conflictDismissal);

                            // refresh conflicts in schedule view
                            refreshConflictsInView();
                        } else {
                            trace.warn("sync action called without conflict dismissal data");
                        }
                    } else {
                        trace.warn("conflict management and conflict dismissal is not enabled");
                    }
                    break;
                case SYNC_METHODS.DeleteConflictDismissals:
                    if (conflictManagementDismissalEnabled && notification.params && notification.params.conflictDismissals) {
                        let conflictDismissals: IConflictDismissEntity[];
                        if (notification.params.conflictDismissals) {
                            conflictDismissals = (notification.params.conflictDismissals as Array<IConflictDismissEntity>).map(conflict => ConflictDismissEntity.fromJson(conflict));
                        }

                        if (conflictDismissals && conflictDismissals.length > 0) {
                            // remove dismissed conflict entity
                            conflictDismissals.forEach((dismissal: IConflictDismissEntity) => {
                                removeDismissedConflictEntity(dismissal);
                            });

                            setupConflicts(null /* shifts added */, null /* shift deleted */, null /* shifts updated */, schedulesViewStateStore().viewStartDate /* view start date */, schedulesViewStateStore().viewEndDate /* view end date */, true /* force calculate */, schedulesViewStateStore().showShiftConflicts);

                            refreshConflictsInView();
                        } else {
                            trace.warn("sync action called without conflict dismissal data");
                        }
                    } else {
                        trace.warn("conflict management and conflict dismissal is not enabled");
                    }
                    break;
                case SYNC_METHODS.UpdateUserLocale:
                case SYNC_METHODS.NewUserConversation:
                case SYNC_METHODS.NewUserMessage:
                case SYNC_METHODS.NewUserParticipants:
                case SYNC_METHODS.LeaveUserConversation:
                case SYNC_METHODS.UserConversationRead:
                case SYNC_METHODS.UserConversationOptionsUpdated:
                case SYNC_METHODS.UserConversationTitleUpdated:
                case SYNC_METHODS.NewInvite:
                case SYNC_METHODS.NewRole:
                case SYNC_METHODS.NewSchedule:
                case SYNC_METHODS.ScheduleUpdated:
                case SYNC_METHODS.ScheduleDeleted:
                case SYNC_METHODS.RoleUpdated:
                case SYNC_METHODS.RoleDeleted:
                case SYNC_METHODS.TimeOffRequest:
                case SYNC_METHODS.UpdateAppSettings:
                case SYNC_METHODS.UpdateWorkPreferences:
                case SYNC_METHODS.PushNotificationPing:
                case SYNC_METHODS.ActivityFeedItemRead:
                case SYNC_METHODS.YammerAnnouncementMessage:
                case SYNC_METHODS.ActivityFeedSeen:
                case SYNC_METHODS.AnnouncementMessage:
                case SYNC_METHODS.NewConversation:
                case SYNC_METHODS.NewMessage:
                case SYNC_METHODS.NewParticipants:
                case SYNC_METHODS.ParticipantLeft:
                case SYNC_METHODS.ConversationRead:
                case SYNC_METHODS.ConversationOptionsUpdated:
                case SYNC_METHODS.ConversationTitleUpdated:
                case SYNC_METHODS.TimeClockEnabled:
                case SYNC_METHODS.TaskCreated:
                case SYNC_METHODS.TaskUpdated:
                case SYNC_METHODS.TaskDeleted:
                case SYNC_METHODS.AnnouncementDeletedMessage:
                case SYNC_METHODS.FilesUpdated:
                    break;

                default:
                    trace.warn("unknown sync notification received - " + notification.method);
                    break;
            }
        }
        return await Promise.all(notificationHandlerPromises);
    }

    /**
     * Reset the sync queue
     */
    private async resetSyncState() {
        await DataServices.resetSyncState();
        this._resetNextSync = true;
    }

    /**
     * Handles the tab going out of sync
     */
    private async handleOutOfSync() {
        await this.resetSyncState();
        // reload the app
        window.location.replace(window.location.href);
    }

    private downloadFile = () => {
        let eventProperties: Array<InstrumentationEventPropertyInterface> = [];
        const viewState = importScheduleDialogViewState();
        const jobData: IJobEntity = viewState.job;
        if (jobData && jobData.jobId) {
            getDownloadErrorFile(jobData.jobId);
        }
        eventProperties = [getGenericEventPropertiesObject(InstrumentationService.properties.ImportExcelErrorsDownloadedFromBanner, true)];
        InstrumentationService.logEvent(InstrumentationService.events.ImportExcel, eventProperties);
    }

    /**
     * Call Sync and handle the notifications
     */
    public async getAndHandleSyncNotifications() {
        if (!this._pendingSync) {
            this._pendingSync = true;

            try {
                this.clearSyncTimer();

                const currentSyncKey: string = await UserDataService.getSessionSyncKey();
                const response: AxiosResponse = await RestClient.getSyncNotifications(currentSyncKey, this._resetNextSync);
                this._resetNextSync = false;
                try {
                    const notifications = response.data.notifications;
                    const nextSyncKey = response.data.nextSyncKey;
                    await this.handleNotifications(notifications);
                    this._lastSyncTimestamp = Date.now();

                    // If there are more than 25 notifications, probably there might be many more pending on service
                    // Service cap the number of reqeusts at 28-29. if there are more, client is supposed to fetch it again.
                    // Try to fetch recursively till everything is handled
                    if (notifications.length > 25) {
                        this._doExtraSync = true;
                    }

                    await UserDataService.setSessionSyncKey(nextSyncKey);
                } catch (err) {
                    trace.error("Error processing sync notifications: " + JSON.stringify(err));
                    // TODO - need to handle this correctly
                }

            } catch (err) {
                const error: StaffHubHttpError = err;
                trace.warn("Error during getAndHandleSyncNotifications: " + JSON.stringify(error));
                // TODO - We need a better way to handle this. Probably put up a dialog telling the user that their data is out of date and we
                // need to refresh the app.
                // Note: For changes triggered by the current client, Service will still send a sync notification and then return a ClientOutOfSync
                // for the current sync key. Need to investigate what to do for this scenario. Should Service be sending a sync notification to this client?
                // Should the client clear the current sync key when it initiates updates? We shouldn't display an error for this case.
                if (error.staffHubTopLevelErrorCode && error.staffHubTopLevelErrorCode === StaffHubErrorCodes.ClientOutOfSync) {
                    // Reset the sync key to get all pending syncs and then try syncing again
                    await this.handleOutOfSync();
                    this._doExtraSync = true;
                }
            } finally {
                this._pendingSync = false;
                // Get the minimum duration to wait until we should call sync again
                let minDurationUntilNextSyncCallMs = this.MinSyncCallFrequencyMs;
                if (this._doExtraSync) {
                    this._doExtraSync = false;
                    minDurationUntilNextSyncCallMs = 0;
                } else {
                    this.notificationsToDeDuplicate.clear();
                }
                // Start a timer to call sync again (Fallback in case we don't get SignalR notifications to sync)
                this.startSyncTimer(minDurationUntilNextSyncCallMs);
            }
        }
    }

    /**
     * The minimum frequency with which to call the sync API (if there were no SignalR notifications)
     */
    get MinSyncCallFrequencyMs(): number {
        // Enforce min 1 minute duration, in case ECS configs are broken
        return Math.max(ECSConfigService.getECSFeatureSetting(ECSConfigKey.MinSyncCallFrequencyMs), this.MIN_SYNC_TIMER_DURATION);
    }

    /**
     * Gets notified when the user's idle state has changed
     * @param isIdle If the user is idle/active
     */
    public async onUserIdleStateChange(isIdle: boolean) {
        if (isIdle) {
            // The user changed from being active to idle
            // stop the sync timer
            this.clearSyncTimer();
        } else {
            // The user changed from being idle to active
            // Restart the sync timer
            const timeElapsedSinceLastSync = Date.now() - this._lastSyncTimestamp;
            // Enforce a minimum wait time of 5 seconds until sync is called when the user resumes being active in the app
            let durationUntilNextSyncMs = Math.max(this.MinSyncCallFrequencyMs - timeElapsedSinceLastSync, 5000);
            this.startSyncTimer(durationUntilNextSyncMs);
        }
    }

    /**
     * Stop the sync timer
     */
    private clearSyncTimer() {
        if (this._syncTimer) {
            clearTimeout(this._syncTimer);
            this._syncTimer = null;
        }
    }

    /**
     * Start a timer to call sync again (Fallback in case we don't get SignalR notifications to sync)
     * @param durationToWaitUntilNextSyncMs The time to wait (in milliseconds) until sync is triggered
     */
    private startSyncTimer(durationToWaitUntilNextSyncMs: number) {
        this.clearSyncTimer();
        this._syncTimer = setTimeout(() => { this.getAndHandleSyncNotifications(); } , durationToWaitUntilNextSyncMs);
    }

    /**
     * Initializes the live-updates Service.
     */
    public async initialize(): Promise<void> {

        // We should reset the sync queue if we aren't preserving the sync state in IndexedDb
        // because we're going to be fetching all data from scratch and we don't want to get duplicate data
        // back in sync
        const enableSyncStateInIndexedDb = ECSConfigService.isECSFeatureEnabled(ECSConfigKey.EnableSyncStateInIndexedDb);

        if (!enableSyncStateInIndexedDb) {
            await this.resetSyncState();
        }

        // call sync but don't await the results
        setTimeout( async () => { await this.getAndHandleSyncNotifications(); }, 1000);
    }
    public isCrossLocationRequestSenderTeam(shiftRequest: IShiftRequestEntity): boolean {
        return this.isCurrentTeam(shiftRequest.senderTeamId) && shiftRequest.requestType === ShiftRequestTypes.CrossLocationOpen;
    }
}

const service = new SyncService();
export default service as SyncService;