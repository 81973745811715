import { mutatorAction } from "satcheljs";
import { TagStore } from "sh-tag-store";

/**
 * Sets people count for a given tag.
 */
export const incrementTagPeopleCount = mutatorAction(
    "INCREMENT_TAG_PEOPLE_COUNT",
    (tagId: string) => {
        const { tagIdToPeopleCount } = TagStore();

        if (tagId && tagIdToPeopleCount.has(tagId)) {
            const currentCount = tagIdToPeopleCount.get(tagId) || 0;
            tagIdToPeopleCount.set(tagId, currentCount + 1);
        }
    }
);
