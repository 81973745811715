import { mutatorAction } from "satcheljs";
import { TagStore } from "sh-tag-store";

/**
 * Sets people count for a given tag.
 */
export const decrementTagPeopleCount = mutatorAction(
    "DECREMENT_TAG_PEOPLE_COUNT",
    (tagId: string) => {
        const { tagIdToPeopleCount } = TagStore();

        if (tagId && tagIdToPeopleCount.has(tagId)) {
            const currentCount = tagIdToPeopleCount.get(tagId);
            tagIdToPeopleCount.set(tagId, currentCount > 0 ? currentCount - 1 : 0);
        }
    }
);
