import * as React from "react";
import * as moment from "moment";
import StringsStore from "sh-strings/store";
import { DayOfWeek, Dropdown, IDropdownOption } from "@fluentui/react";

interface StartOfWeekPickerProps {
    startDayOfWeek: DayOfWeek;
    label?: string;
    className?: string;
    onChanged?: (dayOfWeekIndex: number) => void;
    ariaLabelStartOfWeek?: string;
    disabled?: boolean;
}

/**
 * Start of week picker - Dropdown that allows the selection of a specific day of the week
 */
export default class StartOfWeekPicker extends React.Component<StartOfWeekPickerProps, any> {
    private _options: Array<{ key: number, text: string }> = [];

    constructor(props: StartOfWeekPickerProps) {
        super(props);

        this._options = this.getWeekdaysDropDownList();
    }

    /**
     * Gets Weekdays of the current locale.
     */
    private getWeekdaysDropDownList(): Array<{ key: number, text: string }> {
        let weekdays = moment.localeData(StringsStore().currentLocale).weekdays();
        let retList = new Array<{ key: number, text: string }>();
        for (let i = 0; i < weekdays.length; i++) {
            retList.push({ key: i, text: weekdays[i] });
        }

        return retList;
    }

    private onStartOfWeekChanged = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
        if (this.props.onChanged) {
            this.props.onChanged(index);
        }
    }

    render() {
        const { className, ariaLabelStartOfWeek, startDayOfWeek, label } = this.props;

        return (
            <Dropdown
                disabled={ this.props.disabled }
                label={ label }
                className={ className }
                ariaLabel={ ariaLabelStartOfWeek ? ariaLabelStartOfWeek : ""  }
                options={ this._options }
                selectedKey={ startDayOfWeek }
                onChange={ this.onStartOfWeekChanged }>
            </Dropdown>
        );
    }
}