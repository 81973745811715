import ShiftRequestUtils from "sh-application/utility/ShiftRequestUtils";
import { IGroupedOpenShiftRequestEntity } from "./IGroupedOpenShiftRequestEntity";
import {
    IShiftRequestEntity,
    ShiftRequestState,
    ShiftRequestType,
    ShiftRequestTypes
    } from "./IShiftRequestEntity";
import { Moment } from "moment";
import { ObservableMap } from "mobx";

/**
 * Class to represent the Grouped OpenShiftRequest
 */
export default class GroupedOpenShiftRequestEntity implements IGroupedOpenShiftRequestEntity {
    // New properties in grouped entity
    shiftRequests: ObservableMap<string, IShiftRequestEntity>;

    // Properties from IBaseShiftRequestEntity
    id: string;
    eTag: string;
    shiftId: string;
    requestType: ShiftRequestType;
    startTime: Moment;
    endTime: Moment;
    state: ShiftRequestState;
    lastModifiedTime: Moment;
    openShiftTeamId?: string;
    groupId: string;

    constructor(shiftRequest: IShiftRequestEntity) {
        this.shiftRequests = new ObservableMap<string, IShiftRequestEntity>();

        if (shiftRequest) {
            this.shiftRequests.set(shiftRequest.id, shiftRequest);

            this.requestType = ShiftRequestTypes.GroupedOpenShifts;
            this.id = ShiftRequestUtils.getGroupedEntityIdFromShiftIdAndState(shiftRequest.shiftId, shiftRequest.state); // Item is grouped by the OpenShift and the ShiftRequest state. Use the combination as the Id here

            // The common properties from the requests in the grouping
            this.shiftId = shiftRequest.shiftId;
            this.startTime = shiftRequest.startTime;
            this.endTime = shiftRequest.endTime;
            this.groupId = shiftRequest.groupId;
            this.lastModifiedTime = shiftRequest.lastModifiedTime;
            this.state = shiftRequest.state; // Can be WaitingOnManager/ManagerApproved/ManagerDeclined/AutoDeclined
            this.openShiftTeamId = shiftRequest.openShiftTeamId;
        }
    }

    /**
     * Adds the shift request to this group
     */
    public addShiftRequest(shiftRequest: IShiftRequestEntity): void {
        // Should only add OpenShift requests that are in the same state to this group
        if (!(ShiftRequestUtils.isRequestTypeGroupable(shiftRequest.requestType) && shiftRequest.shiftId === this.shiftId)) {
            return;
        }
        // Add the ShiftRequest to the group
        this.shiftRequests.set(shiftRequest.id, shiftRequest);

        // Update lastModifiedTime for the group
        if (!this.lastModifiedTime || this.lastModifiedTime.isBefore(shiftRequest.lastModifiedTime)) {
            this.lastModifiedTime = shiftRequest.lastModifiedTime;
        }
    }

    /**
     * Removes the shift request from this group
     */
    public removeShiftRequest(shiftRequestId: string): void {
        if (this.shiftRequests.has(shiftRequestId)) {
            const shiftRequest: IShiftRequestEntity = this.shiftRequests.get(shiftRequestId);

            if (shiftRequest.requestType !== ShiftRequestTypes.Open && shiftRequest.requestType !== ShiftRequestTypes.CrossLocationOpen) {
                return;
            }

            // Update lastModifiedTime for the group
            if (this.lastModifiedTime.isSame(shiftRequest.lastModifiedTime)) {
                this.lastModifiedTime = null;
                this.shiftRequests.forEach((shiftRequestItem: IShiftRequestEntity, key: string) => {
                    if (!this.lastModifiedTime || this.lastModifiedTime.isBefore(shiftRequestItem.lastModifiedTime)) {
                        this.lastModifiedTime = shiftRequestItem.lastModifiedTime;
                    }
                });
            }
            // Remove the ShiftRequest from the group
            this.shiftRequests.delete(shiftRequestId);
        }
    }
}
