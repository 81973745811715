import { UserProfile } from "@microsoft/shifts-models-data";
import { mutatorAction } from "satcheljs";
import { UserProfilesStore } from "sh-stores";

export default mutatorAction('UPDATE_USERPROFILES_IN_STORE', function updateUserProfilesInStore(userProfiles: UserProfile[]) {

    userProfiles.map((userProfile: UserProfile) => {
        UserProfilesStore().userprofiles.set(userProfile.id, userProfile);
    });
});
